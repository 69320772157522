import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import {DataCommService} from '../../../main/shared/services/data-comm.service';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit, OnChanges {
    @Output() positiveResp = new EventEmitter();
    @Output() negativeResp = new EventEmitter();
    @Output() crossClicked = new EventEmitter();
    confirmHeaderTxt: string;
    msg: string;
    cancelBtnTxt: string;
    confirmBtnTxt: string;
    @Input() confirmBoxData: any;
    @Input() showCrossButton = true;
    @Input() closeWithoutAction = false;
    public disableButtons: boolean = false;
    constructor(private dataComService: DataCommService, private router: Router) {
    }

    ngOnInit() {
    }

    ngOnChanges(): void {
        const featureToBeDeleted = this.dataComService.featureList.find(feature => {
            return feature.id === this.dataComService.featureId;
        });
        if (this.dataComService.featureId && featureToBeDeleted) {
            const featureNameToBeDeleted = featureToBeDeleted.title;
            this.msg = (this.confirmBoxData && this.confirmBoxData.msg) ?
            this.confirmBoxData.msg.replace('featurename', featureNameToBeDeleted) : 'Are you sure you want to continue?';
        } else {
            this.msg = (this.confirmBoxData &&
                this.confirmBoxData.msg !== undefined) ? this.confirmBoxData.msg : 'Are you sure you want to continue?';
        }
        this.confirmHeaderTxt = (this.confirmBoxData && this.confirmBoxData.confirmHeaderTxt) ?
            this.confirmBoxData.confirmHeaderTxt : 'Are you sure?';
        this.cancelBtnTxt = (this.confirmBoxData && this.confirmBoxData.cancelBtnTxt) ? this.confirmBoxData.cancelBtnTxt : 'Cancel';
        this.confirmBtnTxt = (this.confirmBoxData && this.confirmBoxData.confirmBtnTxt) ? this.confirmBoxData.confirmBtnTxt : 'Ok';
    }


    crossBtnClicked(e) {
        if (!this.closeWithoutAction) {
            this.positiveCTAClicked(e);
        } else {
            this.crossClicked.emit();
        }
    }
    positiveCTAClicked(e) {
        e.stopPropagation();
        this.positiveResp.emit();
    }

    negativeCTAClicked(e) {
        e.stopPropagation();
        if (this.router.url.includes('flowchart')) {
            this.disableButtons = true;
            this.showCrossButton = false;
            setTimeout(() => {
                this.negativeResp.emit();
            }, 1000);
        } else {
            this.negativeResp.emit();
        }
    }
}

import { Component, OnInit, OnChanges, Input, Output, EventEmitter, OnDestroy, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { DataCommService } from '../../../main/shared/services/data-comm.service';
import {DataService} from '../../../main/shared/services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { HotspotModel } from 'src/app/main/shared/models/hotspot.model';
import { PreviewModel } from 'src/app/main/shared/models/preview.model';
import { ManualShareService } from '../manual-hotspot/manual.shared.service';
import { ManualHotspotService } from '../manual-hotspot/manual-hotspot.service';
import { ClickableItemsModel } from 'src/app/main/shared/models/clickable-items.model';
import { OverlayWithContentService } from '../overlay-with-content/overlay-with-content.service';
import { AuthService } from '@core/auth.service';

@Component({
  selector: 'app-hero-frame-listing',
  templateUrl: './hero-frame-listing.component.html',
  styleUrls: ['./hero-frame-listing.component.scss'],
})
export class HeroFrameListingComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('destinationBlock', { static: false }) destinationBlockRef: ElementRef;
  @Output() frameSelected =  new EventEmitter();
  @Input() inputFeatureList: PreviewModel[];
  @Input() isMultiSelect = false;
  @Input() selectedFeature;
  @Input() noHotspotContainer = false;
  featureList: PreviewModel[];
  dataLoader = false;
  errorMsg: string;
  selectedFrame: number;
  selectedHotspot: ClickableItemsModel;
  selectedArea: HotspotModel;
  isHotspotSelected = false;
  subscriptions: Subscription[] = [];
  platform;
  sourcePage;
  apiSubscription = [];
  getDataSubscription: Subscription;
  clickableItemIndex: number;
  searchText: string;
  
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    public dataCommService: DataCommService,
    private manualShareService: ManualShareService,
    public rectangleService: ManualHotspotService,
    private router: Router,
    private overlayWithContentService: OverlayWithContentService,
    private authService: AuthService,
    private renderer: Renderer2) {
    this.isHotspotSelected = false;
    this.route.parent.parent.params.subscribe( (params: any) => {
      this.dataCommService.buildcardId = params.buildcardId;
    });
    this.subscriptions.push(this.dataCommService.selectedHotspot$.subscribe((hotspot) => {
      this.destinationBlockRef.nativeElement.scrollIntoView(); 
      this.commonSelectedHotspot(hotspot);
      this.commonSelTopFun();
    }));

    this.subscriptions.push(this.dataCommService.selectedHotspotFrame$.subscribe((hotspot) => {
      this.commonSelectedHotspot(hotspot);
    }));
    
    // show selected feature for parent
    this.subscriptions.push(this.dataCommService.selectedArea$.subscribe((hotspot) => {
      this.destinationBlockRef.nativeElement.scrollIntoView(); 
      this.commonSelectedArea(hotspot);
      this.commonSelTopFun();
    }));

    this.subscriptions.push(this.dataCommService.selectedFrame$.subscribe((hotspot) => {
      this.commonSelectedArea(hotspot);
    }));

    this.platform = this.dataCommService.platform;
    this.subscriptions.push(this.dataCommService.switchViewSource$.subscribe((newView: string) => {
      this.platform = newView;
    }));
    this.sourcePage = this.dataCommService.sourcePage();
  }

  ngOnInit() {
    if (!this.dataCommService.featureList || this.dataCommService.featureList.length === 0) {
      this.dataLoader = true;
      if (this.getDataSubscription) {
        this.getDataSubscription.unsubscribe();
      }
      this.subscriptions.push(this.dataCommService.switchRole$.subscribe(_ => {
        this.getDataSubscription = this.dataService.getDataByBuildcardId(this.dataCommService.buildcardId, this.dataCommService.platform, this.dataCommService.platformType)
          .subscribe((data: any) => {
            this.dataCommService.successHandlr(data);
            // this.featureList = this.dataCommService.featureList;
            this.setFeatureList();
            this.errorMsg = this.dataCommService.errorMsg;
            this.dataLoader = false;
          },
            (error: any) => {
              // this.dataCommService.errorHandlr(error);
              this.dataLoader = false;
      })}));
      this.apiSubscription.push(this.getDataSubscription);
    } else {
      this.setFeatureList();
    }
  }

  commonSelectedHotspot(hotspot) {
    if (hotspot.carouselObj) {
      this.selectedHotspot = hotspot.carouselObj;
      this.clickableItemIndex = hotspot.index;
      this.selectedFeature = hotspot.carouselObj.target_feature_uniq_code;
    } else {
      this.selectedHotspot = hotspot;
      this.selectedFeature = hotspot.target_feature_uniq_code;
    }
    if (hotspot) {
      this.isHotspotSelected = true;
    }
  }

  commonSelectedArea(hotspot) {
    if (hotspot.target_feature_uniq_code === undefined && hotspot.hotspotCategoryId === 5) {
      this.selectedFeature = hotspot.clickableItems[0].target_feature_uniq_code;
    } else {
      this.selectedFeature = hotspot.target_feature_uniq_code;
    }
  }

  commonSelTopFun() {
    const tInd = this.featureList.findIndex(elm => elm.uniq_code === this.selectedFeature);
      if (tInd > -1) {
        const featureToShift = this.featureList[tInd];
        this.featureList.splice(tInd, 1);
        this.featureList.unshift(featureToShift);
      }
  }

  setFeatureList() {
    if (this.inputFeatureList && this.inputFeatureList.length) {
      this.featureList = this.filterFeatureList(this.dataCommService.featureList, this.inputFeatureList);
    } else {
      this.featureList = this.dataCommService.featureList;
    }
    if (this.selectedFeature && this.selectedFeature.uniq_code) {
      this.featureList = this.featureList.filter(e => e.uniq_code != this.selectedFeature.uniq_code);
    }
  }

  ngOnChanges() {
    this.setFeatureList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach( (subscription) => {
      subscription.unsubscribe();
    });
    this.apiSubscription.forEach( (apiSub) => {
      apiSub.unsubscribe();
    });
  }

  filterFeatureList(featureList: any[], inputFeatureList: any[]): any[] {
    const tempList = [];
    for (let i = 0; i < inputFeatureList.length; i++) {
      const featureUniqueCode = inputFeatureList[i].uniq_code;
      for (let j = 0; j < featureList.length; j++) {
        if (featureUniqueCode === featureList[j].uniq_code) {
          featureList[j].clickable_item_id = inputFeatureList[i].clickable_item_id;
          featureList[j].is_selected = inputFeatureList[i].is_selected;
          tempList.push(featureList[j]);
        }
      }
    }
    return tempList;
  }

  selectFrame($event, frameData) {
    $event.stopPropagation();
    this.destinationBlockRef.nativeElement.scrollIntoView({behavior: 'smooth'}); 

    if (this.sourcePage === 'Add icon') {
      this.selectedFeature = frameData.uniq_code;
    } else {
      if (this.isMultiSelect) {
        frameData.is_selected = !frameData.is_selected;
      }
      const hotspotObj = this.rectangleService.getRectangleComponent();
      if (this.router.url.includes('edit-screen')) {
        if (hotspotObj && hotspotObj.isSelected) {
          if (!this.dataCommService.screenEdited.includes('destination')) this.dataCommService.screenEdited.push('destination');
          if (this.rectangleService.getClickableItem()) {
            this.rectangleService.getClickableItem().target_feature_uniq_code = frameData.uniq_code;
          }
        }
      } else if (this.router.url.includes('add-edit-carousel')) {
        this.overlayWithContentService.enableSaveButtonFun(false);
        this.selectedFeature = frameData.uniq_code;
        hotspotObj.clickableItems[this.clickableItemIndex].target_feature_uniq_code = frameData.uniq_code;
      } else {
        if (hotspotObj && hotspotObj.isSelected) {
          hotspotObj.hero_feature_id = frameData.id;
          hotspotObj.featureTitle = frameData.title;
          hotspotObj.target_feature_uniq_code = frameData.uniq_code;
          this.rectangleService.isNewHotspotDraw = true;
        }
      }
      this.manualShareService.changeHotspotImageFun({id: frameData.id, uniqCode: frameData.uniq_code});
    }
    this.frameSelected.emit(frameData);
  }
  trackById(index: number, item: any) {
    return item.id;
  }
}

import { Component, OnInit } from '@angular/core';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { DataService } from 'src/app/main/shared/services/data.service';
import { environment } from '@env/environment';
import { CookieService } from '@core/cookie.service';
@Component({
  selector: 'app-released-popup',
  templateUrl: './released-popup.component.html',
  styleUrls: ['./released-popup.component.scss']
})
export class ReleasedPopupComponent implements OnInit {
  constructor(public dataCommService: DataCommService, public dataService: DataService, public cookieService: CookieService) { }
  
  ngOnInit() {
  }

  closeReleasePopup(e) {
    e.stopPropagation();
    const email = this.cookieService.getCookieEmail(environment.USER_COOKIE);
    if (email) {
      const payload = {
        'email': email
      }
      this.dataService.markReleaseRead(payload).subscribe((res: any)=>{
        this.dataCommService.releasePopup = false;    
      });
    }
  }

  openPreviewLink(url: any) {
    // const email = this.cookieService.getCookieEmail(environment.USER_COOKIE);
    window.open(url, '_blank');
  }
  goToVideo(url: any) {
    window.open(url, '_blank');
  }
}

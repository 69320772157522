import { Directive, ElementRef, HostListener, Renderer2, OnDestroy, Input, OnInit } from '@angular/core';
import {DataCommService} from '../../main/shared/services/data-comm.service';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective implements OnDestroy {
  startX;
  startY;
  distX;
  distY;
  startTime;
  allowedTime = 300;
  threshold = 150;
  restraint = 20;
  elapsedTime;
  @Input('appHighlight') applyHighlight: boolean;

  constructor(private renderer: Renderer2, private el: ElementRef, private dataCommService: DataCommService) {
    this.renderer.addClass(this.el.nativeElement, 'hotspot-area');
  }
  @HostListener('document:touchstart', ['$event'])
  touchStart(event) {
    const touchobj = event.changedTouches[0];
    this.startX = touchobj.pageX;
    this.startY = touchobj.pageY;
    this.startTime = new Date().getTime();
    this.elapsedTime = new Date().getTime() - this.startTime;
  }
  @HostListener('document:touchend', ['$event'])
  touchEnd(event) {
    const touchobj = event.changedTouches[0];
    this.distX = touchobj.pageX - this.startX;
    this.distY = touchobj.pageY - this.startY;
    if (this.distX === 0) {
      if (event.target.closest('.highlight-off')) {
      } else {
        this.highlightHotpots();
      }
    }
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target.closest('.highlight-off')) {
    } else {
      this.highlightHotpots();
    }
  }
  private highlightHotpots() {
    if (this.applyHighlight) {
      this.renderer.addClass(this.el.nativeElement, 'highlight');
      setTimeout(() => {
        this.renderer.removeClass(this.el.nativeElement, 'highlight');
      }, 1000);
    }
  }

  ngOnDestroy() {
    this.el.nativeElement.removeEventListener('document:click', this.clickout);
    this.el.nativeElement.removeEventListener('document:touchstart', this.touchStart);
    this.el.nativeElement.removeEventListener('document:touchend', this.touchEnd);
  }

}

import { AuthService } from '@core/auth.service';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/main/shared/services/data.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-feedback-popup',
  templateUrl: './feedback-popup.component.html',
  styleUrls: ['./feedback-popup.component.scss']
})
export class FeedbackPopupComponent implements OnInit {
  ratingText: string;
  stars = [{ id: 1, selected: false }, { id: 2, selected: false }, { id: 3, selected: false }, { id: 4, selected: false }, { id: 5, selected: false }];
  feedBackoptions = [];
  feedBackoptiontext;
  feedbackoptionsId = [];
  selectedId: number = NaN;
  feedbackTextOptional: string;
  firstStep: boolean = true;
  otherSteps: boolean = false;
  lastStep: boolean = false;

  constructor(public dataCommService: DataCommService,
              public dataService: DataService,
              public authService: AuthService, private router: Router
  ) { }

  ngOnInit() {
    this.nullSelectedFeedBack();
  }

  nullStars() {
    this.stars.forEach(e => {
      e.selected = false;
    })
  }

  nullSelected() {

    this.feedBackoptions.forEach(element => {
      element.selected = false;
    });

    this.feedbackoptionsId = [];
  }

  nullSelectedFeedBack() {
    if(this.dataCommService.feedBackOptions && this.dataCommService.feedBackOptions.issues && this.dataCommService.feedBackOptions.features){
      this.dataCommService.feedBackOptions.issues.forEach(element => {
        element.selected = false;
      });
  
      this.dataCommService.feedBackOptions.features.forEach(element => {
        element.selected = false;
      });
    }
  }

  manageFeedbackOptions(value) {
    //0 for issues and 1 for features
    if (value == 0) {
      this.feedBackoptions = this.dataCommService.feedBackOptions.issues;
      this.feedBackoptiontext = 'What issues are you having? (You can select more than one)';
    }
    else {
      this.feedBackoptions = this.dataCommService.feedBackOptions.features;
      this.feedBackoptiontext = 'Which features do you find most useful?';
    }
  }

  manageRatingText(value) {
    switch (value) {
      case 1:
        this.ratingText = 'Very Poor';
        this.manageFeedbackOptions(0);
        break;
      case 2:
        this.manageFeedbackOptions(0);
        this.ratingText = 'Poor';

        break;
      case 3:
        this.manageFeedbackOptions(1);
        this.ratingText = 'OK';

        break;
      case 4:
        this.manageFeedbackOptions(1);
        this.ratingText = 'Good';

        break;

      case 5:
        this.manageFeedbackOptions(1);
        this.ratingText = 'Very Good';

        break;

      default:
        break;
    }
  }

  selectStars(id) {
    if (this.selectedId) {
      if (this.selectedId <= 2 && id > 2) {
        this.nullSelected();
      } else if (this.selectedId >= 3 && id < 3) {
        this.nullSelected();
      }
    }
    this.nullStars();
    this.manageRatingText(id);
    this.selectedId = id;

    for (let i = 0; i < this.stars.length; i++) {
      if (i < id) {
        if (this.otherSteps == false) {
          this.otherSteps = true;
          this.lastStep = false;
        }
        this.stars[i].selected = true;
      }
    }
    this.dataCommService.trackEvent('feedback_rating' , {
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice
    });
  }

  selectFeatureIssue(object) {

    if (!object.selected) {
      this.feedbackoptionsId.push(object.id);
    } else {
      this.feedbackoptionsId = this.feedbackoptionsId.filter(e => e != object.id);
    }
    object.selected = !object.selected;
    this.dataCommService.trackEvent('feedback_templates_selected' , {
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice
    });
  }


  showLastStep() {
    this.otherSteps = false;
    this.firstStep = false;
    this.lastStep = true;
  }

  closeWithApiCall() {
    if (this.selectedId) {
      this.submitFeedback('close');
    } else {
      this.dataCommService.openClosePopup(false);
      this.dataCommService.trackEvent('feedback_screen_closed', {
        user_id: this.dataCommService.userUniqueid,
        user_browser: this.dataCommService.userBrowser,
        user_device: this.dataCommService.userDevice
      });
    }
  }

  submitApiCall(payload, closeBtn?) {
    this.dataService.postFeedBack(payload).subscribe(response => {
  
      if (closeBtn == 'close') {
        this.dataCommService.openClosePopup(false);
      } else {
        this.showLastStep();
      }

    }, err => {
      this.showLastStep();
    })
  }

  submitFeedback(closeBtn?) {
    const user = this.authService.getLoggedInUser();
    let data = {
      user_feedback: {
        rating: this.selectedId,
        feedback_option_ids: this.feedbackoptionsId,
        feedback: this.feedbackTextOptional,
        user_email: user.email,
        studio_build_card_id: this.dataCommService.buildcardId
      }
    }

    this.submitApiCall(data, closeBtn);
    if (this.feedbackTextOptional) {
        this.dataCommService.trackEvent('addtional_feedback_provided' , {
          user_id: this.dataCommService.userUniqueid,
          user_browser: this.dataCommService.userBrowser,
          user_device: this.dataCommService.userDevice
        });
    }
    this.dataCommService.trackEvent('feedback_given', {
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice
    });
  }
}

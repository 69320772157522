import { Component, OnInit } from '@angular/core';
import { DataService } from '../main/shared/services/data.service';
import { WindowRefService } from '@core/window-ref.service';
import {DataCommService} from '../main/shared/services/data-comm.service';

@Component({
  selector: 'app-emptyboard',
  templateUrl: './emptyboard.component.html',
  styleUrls: ['./emptyboard.component.scss']
})
export class EmptyboardComponent implements OnInit {
    constructor(
    private dataService: DataService,
    private windowRef: WindowRefService,
    public dataCommService: DataCommService) { }
    private preservedParameters: string;

    ngOnInit() {
      this.preservedParameters = null;
      const url =  this.windowRef.nativeWindow.location.href;
      if (url.indexOf('?') !== -1) {
        this.preservedParameters  = url.substr(url.indexOf('?'), url.length);
      }
    }

    redirectToBuilder() {
      let redirectUrl = this.dataService.getBuilderFeatursUrl();
      if (this.preservedParameters) {
        redirectUrl = `${redirectUrl}${this.preservedParameters}`;
      }
      this.windowRef.nativeWindow.location = redirectUrl;
    }
  }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '@shared/services/utility.service';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';

@Component({
  selector: 'app-embed-share',
  templateUrl: './embed-share.component.html',
  styleUrls: ['./embed-share.component.scss']
})
export class EmbedShareComponent implements OnInit {
  shareUrl: string;
  codeCopied = false;
  constructor(
    private dataCommService: DataCommService,
    private utilityService: UtilityService,
    private router: Router
  ) { }

  ngOnInit() {
    if (this.router.url.includes('playmode')) this.dataCommService.sharingUrl = this.dataCommService.sharingUrl + '/' + this.dataCommService.platform + '/' + this.dataCommService.platformType;
    this.shareUrl = '<iframe src="' + this.dataCommService.sharingUrl + '" width="800" height="600"></iframe>';
  }
  copyAppUrl(inputElement) {
    let eventName = this.router.url.includes('playmode') ? 'play_mode_shared' : 'prototype_shared';
    this.dataCommService.trackEvent(eventName, {
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice,
      'Share type': 'embed',
      'Password protected?': this.dataCommService.isPasswordProtected ? 'Y' : 'N',
      'Social channel': ''
    });
    inputElement.disabled = false;
    this.utilityService.copyText(inputElement);
    inputElement.disabled = true;
    this.codeCopied = true;
    setInterval(() => {
      this.codeCopied = false;
    }, 1500);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'nameInitials'})
export class NameInitialsPipe implements PipeTransform {
  transform(userName: string): string {
    const nameSplit = userName.split(' ');
    if (nameSplit.length > 1) {
      return nameSplit[0].charAt(0) + nameSplit[nameSplit.length - 1].charAt(0);
    } else {
      return nameSplit[0].charAt(0);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {DataCommService} from '../../../main/shared/services/data-comm.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '@core/auth.service';
import { CookieService } from '@core/cookie.service';

@Component({
  selector: 'app-prototype-link',
  templateUrl: './prototype-link.component.html',
  styleUrls: ['./prototype-link.component.scss']
})
export class PrototypeLinkComponent implements OnInit {
  isDesigner = false;
  routerLinkPreview = '';
  routerLinkFlowchart = '';
  routerLinkWhiteBoard = '';
  routerLinkPlayMode = '';
  platform = '';

  constructor(public dataCommService: DataCommService,
    public router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private cookieService: CookieService
  ) {
    this.isDesigner = this.authService.userProfile.isDesigner;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params.device) {
        this.dataCommService.platform = params.device;
        this.platform = params.device;
      }
      if (params.platform) {
        this.dataCommService.platformType = params.platform;
      }
    });
    this.platform = this.dataCommService.platform;
    this.changeView();
    this.dataCommService.switchViewSource$.subscribe((platform: any) => {
      this.platform = platform;
      this.changeView();
    });
  }

  checkPreviewpage() {
    if (this.router.url.includes('preview')) {
      return true;
    }
    return false;
  }

  checkFlowChart(){
    if (this.router.url.includes('flowchart')) {
      return true;
    }
    return false;
  }

  checkPlayMode(){
    if (this.router.url.includes('playmode')) {
      return true;
    }
    return false;
  }


  editModeClicked(pageName, event) {
    if(pageName === 'flowchart' && (this.checkPreviewpage() || this.dataCommService.checkWhiteBoarding())) this.dataCommService.isFlowModeClicked = true;
    event.stopPropagation();
    this.dataCommService.releasePopup = false;
    this.dataCommService.maintenancePopup = false;
    if (this.dataCommService.onBoarding.whatsEditMode || this.dataCommService.onBoarding.yourFlow) {
      this.goToNextStep('whatsEditMode');
    }
    this.segmentData(pageName);
    this.dataCommService.trackEvent('edit_viewed' , {
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice
    });
  }

  prototypeModeClicked(pageName) {
    if (this.dataCommService.onBoarding.areYouDone) {
      this.goToNextStep('areYouDone');
    }
    this.segmentData(pageName);
  }

  whiteBoardClicked(event) {
    event.stopPropagation();
    window.location.href = this.routerLinkWhiteBoard;

  }
  segmentData(pageName) {
    if (pageName === 'prototype') {
      this.dataCommService.trackEvent('Preview Clicked', '');
    } else {
      this.dataCommService.trackEvent('edit_viewed', {
        user_id: this.dataCommService.userUniqueid,
        user_browser: this.dataCommService.userBrowser,
        user_device: this.dataCommService.userDevice
      });
    }
  }

  changeView() {
    //white board route setup
    const dcs = this.dataCommService;
    if (this.authService.cutsotmPrototypeVersion === null) {
      this.routerLinkPreview = '/' + this.dataCommService.buildcardId + '/preview/' + dcs.platform + '/' + dcs.platformType;
      this.routerLinkFlowchart = '/' + this.dataCommService.buildcardId + '/' + '/dashboard/' + this.platform + '/' + dcs.platformType + '/flowchart';
      this.routerLinkWhiteBoard = '/' + this.dataCommService.buildcardId + '/' + 'whiteBoard';
      this.routerLinkPlayMode = '/' + this.dataCommService.buildcardId + '/playmode/' + dcs.platform + '/' + dcs.platformType;
    } else {
      this.routerLinkPreview = '/' + this.dataCommService.buildcardId + '/' + this.authService.cutsotmPrototypeVersion +
      '/preview/'  + dcs.platform + '/' + dcs.platformType;
      this.routerLinkFlowchart = '/' + this.dataCommService.buildcardId + '/' + this.authService.cutsotmPrototypeVersion +
      '/dashboard/' + this.platform  + '/' + dcs.platformType + '/flowchart';
      this.routerLinkWhiteBoard = '/' + this.dataCommService.buildcardId + '/' + this.authService.cutsotmPrototypeVersion + '/whiteBoard';
      this.routerLinkPlayMode = '/' + this.dataCommService.buildcardId + '/' + this.authService.cutsotmPrototypeVersion +
      '/playmode/'  + dcs.platform + '/' + dcs.platformType;
    }
  }

  goToNextStep(stepName) {
    switch (stepName) {
        case 'whatsEditMode':
          this.dataCommService.onBoarding.inProgressPrototype = false;
          this.dataCommService.onBoarding.inProgressEditMode = true;
          this.dataCommService.onBoarding.onBoardingIconTooltipPrototype = false;
          this.dataCommService.onBoarding.actionMenu = false;
          this.dataCommService.onBoarding.launchScreen = false;
          this.dataCommService.onBoarding.addExtraFeatures = false;
          this.dataCommService.onBoarding.areYouDone = false;
          this.dataCommService.trackEvent('tooltip_steps_completed', {
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            tooltip_finish: 'Y'
          });
          setTimeout(() => {
            this.dataCommService.onBoarding.onBoardingIconTooltipPrototype = false;
            this.dataCommService.setOnBoardingCookie('whatsEditMode', 'yourFlow');
          }, 3000);
          this.dataCommService.setOnBoardingCookie('whatsEditMode', 'yourFlow');
          this.dataCommService.maintainReleaseNote();
          //this.router.navigate([this.routerLinkFlowchart]);
          break;
        case 'areYouDone':
          this.dataCommService.onBoarding.inProgressEditMode = false;
          this.dataCommService.onBoarding.onBoardingIconTooltipEditMode = false;
          this.dataCommService.setOnBoardingCookie('areYouDone', '');
          this.dataCommService.trackEvent('edit_guide_completed', {
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            edit_guide_finish: 'Y'
          });
          this.dataCommService.trackEvent('edit_guide_closed', {
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            edit_guide_close: 5
          });
          setTimeout(() => {
            this.dataCommService.onBoarding.onBoardingIconTooltipEditMode = false;
            this.dataCommService.setOnBoardingCookie('areYouDone', '');
          }, 3000);
          this.dataCommService.maintainReleaseNote();
          break;
        default:
            break;
    }
  }

  onSelectPlayMode() {
    this.dataCommService.trackEvent('play_mode_opened', {
      user_id: this.dataCommService.userUniqueid
    });
    if (this.router.url.includes('preview')) this.cookieService.setCookie('playModeFrom', 'preview');
    else this.cookieService.setCookie('playModeFrom', 'flowchart');
  }
}

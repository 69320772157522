import { Component, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, DoCheck, ChangeDetectorRef } from '@angular/core';
import { HotspotModel } from '../../../main/shared/models/hotspot.model';
import { UtilityService } from '@shared/services/utility.service';
import { Subscription } from 'rxjs';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { OverlayWithContentService } from '../overlay-with-content/overlay-with-content.service';
import { ManualShareService } from '../manual-hotspot/manual.shared.service';

@Component({
  selector: 'app-sidekick-image-list',
  templateUrl: './sidekick-image-list.component.html',
  styleUrls: ['./sidekick-image-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidekickImageListComponent implements OnDestroy, DoCheck {

  @Input() featureId: number;
  @Input() type;
  @Input() hotspots: HotspotModel[];
  @Input() frameAttributes;
  @Input() hotspotSelectionEnabled = false;
  @Output() newFrameSelected: EventEmitter<any> = new EventEmitter();
  @Output() hotspotSelected: EventEmitter<string> = new EventEmitter();
  @Input() isEditMode;
  @Input() isAddMode;
  private subscriptions: Subscription[] = [];
  private selectedHotspot: HotspotModel;

  hotspotfootercollapse = false;
  hotspottopcollapse = false;

  constructor(
    public utilityService: UtilityService, private dataCommService: DataCommService,
    private overlayWithContentService: OverlayWithContentService,
    private manualShareService: ManualShareService,
    private cd: ChangeDetectorRef) {
    this.subscriptions.push(this.dataCommService.selectedHotspot$.subscribe((hotspot) => {
      this.selectedHotspot = hotspot;
    }));
  }

  ngDoCheck() {
    this.cd.markForCheck();
  }

  changeFrame(hotspot: any) {
    this.newFrameSelected.emit(hotspot);
  }

  collapseHeader() {
    this.hotspottopcollapse = !this.hotspottopcollapse;
  }

  collapseFooter() {
    this.hotspotfootercollapse = !this.hotspotfootercollapse;
  }

  sidekickResponsiveSize() {
    const styleObj: any = {};
    const frameWidth = +this.frameAttributes.width;
    styleObj.maxWidth = ((24 / 375) * frameWidth) + 'px';
    styleObj.maxHeight = styleObj.maxWidth;
    return styleObj;
  }

  sidekickResponsiveWrap() {
    const styleObj: any = {};
    const frameHeight = +this.frameAttributes.height;
    styleObj.height = ((39 / 667) * frameHeight) + 'px';
    return styleObj;
  }

  collapseSidekickWrap() {
    const styleObj: any = {};
    const frameHeight = +this.frameAttributes.height;
    styleObj.height = ((34 / 667) * frameHeight) + 'px';
    return styleObj;
  }

  setCollapseMaxHeigh() {
    const styleObj: any = {};
    const frameHeight = +this.frameAttributes.height;
    styleObj.maxHeight = ((72 / 667) * frameHeight) + 'px';
    return styleObj;
  }

  checkForHighlight(hotspotVar): boolean {
    if (!this.isEditMode) {
      return hotspotVar.uniq_code !== null && hotspotVar.is_hero_image_present;
    } else {
      return false;
    }
  }

  hotspotClick($event, hotspot) {
    if (this.checkForHighlight(hotspot)) {
      this.changeFrame(hotspot);
    } else {
      this.hotspotSelectedhandler($event, hotspot);
    }
    this.manualShareService.uncheckHotspotFun(); // uncheck selected manual hotspot
  }

  hotspotSelectedhandler($event, hotspot: HotspotModel) {
    $event.stopPropagation();
    this.overlayWithContentService.showHideSliderFun(true);
    this.dataCommService.setSelectedHotspot(hotspot);
  }

  setSpotClass(hotspot) {
    let classList = '';
    if (this.isEditMode) {
      classList += ' cursorPointer';
      if (this.selectedHotspot && hotspot.id === this.selectedHotspot.id) {
        classList += ' active';
      }
    } else {
      if (hotspot.uniq_code !== null && hotspot.is_hero_image_present) {
        classList += ' cursorPointer';
      }
    }
    return classList;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

}


/*
 * The MousePosition object
 */
export class MousePosition {
  x: number;
  y: number;

  constructor() {
    this.x = 0;
    this.y = 0;
  }
}

/*
 * The ShapeProperties object
 */
export class ShapeProperties {
  fillColor: string;
  strokeColor: string;
  strokeWidth: number;
  fill: boolean;
  stroke: boolean;
  name: string;
  visible: boolean;
  imgUrl: string;
  userRequested: boolean;

  constructor() {
    this.fill = true;
    this.fillColor = 'rgba(19, 14, 239, 0.4)';
    this.stroke = true;
    this.strokeColor = 'black';
    this.strokeWidth = 1;
    this.name = 'unknown';
    this.visible = true;
  }
}

/*
 * The Shape object=
 */
export class Shape {
  public shapeProperties: ShapeProperties;
  public originX: number;
  public originY: number;
  public width: number;
  public height: number;
  public percent_originX: number;
  public percent_originY: number;
  public percent_width: number;
  public percent_height: number;
  public imgUrl: string;
  public sidekickId: number;
  constructor(name: string) {
    this.shapeProperties = new ShapeProperties();
    this.originX = this.originY = 0;
    this.shapeProperties.name = name;
  }
}

/*
 * The Rectangle class.
 */
export class Rectangle extends Shape {
  private static id = 0;
  public width: number;
  public height: number;
  public imgUrl: string;

  constructor() {
      super('rectangle-' + Rectangle.id++);
      this.width = this.height = 0;
  }
}

import {Component, EventEmitter, OnInit, Output, OnDestroy} from '@angular/core';
import {DataCommService} from '../../../main/shared/services/data-comm.service';
import {Router} from '@angular/router';
import {DataService} from '../../../main/shared/services/data.service';
import {ToasterService} from '@core/toaster.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-industry-type',
  templateUrl: './industry-type.component.html',
  styleUrls: ['./industry-type.component.scss']
})
export class IndustryTypeComponent implements OnInit, OnDestroy {
  @Output() closeIndustryPanel = new EventEmitter();
  industries: any[] = [];
  saveBtnDisabled = true;
  industryId;
  apiSubscriptions = [];
  updateIndustrySubscription: Subscription;
  constructor(public dataCommService: DataCommService,
              public router: Router, public dataService: DataService,
              public toaster: ToasterService) { }

  ngOnInit() {
    this.industries = this.dataCommService.industries;
    this.industryId = this.dataCommService.defaultIndustry;
  }
  closeSideKickHandlr() {
    this.closeIndustryPanel.emit();
  }
  setIndustryType(industryId) {
    this.saveBtnDisabled = false;
    this.industryId = industryId;
  }
  saveSideKicks() {
    const industryData = {
      industry_id: this.industryId,
      platform: this.dataCommService.platform
    };
    if (this.updateIndustrySubscription) {
      this.updateIndustrySubscription.unsubscribe();
    }
    this.updateIndustrySubscription = this.dataService.updateIndustry(industryData, this.dataCommService.buildcardId)
      .subscribe((res: any) => {
        this.saveBtnDisabled = true;
        this.industryId = res.industry_id;
        this.industries.forEach((elm, index) => {
          if (elm.id === this.industryId) {
            this.industries[index]['is_default'] = true;
          } else {
            this.industries[index]['is_default'] = false;
          }
        });
        this.dataCommService.defaultIndustry = res.industry_id;
        this.closeIndustryPanel.emit(res);
        // this.toaster.success('Saved Successfully');
        this.dataCommService.cpeSavedFun(false);
      }, (err) => {
    });
    this.apiSubscriptions.push(this.updateIndustrySubscription);
  }
  ngOnDestroy() {
    this.apiSubscriptions.forEach((apiSub: Subscription) => {
      apiSub.unsubscribe();
    });
  }
    trackById(index, industry) {
        return industry.id;
    }
}

import { Component, OnInit } from '@angular/core';
import {DataCommService} from '../../../main/shared/services/data-comm.service';

@Component({
  selector: 'app-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss']
})
export class SpinnerLoaderComponent implements OnInit {

  constructor(
      public dataCommService: DataCommService
  ) { }

  ngOnInit() {
  }

}

import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { CookieService } from '@core/cookie.service';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';

@Component({
  selector: 'app-undo-redo-toast',
  templateUrl: './undo-redo-toast.component.html',
  styleUrls: ['./undo-redo-toast.component.scss']
})
export class UndoRedoToastComponent implements OnInit, OnChanges {
  @Input() undoList;
  @Input() redoList;
  undoToastArr: any[] = [];
  undoListCopy: any[] = [];
  public closeToaster: boolean[] = [false, false, false];
  undoRedoTimer: any;

  constructor(
    public dataCommService: DataCommService,
    private cookieService: CookieService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.undoList && this.undoList.length) this.createUndoToastArr();
    if (this.cookieService.getLocalStorage('showUndoRedoToaster') === 'yes') {
      this.undoRedoTimer = setTimeout(() => {
        let index = this.undoToastArr.length;
        while (index >= 0) {
          index--;
          this.closeToaster[index] = true;
        }
        this.cookieService.setLocalStorage('showUndoRedoToaster', '');
      }, 15000);
    }
  }

  createUndoToastArr() {
    this.undoToastArr = [];
    this.closeToaster = [false, false, false];
    this.undoList.sort((a, b) => b.version - a.version);
    this.undoListCopy = JSON.parse(JSON.stringify(this.undoList));
    this.undoListCopy = this.undoListCopy.filter(e => this.getTimeDifferenceinSec(new Date(e.created_at), new Date()) < 3600);
    let index = 0;
    while (index < 3 && index < this.undoListCopy.length) {
      this.undoToastArr.push(JSON.parse(JSON.stringify(this.undoListCopy[index])));
      index++;
    }
  }

  undoRedo(event, val, itemObj) {
    event.stopPropagation();
    if (this.undoToastArr && !this.undoToastArr.length) this.cookieService.setLocalStorage('showUndoRedoToaster', '');
    this.dataCommService.undoRedoCommonFun(val, itemObj);
  }

  closeToasterUndoRedo(event, index) {
    event.stopPropagation();
    this.closeToaster[index] = true;
    if (!index) this.cookieService.setLocalStorage('showUndoRedoToaster', '');
    while (index < 2) {
      index++;
      this.closeToaster[index] = true;
    }
  }

  ngOnDestroy() {
    clearInterval(this.undoRedoTimer);
  }

  getTimeDifferenceinSec(startDate, endDate) {
    return (endDate.getTime() - startDate.getTime()) / 1000;
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';

@Component({
  selector: 'app-description-card',
  templateUrl: './description-card.component.html',
  styleUrls: ['./description-card.component.scss']
})
export class DescriptionCardComponent implements OnInit {

  @Input() featureData;
  @Input() boxScale;
  @Input() framePrevIndex;
  @Output() closeBox = new EventEmitter();

  constructor(public dataCommService: DataCommService) { }

  ngOnInit() {
    this.enableDisableTabCtas(true);
  }

  /**
   * @description: method to hide description card
   */
  hideCard($event) {
    $event.stopPropagation();
    this.dataCommService.showFtrDesc = false;
    this.dataCommService.trackEvent('feature_infobox_closed', {
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice,
      infobox_feature: this.featureData.title
    });
    this.enableDisableTabCtas(false);
  }
  /**
   * @description: method to set scale of the desciption
   * box according to the scale of frame from parent
   */
  setScale() {
    if (!this.dataCommService.isVideoAnimation)
      return {
        transform: `scale(${this.boxScale})`
      };
    else return {};
  }

  enableDisableTabCtas(isAdd) {
    if (this.dataCommService.checkIsTab()) {
      let ctas: any = document.querySelectorAll('.showHotspots, .screen-control');
      for (let el of ctas) {
        if (isAdd) el.classList.add('disableTabCtas');
        else el.classList.remove('disableTabCtas');
      }
    }
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {DataCommService} from '../../../../main/shared/services/data-comm.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-left-side',
  templateUrl: './left-side.component.html',
  styleUrls: ['./left-side.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          // transform: 'translateX(100%)'
          right:'-100%'
        }),
        animate(200, style({
          right:'0'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          right:'-100%'
        }))
      ])
    ]),
    trigger('tooltipAni', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({
          opacity:'0',
          transform: 'translateX(-50%)'
        }),
        animate(200, style({
          opacity:'1',
          transform: 'translateX(-50%)'
        }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(200, style({
          opacity:'0',
          transform: 'translateX(-50%)'
        }))
      ])
    ])
  ]
})
export class LeftSideComponent implements OnInit {

  @Input() appDetails;
  public loginFlow = false;

  constructor(public dataCommService: DataCommService) {
    this.dataCommService.showRentalPaymentFlow$.subscribe((val: boolean) => {
      this.loginFlow = true;
    });
  }

  ngOnInit(): void {
    this.loginFlow = [0, 7, 8].includes(this.dataCommService.selectedSectionIndex);
  }

  handlePaymentoption(index: number) {
    if (this.dataCommService.buildCardData.studio_store_nda_signed && !this.dataCommService.isRentalCardPaid) {
      this.dataCommService.handleNextPrevForRentalFlow(index);
    }
  }

  handleContractClick(index: number) {
    if (!this.dataCommService.isRentalCardPaid) {
      this.dataCommService.handleNextPrevForRentalFlow(index);
    }
  }

  handlePaymentOverViewClick(index: number) {
    if (!this.dataCommService.isRentalCardPaid) {
      this.dataCommService.handleNextPrevForRentalFlow(1);
    }
  }
}

import { Injectable } from '@angular/core';
import { RectangleComponent } from './rectangle-component/rectangle-component';
import {DataCommService} from '../../../main/shared/services/data-comm.service';

@Injectable()
export class ManualHotspotService {
  public isNewHotspotDraw = true;

  private shapesComponents: RectangleComponent[] = [];

  public selectedComponent: RectangleComponent;
  public showHideTooltip = false;
  public isDragging = false;
  public isDrawing = false;
  public isResizing = false;
  public selectedItemId;
  public selectedClickableItem;
  public tooltipPos = {
    x: null,
    y: null
  };
  public showHideToolTip = false;
  public showContextMenu = false;
  public carouselDelImgIdArr = [];

  constructor(private dataCommService: DataCommService) {
  }

  getRectangleComponents(): RectangleComponent[] {
    return this.shapesComponents;
  }

  removeSelectedRectangleComponent(component: RectangleComponent): void {
    const comps = this.getRectangleComponents();
    const index = comps.indexOf(component, 0);
    if (index > -1) {
      comps.splice(index, 1);
    }
  }

  removeAllRectangleComponents(): void {
    this.shapesComponents = [];
  }

  setRectangleComponent(component: RectangleComponent): void {
    this.selectedComponent = component;
    this.shapesComponents.push(component);
  }

  getRectangleComponent(): RectangleComponent {
    return this.selectedComponent;
  }

  findRectangleComponent(name: string): RectangleComponent {
    this.selectedComponent =  this.shapesComponents.find(x => x.shape.shapeProperties.name === name);
    return this.selectedComponent;
  }

  findRectangleComponentByCategory(catId: any): RectangleComponent {
    this.selectedComponent =  this.shapesComponents.find(x => x.hotspotCategoryId === catId);
    return this.selectedComponent;
  }

  findClickableItem(id) {
    this.selectedClickableItem = this.selectedComponent.clickableItems.find(x => x.id === id);
    return this.selectedClickableItem;
  }
  getClickableItem() {
    return this.selectedClickableItem;
  }
  deleteHotspot() {
    const comps = this.getRectangleComponents();
    const index = comps.indexOf(this.selectedComponent, 0);
    if (index > -1) {
      comps.splice(index, 1);
    }
  }

  resetManualHotspotFlags() {
    this.isDragging = false;
    this.isDrawing = false;
    this.isResizing = false;
    this.showHideTooltip = false;
  }

  /**
   * @description: Deselect any selected
   * manual hotspot
   */
  deSelectComponents() {
    const shapes = this.getRectangleComponents();
    shapes.forEach((elm, index) => {
      shapes[index].isSelected = false;
      shapes[index].hotspotEditable = false;
    });
  }
}

import {Component, EventEmitter, Output, Input, OnInit, OnChanges} from '@angular/core';
import { OverlayWithContentService } from './overlay-with-content.service';
import { DataCommService } from './../../../main/shared/services/data-comm.service';
import { Router } from '@angular/router';
import {ManualHotspotService} from '@shared/components/manual-hotspot/manual-hotspot.service';
import { PreviewModel } from 'src/app/main/shared/models/preview.model';

@Component({
  selector: 'app-overlay-with-content',
  templateUrl: './overlay-with-content.component.html',
  styleUrls: ['./overlay-with-content.component.scss']
})
export class OverlayWithContentComponent implements OnInit, OnChanges {

  @Output() dialogSaved = new EventEmitter();
  @Output() deleteHotspot = new EventEmitter();
  @Output() crossClick = new EventEmitter();
  @Output() backArrowClick = new EventEmitter();
  @Output() confirmClick = new EventEmitter();
  @Input() saveDisabled: boolean;
  @Input() overlayTitle: string;
  @Input() showHideSliderFlag = false;
  @Input() sliderOpen: boolean;
  @Input() deleteBtnHide: boolean;
  @Input() industryType: boolean = false;
  @Input() clickedHotspotSource: any;
  @Input() frameData: any;
  public addIconClass: boolean = false;
  public disableDeleteBtn: boolean = true;
  public disableConfirmBtn: boolean = true;
  editScreen: boolean;
  addHotspot: boolean;
  showToolTip: boolean = false;
  constructor(
    public overlayWithContentService: OverlayWithContentService,
    public dataCommService: DataCommService,
    private router: Router,
    private rectangleService: ManualHotspotService) {
      if (this.router.url.includes('add-icon')) {
        this.addIconClass = true;
      }
      this.editScreen = this.router.url.includes('edit-screen');
      this.addHotspot = this.router.url.includes('add-hotspot');
      if (this.editScreen || this.addHotspot) {
        this.showToolTip = true;
      }
    }
  ngOnInit() {
    // subscribe serice method to show hide slider
    this.showHideSlider();
    this.enableSaveButton();
    this.enableDeleteButton();
    this.enableConfirmButton();
  }
  ngOnChanges(): void {
    if (this.router.url.includes('add-icon') || this.router.url.includes('add-sidekick')) {
      if (this.clickedHotspotSource === 'Add icon' || this.clickedHotspotSource === 'Add sidekick') {
        this.addIconClass = false;
      } else {
        this.addIconClass = true;
      }
    }
  }

  showHideSlider() {
    this.overlayWithContentService.showHideSlider$.subscribe((data) => {
      this.showHideSliderFlag = data;
    });
  }
  enableSaveButton() {
    this.overlayWithContentService.enableSaveButton$.subscribe((data) => {
      this.saveDisabled = data;
      this.dataCommService.cpeSaveDisabled = data;
    });
  }
  enableDeleteButton() {
    this.overlayWithContentService.enabeDeleteButton$.subscribe((data) => {
      this.disableDeleteBtn = data;
    });
  }
  enableConfirmButton() {
    this.overlayWithContentService.enabeConfirmButton$.subscribe((data) => {
      this.disableConfirmBtn = data;
    });
  }
  delete() {
    this.deleteHotspot.emit();
  }
  save(e) {
    e.stopPropagation();
    if (this.frameData.carouselWidth) {
      this.dataCommService.trackEvent('carousel_added', {
        user_id: this.dataCommService.userUniqueid,
        user_browser: this.dataCommService.userBrowser,
        user_device: this.dataCommService.userDevice,
        screen_edited: this.frameData.title
      });
    } else {
      this.dataCommService.trackEvent('hotspot_saved', {
        user_id: this.dataCommService.userUniqueid,
        user_browser: this.dataCommService.userBrowser,
        user_device: this.dataCommService.userDevice,
        screen_edited: this.frameData.title
      });
    }
    this.dataCommService.isContentChanged = false;
    this.dialogSaved.emit();
  }
  crossHandler() {
    if (this.dataCommService.isContentChanged === true) {
      this.dataCommService.openConfirmBox = true;
    } else {
      this.crossClick.emit();
    }
  }
  backArrowClicked(e) {
    e.stopPropagation();
    if (this.dataCommService.isContentChanged === true) {
      this.dataCommService.openConfirmBox = true;
    } else {
      this.backArrowClick.emit();
    }
  }
  confirm() {
    this.disableConfirmBtn = true;
    this.overlayWithContentService.clickedConfirmBtnFun();
    this.confirmClick.emit();
  }

  onScrollEvent(event) {
    if (this.overlayWithContentService.isHotspotHorizontalScroll) this.overlayWithContentService.hotspotImageScrollX = event.srcElement.scrollLeft;
    else if (this.overlayWithContentService.isHotspotVerticalScroll) this.overlayWithContentService.hotspotImageScrollY = event.srcElement.scrollTop
  }

}

import {Component, ElementRef, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import {DataService} from '../../../main/shared/services/data.service';
import {Constants} from '@shared/services/constants';
import {ToasterService} from '@core/toaster.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-share-prototype',
  templateUrl: './share-prototype.component.html',
  styleUrls: ['./share-prototype.component.scss']
})
export class SharePrototypeComponent implements OnInit, OnDestroy {
  showPasswordProtectedBox = false;
  responseAfterAPi = false;
  IsnewPassword = false;
  passwordInput: string;
  apiSubscriptions = [];
  setPasswordSubscription: Subscription;
  getPasswordSubscription: Subscription;

  constructor(private dataCommService: DataCommService,
              private dataService: DataService,
              private toasterService: ToasterService,
              private router: Router
  ) { }

  ngOnInit() {
    if (this.dataCommService.isPasswordProtected) {
      this.showPasswordProtectedBox = true;
    }
    this.getPassword();
    if (this.router.url.includes('playmode')) this.dataCommService.sharingUrl = this.dataCommService.sharingUrl.replace('preview', 'playmode');
    else if (!this.router.url.includes('playmode') && this.dataCommService.sharingUrl.includes('playmode')) this.dataCommService.sharingUrl = this.dataCommService.sharingUrl.replace('playmode', 'preview');
  }

  crossHandler() {
    this.dataCommService.showShareModal = false;
  }
  togglePasswordProtected() {
    if (this.showPasswordProtectedBox) {
      this.showPasswordProtectedBox = false;
      const passwordData = {
          password_enable: false
      };
      if (this.setPasswordSubscription) {
        this.setPasswordSubscription.unsubscribe();
      }
      this.setPasswordSubscription = this.dataService.setPassword(this.dataCommService.nowBuildCardId, passwordData)
        .subscribe((data: any) => {
              this.dataCommService.isPasswordProtected = false;
              this.toasterService.success(Constants.passwordProtectedMessage.DISABLEDPASSWORD);
          },
          (error: any) => {
              // this.dataCommService.errorHandlr(error);
          });
      this.apiSubscriptions.push(this.setPasswordSubscription);
    } else {
        this.getPassword();
        this.showPasswordProtectedBox = true;
    }
  }
  setPassword() {
    if (this.passwordInput !== '') {
      const passwordData = {
        password: this.passwordInput,
        password_enable: true
      };
      if (this.setPasswordSubscription) {
        this.setPasswordSubscription.unsubscribe();
      }
      this.dataService.setPassword(this.dataCommService.nowBuildCardId, passwordData).subscribe((data: any) => {
            this.responseAfterAPi = true;
            this.dataCommService.isPasswordProtected = true;
            this.toasterService.success(Constants.passwordProtectedMessage.SETPASSWORD);
            setTimeout(() => {
              this.responseAfterAPi = false;
            }, 3000);
          },
          (error: any) => {
            // this.dataCommService.errorHandlr(error);
          });
      this.apiSubscriptions.push(this.setPasswordSubscription);
    }
  }
  getPassword() {
    if (this.getPasswordSubscription) {
      this.getPasswordSubscription.unsubscribe();
    }
    this.getPasswordSubscription = this.dataService.getPassword(this.dataCommService.nowBuildCardId).subscribe((data: any) => {
          if (data.build_card_password) {
              this.passwordInput = data.build_card_password;
              this.dataCommService.passwordProtected = true;
          } else {
              this.passwordInput = '';
              this.dataCommService.passwordProtected = false;
          }
      },
      (error: any) => {
          // this.dataCommService.errorHandlr(error);
      });
    this.apiSubscriptions.push(this.getPasswordSubscription);
  }

  ngOnDestroy() {
    this.apiSubscriptions.forEach((apiSub: Subscription) => {
      apiSub.unsubscribe();
    });
  }
}

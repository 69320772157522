import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { Router } from '@angular/router';
import {AuthService} from '@core/auth.service';

@Component({
  selector: 'app-side-toolbar',
  templateUrl: './side-toolbar.component.html',
  styleUrls: ['./side-toolbar.component.scss']
})
export class SideToolbarComponent {
  @Output() addRemoveMapping = new EventEmitter();
  @Output() addRemoveBackConnectors = new EventEmitter();
  @Output() zoomIn = new EventEmitter();
  @Output() zoomOut = new EventEmitter();
  @Output() fitToZoom = new EventEmitter();
  @Output() setInitialZoom = new EventEmitter();
  @Input() disableToolbar: boolean;
  @Input() isFullZoomIn;
  @Input() isFullZoomOut;
  @Output() showHideSlider = new EventEmitter();
  @Input() unlickFeatureCount;
  showSliderIcon: boolean;
  routerLink = '';
  isSubFeatureScreen = false;
  public showHideMapping = true;
  public showHideBackConnectors = false;
  isRouterActive: boolean = false;
  // showOnboardingSubmenu = false;
  constructor(public dataCommService: DataCommService,
              public router: Router,
              private authService: AuthService) {
    this.showSliderIcon = this.router.url.includes('flowchart');
    this.dataCommService.switchViewSource$.subscribe((value: string) => {
      this.dataCommService.platform = value;
      this.changeView();
    });
    this.changeView();
    this.isSubFeatureScreen = this.router.url.includes('sub-features');
  }
  changeView() {
    this.isRouterActive = this.router.url.includes('flowchart');
    if (this.authService.cutsotmPrototypeVersion === null) {
      this.routerLink = '/' + this.dataCommService.buildcardId + '/dashboard/' + this.dataCommService.platform + '/' + this.dataCommService.platformType;
    } else {
      this.routerLink = '/' + this.dataCommService.buildcardId + '/' + this.authService.cutsotmPrototypeVersion +
          '/dashboard/' + this.dataCommService.platform + '/' + this.dataCommService.platformType;
    }
  }
  addRemoveMappingFun() {
    if (!this.dataCommService.guidline.showHideConnector) {
      this.addRemoveMapping.emit();
      this.showHideMapping = !this.showHideMapping;
    }
  }
  
  addRemoveBackwardLineFun() {
    if (!this.dataCommService.guidline.showHideConnector) {
      this.addRemoveBackConnectors.emit();
      this.showHideBackConnectors = !this.showHideBackConnectors;
    }
  }

  zoomInFun() {
    this.zoomIn.emit();
  }
  zoomOutFun() {
    this.zoomOut.emit();
  }
  fitToZoomFun() {
    this.fitToZoom.emit();
  }
  showHideSliderFun() {
    if (!this.dataCommService.guidline.viewUnlinkedScreen) {
      this.showHideSlider.emit();
    }
  }
  
  openOnboarding(e) {
    e.stopPropagation();
    this.dataCommService.onBoarding.inProgressEditMode = true;
    this.dataCommService.showOnboardingSubmenu = false;
    this.dataCommService.setOnBoardingCookie('', 'yourFlow');
    this.dataCommService.trackEvent('edit_guide_re-opened', {
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice
    });
    this.setInitialZoom.emit();
    return false;
  }

  showHelpMenu(e) {
    e.stopPropagation();
    this.dataCommService.showOnboardingSubmenu = !this.dataCommService.showOnboardingSubmenu;
  }
}

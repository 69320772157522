import { ToasterService } from '@core/toaster.service';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DataService } from 'src/app/main/shared/services/data.service';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { AuthService } from '@core/auth.service';
import { UtilityService } from '@shared/services/utility.service';
import { CommentModel } from 'src/app/main/shared/models/comment.model';
import { CookieService } from '@core/cookie.service';
import { Constants } from '@shared/services/constants';

@Injectable({
  providedIn: 'root'
})
export class CommentService implements OnDestroy {

  commentModeToggleSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  commentModeToggle$ = this.commentModeToggleSubject.asObservable();
  singleCommentDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  singleCommentData$ = this.singleCommentDataSubject.asObservable();
  allCommentsDataSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  allCommentsData$ = this.allCommentsDataSubject.asObservable();
  commentMarksSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  commentMarks$ = this.commentMarksSubject.asObservable();
  subs: Subscription[] = [];
  public heroImageId;
  commentsData = null;
  public commentModeEnabled;
  private selectedCommentData;
  public trackerCommentFinished = false;
  perPage;
  userInfo;
  pageNo;
  promiseNeeded;
  constructor(
      private dataService: DataService, private dataCommService: DataCommService,
      private authService: AuthService, private utilityService: UtilityService,
      private cookieService: CookieService,
      private toasterService :ToasterService) {
        this.subs.push(this.allCommentsData$.subscribe(val => {
            this.commentsData = utilityService.cloneObject(val);
        }));
        this.subs.push(this.commentModeToggle$.subscribe(val => {
          this.commentModeEnabled = val;
          if (!val) {
            this.commentMarksSubject.next(null);
            this.trackerCommentFinished = false;
          }
      }));
  }

  getCommentsData(featureData, promiseNeeded?, pageNo = 1, perPage = 100, isReview?) {
    if (pageNo === 1) {
      perPage = 100;
    } else if (isReview) {
        perPage = 100;
    }
    this.perPage = perPage;
    this.userInfo = this.authService.getLoggedInUser();
    this.pageNo = pageNo;
    this.promiseNeeded = promiseNeeded;
    if (this.dataCommService.prototypeType === 'instant') {
      if (promiseNeeded) {
        return this.instantComments(featureData);
      } else {
        this.instantComments(featureData);
      }
    } else {
        if (this.dataCommService.isCurrentPrototypeApproved && this.trackerCommentFinished) {
          if (promiseNeeded) {
            return this.instantComments(featureData);
          } else {
            this.instantComments(featureData);
          }
        } else {
            let build_feature_id = featureData['studio_feature_id'] ? featureData['studio_feature_id']: this.dataCommService.studioFeatureIdForSubFeatureComment;
            if(build_feature_id){
                const featureComments = this.dataService.showcustomFeaturesComments(
                    this.dataCommService.trackerProjectId,
                    build_feature_id,
                    // userTypeVal,
                    pageNo,
                    perPage,
                    featureData['story_id'],
                    this.dataCommService.platformType
                );
                if (!promiseNeeded) {
                    featureComments.subscribe((data: any) => {
                        this.allCommentsDataSubject.next(this.modifyTrackerCommentData(data, featureData.title));
                        if (this.dataCommService.isCurrentPrototypeApproved && data && data.meta.total_pages <= data.meta.current_page) {
                            this.trackerCommentFinished = true;
                            if (promiseNeeded) {
                              return this.instantComments(featureData);
                            } else {
                              this.instantComments(featureData);
                            }
                        }
                    });
                } else {
                    return featureComments.toPromise();
                }
            }
            else{
                if (featureData['story_type'] !== 'sub_task') {
                    this.toasterService.success(Constants.customScreenMsg);
                }                
            }        
            /*featureComments.subscribe((data: any) => {
                if (!promiseNeeded) {
                    this.allCommentsDataSubject.next(this.modifyTrackerCommentData(data, featureData.title));
                } else {
                    return featureComments.toPromise();
                }
                if (this.dataCommService.isCurrentPrototypeApproved && data && data.meta.total_pages === data.meta.current_page) {
                    this.trackerCommentFinished = true;
                    this.instantComments(featureData, pageNo, perPage, userInfo, promiseNeeded);
                }
            });*/
        }
    }
  }

  instantComments(featureData) {
      const payload  =  {
          build_card_hero_image_id: featureData.build_card_hero_image_id,
          device: this.dataCommService.platform,
          platform: this.dataCommService.platformType,
          version: featureData.version,
          page: this.pageNo,
          per_page: this.perPage,
          comment_user: {},
          studio_build_card_id:this.dataCommService.buildcardId
      };
      if ( this.userInfo !== null) {
          payload.comment_user = {
              name: this.userInfo.first_name + (this.userInfo.last_name ? ' ' + this.userInfo.last_name : ''),
              email: this.userInfo.email,
              studio_user_id: this.userInfo.id
          };
      }
      if (!this.promiseNeeded) {
          this.dataService.getScreenComments(this.dataCommService.nowBuildCardId, payload).subscribe((res: any) => {
              this.allCommentsDataSubject.next(res);
          });
      } else {
          return this.dataService.getScreenComments(this.dataCommService.nowBuildCardId, payload).toPromise();
      }
  }

  maintainMentions(note) {
    let isLoop = true;
    let startIndex = 0;
    while(isLoop) {
        let a = note.indexOf('{"name', startIndex);
        let b = note.indexOf('"}', a);
        startIndex = a + 5;
        if( a >= 0 && b  >= 0  && note.substring(a, b + 2).trim()) {
            let obj;
            try {
                obj = JSON.parse(note.substring(a, b + 2));
            } catch (e) {
                obj = ''; // error in the above string (in this case, yes)!
            }
            if (typeof obj === 'object') {
                note = note.substring(0, a) + (obj.name !== '@customer' ? '@' : '') + obj.name + note.substring(b + 2);
                // note = note.substring(0, a) + '@' + obj.name + note.substring(b + 2);
            }
        }
        if (note.indexOf('{"name', startIndex) >= 0) {
            isLoop = true;
        } else {
            isLoop = false;
        }
    }
    return note;
}

   checkForCustomer(object){
    if(object.user_type == "designer"){   
    const parentCheck = object.note.includes('@customer');
    if(parentCheck){
        return true;
    }
    else{
      if(object.replies.length > 0) {
        let childCheck = false;
        object.replies.forEach(element => {
            if(element.note.includes('@customer')){
                childCheck = true;
            }
        }); 
        if(childCheck){
            return true;
        }
      }
    }
    return false;
    }
    return false;
  }

  modifyTrackerCommentData(data, title) {
        const userInfo = this.authService.getLoggedInUser();
        const tempArray = [];
        data.comments.forEach((arrObj) => {
            //notification-- for showign all comments besides the one not having position.
        arrObj.note = this.maintainMentions(arrObj.note);
        if(arrObj.replies.length > 0){
            arrObj.replies.forEach(element => {
            element.note = this.maintainMentions(element.note);
            });
        }
      
            if(arrObj.position && (this.checkForCustomer(arrObj) || arrObj.user_type == 'User') ){
            const myArr: any = {
                body: arrObj.note,
                position: {
                    originX: arrObj.position.originX.toString(),
                    originY: arrObj.position.originY.toString()
                },
                stamp_mark: arrObj.number,
                id: arrObj.id,
                created_at: arrObj.created_at,
                updated_at: arrObj.created_at,
                commented_by: arrObj.user_name ? arrObj.user_name : '',
                commented_on: title,
                target_feature_id: parseInt(arrObj.hero_image_feature_id, 10),
                is_owner: userInfo ? (arrObj.user_email === userInfo.email) ? true : false : false,
                version: arrObj.version,
                // userType: 'User',
                set_as_deigner_task: arrObj.set_as_deigner_task,
                is_resolved:arrObj.is_resolved
            };
            if (arrObj.replies) {
                const replyCommentData = [];
                arrObj.replies.forEach((replyArrObj) => {
                    if(replyArrObj.position){
                        const replyArr = {
                            body: replyArrObj.note,
                            position: {
                                originX: replyArrObj.position.originX.toString(),
                                originY: replyArrObj.position.originY.toString()
                            },
                            stamp_mark: replyArrObj.number,
                            id: replyArrObj.id,
                            created_at: replyArrObj.created_at,
                            updated_at: replyArrObj.created_at,
                            replied_by: replyArrObj.user_name ? replyArrObj.user_name : '',
                            commented_on: title,
                            target_feature_id: parseInt(replyArrObj.hero_image_feature_id, 10),
                            is_owner: userInfo ? (replyArrObj.user_email === userInfo.email) ? true : false : false,
                            version: replyArrObj.version
                        };
                        replyCommentData.push(replyArr);
                    }
                });
                myArr.replies = replyCommentData;
            } else {
                myArr.replies = [];
            }
            tempArray.push(myArr);
        }}
        );
        const customFeaturesComments = {
            comments: tempArray,
            page_data: data.meta
        };
        return customFeaturesComments;
  }
    modifyTrackerSingleCommentData(arrObj, title) {
        const userInfo = this.authService.getLoggedInUser();
        const tempArray = [];
        arrObj.note = this.maintainMentions(arrObj.note);
        if(arrObj.replies.length > 0){
            arrObj.replies.forEach(element => {
            element.note = this.maintainMentions(element.note);
            });
        }
        const myArr: any = {
            body: arrObj.note,
            position: {
                originX: arrObj.position.originX.toString(),
                originY: arrObj.position.originY.toString()
            },
            stamp_mark: arrObj.number,
            id: arrObj.id,
            created_at: arrObj.created_at,
            updated_at: arrObj.created_at,
            commented_by: arrObj.user_name ? arrObj.user_name : '',
            commented_on: title ? title : '',
            target_feature_id: parseInt(arrObj.hero_image_feature_id, 10),
            is_owner: (userInfo && arrObj.user_email === userInfo.email) ? true : false,
            version: arrObj.version,
            set_as_deigner_task: arrObj.set_as_deigner_task,
            userType: 'User'
        };
        if (arrObj.replies) {
            const replyCommentData = [];
            arrObj.replies.forEach((replyArrObj) => {
                const replyArr = {
                    body: replyArrObj.note,
                    position: {
                        originX: replyArrObj.position.originX.toString(),
                        originY: replyArrObj.position.originY.toString()
                    },
                    stamp_mark: replyArrObj.number,
                    id: replyArrObj.id,
                    created_at: replyArrObj.created_at,
                    updated_at: replyArrObj.created_at,
                    replied_by: replyArrObj.user_name ? replyArrObj.user_name : '',
                    commented_on: '',
                    target_feature_id: parseInt(replyArrObj.hero_image_feature_id, 10),
                    is_owner: (replyArrObj.user_email === userInfo.email) ? true : false,
                    version: replyArrObj.version
                };
                replyCommentData.push(replyArr);
            });
            myArr.replies = replyCommentData;
        } else {
            myArr.replies = [];
        }
        tempArray.push(myArr);
        return myArr;
    }

  getAllCommentMarks() {
     if (this.dataCommService.prototypeType === 'instant') {
         this.dataService.getDashboardCommentMarks(this.dataCommService.nowBuildCardId, this.dataCommService.platform, this.dataCommService.platformType, this.dataCommService.buildcardId).subscribe(res => {
             this.commentMarksSubject.next(res);
         });
     } else {
         if (this.dataCommService.isCurrentPrototypeApproved) {
             this.dataService.getDashboardCommentMarks(this.dataCommService.nowBuildCardId, this.dataCommService.platform, this.dataCommService.platformType).subscribe((res: any) => {
                 res.fetchAll = true;
                 this.commentMarksSubject.next(res);
             });
         }
         this.dataService.customCommentsPosition(this.dataCommService.trackerProjectId).subscribe((data: any) => {
             let getCurrentFeatureComments = data.comments.filter(arrObj =>  arrObj.position !== null);
             let array=[];
             let res;
            //  const getCurrentFeatureComments = data.comments.filter(arrObj => (arrObj.user_type !== 'designer' && arrObj.position !== null));
            //  let res = {comments: getCurrentFeatureComments};
             if(getCurrentFeatureComments.length > 0){
                getCurrentFeatureComments.forEach(element => {
                     element.note = this.maintainMentions(element.note);
                     if(element.replies.length > 0){
                        element.replies.forEach(reply => {
                            reply.note = this.maintainMentions(reply.note);
                        });
                    }
                 });
                 getCurrentFeatureComments.forEach(comment => {
                    if(this.checkForCustomer(comment) || comment.user_type == 'User'){
                        array.push(comment);
                    }
                 });
                 res = {comments: array};
             }

             this.commentMarksSubject.next(res);
         });
     }
  }

  getAllSubFeatureCommentMarks(studiofeatureid?) {
    if (this.dataCommService.prototypeType === 'custom') {
        this.dataService.getSubFeatureCommentMarks(this.dataCommService.trackerProjectId, studiofeatureid ? studiofeatureid : this.dataCommService.studioFeatureIdForSubFeatureComment).subscribe(res => {
            this.commentMarksSubject.next(res);
        });
    } 
  }

  putNewComment($event) {
    if (this.commentModeEnabled && !$event.target.classList.contains('dropped-comment')) {
      this.selectedCommentData = new CommentModel();
      const userInfo = this.authService.getLoggedInUser();
      if (userInfo) {
          this.selectedCommentData.commented_by = userInfo.first_name + ' ' + userInfo.last_name;
      } else {
        this.dataCommService.isUserClickedOnPreviewToPutComment = true;
        const segmentTrackerData = {
            referrer_trigger: this.dataCommService.referrer,
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice
          };
        this.dataCommService.trackEvent('sign_up_screen_opened', segmentTrackerData);
        this.dataCommService.eventPostLogin = $event;
        this.dataCommService.showHidePaymentFlow(true);
      }
      this.selectedCommentData.position.originX = $event.offsetX;
      this.selectedCommentData.position.originY = $event.offsetY;
      this.singleCommentDataSubject.next(this.selectedCommentData);
    }
  }

  showComment(event, comment) {
    const commentId = comment.id;
    const userInfo = this.authService.getLoggedInUser();
    this.dataCommService.isReplyPostLogin = {event, comment};
    let payload;
    if (userInfo) {
        payload = {
            comment_user: {
                name: userInfo.first_name + (userInfo.last_name ? ' ' + userInfo.last_name : ''),
                email: userInfo.email,
                studio_user_id: userInfo.id
            }
        };
    } else {
        payload = {};
    }
    if (this.dataCommService.prototypeType === 'instant' || (this.dataCommService.isCurrentPrototypeApproved && comment.commentType === 'instant')) {
        this.dataService.showComment(commentId, payload).subscribe((res: any) => {
            this.singleCommentDataSubject.next(res);
        });
    } else {
        this.dataService.getTrackerCommentById(this.dataCommService.trackerProjectId, commentId).subscribe((data: any) => {
            // console.log('data', data);
            // const prepareCommentData = {
            //     id: data.id,
            //     body: data.note,
            //     position: data.position,
            //     created_at: data.created_at,
            //     updated_at: data.created_at,
            //     stamp_mark: data.number,
            //     commented_by: data.user_name,
            //     commented_on: '',
            //     target_feature_id: data.hero_image_feature_id,
            //     is_owner: true,
            //     replies: data.replies
            // };
            const modifyShowCommentData = this.modifyTrackerSingleCommentData(data, '');
            this.singleCommentDataSubject.next(modifyShowCommentData);
        });
    }
  }

  ngOnDestroy() {
      this.utilityService.unsubscribeOnDestroy(this.subs);
  }
  
  checkCommentTooltip(cookieName): boolean {
    let showTooltip;
    if (this.cookieService.getCookie(cookieName) === null) {
        this.cookieService.setCookie(cookieName, 'false');
        showTooltip = true;
    } else {
        if (JSON.parse(this.cookieService.getCookie(cookieName)[1])) {
            showTooltip = false;
        } else {
            showTooltip = true;
        }
    }
    return showTooltip;
  }
}

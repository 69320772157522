import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    ViewChild,
    SimpleChanges,
    OnDestroy,
    ViewChildren,
    QueryList,
    ElementRef
} from '@angular/core';
import {HotspotModel} from '../../../main/shared/models/hotspot.model';
import {DataCommService} from '../../../main/shared/services/data-comm.service';
import {UtilityService} from '@shared/services/utility.service';
import {Constants} from '@shared/services/constants';
import {OverlayWithContentService} from '../overlay-with-content/overlay-with-content.service';
import {ClickableItemsModel} from 'src/app/main/shared/models/clickable-items.model';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth.service';

@Component({
    selector: 'app-feature-frame',
    templateUrl: './feature-frame.component.html',
    styleUrls: ['./feature-frame.component.scss']
})

export class FeatureFrameComponent implements OnChanges, AfterViewInit, OnDestroy {

    @Input() featureId: number;
    @Input() frameData;
    @Input() frameWidth;
    @Output() frameChanged: EventEmitter<any> = new EventEmitter();
    @Input() showAnimation: boolean;
    @Input() embedWaterMark: boolean;
    @Input() downloadPreview: boolean;
    @Input() interactions: boolean;
    @Input() noHotspotContainer: boolean;
    @Input() isUnlinkedFeature: boolean;
    @Input() isFeatureFullScreen: boolean;
    @Input() isReviewLeftSection: boolean;
    @Input() isReviewRightSection: boolean;
    @Output() isImageDownloaded: EventEmitter<any> = new EventEmitter();

    @ViewChild('frameWrapper', {static: true}) frameWrapper;
    @ViewChildren('customWrapper') customWrapper: QueryList<ElementRef>;
    
    ngOnChangeCount = 0;
    hotspots: HotspotModel[] = [];
    windowWidth: number = window.innerWidth;
    imageLoaded = false;
    frameAttributes = {
        width: 0,
        height: 0
    };
    featureImg: any = '';
    hiddenItems = [];
    displayHiddenItems = false;
    platform;
    platformType;
    public showMobOtherOptions = false;
    public waterMarkLogo: string;
    public customHotSpotImage: string;
    dataLoadSubscription: Subscription;
    isPreviewScreen: boolean;
    imageWidth: number;
    imageHeight: number;
    uploadedDesignUrl: any;
    isMobileDeviceImgFit: boolean = false;
    isMobileDeviceImgFitXAxis: boolean = false;
    originalImgHeight: number = 0;
    originalImgWidth: number = 0; 
    public padding = 24;

    constructor(
        public dataCommService: DataCommService, private overlayWithContentService: OverlayWithContentService,
        public utilityService: UtilityService, private cdRef: ChangeDetectorRef, private router: Router,
        public authService: AuthService) {
        this.isPreviewScreen = this.router.url.includes('preview');
        this.platform = this.dataCommService.platform;
        this.platformType = this.dataCommService.platformType;

        this.dataLoadSubscription = this.dataCommService.switchViewSource$.subscribe((value: string) => {
            this.platform = value;
        });
        
        this.waterMarkLogo = Constants.watermarklogo;
        this.customHotSpotImage = Constants.customeHotSpotImage;

        this.dataCommService.touchGesture$.subscribe((value: string) => {
            if(!this.dataCommService.landScapeMode) this.dataCommService.isPreviewHorizontalScroll = false;
            this.imageHeight = 0;
            this.imageWidth = 0;
        });

        if (router.url.includes('preview'))
            this.dataCommService.getPreviewHotspotHighlight().subscribe((flag: boolean) => {
                setTimeout(_ => {
                    if (flag) {
                        this.customWrapper?.forEach(e => {
                            if (e.nativeElement.children.length && this.getAlwaysHighlight(e))
                                for (let i = 0; i < e.nativeElement.children.length; i++) e.nativeElement.children[i].classList.add('always-highlight');
                        });
                    }
                    else {
                        this.customWrapper?.forEach(e => {
                            if (e.nativeElement.children.length && this.getAlwaysHighlight(e))
                                for (let i = 0; i < e.nativeElement.children.length; i++) e.nativeElement.children[i].classList.remove('always-highlight');
                        });
                    }
                });
            });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth > 480) {
            this.showMobOtherOptions = false;
        }
    }

    tap(evt) {
        if (evt.tapCount === 2 && this.windowWidth <= 480) {
            this.showMobOtherOptions = true;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
      //  this.cdRef.detectChanges();
        if (this.frameData && changes.frameData) {
            this.displayHiddenItems = false;
            if (this.ngOnChangeCount > 1 && changes.frameData.currentValue.id !== changes.frameData.previousValue.id) {
                this.imageLoaded = false;
            }
            this.ngOnChangeCount++;
            this.cdRef.detectChanges();
            this.sortCarouselImgs();
            this.segregateHotspots(this.frameData.hotspots);
            if (this.downloadPreview) {
                this.encodeImageToBase64();
            } else {
                this.featureImg = this.frameData.hero_image_url ? this.frameData.hero_image_url : (this.dataCommService.platform === 'mobile') ? '../../../assets/images/default_Splash.png': '../../../assets/images/default_Splash_web.jpg';
            }
        }
    }

    sortCarouselImgs() {
        const findCarousel = this.frameData.hotspots.findIndex(arrObj => arrObj.category_id === 6);
        if (findCarousel !== -1) {
            this.frameData.hotspots[findCarousel].clickable_items.sort((a, b) => {
                if (a.image_attributes.image_number > b.image_attributes.image_number) {
                  return 0;
                } else {
                  return -1;
                }
            });
        }
    }

    encodeImageToBase64() {
        this.utilityService.imgToBase64(this.frameData.hero_image_url).then((imageTobase64) => {
            this.featureImg = imageTobase64;
        });
    }

    setScale(frameWidth) {
        this.isMobileDeviceImgFit = false;
        this.isMobileDeviceImgFitXAxis = false;
        const url = this.router.url;
        if (url.includes('preview')  || url.includes('reviewScreens') || this.isFeatureFullScreen ||  url.includes('playmode')) {
            const styleObj: any = {},
                maxHeight: number = url.includes('reviewScreens') ? 481 : this.isFeatureFullScreen ? this.dataCommService.platform === 'web' ? 386 : 534 : url.includes('playmode') ? this.dataCommService.userDevice === 'mobile' ? this.dataCommService.userDeviceOS === 'iOS' ? document.querySelector('body').getBoundingClientRect().height : document.querySelector('body').getBoundingClientRect().height - 60 : 359 : this.dataCommService.checkIsMobileDevice() || (this.dataCommService.landScapeMode && this.dataCommService.isPreviewHorizontalScroll) ? document.querySelector('.prototype-container.custom-view').getBoundingClientRect().height - 50 : this.dataCommService.platform === 'web' ? 545 : 575,
                maxWidth: number =  url.includes('reviewScreens') ? this.dataCommService.platform === 'web' ? 650 : 272 : this.isFeatureFullScreen ? this.dataCommService.platform === 'web' ? 720 : 300 : url.includes('playmode') ? this.dataCommService.userDevice === 'mobile' ? document.querySelector('body').getBoundingClientRect().width : 201 : this.dataCommService.checkIsMobileDevice() || (this.dataCommService.landScapeMode && this.dataCommService.isPreviewHorizontalScroll) ? document.querySelector('.prototype-container.custom-view').getBoundingClientRect().width - 30 : this.dataCommService.platform === 'web' ? this.dataCommService.isLargeWebFrame ? 970 : 727 : 272,
                moveToCenterWidthCheck: number = url.includes('reviewScreens') ? this.dataCommService.platform === 'web' ? 625 : 250 : this.isFeatureFullScreen ? this.dataCommService.platform === 'web' ? 700 : 280 :  url.includes('playmode') ? this.dataCommService.userDevice === 'mobile' ? document.querySelector('body').getBoundingClientRect().width - 50 : 179 : this.dataCommService.platform === 'web' ? this.dataCommService.isLargeWebFrame ? 943 : 700 : 250;

            if(this.dataCommService.landScapeMode && this.imageWidth && this.imageWidth <= 480) this.dataCommService.isPreviewHorizontalScroll = false;
            
            if(!url.includes('reviewScreens') && this.imageHeight && this.imageWidth)
            this.dataCommService.previewPerfectScrollBarConfig = {
                suppressScrollX: true,
                suppressScrollY: true
            };

            styleObj.position = 'absolute';
            styleObj.height = url.includes('preview') ? this.imageHeight >= 575 ? this.imageHeight + 'px' : '575px' : url.includes('playmode') ? this.imageHeight >= 359 ? this.imageHeight + 'px' : '359px' : this.imageHeight >= 667 ? this.imageHeight + 'px' : '667px';
            styleObj.width = url.includes('preview') ? this.imageWidth >= 272 ? this.imageWidth + 'px' : '272px' : url.includes('playmode') ? this.imageHeight >= 201 ? this.imageHeight + 'px' : '201px' : this.imageWidth >= 375 ? this.imageWidth + 'px' : '375px';

            if(this.imageHeight) this.imageHeight = url.includes('preview') ? this.imageHeight >= 575 ? this.imageHeight : 575 : url.includes('playmode') ? this.imageHeight >= 359 ? this.imageHeight : 359 : this.imageHeight >= 667 ? this.imageHeight : 667;
            if(this.imageWidth) this.imageWidth = url.includes('preview') ? this.imageWidth >= 272 ? this.imageWidth : 272 : url.includes('playmode') ? this.imageWidth >= 201 ? this.imageWidth : 201 : this.imageWidth >= 375 ? this.imageWidth : 375;

            if (this.imageHeight > maxHeight && this.imageWidth > maxWidth) {
                let updatedImage = this.calculateAspectRatioFitDimensions(this.imageWidth, this.imageHeight, maxWidth, maxHeight);
                
                if (updatedImage.width > maxWidth) {
                    if (url.includes('reviewScreens')){
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id] = {};
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id]['suppressScrollX'] = false;
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id]['suppressScrollY'] = true;
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id]['useBothWheelAxes'] = true;
                    }
                    else {
                        this.dataCommService.previewPerfectScrollBarConfig.suppressScrollX = false;
                        this.dataCommService.previewPerfectScrollBarConfig.suppressScrollY = true;
                        this.dataCommService.previewPerfectScrollBarConfig.useBothWheelAxes = true;
                    }
                }

                if (updatedImage.height > maxHeight) {
                    if (url.includes('reviewScreens')) {
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id] = {};
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id]['suppressScrollX'] = true;
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id]['suppressScrollY'] = false;
                    }
                    else {
                        this.dataCommService.previewPerfectScrollBarConfig.suppressScrollX = true;
                        this.dataCommService.previewPerfectScrollBarConfig.suppressScrollY = false;
                    }
                }

                if(this.isReviewLeftSection) this.dataCommService.leftReviewImageScale = updatedImage.ratio;
                else if(this.isReviewRightSection) this.dataCommService.rightReviewImageScale = updatedImage.ratio;
                else this.overlayWithContentService.hotspotImageScale = updatedImage.ratio;
                
                if (this.imageWidth * updatedImage.ratio < moveToCenterWidthCheck && !this.dataCommService.checkIsMobileDevice()) {
                    styleObj.transform = `translateX(-50%) scale( ${updatedImage.ratio})`;
                    styleObj['transform-origin'] = 'top';
                    styleObj['left'] = this.dataCommService.checkIsTab() ? '50%!important' : '50%';
                }
                else {
                    if (url.includes('playmode') && this.dataCommService.userDevice === 'mobile') {
                        styleObj['left'] = '50%';
                        styleObj['transform'] = `translateX(-50%) scale( ${updatedImage.ratio})`;
                        styleObj['transform-origin'] = 'top';
                    }
                    else {
                        styleObj.transform = `scale( ${updatedImage.ratio})`;
                        styleObj['transform-origin'] = 'top left';
                    }
                }

            } else {
                if (this.imageHeight > maxHeight) {
                    if (url.includes('reviewScreens')) {
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id] = {};
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id].suppressScrollX = true;
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id].suppressScrollY = false;
                    }
                    else {
                        this.dataCommService.previewPerfectScrollBarConfig.suppressScrollX = true;
                        this.dataCommService.previewPerfectScrollBarConfig.suppressScrollY = false;
                    }
                }
                else if (this.imageWidth > maxWidth) {
                    if (url.includes('reviewScreens')) {
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id] = {};
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id].suppressScrollX = false;
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id].suppressScrollY = true;
                        this.dataCommService.reviewPerfectScrollBarConfig[this.frameData.hero_image_id]['useBothWheelAxes'] = true;
                    }
                    else {
                        this.dataCommService.previewPerfectScrollBarConfig.suppressScrollX = false;
                        this.dataCommService.previewPerfectScrollBarConfig.suppressScrollY = true;
                    }
                }

                if (this.imageWidth < moveToCenterWidthCheck) {
                    if (this.dataCommService.checkIsMobileDevice()) {
                        styleObj['transform-origin'] = 'left top';
                    } else {
                        styleObj.transform = `translateX(-50%)`;
                        styleObj['transform-origin'] = 'top';
                        styleObj['left'] = '50%';
                    }
                }
                
                if (url.includes('playmode') && this.dataCommService.userDevice === 'mobile') {
                    styleObj['left'] = '50%';
                    styleObj['transform'] = `translateX(-50%)`;
                    styleObj['transform-origin'] = 'top';
                }
            }

            if (url.includes('reviewScreens')) this.dataCommService.commentStyleObj[this.frameData.hero_image_id] = styleObj;
            else this.dataCommService.commentStyleObj = styleObj;

            this.dataCommService.previewStyleObj = styleObj;

            if ((this.dataCommService.checkIsMobileDevice() && !this.dataCommService.previewPerfectScrollBarConfig.suppressScrollX) || (this.dataCommService.landScapeMode && this.dataCommService.isPreviewHorizontalScroll)) {
                styleObj.height = maxHeight + 'px';
                styleObj.width = maxWidth + 'px';
                styleObj['transform'] = 'scale(1)';
                styleObj['left'] = 0;
                styleObj['top'] = 0;
                this.dataCommService.previewPerfectScrollBarConfig.suppressScrollX = true;
                if(this.imageWidth > 480) {
                    this.dataCommService.isPreviewHorizontalScroll = true;
                    this.overlayWithContentService.hotspotImageScale = 1;
                }
            }
            else if (this.dataCommService.checkIsMobileDevice() && this.imageHeight < maxHeight && this.imageWidth < maxWidth) {
                styleObj.height = maxHeight + 'px';
                styleObj.width = maxWidth + 'px';
                this.isMobileDeviceImgFit = true;
            }
            else if (this.dataCommService.checkIsMobileDevice() && (this.originalImgWidth < maxWidth || this.imageWidth < maxWidth) && this.imageHeight > maxHeight) {
                styleObj.width = (this.originalImgWidth < maxWidth) ? this.imageWidth + 'px' : maxWidth + 'px';
                this.isMobileDeviceImgFitXAxis = true;
            }

            return styleObj;
        } else {
            const styleObj: any = {};
            const viewWidth = this.platform === 'web' ? 1440 : 375;
            // const webViewScreen = frameWidth - ((40 * frameWidth) / 100 );
            let paddingHeight: number = this.padding,
            paddingWidth: number = this.padding;
            if (this.windowWidth > 480) {
                if (this.platform === 'web' && this.dataCommService.prototypeType === 'custom' && this.isPreviewScreen) {
                    // styleObj.transform = `translate(-50%,-50%) scale( ${webViewScreen / viewWidth} )`;
                    styleObj.transform = `translate(-50%,-50%) scale(0.6)`;
                    styleObj.top = `50%`;
                    styleObj.left = `50%`;
                } else {
                    if (this.isUnlinkedFeature || this.isPreviewScreen) {
                        styleObj.transform = `translate(-50%,-50%) scale(${frameWidth / viewWidth})`;
                        styleObj.left = `50%`;
                        styleObj.top = `50%`;
                    } else {
                        styleObj.height = this.imageHeight >= 667 ? this.imageHeight + paddingHeight + 'px' : '691px';
                        styleObj.width = this.imageWidth >= 375 ? this.imageWidth + paddingWidth + 'px' : '399px';
                    }
                }
            } else {
                styleObj.transform = `translate(-50%,-50%) scale( ${this.windowWidth / viewWidth} )`;
                styleObj.top = `50%`;
                styleObj.left = `50%`;
            }
            return styleObj;
        }
    }

    calculateAspectRatioFitDimensions(srcWidth, srcHeight, maxWidth, maxHeight) {
        var ratio = 1;
        if (srcWidth > srcHeight) ratio = maxHeight / srcHeight;
        else ratio = maxWidth / srcWidth;
        return { width: srcWidth * ratio, height: srcHeight * ratio, ratio: ratio };
      }

    getImageStyle() {
        let styleObj = {};

        if (this.router.url.includes('playmode') && this.dataCommService.userDevice === 'mobile' && !this.dataCommService.landScapeMode) {
            styleObj['left'] = '50%';
            styleObj['position'] = 'relative';
            styleObj['transform'] = 'translateX(-50%)';
        }

        if (this.dataCommService.checkIsMobileDevice() && this.isMobileDeviceImgFit && !this.router.url.includes('playmode')) {
            styleObj['height'] = document.querySelector('.prototype-container.custom-view').getBoundingClientRect().height - 50 + 'px';
            styleObj['width'] = '100%';
        }
        else if (this.dataCommService.checkIsMobileDevice() && this.isMobileDeviceImgFitXAxis) {
            styleObj['height'] = this.imageHeight + 'px';
            styleObj['width'] = '100%';
        }

        return styleObj;
    }

    ngAfterViewInit(): void {
        if (this.frameWrapper && this.frameWrapper.nativeElement) {
            this.frameAttributes.width = this.frameWrapper.nativeElement.offsetWidth;
            this.frameAttributes.height = this.frameWrapper.nativeElement.offsetHeight;
        }
    }

    encodeImage(imageUrl, hotspotIndex, itemIndex) {
        if (this.downloadPreview) {
            this.utilityService.imgToBase64(imageUrl).then((imageTobase64) => {
                this.hotspots[hotspotIndex].clickable_items[itemIndex].encodedImageUrl = imageTobase64;
            });
        } else {
            this.hotspots[hotspotIndex].clickable_items[itemIndex].encodedImageUrl = imageUrl;
        }
    }

    rightClickHndlr(evt) {
        evt.preventDefault();
    }

    segregateHotspots(data: HotspotModel[]) {
        this.hotspots = this.utilityService.cloneObject(data);
        if (this.hotspots) {
            // encode all hotspots images to base64
            this.hotspots.forEach((hotspot, hIndex) => {
                hotspot.clickable_items.forEach((item: ClickableItemsModel, itemIndex) => {
                    if (item.image !== null) {
                        this.encodeImage(item.image, hIndex, itemIndex);
                    }
                });
            });
        }
    }

    // getting style for custom hotspots using their position object
    getStyle(refObj: HotspotModel) {
        if (refObj && (refObj.clickable_items.length > 0  || refObj.category_id === 5) && this.imageHeight && this.imageWidth) {
            let updatedDate = refObj.clickable_items.length ? new Date(refObj.clickable_items[0]['updated_at']) : new Date(),
                releaseDate = new Date('2021-06-03');
            const positionObj = refObj.position;
            const styleObj: any = {};
            const scalefactor = (updatedDate > releaseDate || (screen.width <= 480 || screen.height <= 480)) && (this.imageWidth > 375 && this.imageHeight > 667) ? (this.router.url.includes('playmode') || refObj.sketch_generated) && this.dataCommService.userDevice !== 'mobile' ? 1 : this.calculateScaleFactorForHotspotPlacement(this.imageWidth, this.imageHeight) : 1;

            const imageWidth = (screen.width <= 480 || screen.height <= 480) ? document.querySelector('.hero-image-container img') && document.querySelector('.hero-image-container img').getBoundingClientRect().width / this.overlayWithContentService.hotspotImageScale : this.originalImgWidth;
            const imageHeight = (screen.width <= 480 || screen.height <= 480) ? document.querySelector('.hero-image-container img') && document.querySelector('.hero-image-container img').getBoundingClientRect().height / this.overlayWithContentService.hotspotImageScale : this.originalImgHeight;
            
            if (refObj.category_id === 6) {
                if (screen.width <= 480 || screen.height <= 480 && this.router.url.includes('preview')) {
                    styleObj.width = imageWidth;
                    styleObj.height = refObj.position.height * scalefactor;
                    styleObj.left = 0;
                    styleObj.top = positionObj.originY * scalefactor;
                    styleObj.padding = '0px';
                }
                else {
                    styleObj.width = imageWidth + this.padding;
                    styleObj.height = refObj.position.height;
                    if (this.router.url.includes('preview')) {
                        styleObj.left = -4;
                        styleObj.top = positionObj.originY - 3;
                    } else {
                        styleObj.left = 0;
                        styleObj.top = positionObj.originY;
                    }
                }
            } else if (+positionObj.percent_height) {
                styleObj.left = (imageWidth * positionObj.percent_originX)/100;
                styleObj.top = (imageHeight * positionObj.percent_originY)/100;
                styleObj.width = (imageWidth * positionObj.percent_width)/100;
                styleObj.height = (imageHeight * positionObj.percent_height)/100;
            }
            else {
                if (screen.height <= 480 || screen.width <= 480) {
                    styleObj.left = positionObj.originX * scalefactor;
                    styleObj.top = positionObj.originY * scalefactor;
                    styleObj.width = refObj.position.width * scalefactor;
                    styleObj.height = refObj.position.height * scalefactor;
                }
                else {
                    styleObj.left = positionObj.originX / scalefactor;
                    styleObj.top = positionObj.originY / scalefactor;
                    styleObj.width = refObj.position.width / scalefactor;
                    styleObj.height = refObj.position.height / scalefactor;
                }
            } 

            if (refObj.category_id !== 6) {
                this.randomlyScatterHotspots(styleObj, imageWidth, imageHeight);
            }         

            styleObj.left = styleObj.left + 'px';
            styleObj.top = styleObj.top + 'px';
            styleObj.width = styleObj.width + 'px';
            styleObj.height = styleObj.height + 'px';

            return styleObj;
        } else {
            return {};
        }
    }

    randomlyScatterHotspots(position, imageWidth, imageHeight) {
        let currentSize = {
            height: this.dataCommService.checkIsMobileDevice() || (this.dataCommService.landScapeMode && this.dataCommService.isPreviewHorizontalScroll) ? document.querySelector('.hero-image-container img') && document.querySelector('.hero-image-container img').getBoundingClientRect().height / this.overlayWithContentService.hotspotImageScale : imageHeight,
            width: this.dataCommService.checkIsMobileDevice() || (this.dataCommService.landScapeMode && this.dataCommService.isPreviewHorizontalScroll) ? document.querySelector('.hero-image-container img') && document.querySelector('.hero-image-container img').getBoundingClientRect().width / this.overlayWithContentService.hotspotImageScale : imageWidth
        };
    
        // if (!position.left) {
        //   position.left = 100;
        // }
    
        // if (!position.top) {
        //   position.top = 50;
        // }
    
        if (position.left > (currentSize.width - position.width)) {
          position.left = currentSize.width - position.width - 10;
        }
    
        if (position.top > (currentSize.height - position.height)) {
          position.top = currentSize.height - position.height;
        }
    }
    
    calculateScaleFactorForHotspotPlacement(srcWidth, srcHeight) {
        let maxHeight: number = (screen.width <= 480 || screen.height <= 480) ? document.querySelector('.hero-image-container img') && document.querySelector('.hero-image-container img').getBoundingClientRect().height / this.overlayWithContentService.hotspotImageScale : this.imageHeight,
            maxWidth: number =  (screen.width <= 480 || screen.height <= 480) ? document.querySelector('.hero-image-container img') && document.querySelector('.hero-image-container img').getBoundingClientRect().width / this.overlayWithContentService.hotspotImageScale : this.imageWidth;
        let ratio = 1;

        if (srcWidth > srcHeight) ratio = maxHeight / srcHeight;
        else ratio = maxWidth / srcWidth;

        return ratio;
    }

    changeFrame(clickableItem) {
        if (clickableItem && clickableItem.target_feature_uniq_code) {
            // this.hotspottopcollapse = false;
            // this.hotspotfootercollapse = false;
            const frameIndex = this.dataCommService.featureListMobile.findIndex(element => {
                return element.uniq_code === clickableItem.target_feature_uniq_code;
            });
            if (frameIndex !== -1) {
                this.dataCommService.featureUniqCode = this.dataCommService.featureListMobile[frameIndex].uniq_code;
                this.frameChanged.next({
                        uniq_code: clickableItem.target_feature_uniq_code,
                        transition: clickableItem.transition
                    }
                );
            }
        }
    }

    setManualHotspotSize(hotspot) {
        const styleObj: any = {};
        const imageWidth = +hotspot.sidekick_image_attributes.width;
        const imageHeight = +hotspot.sidekick_image_attributes.height;
        const hotspotWidth = +hotspot.position.width;
        const hotspotHeight = +hotspot.position.height;
        if (imageWidth === imageHeight) {
            if (hotspotWidth >= hotspotHeight) {
                styleObj.height = hotspotHeight;
                styleObj.width = hotspotHeight;
            } else {
                styleObj.height = hotspotWidth;
                styleObj.width = hotspotWidth;
            }
        } else if (imageWidth > imageHeight) {
            if (hotspotWidth > hotspotHeight) {
                if ((imageWidth / imageHeight) >= (hotspotWidth / hotspotHeight)) {
                    styleObj.height = imageHeight * (hotspotWidth / imageWidth);
                    styleObj.width = hotspotWidth;
                } else {
                    styleObj.height = hotspotHeight;
                    styleObj.width = imageWidth * (hotspotHeight / imageHeight);
                }
            } else {
                styleObj.height = imageHeight * (hotspotWidth / imageWidth);
                styleObj.width = hotspotWidth;
            }
        } else {
            if (hotspotWidth > hotspotHeight) {
                styleObj.height = hotspotHeight;
                styleObj.width = imageWidth * (hotspotHeight / imageHeight);
            } else {
                if ((imageHeight / imageWidth) >= (hotspotHeight / hotspotWidth)) {
                    styleObj.height = hotspotHeight;
                    styleObj.width = imageWidth * (hotspotHeight / imageHeight);
                } else {
                    styleObj.height = imageHeight * (hotspotWidth / imageWidth);
                    styleObj.width = hotspotWidth;
                }
            }
        }
        styleObj.width = styleObj.width + 'px';
        styleObj.height = styleObj.height + 'px';
        return styleObj;
    }

    setManualHotsoptClass(hotspot, type?) {
        return hotspot.uniq_code !== null && hotspot.is_hero_image_present ? 'cursorPointer' : '';
    }

    onManualClick(clickableItem, type?) {
        if (type !== 'hotspot') {
            this.dataCommService.trackEvent('preview_hotspot_interacted', {
                user_id: this.dataCommService.userUniqueid,
                user_browser: this.dataCommService.userBrowser,
                user_device: this.dataCommService.userDevice
            });
            this.changeFrame(clickableItem);
            this.dataCommService.featureTitleSouce.next(false);
            this.displayHiddenItems = false;
        } else {
            this.dataCommService.trackEvent('prototype_interacted', {
                user_id: this.dataCommService.userUniqueid,
                user_browser: this.dataCommService.userBrowser,
                user_device: this.dataCommService.userDevice,
                tap_type: 'Dropdown'
            });
            this.changeFrame(clickableItem.clickable_items[0]);
            this.dataCommService.featureTitleSouce.next(false);
            this.displayHiddenItems = false;
        }
    }

    isUserRequested(hotspot) {
        if (hotspot.user_requested || hotspot.sidekick_image_url !== null) {
            return true;
        } else {
            return false;
        }
    }

    hideImageLoader() {
        this.imageLoaded = true;
        this.getImageDetails();
        if (this.isImageDownloaded) {
            this.isImageDownloaded.emit();
        }
        this.cdRef.detectChanges();
    }


    getImageDetails(){
        var img = new Image();
        img.onload = () => {
            this.originalImgHeight = img.height;
            this.originalImgWidth = img.width;
            this.imageHeight = img.height;
            this.imageWidth = img.width;
        };
        img.src = this.featureImg ? this.featureImg : (this.dataCommService.platform === 'mobile'|| this.dataCommService.platform === 'watch') ? '../../../assets/images/default_Splash.png': '../../../assets/images/default_Splash_web.jpg';
    }

    showHiddenItems(hotspot) {
        const itemsDisplayed = (Math.floor(hotspot.position.width / 40)) * Math.floor((hotspot.position.height / 40)) - 1;
        this.hiddenItems = hotspot.clickable_items.slice(itemsDisplayed);
        if (this.interactions) {
            this.dataCommService.featureTitleSouce.next(true);
            this.displayHiddenItems = true;
        }
    }

    findHiddenItems(hotspot) {
        const itemsDisplayed = (Math.floor(hotspot.position.width / 40)) * Math.floor((hotspot.position.height / 40)) - 1;
        this.hiddenItems = hotspot.clickable_items.slice(itemsDisplayed);
        return this.hiddenItems;
    }

    hideHiddenItems() {
        if (this.interactions) {
            this.dataCommService.featureTitleSouce.next(false);
            this.displayHiddenItems = false;
        }
    }

    displayItem(index, hotspot, clickableItem) {
        if (!clickableItem.target_feature_uniq_code) {
            return false;
        }

        if (!hotspot.category_id) {
            return true;
        }
        if (this.dataCommService.prototypeType === 'instant') {
            const col = Math.floor(hotspot.position.width / 40);
            const row = Math.floor(hotspot.position.height / 40);
            const totalItemsPossible = row && col ? row * col : row ? row : col;

            if (hotspot.clickable_items.length > totalItemsPossible) {
                if (index <= totalItemsPossible - 2) {
                    return true;
                } else {
                    return false;
                }
            } else if (hotspot.clickable_items.length <= totalItemsPossible) {
                if (index <= totalItemsPossible - 1) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        else return true;
    }

    displayDropDown(hotspot) {
        const col = Math.floor(hotspot.position.width / 40);
        const row = Math.floor(hotspot.position.height / 40);
        const totalItemsPossible = row && col ? row * col : row ? row : col;

        if (hotspot.clickable_items.length > totalItemsPossible && this.dataCommService.prototypeType === 'instant') {
            return true;
        } else {
            return false;
        }
    }

    hideMobOtherOptions() {
        this.showMobOtherOptions = false;
    }

    getUserReqHotspotImage(clickableItem, hotspot) {
        let clickableItemDiv: any = {};
        if (hotspot.position.width < 60) {
            const ratio = 60 + ((hotspot.position.width - 40) * 2);
            const width = (hotspot.position.width * ratio) / 100;
            const height = (hotspot.position.height * ratio) / 100;
            // const left = (hotspot.position.width * ((100 - ratio) / 2)) / 100;
            // const top = (hotspot.position.height * ((100 - ratio) / 2)) / 100;
            clickableItemDiv = {
                // 'background-image': `url('${clickableItem.encodedImageUrl}')`,
                // width: `${Math.floor(width)}px`,
                // height: `${Math.floor(height)}px`,
                // left: `${Math.floor(left)}px`,
                // top: `${Math.floor(top)}px`
                'background-size': `${Math.floor(width)}px ${Math.floor(height)}px`
            }
        }

        if (clickableItem.encodedImageUrl)
            clickableItemDiv['background-image'] = `url('${clickableItem.encodedImageUrl}')`;

        return clickableItemDiv;
    }

    doAction($event, clickableItem) {
        this.onManualClick(clickableItem);
    }

    ngOnDestroy() {
        this.dataLoadSubscription.unsubscribe();
    }
    trackById(index, clickableItem) {
        return clickableItem.id;
    }

    getAppHighlight(hotspot) {
        return hotspot ? hotspot.clickable_items.length ? hotspot.clickable_items[0].image === null && hotspot.user_requested : false : false;
    }

    getHotspotContainerStyle() {
        let styleObj = {};

        styleObj['width'] = this.dataCommService.checkIsMobile() ? document.querySelector('.hero-image-container img') && document.querySelector('.hero-image-container img').getBoundingClientRect().width / this.overlayWithContentService.hotspotImageScale + 'px' : this.imageWidth + 'px';
        styleObj['height'] = this.dataCommService.checkIsMobile() ? document.querySelector('.hero-image-container img') && document.querySelector('.hero-image-container img').getBoundingClientRect().height / this.overlayWithContentService.hotspotImageScale + 'px' : this.imageHeight + 'px';

        if (screen.width <= 480 || screen.height <= 480) {
            styleObj['align-items'] = 'center';
            styleObj['justify-content'] = 'center';
            styleObj['position'] = ' absolute';
            styleObj['display'] = 'inline-block';
            styleObj['top'] = '50%';
            styleObj['left'] = '50%';
            styleObj['transform'] = 'translate(-50%, -50%)';
        }

        return styleObj;
    }

    getAlwaysHighlight(element) {
        let selectedHotspot;
        this.hotspots.forEach(hotspot => {
            if (hotspot.clickable_items.length)
                hotspot.clickable_items.forEach(clickableItem => {
                    if (clickableItem.id === +element.nativeElement.children[0].id.split('-')[1]) selectedHotspot = hotspot;
                });
        });
        return selectedHotspot ? selectedHotspot.clickable_items.length ? (selectedHotspot.category_id !== 5 && selectedHotspot.clickable_items[0].target_feature_uniq_code && (selectedHotspot.clickable_items[0].image !== null || selectedHotspot.user_requested)) || (selectedHotspot.category_id === 5 && selectedHotspot.clickable_items[0].target_feature_uniq_code && selectedHotspot.clickable_items[0].image) : false : element.nativeElement.children[0].classList.contains('iconitems');
    }

}

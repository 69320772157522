import {Component, OnInit} from '@angular/core';
import {DataCommService} from '../main/shared/services/data-comm.service';
import {AuthService} from '@core/auth.service';
import {DataService} from '../main/shared/services/data.service';
import {Router} from '@angular/router';
import {CookieService} from '@core/cookie.service';
import {Constants} from '@shared/services/constants';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})
export class Page404Component implements OnInit {
  queryParams = {};
  apiError = false;
  appName: string = '';
  constructor(public dataCommService: DataCommService, public authService: AuthService,
              private dataService: DataService, private router: Router, private cookieService: CookieService) { }

  ngOnInit() {
    this.createBuildCard();
  }

  createBuildCard() {
    this.queryParams = this.dataCommService.setQueryparams();
    this.appName = this.queryParams['application'];
    if (this.authService.isCurrencyPresentInUser()) {
      this.dataCommService.showLoaderForPrototpeCreation = true;
      this.dataService.createAndSyncBuildCardToNow(this.queryParams['application']).subscribe(resp => {
        this.queryParams = {};
        this.dataCommService.buildCardData = resp['data']['attributes'];
        this.cookieService.setCookie('buildernow_visited', 'true');
        if (this.appName === 'create-from-scratch') {
          this.getInitialPlatform();
        } else {
          this.router.navigate(['/' + this.dataCommService.buildCardData.id + '/preview']);
        }
        this.dataCommService.showLoaderForPrototpeCreation = true;
        this.dataCommService.isRedNow = true;
      }, error => {
        this.dataCommService.showLoaderForPrototpeCreation = false;
        this.queryParams = {};
        return;
      });
    } else {
      this.dataCommService.showLoaderForPrototpeCreation = true;
      this.dataService.getConfigData().subscribe((res: any) => {
        this.apiError = false;
        this.dataCommService.popUpHeadings = res.flows;
        if (this.queryParams && this.queryParams['admin_access'] && this.queryParams['token']) {
          this.dataCommService.getTouchPointHeadings('resetPassword');
          this.dataCommService.showHidePaymentFlow(true);
          this.dataCommService.selectedSectionIndex = 8;
        } else if (!this.queryParams
          || (this.queryParams['?red'] && this.queryParams['?red'] !== 'now')
          || !this.queryParams['application']) {
          this.dataCommService.showLoaderForPrototpeCreation = false;
          return;
        } else if (this.queryParams && this.queryParams['?red'] === 'now' && this.queryParams['application']) {
          this.dataCommService.isRedNow = true;
          this.dataCommService.touchPoint = Constants.touchPoints.campaignLink;
          this.dataCommService.getTouchPointHeadings('checkEmail');
          const segmentTrackerData = {
            referrer_trigger: this.dataCommService.referrer,
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice
          };
          this.dataCommService.trackEvent('sign_up_screen_opened', segmentTrackerData);
          this.dataCommService.showHidePaymentFlow(false);
          this.dataService.getClientIP().subscribe((ipRes: any) => {
            this.dataService.createGuestBuildCard(ipRes.ip, this.appName).subscribe((resp: any) => {
              this.dataCommService.buildCardData = resp['data']['attributes'];
              this.cookieService.setCookie('buildernow_visited', 'true');
              if (this.appName === 'create-from-scratch') {
                this.getInitialPlatform();
              } else {
                this.router.navigate(['/' + this.dataCommService.buildCardData.id + '/preview']);
              }              
            });
          });
        } else if (this.queryParams['?red'] && this.queryParams['?red'] === 'now') {
          this.cookieService.setCookie('buildernow_visited', 'true');
        }
      }, error => {
          if (error === 'Please try after some time!') {
              this.apiError = true;
          }
      });
    }
  }
  getInitialPlatform() {
    this.dataService.getPlatforms(this.dataCommService.buildCardData.id).subscribe((response: any) => {
      if (!response.platforms || (response.platforms && response.platforms.length === 0)) {
          setTimeout(() => {
              this.getInitialPlatform();
          }, 3000);
      }
      if (response.platforms[0].device && response.platforms[0].title) {
        this.dataCommService.platform = response.platforms[0].device;
        this.dataCommService.platformType = response.platforms[0].title.toLowerCase();
        this.router.navigate(['/' + this.dataCommService.buildCardData.id + '/dashboard/' + this.dataCommService.platform + '/' + this.dataCommService.platformType + '/flowchart']);
      }
    });
  }
}

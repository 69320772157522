import {Component, OnInit, HostListener, OnDestroy, ElementRef, Output, EventEmitter, Input} from '@angular/core';
import { CommentService } from '@shared/components/comments/comment.service';
import { UtilityService } from '@shared/services/utility.service';
import { Subscription } from 'rxjs';
import {DataCommService} from '../../../../main/shared/services/data-comm.service';
import {DataService} from '../../../../main/shared/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comment-pannel',
  templateUrl: './comment-panel.component.html',
  styleUrls: ['./comment-panel.component.scss']
})
export class CommentPanelComponent implements OnInit, OnDestroy {
  @Output() closeSideDrawer = new EventEmitter();
  commentsData: any;
  comments = [];
  pagination: any;
  commentModeEnabled = false;
  subs: Subscription[] = [];
  private commentAction = false;
  public selectedCommentId = null;
  public commentLoaded = false;
  public previewPanel = false;
  // @Input() heroImageId: any;
  @Input() frameData: any;
  public heroImgUrl;
  public showSearch: boolean = false;
  searchText: string;
  isResolvedExist = false; 
  constructor(
    private commentService: CommentService, private elRef: ElementRef,
    private utilityService: UtilityService, public dataCommService: DataCommService,
    private dataService: DataService, private router: Router
  ) {
    this.subs.push(this.commentService.singleCommentData$.subscribe((val: any) => {
      if (val) {
        //if an old version comment is opened then to close that preview drawer on same feature click.
        this.previewPanel = false;
        this.selectedCommentId = val.id;
        if (val.isReplied) {
          const commIndex = this.comments.findIndex(arrObj => arrObj.id === this.selectedCommentId);
          this.comments[commIndex] = val;
          /*this.comments.unshift(val);
          this.comments.splice(commIndex + 1, 1);*/
        }
      } else {
        this.selectedCommentId = null;
      }
    }));
    this.subs.push(this.commentService.commentModeToggle$.subscribe(val => {
      this.commentModeEnabled = val;
      if (!val) {
        this.selectedCommentId = null;
      }
    }));
    this.subs.push(this.commentService.allCommentsData$.subscribe(val => {
      if (val) {
        //if an old version comment is opened then to close that preview drawer on another feature click.
        this.previewPanel = false;
        if (val.commentAction) {
          if (val.commentAction === 'added') {
            this.comments.unshift(val.comment);
            this.commentAction = true;
            this.cloneComment();
          } else if (val.commentAction === 'deleted') {
            const commIndex = this.comments.findIndex(arrObj => arrObj.id === val.commentId);
            this.comments.splice(commIndex, 1);
            this.cloneComment();
          } else if (val.commentAction === 'updated') {
            const commIndex = this.comments.findIndex(arrObj => arrObj.id === val.selectedComment.id);
            this.comments[commIndex] = val.selectedComment;
            this.cloneComment();
          }
        } else {
          this.commentsData = utilityService.cloneObject(val);
          this.comments = [...this.comments, ...this.commentsData.comments];
          this.cloneComment();
          let isExistResolved = this.comments.find(e=> e.is_resolved);
          if(isExistResolved){
            this.isResolvedExist =false;
          }
          else{
            this.isResolvedExist = true;
          }
          if (this.dataCommService.prototypeType != 'instant') {
            this.dataCommService.resolvedCheckbox = false;
            this.resolvedCommentsToggle(false);
          }
          // this.dataCommService.resolvedCheckbox =true;
          if (this.commentAction) {
            this.comments = this.getUniqueData(this.comments);
            this.commentAction = false;
          }
          this.pagination = this.commentsData.page_data;
        
          //notification-- check when component loaded for selected notification..
          if(this.dataCommService.selectedNotification && Object.keys(this.dataCommService.selectedNotification).length !== 0){
            //notification-- show selected comment if present in the comment array
            let comment;
            if(this.dataCommService.selectedNotification.metadata.note.parent_id){
                comment = this.comments.find(e=> e.id == this.dataCommService.selectedNotification.metadata.note.parent_id);
            }
            else{
                comment = this.comments.find(e=> e.id == this.dataCommService.selectedNotification.metadata.note.id);
            }
            if(comment){
              this.showComment(comment);
              setTimeout(() => {
                let el = document.getElementById(`${comment.id}`);
                el.scrollIntoView(); 
              }, 100);
            }else{
              let allComments  = JSON.parse(JSON.stringify(this.dataCommService.clonedComments));
              comment = allComments.find(e=> e.id == this.dataCommService.selectedNotification.metadata.note.id);
              console.log(this.dataCommService.selectedNotification.metadata.note);
              if(comment && comment.is_resolved){
                this.dataCommService.resolvedCheckbox =true;
                this.resolvedCommentsToggle(true);
                this.showComment(comment);
                this.selectedCommentId = comment.id;
                setTimeout(() => {
                  let el = document.getElementById(`${comment.id}`);
                  el.scrollIntoView(); 
                }, 200);
              }
              // if (this.pagination && this.pagination.total_pages > this.pagination.current_page) {
              //   this.commentService.getCommentsData(this.frameData, false, this.pagination.current_page + 1);
              // }           
             }   
          }
          else{
            this.selectedCommentId = null;
          }
        }
      } else {
        this.isResolvedExist = true;
        this.commentsData = null;
        this.comments = [];
        this.pagination = null;
        this.frameData = null;
      }
    }));
  }
  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    event.stopPropagation();
  }

  @HostListener('document:keydown.escape', ['$event'])
  escKeyHandler(event: KeyboardEvent) {
    this.commentService.commentModeToggleSubject.next(false);
    this.commentService.singleCommentDataSubject.next(null);
  }

  ngOnInit(): void {
    this.commentLoaded = true;
    if (this.dataCommService.featureUniqCode && this.router.url.includes('preview')) {
      this.frameData = this.dataCommService.featureList.find(x =>
          x.uniq_code === this.dataCommService.featureUniqCode);
    }
    if (this.commentModeEnabled && this.frameData) {
        this.commentService.getCommentsData(this.frameData, false, 1);
    }
  }

  onReachEnd = async (event) => {
    event.stopPropagation();
    if (this.pagination && this.pagination.total_pages > this.pagination.current_page) {
      this.commentService.getCommentsData(this.frameData, false, this.pagination.current_page + 1);
    }
  }

  closeSideDrawerFun(e) {
    e.stopPropagation();
    this.comments = [];
    this.commentLoaded = false;
    this.commentService.allCommentsDataSubject.next(null);
    this.commentService.commentModeToggleSubject.next(!this.commentModeEnabled);
    this.dataCommService.trackEvent('comments_window_closed', {
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice
    });
  }

  getUniqueData(arr) {
    const uniqueArr = [];
    const itemsFound = {};
    for (let i = 0, l = arr.length; i < l; i++) {
      const stringified = JSON.stringify(arr[i]);
      if (itemsFound[stringified]) { continue; }
      uniqueArr.push(arr[i]);
      itemsFound[stringified] = true;
    }
    return uniqueArr;
  }
  showComment(comment) {
    this.dataCommService.showFilter =false;
    if (this.dataCommService.prototypeType !== 'instant' && this.frameData && comment.version < this.frameData.version) {
      this.dataService.getPreview(this.dataCommService.nowBuildCardId, comment.target_feature_id)
        .subscribe((res: any) => {
          this.previewPanel = true;
          this.heroImgUrl = res.hero_image_url;
        }, error => {
        });
    } else {
      this.previewPanel = false;
    }
    let valObj;
    if (this.dataCommService.prototypeType === 'instant' ||
        (this.dataCommService.prototypeType === 'custom' &&
            (parseInt(comment.version, 10) === this.frameData.version) || this.dataCommService.isCurrentPrototypeApproved)
    ) {
      valObj = this.utilityService.cloneObject(comment);
    } else {
      valObj = null;
    }
    this.commentService.singleCommentDataSubject.next(valObj);
  }
  closePreviewPanel() {
    this.previewPanel = false;
  }
  getCommentOrReply(comment, label) {
    const lastReply = comment.replies[comment.replies.length - 1];
    if (label === 'time') {
      let replyTime = null;
      if (comment.replies.length > 0) {
        replyTime = lastReply.created_at;
      }
      if (replyTime && new Date(replyTime) > new Date(comment.created_at)) {
        return replyTime;
      } else {
        return comment.created_at ? comment.created_at : null;
      }
    } else if (label === 'author') {
      return (comment.replies.length > 0) ? lastReply.replied_by.split(' ')[0] + '|' +
          'Replied' : comment.commented_by.split(' ')[0] + '|' + 'Commented';
    }
  }
  ngOnDestroy() {
    this.searchText =""
    this.comments = JSON.parse(JSON.stringify(this.dataCommService.clonedComments));
    this.utilityService.unsubscribeOnDestroy(this.subs);
    this.elRef.nativeElement.removeEventListener('document:keydown.escape', this.escKeyHandler);
    this.dataCommService.showFilter = false;
    this.dataCommService.resolvedCheckbox = false;
  }

  showFilter(event){
   if(event){
    event.stopPropagation();
   }
   this.dataCommService.showFilter = !this.dataCommService.showFilter;
  }

  cloneComment(){
    this.dataCommService.clonedComments = JSON.parse(JSON.stringify(this.comments));
  }
  resolvedCommentsToggle(value){
    if(value){
       const allComments = JSON.parse(JSON.stringify(this.dataCommService.clonedComments));
       this.comments = allComments;
    }
   else{    
    const allComments = JSON.parse(JSON.stringify(this.dataCommService.clonedComments));
    this.comments = allComments.filter(e=>e.is_resolved != true);
   }
  }

  openCloseSearchComment(event) {
    event.stopPropagation();
    this.searchText ="";
    this.showSearch = !this.showSearch;
  }

  emptyDiv($event){
    $event.stopPropagation();
    this.dataCommService.showFilter =false;

  }

}

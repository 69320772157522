// tslint:disable: variable-name
import { HotspotModel } from './hotspot.model';

export class PreviewModel {
    uniq_code: string;
    id: number;
    title: string;
    hero_image_url: string;
    // description: string;
    hotspots: HotspotModel[];
    // transition: string;
    is_launch_screen: boolean;
    hero_image_attributes: any;
    user_uploaded: boolean;
    platform: string;
    hero_image_id: any;
    image_downloaded: boolean;
    status: string;
    comment: string;
    version: any;
    studio_feature_id: any;
    description: string;
    sub_features: any[];
    frameIndex: any;
    parentTitle: any;
    frameStyleObj: any;
    previewStyleObj: any;
    transition: any;
}

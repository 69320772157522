import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent {

  projectName;
  shareUrl;
  platform;
  platformType;

  constructor(
    private dataCommService: DataCommService,
    private router: Router
  ) {
    this.projectName = this.dataCommService.projectName;
    this.platform = this.dataCommService.platform;
    this.platformType = this.dataCommService.platformType;
  }

  ngOnInit() {
    if (this.router.url.includes('playmode')) this.shareUrl = this.dataCommService.sharingUrl + `/${this.platform}/${this.platformType}`;
    else this.shareUrl = this.dataCommService.sharingUrl + `/${this.platform}/${this.platformType}/launch`;
  }

  openedSocial(e) {
    let eventName = this.router.url.includes('playmode') ? 'play_mode_shared' : 'prototype_shared';
    this.dataCommService.trackEvent(eventName, {
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice,
      'Share type': 'social',
      'Password protected?': this.dataCommService.isPasswordProtected ? 'Y' : 'N',
      'Social channel': e
    });
  }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../../main/shared/services/data.service';
import {Constants} from '@shared/services/constants';
import {Subscription} from 'rxjs';
import {DataCommService} from '../../../main/shared/services/data-comm.service';
import {ToasterService} from '@core/toaster.service';
import {WindowRefService} from '@core/window-ref.service';
import {Router} from '@angular/router';
import {UtilityService} from "@shared/services/utility.service";
@Component({
    selector: 'app-sync-data',
    templateUrl: './sync-data.component.html',
    styleUrls: ['./sync-data.component.scss']
})
export class SyncDataComponent implements OnInit, OnDestroy {
    @Input() cmsUpdated;
    @Input() userUpdated;
    updateDataOverlay = false;
    syncDataSubscription: Subscription;
    headText = '';
    msgBody = '';
    checkMsg = '';
    msgNoUpdated = '';
    updateBtnTxt = '';
    cancelBtnTxt = '';
    overlap = true;
    constructor(private dataService: DataService,
                public dataCommService: DataCommService,
                private toasterService: ToasterService,
                private windowRef: WindowRefService,
                private router: Router,
                private utilityService: UtilityService ) {
        this.dataCommService.syncData$.subscribe((data: any) => {
            this.cmsUpdated = data.cmsUpdated;
            this.userUpdated = data.userUpdated;
            const dialogBoxMsg = (dataCommService.isCpe === true) ? Constants.syncDialogboxCPE : Constants.syncDialogbox;
            this.headText = dialogBoxMsg.confirmHeaderTxt.replace('{syncScreenNo}', this.cmsUpdated);
            this.msgBody = dialogBoxMsg.msg.replace('{editedScreenNo}', this.userUpdated);
            this.msgNoUpdated = dialogBoxMsg.msgNoUpdated;
            this.checkMsg = dialogBoxMsg.checkMsg;
            this.updateBtnTxt = dialogBoxMsg.confirmBtnTxt;
            this.cancelBtnTxt = dialogBoxMsg.cancelBtnTxt;
        });

        this.dataCommService.syncOverlay$.subscribe((data: any) => {
            this.openUpdateOverlay();
        });
    }

    ngOnInit() {
    }

    openUpdateInfo() {
        this.dataCommService.updateInfo = true;
        this.dataCommService.rippleEffect = false;
    }

    closeUpdateInfo($event) {
        $event.stopPropagation();
        this.dataCommService.updateInfo = false;
        this.dataCommService.rippleEffect = true;
    }

    overlapCMSData($event) {
        if ($event.target.checked) {
            this.overlap = true;
        } else {
            this.overlap = false;
        }
    }

    openUpdateOverlay() {
        this.updateDataOverlay = true;
    }

    cancelUpdateOverlay() {
        this.updateDataOverlay = false;
    }

    syncCMSData() {
        if (this.syncDataSubscription) {
            this.syncDataSubscription.unsubscribe();
        }
        const data = {
            device: this.dataCommService.platform,
            platform: this.dataCommService.platformType,
            overlap_changes: this.overlap
        };
        this.syncDataSubscription = this.dataService.syncData(data,
            this.dataCommService.nowBuildCardId).subscribe((res: any) => {
                this.dataCommService.setFeatureList(res);
                this.updateDataOverlay = false;
                this.dataCommService.synOverlay = false;
                this.toasterService.success(res.message);
                this.utilityService.redirectTo(this.router.url);
        }, err => {
            this.toasterService.success(Constants.toasterMessage.ERROR);
        });
    }

    ngOnDestroy(): void {
        if (this.syncDataSubscription) {
            this.syncDataSubscription.unsubscribe();
        }
    }
}

import {
  Component,
  OnDestroy,
  Output,
  EventEmitter,
  HostListener,
  ElementRef,
  Input,
  SecurityContext,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { CommentModel } from 'src/app/main/shared/models/comment.model';
import { AuthService } from '@core/auth.service';
import { CommentService } from '../comment.service';
import { Subscription } from 'rxjs';
import { UtilityService } from '@shared/services/utility.service';
import { DataService } from 'src/app/main/shared/services/data.service';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Constants } from '@shared/services/constants';
import { ToasterService } from '@core/toaster.service';
import { OverlayWithContentService } from '@shared/components/overlay-with-content/overlay-with-content.service';
import { CookieService } from '@core/cookie.service';

@Component({
  selector: 'app-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.scss']
})
export class CommentBoxComponent implements OnDestroy ,AfterViewInit{

  currentUserName = '';
  @Input() heroImageId: number;
  @Input() versionToCommentOn;
  @Input() storyId;
  @Input() feature;
  @Input() isReviewLeftSection: boolean;
  @Input() isReviewRightSection: boolean;
  commentData: CommentModel;
  commentDataMaster: CommentModel;
  subs: Subscription[] = [];
  writingDesignerTask = false;
  builderTxtPosition = 0;
  @Output() cancelCommentClick = new EventEmitter();
  @Output() commentDeleted = new EventEmitter();
  @Output() newCommentAdded = new EventEmitter();
  editingComment = false;
  userInfo;
  public isCustomPrototype = false;
  // public isChecked;
  private postingComment = false;
  @ViewChild('newReply', {static: false}) newReply: ElementRef;

  constructor(
      private authService: AuthService, private commentService: CommentService,
      private utilityService: UtilityService, private elRef: ElementRef,
      private dataService: DataService, public dataCommService: DataCommService,
      private router: Router, private sanitizer: DomSanitizer,
      public toaster: ToasterService,
      private overlayService: OverlayWithContentService,
      private cookieService: CookieService
      ) {
      this.userInfo = this.authService.getLoggedInUser();
      if (this.userInfo) {
        this.currentUserName = this.userInfo.first_name + (this.userInfo.last_name ? ' ' + this.userInfo.last_name : '');
      }
      this.isCustomPrototype = this.dataCommService.prototypeType === 'custom';
      this.subs.push(this.commentService.singleCommentData$.subscribe(val => {
        this.commentData = val;
        this.commentDataMaster = utilityService.cloneObject(val);
        this.editingComment = false;
      }));
  }

  @HostListener('document:click', ['$event.target'])
    public onOutsideClick(targetElement) {
        const clickedInside = this.elRef.nativeElement.contains(targetElement);
        const clickedOnCommentPanel = targetElement.closest('.commentRow');
        const clickedOnEditBtn = ['Edit', 'Cancel'].includes(targetElement.innerHTML);
        if (!clickedInside && !clickedOnCommentPanel && !clickedOnEditBtn) {
            this.cancelCommentClick.emit();
        }
    }
   //notification for focus
    ngAfterViewInit(){
      if( this.newReply && this.authService.isLoggedInUser()){
        this.newReply.nativeElement.focus();
      }
    }

  ngOnDestroy() {
    this.utilityService.unsubscribeOnDestroy(this.subs);
    this.elRef.nativeElement.removeEventListener('document:click', this.onOutsideClick);
  }

  deleteComment(commentId, targetFeatureId) {
    // const frameData = this.dataCommService.featureList.find(x => x.id === targetFeatureId);
    //const customPtototypeStage = this.dataCommService.grayStyle ? 'Custom Prototype: V0' : 'Custom Prototype';
    this.dataCommService.trackEvent('comment_deleted', {
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice,
      comment_location: this.router.url.includes('preview') ? 'Preview' : this.router.url.includes('flowchart') ? 'Flowchart' : 'Dashboard',
      comment_mode: this.router.url.includes('preview') ? 'prototype' : this.router.url.includes('flowchart') ? 'Flow mode' : 'Flow mode'
    });
    const userData = this.authService.getLoggedInUser();
    if (this.dataCommService.prototypeType === 'instant') {
      const payload = {
        comment_user : {
          name: this.currentUserName,
          email: userData.email,
          studio_user_id: userData.id
        }
      };
      this.dataService.deleteComment(commentId, payload).subscribe(res => {
        const commIndex = this.commentService.commentsData.comments.findIndex(x => x.id === commentId);
        this.commentService.commentsData.comments.splice(commIndex, 1);
        this.commentService.singleCommentDataSubject.next(null);
        this.commentService.commentsData.commentId = commentId;
        this.commentService.commentsData.commentAction = 'deleted';
        this.commentService.allCommentsDataSubject.next(this.commentService.commentsData);
        this.commentDeleted.emit({commentId, targetFeatureId});
      });
    } else {
      this.dataService.trackerDeleteCustomComment(this.dataCommService.trackerProjectId, commentId).subscribe(res => {
        const commIndex = this.commentService.commentsData.comments.findIndex(x => x.id === commentId);
        this.commentService.commentsData.comments.splice(commIndex, 1);
        this.commentService.singleCommentDataSubject.next(null);
        this.commentService.commentsData.commentId = commentId;
        this.commentService.commentsData.commentAction = 'deleted';
        this.commentService.allCommentsDataSubject.next(this.commentService.commentsData);
        this.commentDeleted.emit({commentId, targetFeatureId});
      });
    }
  }

  editComment() {
    this.editingComment = true;
    setTimeout(() => {
      document.getElementById('editCommentInput').focus();
    }, 100);
  }

  cancelEditing() {
    this.editingComment = false;
    this.commentData.body = this.commentDataMaster.body;
    // this.commentData.set_as_deigner_task = this.commentDataMaster.set_as_deigner_task;
    this.writingDesignerTask = false;
  }

  postComment(commentString: string, isReply?: boolean) {
    ////////// mix panel event start////////////
    //const customPtototypeStage = this.dataCommService.grayStyle ? 'Custom Prototype: V0' : 'Custom Prototype';
    const propObj = {
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice,
      comment_location: this.router.url.includes('preview') ? 'Preview' : this.router.url.includes('flowchart') ? 'Flowchart' : 'Dashboard',
      comment_mode: this.router.url.includes('preview') ? 'prototype' : this.router.url.includes('flowchart') ? 'Flow mode' : 'Flow mode',
    };
    if (!isReply) {
      this.dataCommService.trackEvent('comment_added', propObj);
    } else {
      this.dataCommService.trackEvent('Comment Reply posted', propObj);
    }
    ////////// mix panel event end////////////
    if (!this.postingComment) {
      this.postingComment = true;
      let commentDataClone = JSON.parse(JSON.stringify(this.commentData));

      if (this.isReviewLeftSection) {
        commentDataClone.position.originX = (this.commentData.position.originX + this.dataCommService.reviewScreenLeftScrollPosition.scrollLeft) / this.dataCommService.leftReviewImageScale;
        commentDataClone.position.originY = (this.commentData.position.originY + this.dataCommService.reviewScreenLeftScrollPosition.scrollTop) / this.dataCommService.leftReviewImageScale;
      }
      else if (this.isReviewRightSection) {
        commentDataClone.position.originX = (this.commentData.position.originX + this.dataCommService.reviewScreenRightScrollPosition.scrollLeft) / this.dataCommService.rightReviewImageScale;
        commentDataClone.position.originY = (this.commentData.position.originY + this.dataCommService.reviewScreenRightScrollPosition.scrollTop) / this.dataCommService.rightReviewImageScale;
      }
      else {
        commentDataClone.position.originX = (this.commentData.position.originX + this.dataCommService.previewScreenScrollPosition.scrollLeft) / this.overlayService.hotspotImageScale;
        commentDataClone.position.originY = (this.commentData.position.originY + this.dataCommService.previewScreenScrollPosition.scrollTop) / this.overlayService.hotspotImageScale;
      }

      if (this.dataCommService.prototypeType === 'instant' || this.dataCommService.isCurrentPrototypeApproved) {
        const userData = this.authService.getLoggedInUser();
        const payload = {
          comment_user: {
            name: this.currentUserName,
            email: userData.email,
            studio_user_id: userData.id
          },
          body: commentString,
          position: commentDataClone.position,
          commentable_type: isReply ? 'Comment' : 'BuildCardHeroImage',
          commentable_id: isReply ? this.commentData.id : this.heroImageId
        };
        if(this.storyId) {
          payload['story_id'] = this.storyId;
        }
        this.dataService.addComment(payload).subscribe((res: any) => {
          if (!isReply) {
            const comment = res.comment;
            comment.commentType = 'instant';
            this.commentService.commentsData.comment = this.utilityService.cloneObject(comment);
            this.commentService.commentsData.commentAction = 'added';
            this.commentService.allCommentsDataSubject.next(this.commentService.commentsData);
            this.commentService.singleCommentDataSubject.next(comment);
            const featureData = this.dataCommService.featureList.find(x => x.id === comment.target_feature_id);
            this.newCommentAdded.emit({
              commentMark: {
                id: comment.id, body: comment.body, position: comment.position,
                stamp_mark: comment.stamp_mark, build_card_feature_id: comment.target_feature_id,
                commentType: comment.commentType
                // build_card_hero_image_id: featureData.hero_image_id
              },
              targetFeatureId: comment.target_feature_id});
          } else {
            this.commentData.replies.push(res.reply);
            this.commentData.isReplied = true;
            this.commentService.singleCommentDataSubject.next(this.commentData);
          }
          this.postingComment = false;
        });
      } else {
        let frameData;
        if(this.storyId) {
          frameData = this.feature;
        }
        else {
           frameData = this.dataCommService.featureList.find(x => {
           return x.hero_image_id === this.heroImageId
          });
        }
        const payload: any = {
          builder_feature_id: frameData.studio_feature_id,
          note: commentString.trim(),
          version: this.versionToCommentOn ? this.versionToCommentOn.version : frameData.version,
          user_name: this.currentUserName,
          user_type: 'User',
          user_email: this.authService.userProfile.userData.email,
          hero_image_feature_id: this.versionToCommentOn ? this.versionToCommentOn.hero_image_id : frameData.hero_image_id,
          set_as_deigner_task: this.builderTagPresent(commentString),
          position: commentDataClone.position
        };
        if(this.storyId) {
          payload['story_id'] = +this.storyId;
          payload['builder_feature_id'] =this.dataCommService.studioFeatureIdForSubFeatureComment;
        }
        if (isReply) {
          payload.parent_id =  this.commentData.id;
        }
        payload['platform_name'] =  this.dataCommService.platformType;
        if (this.cookieService.getLocalStorage('roleId') && this.cookieService.getLocalStorage('roleId') !== 'undefined') payload['studio_role_id'] = this.dataCommService.userRoles.filter(e => e.id === +this.cookieService.getLocalStorage('roleId'))[0].studio_id;
        this.dataService.postRejectionComment(this.dataCommService.trackerProjectId, payload).subscribe((res: any) => {
          if (!isReply) {
            // const comment = {
            //   id: res.id,
            //   body: res.note,
            //   position: {
            //     originX: res.position.originX.toString(),
            //     originY: res.position.originY.toString()
            //   },
            //   created_at: res.created_at,
            //   updated_at: res.created_at,
            //   commented_by: res.user_name,
            //   commented_on: frameData.title,
            //   target_feature_id: res.hero_image_feature_id,
            //   stamp_mark: res.number,
            //   is_owner: true,
            //   replies: res.replies,
            //   version: res.version
            // };
            const comment = this.commentService.modifyTrackerSingleCommentData(res, frameData.title);
            comment.commentType = 'custom';
            this.commentService.commentsData.comment = this.utilityService.cloneObject(comment);
            this.commentService.commentsData.commentAction = 'added';
            this.commentService.allCommentsDataSubject.next(this.commentService.commentsData);
            this.commentService.singleCommentDataSubject.next(comment);
         let commentmark =    {
              commentMark: {
                id: comment.id, body: comment.body, position: comment.position,
                stamp_mark: comment.stamp_mark, build_card_feature_id: frameData.id,
                commentType: comment.commentType
              },
              targetFeatureId: comment.target_feature_id}
              if(this.storyId) {
                commentmark['tracker_story_id'] = this.storyId;
              }
            this.newCommentAdded.emit(commentmark);
          } else {
            const replyData: any = {
              id: res.id,
              body: res.note,
              created_at: res.created_at,
              updated_at: res.created_at,
              replied_by: this.currentUserName
            };
            this.commentData.replies.push(replyData);
            this.commentData.isReplied = true;
            this.commentService.singleCommentDataSubject.next(this.commentData);
          }
          this.postingComment = false;
        },err=>{
          if(err && err.error && err.error.errors){
            let msg = this.dataCommService.genericMethodForNoStory(err.error.errors,this.storyId);
            if(msg){
             this.cancelCommentClick.emit();
             this.toaster.error(msg);
            }
          }
        });
      }
    }
  }

  updateComment(commentString: string) {
    if (!this.postingComment) {
      let frameData;
      if(this.storyId) {
        frameData = this.feature;
      } else {
        frameData = this.dataCommService.featureList.find(x =>
          x.hero_image_id === this.heroImageId);
      }
    
      const userData = this.authService.getLoggedInUser();
      const payload = {
        comment_user : {
          name: this.currentUserName,
          email: userData.email,
          studio_user_id: userData.id
        },
        body: commentString
      };
      if (this.dataCommService.prototypeType === 'instant' || this.dataCommService.isCurrentPrototypeApproved) {
        this.dataService.updateComment(this.commentData.id, payload).subscribe((res: any) => {
          const comment = res.comment;
          const commIndex = this.commentService.commentsData.comments.findIndex(x => x.id === res.comment.id);
          this.commentService.commentsData.comments[commIndex] = comment;
          this.commentService.singleCommentDataSubject.next(comment);
          this.commentService.commentsData.selectedComment = this.utilityService.cloneObject(comment);
          this.commentService.commentsData.commentAction = 'updated';
          this.commentService.allCommentsDataSubject.next(this.commentService.commentsData);
          this.editingComment = false;
          this.postingComment = false;
          this.writingDesignerTask = false;
        });
      } else {
        const data = {
          note: commentString.trim(),
          set_as_deigner_task: this.builderTagPresent(commentString)
        };
        this.dataService.trackerUpdateCustomComment(
            this.dataCommService.trackerProjectId, this.commentData.id, data).subscribe((res: any) => {
          const comment = this.commentService.modifyTrackerSingleCommentData(res.comment, frameData.title);
          const commIndex = this.commentService.commentsData.comments.findIndex(x => x.id === res.comment.id);
          this.commentService.commentsData.comments[commIndex] = comment;
          this.commentService.singleCommentDataSubject.next(comment);
          this.commentService.commentsData.selectedComment = this.utilityService.cloneObject(comment);
          this.commentService.commentsData.commentAction = 'updated';
          this.commentService.allCommentsDataSubject.next(this.commentService.commentsData);
          this.editingComment = false;
          this.postingComment = false;
          this.writingDesignerTask = false;
        });
      }
    }
  }

  cancelComment($event) {
    $event.stopPropagation();
    this.cancelCommentClick.emit();
    this.writingDesignerTask = false;
  }

  onClickInside($event) {
    $event.stopPropagation();
  }
  toggleCheckbox(event) {
    if (event.target.checked) {
      this.commentData.set_as_deigner_task = true;
    } else {
      this.commentData.set_as_deigner_task = false;
    }
  }

  keyupInCommentTxt($event: KeyboardEvent, newCmtBox) {
    $event.stopPropagation();
    if (this.dataCommService.prototypeType !== 'instant' && !this.dataCommService.isCurrentPrototypeApproved) {
      if (newCmtBox.selectionStart || newCmtBox.selectionStart === '0') {
        this.builderTxtPosition = newCmtBox.selectionStart;
      }
      if ($event.key === '@') {
        if (new RegExp('^@+$').test(newCmtBox.value) || newCmtBox.value.charAt(this.builderTxtPosition - 2) === ' ') {
          this.writingDesignerTask = true;
        }
      } else if ($event.key === ' ' && this.writingDesignerTask) {
        this.writingDesignerTask = false;
      } else if (!['Shift', ' ', 'Backspace', '2'].includes($event.key) && this.writingDesignerTask) {
        // const enteredStr = newCmtBox.value.substr(this.builderTxtPosition);
        const enteredStr = newCmtBox.value.substring(this.builderTxtPosition, newCmtBox.value.lastIndexOf('@')).slice(1);
        if (new RegExp('^[b|u|i|l|d|e|r]+$', 'i').test(enteredStr)) {
          this.writingDesignerTask = true;
        } else {
          this.writingDesignerTask = false;
        }
      } else if ($event.key === 'Backspace') {
        let enteredStr;
        if (this.builderTxtPosition === newCmtBox.value.lastIndexOf(' ')) {
          enteredStr = newCmtBox.value.slice(0, this.builderTxtPosition);
        } else {
          enteredStr = newCmtBox.value.lastIndexOf(' ') !== -1 ?
                        newCmtBox.value.substring(newCmtBox.value.lastIndexOf(' ')).slice(1) :
                        newCmtBox.value.substring(newCmtBox.value.lastIndexOf(' '));
        }
        if (new RegExp('^@+$').test(newCmtBox.value)
          || new RegExp('^[@|b|u|i|l|d|e|r]+$', 'i').test(enteredStr)) {
          this.writingDesignerTask = true;
        } else {
          this.writingDesignerTask = false;
        }
      } else if (['Shift', '2'].includes($event.key)) {
        if (newCmtBox.value.substring(newCmtBox.value.length - 1) === '@') {
          this.writingDesignerTask = true;
        }
      }
    }
  }

  addBuilderTxt(cmtBox, isReply?) {
    if (isReply) {
      cmtBox = document.getElementById('editCommentInput');
    }
    let oldtext ;
    if (cmtBox.selectionStart || cmtBox.selectionStart === '0') {
      this.builderTxtPosition = cmtBox.selectionStart;
    }
    oldtext = cmtBox.value;
    const curpos = this.builderTxtPosition;
    const temp = oldtext.substring(0, curpos);
    const strArr = temp.split(' ');
    strArr[strArr.length - 1] = strArr[strArr.length - 1].slice(0, 1);
    const pretext = strArr.join(' ');
    const posttest = oldtext.substring(curpos, oldtext.length);
    this.commentData.body = pretext + 'Builder ' + posttest;
    cmtBox.focus();
    this.writingDesignerTask = false;
  }

  builderTagPresent(text): boolean {
    const keyArr = Array.from(text.matchAll(/@builder/gmi));
    const mailArr = Array.from(
      text.matchAll(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm));
    if (keyArr.length > mailArr.length) {
      return true;
    }
    return false;
  }

  highlightBuilderTag(text) {
    if (this.builderTagPresent(text) && this.dataCommService.prototypeType !== 'instant'
        && !this.dataCommService.isCurrentPrototypeApproved) {
      const regEx = /@builder/gi;
      text = text.replace(regEx, this.sanitizer.sanitize(SecurityContext.NONE, '<span class="name-tag">@Builder</span>'));
      // text = text.replace('@Builder', this.sanitizer.sanitize(SecurityContext.NONE, '<span class="name-tag">@Builder</span>'));
      return text;

    } else {
      return text;
    }
  }

  replyFocus() {
    if (this.authService.isLoggedInUser()) {
      this.newReply.nativeElement.focus();
    } else {
      // this.dataCommService.showLoginPopup = true;
      this.dataCommService.touchPoint = Constants.touchPoints.putComment;
      this.dataCommService.isCommentSigninSignUpForm = true;
      this.dataCommService.showHidePaymentFlow(true);
    }
  }

  inputReply($event) {
console.log($event.target.value)
  }
}

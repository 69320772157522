import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  ChangeDetectorRef,
  ChangeDetectionStrategy, HostListener
} from '@angular/core';
import {DataService} from '../../../main/shared/services/data.service';
import {DataCommService} from '../../../main/shared/services/data-comm.service';
import {ToasterService} from '@core/toaster.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-all-features',
  templateUrl: './all-features.component.html',
  styleUrls: ['./all-features.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllFeaturesComponent implements OnInit, OnChanges {
  public allFeatures: any;
  public suggestedFeatures: any;
  public buildCardFeatures: any;
  public allFeaturesSet: any;
  public suggestedFeaturesSet: any;
  public buildCardFeaturesSet: any;
  public showBuildcardCrossBtn = false;
  public showAllCrossBtn = false;
  public showSuggestedCrossBtn = false;
  public disableAddition = false;
  public subs: Subscription[] = [];
  @Output() addedFeature = new EventEmitter();
  @Output() deletedFeature = new EventEmitter();
  @Input() deletedFeatureId;
  allFeaturesSearch: string;
  buildCardFeaturesSearch: string;
  suggestedFeaturesSearch: string;
  suggestedAndAllFeatures: any;
  public suggestedAndAllFeaturesSet: { allFeatures: any; suggestedFeatures: any };
  toolTipID: number;
  constructor(
      private dataService: DataService,
      public dataCommService: DataCommService,
      private toasterService: ToasterService,
      private cdRef: ChangeDetectorRef
  ) { }

    @HostListener('click', ['$event'])
    public onClick(event: any): void {
      event.stopPropagation();
    }

  ngOnInit() {
    this.getDashboardFeatureList(this.dataCommService.allFeatures);
    this.subs.push(this.dataCommService.allFeaturesSource$.subscribe((res: any) => {
      this.getDashboardFeatureList(res);
    }));
    this.subs.push(this.dataCommService.disableFeatureAdditionSource$.subscribe((newVal: any) => {
      this.disableAddition = newVal;
    }));
  }

  trackById(index, val) {
    return val.id;
  }
  getDashboardFeatureList(res) {
    this.allFeatures = res.all_features;
    this.allFeatures.forEach((feature, index) => {
      this.allFeatures[index].is_checked = false;
    });
    this.suggestedFeatures = res.suggested_features;
    this.suggestedFeatures.forEach((feature, index) => {
      this.suggestedFeatures[index].is_checked = false;
    });
    this.buildCardFeatures = res.build_card_features;
    this.buildCardFeatures.forEach((feature, index) => {
      this.buildCardFeatures[index].is_checked = true;
    });
    this.allFeaturesSet = res.all_features;
    this.suggestedFeaturesSet = res.suggested_features;
    this.buildCardFeaturesSet = res.build_card_features;
    this.buildCardFeatures.forEach((feature, index) => {
      const featureIndex = this.suggestedFeatures.findIndex(suggestFeature => suggestFeature.id === feature.id);
      if (featureIndex > -1) {
        this.suggestedFeatures.splice(featureIndex, 1);
      }
      const allFeatureIndex = this.allFeatures.findIndex(allFeature => allFeature.id === feature.id);
      if (allFeatureIndex > -1) {
        this.allFeatures.splice(allFeatureIndex, 1);
      }
    });
    this.suggestedFeatures.forEach((feature, index) => {
      const finalAllfeaturesIndex = this.allFeatures.findIndex(allFeature => allFeature.id === feature.id);
      if (finalAllfeaturesIndex > -1) {
        this.allFeatures.splice(finalAllfeaturesIndex, 1);
      }
    });
    this.suggestedAndAllFeaturesSet = {
      suggestedFeatures: this.suggestedFeatures,
      allFeatures: this.allFeatures
    };
    this.suggestedAndAllFeatures = {
      suggestedFeatures: this.suggestedFeatures,
      allFeatures: this.allFeatures
    };
  }
  hideDashboardFeatures() {
    this.dataCommService.showAllFeaturesWindow = false;
  }

  searchHandler(event, needle) {
    if (needle === 1) {
      if (event.target.value !== '') {
        this.showSuggestedCrossBtn = true;
        this.suggestedFeatures = this.suggestedFeaturesSet.filter(feature => {
          return feature.title.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
        });
      } else {
        this.showSuggestedCrossBtn = false;
        this.suggestedFeatures = this.suggestedFeaturesSet;
      }
    } else if (needle === 2) {
      if (event.target.value !== '') {
        this.showAllCrossBtn = true;
        this.suggestedAndAllFeatures.suggestedFeatures = this.suggestedAndAllFeatures.suggestedFeatures.filter(feature => {
          return feature.title.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
        });
        this.suggestedAndAllFeatures.allFeatures = this.suggestedAndAllFeatures.allFeatures.filter(feature => {
          return feature.title.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
        });
      } else {
        this.showAllCrossBtn = false;
        // this.allFeatures = this.allFeaturesSet;
        this.suggestedAndAllFeatures.suggestedFeatures = this.suggestedAndAllFeaturesSet.suggestedFeatures;
        this.suggestedAndAllFeatures.allFeatures = this.suggestedAndAllFeaturesSet.allFeatures;
      }
    } else {
      if (event.target.value !== '') {
        this.showBuildcardCrossBtn = true;
        this.buildCardFeatures = this.buildCardFeaturesSet.filter(feature => {
          return feature.title.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
        });
      } else {
        this.showBuildcardCrossBtn = false;
        this.buildCardFeatures = this.buildCardFeaturesSet;
      }
    }
  }
  updateFeatures(checked, item, index, tabSelected) {
    // event.stopPropagation();
    this.cdRef.detectChanges();
    this.dataCommService.allScreenDeleted = false;
    // const checked = event.target.checked;
    if (checked) {
      const suggestedFeatureIndex = this.suggestedAndAllFeatures.suggestedFeatures.findIndex(suggestFeature => suggestFeature.uniq_code === item.uniq_code);
      if (suggestedFeatureIndex > -1) {
        this.suggestedAndAllFeatures.suggestedFeatures[suggestedFeatureIndex].is_checked = true;
      }
      const featureIndex = this.suggestedAndAllFeatures.allFeatures.findIndex(allFeature => allFeature.uniq_code === item.uniq_code);
      if (featureIndex > -1) {
        this.suggestedAndAllFeatures.allFeatures[featureIndex].is_checked = true;

      }
      this.cdRef.detectChanges();
      const data = {
        version: this.dataCommService.featureVersion,
        device: this.dataCommService.platform,
        platform: this.dataCommService.platformType,
        feature_ids: [item.id]
      };
      this.dataCommService.setAddedScreensCount({type: 'add', id: item.id + '-loader'});
      this.dataService.addFeatures(this.dataCommService.nowBuildCardId, data).subscribe((res: any) => {
        this.dataCommService.undoRedoToastFun(res);
        this.dataCommService.setAddedScreensCount({type: 'remove', id: item.id + '-loader'});
        if (res.undo_footsteps && res.undo_footsteps.length > 0) {
            this.dataCommService.getUndoListFun(res.undo_footsteps);
        }
        if (res.redo_footsteps && res.redo_footsteps.length > 0) {
            this.dataCommService.getRedoListFun(res.redo_footsteps);
        }
        this.addedFeature.emit(res);
      }, (error) => {
        // document.getElementById(event.target.id).removeAttribute('disabled');
      });
      this.dataCommService.trackEvent('feature_added',
          {
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            'Feature Name': item.title,
            Tab: tabSelected
          });
    } else {
      const suggestedFeatureIndex = this.suggestedAndAllFeatures.suggestedFeatures.findIndex(suggestFeature => suggestFeature.uniq_code === item.uniq_code);
      if (suggestedFeatureIndex > -1) {
        this.suggestedAndAllFeatures.suggestedFeatures[suggestedFeatureIndex].is_checked = false;
      }
      const featureIndex = this.suggestedAndAllFeatures.allFeatures.findIndex(allFeature => allFeature.uniq_code === item.uniq_code);
      if (featureIndex > -1) {
        this.suggestedAndAllFeatures.allFeatures[featureIndex].is_checked = false;
      }
      this.cdRef.detectChanges();
      const removeData = {
        feature_ids: [item.id]
      };
      if (tabSelected === 'my') {
        this.dataCommService.deletingMyFeature = true;
        this.dataCommService.featureId = item.uniq_code;
        this.dataCommService.confirmDeleteScreen = true;
      } else {
        this.dataService.deleteFeatures(this.dataCommService.nowBuildCardId, removeData).subscribe((res: any) => {
          // document.getElementById(event.target.id).removeAttribute('disabled');
          this.deletedFeature.emit(res.deleted_ids);
        }, (error) => {
          // document.getElementById(event.target.id).removeAttribute('disabled');
          this.dataCommService.errorHandlr(error);
        });
      }
    }
  }
  resetSearch(needle) {
    if (needle === 1) {
      this.showSuggestedCrossBtn = false;
      this.suggestedFeaturesSearch = '';
      this.suggestedFeatures = this.suggestedFeaturesSet;
    } else if (needle === 2) {
      this.showAllCrossBtn = false;
      this.suggestedAndAllFeatures.suggestedFeatures = this.suggestedAndAllFeaturesSet.suggestedFeatures;
      this.suggestedAndAllFeatures.allFeatures = this.suggestedAndAllFeaturesSet.allFeatures;
    } else {
      this.showBuildcardCrossBtn = false;
      this.buildCardFeaturesSearch = '';
      this.buildCardFeatures = this.buildCardFeaturesSet;
    }
  }
  ngOnChanges(changes) {
    if (changes.deletedFeatureId && !changes.deletedFeatureId.firstChange) {
      this.uncheckFeature(changes.deletedFeatureId.currentValue);
    }
  }
  uncheckFeature(featureId) {
    const suggestedFeatureIndex = this.suggestedAndAllFeatures.suggestedFeatures.findIndex(suggestFeature => suggestFeature.uniq_code === featureId);
    if (suggestedFeatureIndex > -1) {
      this.suggestedAndAllFeatures.suggestedFeatures[suggestedFeatureIndex].is_checked = false;
    }
    const featureIndex = this.suggestedAndAllFeatures.allFeatures.findIndex(allFeature => allFeature.uniq_code === featureId);
    if (featureIndex > -1) {
      this.suggestedAndAllFeatures.allFeatures[featureIndex].is_checked = false;
    }
    const buildCardFeatureIndex = this.buildCardFeatures.findIndex(allFeature => allFeature.uniq_code === featureId);
    if (buildCardFeatureIndex > -1) {
      this.buildCardFeatures[buildCardFeatureIndex].is_checked = false;
    }
  }
  toolTipToggle(val) {
    this.toolTipID = val;
  }
}

import { Component, OnInit } from '@angular/core';
import { CookieService } from '@core/cookie.service';
import { environment } from '@env/environment';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { DataService } from 'src/app/main/shared/services/data.service';

@Component({
  selector: 'app-maintenance-support-toast',
  templateUrl: './maintenance-support-toast.component.html',
  styleUrls: ['./maintenance-support-toast.component.scss']
})
export class MaintenanceSupportToastComponent implements OnInit {
  constructor(public dataCommService: DataCommService, public dataService: DataService, public cookieService: CookieService) { }

  ngOnInit() {
  }

  closeMaintenancePopup(e) {
    e.stopPropagation();
    const email = this.cookieService.getCookieEmail(environment.USER_COOKIE);
    if (email) {
      const payload = {
        'email': email
      }
      this.dataService.markMaintenanceRead(payload).subscribe(res=>{
        this.dataCommService.maintenancePopup = false;
      });  
    }
  }
}

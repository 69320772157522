import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpClient, HttpRequest } from '@angular/common/http';
import { HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '@core/base.service';
import { AuthService } from '@core/auth.service';
import { ToasterService } from '@core/toaster.service';
import {DataCommService} from '../main/shared/services/data-comm.service';
@Injectable()
export class AuthInterceptor extends BaseService implements HttpInterceptor {

    constructor(
        public router: Router,
        public toaster: ToasterService,
        public authService: AuthService,
        public http: HttpClient,
        public dataCommService: DataCommService) {
        super(router, toaster);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(!req.url.includes('api.worldpostallocations.com') && !req.url.includes('jsonip.com'))
         req = this.addAuthenticationToken(req);
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err && err.status === 404 && !err.error.message.includes('BuildCard not found')) {
                    return this.authTokenErrorHndlr(err);
                } else if (err && err.status === 401) {
                    return this.serverError(err);
                } else if (err && (err.status === 0 || err.status === 502 || err.status === 503 || err.status === 504)) {
                   return this.serverDown(err);
                } else if(req.url.includes('notoast')) {
                    return throwError(err);
                } else {
                    return this.genericError(err, this.dataCommService.showRentalPaymentFlow);
                }
            }),
            map((response: any) => {
                if (req.method.toLocaleLowerCase() === 'put' && !req.url.includes('notoast')) {
                    if (response.body && !this.dataCommService.showRentalPaymentFlow && !this.dataCommService.brandingRequest) {
                        this.genericSuccess(req, response);
                    }
                }
                return response;
            })
        );
    }

    private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        this.authService.userProfile.isUrlWithBCToken = false;
        const user = this.authService.getLoggedInUser();
        if (user !== null && this.authService.isMasterTemplateUser()) {
            request = request.clone({
                headers: request.headers.set('email', user.email).set('studiobuildcardid', this.dataCommService.buildcardId)
            });
        }
        if (this.authService.isTrackerApi) {
            //change in comments api for secert key//
            if(!this.dataCommService.manageCommentsMultiple) {
                this.authService.isTrackerApi = false;
            }
            return request.clone({
                headers: request.headers.set('secret-key', this.authService.getTrackerSecretKey())
            });
        } else if (this.authService.isDashboardApi) {
            return request.clone({
                headers: request.headers.set('secret-key', this.authService.getdashboardSecretKey()).set('partner-code' , 'e-ai').set('Content-Type', 'application/json' )
            });
        } else if (this.authService.userProfile.isDesigner
            && this.authService.userProfile.isUrlWithBCToken
            && this.authService.cutsotmPrototypeVersion) {
            if (!this.authService.getBCAuthToken()) {
                return request;
            }
            return request.clone({
                headers: request.headers.set('bcauthtoken', this.authService.getBCAuthToken())
            });
        } else {
            if (!this.authService.getAuthToken()) {
                return request;
            }
            return request.clone({
                headers: request.headers.set('authtoken', this.authService.getAuthToken())
            });
        }
    }

}

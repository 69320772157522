import { Injectable } from '@angular/core';
import { ToasterService } from '@core/toaster.service';
import { DataService } from 'src/app/main/shared/services/data.service';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { Constants } from './constants';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {
  errorMsgs = {
    someFilesHaveError: 'Some images don\'t have format as per the guidelines.',
    allFilesHaveError: 'Image format is not as per the guidelines.',
    heroSizeErr: 'Hero image dimensions should not be greater than 375 x 667px.',
    fileSizeErr: 'Image file size should not be more than 2 Mb.',
    dataTypeErr: 'The selected image type is not supported. Supported image types are .jpg, .png, .gif.',
    dynamicFileSizeErr: 'Image file size should not be more than 5 Mb.'
  };
  allFilesValidated = false;
  selectedFiles = [];
  validFiles = [];
  acceptedTypes = [];
  filesWaitingForLoad = [];
  validationResponseArray = [];
  uploadFor = '';
  platform = 'mobile';
  viewConfigTag;
  uploadDropUIGuidelines;
  carouseDimensions = {
      width: 0,
      height: 0
  };
  constructor(
    private toasterService: ToasterService, private dataService: DataService,
    private dataCommService: DataCommService, private router: Router
  ) {
        this.dataCommService.platform = this.router.url.includes('web') ? 'web' : 'mobile';
        this.platform = this.dataCommService.platform;
        this.doViewChangeConfig();
        this.dataCommService.switchViewSource$.subscribe((newView: string) => {
            this.platform = newView;
            this.doViewChangeConfig();
        });
    }

    doViewChangeConfig() {
        this.viewConfigTag = this.platform === 'web' ? 'fileGuidelinesDesktop' : 'fileGuidelinesMobile';
        this.uploadDropUIGuidelines = Constants[`${this.viewConfigTag}`];
        // this.uploadDropUIGuidelines.acceptedTypes.splice(this.uploadDropUIGuidelines.acceptedTypes.findIndex(type => {
        //     return type === 'JPEG';
        // }), 1);
        this.uploadDropUIGuidelines.acceptedTypes.forEach((type: string) => {
            this.acceptedTypes.push('image/' + type.toLocaleLowerCase());
        });
        if (this.platform === 'mobile') {
            this.carouseDimensions.width = 375;
            this.carouseDimensions.height = 167;
        } else {
            this.carouseDimensions.width = 1440;
            this.carouseDimensions.height = 238;
        }
    }

    validateFile(file, indexOfFile, featureId = '', type = '') {
        const reader = new FileReader();
        const size = file.size;
        const fileExtension = 'image/' + file.name.split('.').pop();

       // if (type === 'update') {
            this.uploadDropUIGuidelines = Constants['fileGuidelinesDynamic'];
            this.uploadDropUIGuidelines.acceptedTypes.forEach((type: string) => {
                this.acceptedTypes.push('image/' + type.toLocaleLowerCase());
            });
        //}

        this.allFilesValidated = (indexOfFile === this.selectedFiles.length - 1);

        if (this.acceptedTypes.indexOf(file.type) !== -1 && this.acceptedTypes.indexOf(fileExtension.toLowerCase()) !== -1) {
            if (size > this.uploadDropUIGuidelines.size) {
                this.validationResponseArray.push({errorType: 'non-load-error', error: this.errorMsgs.dynamicFileSizeErr, index: indexOfFile});
                if (type === 'update') {
                    this.checkErrors(featureId, type);
                } else {
                    this.checkErrors();
                }
            } else {
                reader.onload = (e: any) => {
                    const img = new Image();
                    img.src = e.target.result;
                    img.setAttribute('name', file.name);
                    img.onload = () => {
                        const fileScanned = this.filesWaitingForLoad.find(obj => {
                            return obj.name === img.getAttribute('name');
                        });
                        let dimensions;
                        if (this.uploadFor === 'icon') {
                            dimensions = Constants.iconGuidelines.dimensions;
                        } else if (this.uploadFor === 'carousel') {
                            dimensions = this.carouseDimensions;
                        } else {
                            dimensions = this.uploadDropUIGuidelines.dimensions;
                        }
                        if ((this.uploadFor !== 'carousel') && (this.uploadFor !== 'logo') && dimensions.width && dimensions.height && (img.width > dimensions.width || img.height > dimensions.height)) {
                            this.validationResponseArray.push(
                                {errorType: 'load-error', error: this.errorMsgs.heroSizeErr, index: indexOfFile}
                            );
                            if (type === 'update') {
                                this.checkErrors(featureId, type);
                            } else {
                                this.checkErrors();
                            }
                        } else {
                            this.validationResponseArray.push({imageAttributes: {
                                    width: img.width,
                                    height: img.height
                                }, error: null, index: indexOfFile});
                        }
                        fileScanned.waiting = false;
                        if (type === 'update') {
                            this.checkErrors(featureId, type);
                        } else {
                            this.checkErrors();
                        }
                    };
                };
                reader.readAsDataURL(file);
                this.filesWaitingForLoad.push({name: file.name, waiting: true, imgAttribs: null, error: false});
            }
        } else {
            this.validationResponseArray.push({errorType: 'non-load-error', error: this.errorMsgs.dataTypeErr});
            if (type === 'update') {
                this.checkErrors(featureId, type);
            } else {
                this.checkErrors();
            }
        }
    }

    validateSelectedFiles(files, featureId = '', type = '', frameData?) {
        if (frameData) {
            this.carouseDimensions.width = frameData['carouselWidth'];
        }
        this.validationResponseArray = [];
        this.selectedFiles = files;
        this.uploadFor = type;
        Array.from(files).forEach((file, index) => {
            if (type === 'update') {
                this.validateFile(file, index, featureId, 'update');
            } else {
                this.validateFile(file, index);
            }
        });
    }

    checkErrors(featureId = '', type= '') {
        if (this.filesWaitingForLoad.findIndex(obj => {
            return obj.waiting === true;
        }) === -1 && this.allFilesValidated) {
            if (type === 'update') {
                this.collateErrors(featureId, type);
            } else {
                this.collateErrors();
            }
        }
    }
    collateErrors(featureId = '', type= '') {
        let errorCount = 0;
        this.validationResponseArray.forEach(resObj => {
            if (resObj.error) {
                errorCount++;
            } else {
                this.validFiles.push({
                    imageFile: this.selectedFiles[resObj.index],
                    imgAttrs: resObj.imageAttributes
                });
            }
        });

        if (errorCount) {
            if (errorCount < this.selectedFiles.length) {
                this.toasterService.error(this.errorMsgs.someFilesHaveError);
            } else if (errorCount === this.selectedFiles.length) {
                if (this.uploadFor === 'logo') {
                    const data = {file: '', formdataObj: {}};
                    this.dataCommService.logoSourceFun(data);
                } else {
                    this.toasterService.error(this.errorMsgs.allFilesHaveError);
                }
            }
            this.filesWaitingForLoad = [];
        }
        if (this.validFiles.length) {
            if (type === 'update') {
                this.uploadValidFiles(this.validFiles, featureId, 'update');
            } else {
                this.uploadValidFiles(this.validFiles);
            }
        }
    }
    uploadValidFiles(validFiles, featureId = '', type = '') {
        const formdata = new FormData();
        if (this.uploadFor === 'update') {
            this.dataCommService.dashboardLoader = true;
            validFiles.forEach((file, index) => {
                formdata.append(`file`, file.imageFile);
                formdata.append(`build_card_id`, `${this.dataCommService.nowBuildCardId}`);
                formdata.append(`build_card_hero_image_id`, this.dataCommService.heroImageId);
                formdata.append(`device` , this.dataCommService.platform);
                formdata.append(`platform`, this.dataCommService.platformType);
                formdata.append(`version`, this.dataCommService.featureVersion);
                formdata.append(`image_attributes`, `{width: ${file.imgAttrs.width}, height: ${file.imgAttrs.height}, dimensions_updated: ${true}}`);
            });
            this.dataCommService.setUploadedFilesCount(
                {
                    fileseCount: validFiles.length,
                    featureObj: {},
                    updadeType: 'update'
                });
            this.dataService.updateScreen(formdata, featureId).subscribe( (res: any) => {
                this.reinitializeArrays();
                this.dataCommService.setUploadedFilesCount(
                    {
                        fileseCount: 0,
                        featureObj: res.features,
                        updadeType: 'update'
                    });
                this.dataCommService.featureList = res.features;
                // this.toasterService.success(Constants.toasterMessage.SUCCESS);
                this.uploadFor = '';
                if (res != null) {
                    /*const foundIndex = this.dataCommService.featureList.findIndex(x => x.id === res.feature.id);
                    this.dataCommService.featureList[foundIndex] = res.feature;
                    this.dataCommService.setFeatureList({features : this.dataCommService.featureList});*/
                    this.dataCommService.setFeatureList(res);
                }
                this.dataCommService.dashboardLoader = false;
                window.location.reload();
            }, err => {
                this.dataCommService.dashboardLoader = false;
                this.toasterService.error('Something Went Wrong!');
            });
        } else  if (this.uploadFor === 'icon') {
            validFiles.forEach((file, index) => {
                formdata.append(`hotspot_icons[${index}][title]`, `${file.imageFile.name.split('.').slice(0, -1).join('.')}`);
                formdata.append(`hotspot_icons[${index}][image]`, file.imageFile);
            });
            this.dataService.uploadIcons(formdata, this.dataCommService.nowBuildCardId).subscribe((res: any) => {
                res.build_card_specific.sort((a, b) => b.id - a.id);
                this.dataCommService.updateIconList([...res.build_card_specific, ...res.admin_created]);
                this.reinitializeArrays();
                this.dataCommService.setUploadedFilesCount(0);
            }, err => {
                this.toasterService.error('Something Went Wrong!');
            });
        } else  if (this.uploadFor === 'logo') {
            validFiles.forEach((file, index) => {
                formdata.append(`brand_logo`, file.imageFile);
                formdata.append(`device` , this.dataCommService.platform);
                formdata.append(`platform`, this.dataCommService.platformType);
                formdata.append(`version`, this.dataCommService.featureVersion);
                this.reinitializeArrays();
                const data = {file: file.imageFile, formdataObj: formdata};
                this.dataCommService.logoSourceFun(data);
            });
        } else if (this.uploadFor === 'carousel') {
            validFiles.forEach((file, index) => {
                this.reinitializeArrays();
                const data = {file: file.imageFile};
                this.dataCommService.logoSourceFun(data);
            });
        } else {
            validFiles.forEach((file, index) => {
                formdata.append(`build_card_features[${index}][title]`, `${file.imageFile.name.split('.').slice(0, -1).join('.')}`);
                formdata.append(`build_card_features[${index}][user_requested]`, `${true}`);
                formdata.append(`build_card_features[${index}][user_uploaded]`, `${true}`);
                formdata.append(`build_card_features[${index}][build_card_hero_images_attributes][0][file]`, file.imageFile);
                formdata.append(`build_card_features[${index}][build_card_hero_images_attributes][0][device]`, this.dataCommService.platform);
                formdata.append(`build_card_features[${index}][build_card_hero_images_attributes][0][platform]`, this.dataCommService.platformType);
                formdata.append(
                    `build_card_features[${index}][build_card_hero_images_attributes][0][image_attributes]`,  `{width: ${file.imgAttrs.width},  height: ${file.imgAttrs.height}, dimensions_updated: ${true}}`
                );
                formdata.append(
                    `build_card_features[${index}][build_card_hero_images_attributes][0][build_card_id]`,
                    `${this.dataCommService.nowBuildCardId}`
                );
            });
            formdata.append(`build_card_id`, `${this.dataCommService.nowBuildCardId}`);
            formdata.append(`device`, this.dataCommService.platform);
            formdata.append(`platform`, this.dataCommService.platformType);
            formdata.append(`version`, this.dataCommService.featureVersion);
            this.dataCommService.setUploadedFilesCount({fileseCount: validFiles.length, featureObj: {}, updadeType: 'add'});
            this.dataService.uploadImageFeature(formdata).subscribe((res: any) => {
                this.dataCommService.undoRedoToastFun(res);
                this.reinitializeArrays();
                this.dataCommService.setUndoRedoValues(res);
                // this.dataCommService.setFeatureList(res);
                this.dataCommService.setUploadedFilesCount({
                    fileseCount: 0,
                     featureObj: res.features.slice(0, validFiles.length),
                     updadeType: 'add',
                     featureList: res.features
                });
                if (res.undo_footsteps && res.undo_footsteps.length > 0) {
                    this.dataCommService.getUndoListFun(res.undo_footsteps);
                }
                if (res.redo_footsteps && res.redo_footsteps.length > 0) {
                    this.dataCommService.getRedoListFun(res.redo_footsteps);
                }
            }, err => {
                this.toasterService.error('Something Went Wrong!');
            });
        }
    }

    reinitializeArrays() {
        this.validFiles = [];
        this.selectedFiles = [];
        this.filesWaitingForLoad = [];
        this.validationResponseArray = [];
    }
}

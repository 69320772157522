import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from '@core/toaster.service';
import { CookieService } from '@core/cookie.service';
import { environment } from '@env/environment';
import { WindowRefService } from '@core/window-ref.service';
import {UserModel} from '@shared/models/user.model';
import {Constants} from '@shared/services/constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userProfile = {
    isCPEUser: false,
    isClient: false,
    isDesigner: false,
    isUrlWithBCToken: false,
    isPlatformMobile: false,
    userData: null
  };
  public isTrackerApi = false;
  public isDashboardApi = false;
  public authToken;
  public bcAuthTokenMobile;
  public bcAuthTokenWeb;
  public cutsotmPrototypeVersion = null;
  user: UserModel;
  countryNameCode: string;
  user_cookie = null;
  cookie_domain = null;

  constructor(
    public cookieService: CookieService,
    public router: Router,
    public toaster: ToasterService,
    private windowRef: WindowRefService,
    private http: HttpClient) {
  }

  isBranding(): boolean {

    return this.check_Url();
  }


  check_Url(): boolean {
    return this.router.url.includes('branding');
  }


  isCPERole(userData): boolean {
    if (userData && userData.roles && userData.roles.toUpperCase() === 'CPE') {
      return true;
    } else {
      return false;
    }
  }
  isMasterTemplateUser(): boolean {
    if (this.userProfile.userData) {
      const isProd = this.windowRef.nativeWindow.location.href.indexOf('now.builder.ai') !== -1 ? true : false;
      const cpeEmail = isProd ? Constants.cpeProdEmail : Constants.cpeEmailOther;
      const userRoles = this.userProfile.userData.roles.split(',');
      if (userRoles.includes('CPE') && this.userProfile.userData.email === cpeEmail) {
        return true;
      }
    }
    return false;
  }
  isClientRole(userData) {
    if (userData && !userData.roles) {
      return true;
    } else {
      return false;
    }
  }

  isDesignerRole(userData) {
    if (!this.isClientRole(userData) && this.userProfile.isUrlWithBCToken) {
      return true;
    } else {
      return false;
    }
  }

  isLoggedInUser() {
    const userCookieData = this.cookieService.getCookie(environment.USER_COOKIE);
    if (userCookieData) {
      return true;
    } else {
      return false;
    }
  }

  isCurrencyPresentInUser() {
    const userCookieData = this.cookieService.getCookie(environment.USER_COOKIE);
    let userObject = userCookieData && userCookieData[1] ? JSON.parse(userCookieData[1]) : '';
    if (userObject && userObject.currency) {
      return true;
    } else {
        return false;
    }
  }

  getLoggedInUser() {
    if (this.isLoggedInUser()) {
      const userCookieData = this.cookieService.getLocalStorage(environment.USER_LOCAL_STORAGE);
      if (userCookieData) {
        return JSON.parse(userCookieData);
      } else {
        return null;
      }
    } else {
      this.cookieService.removeLocalStorage(environment.USER_LOCAL_STORAGE);
    }
    return null;
  }
  logout() {
    this.cookieService.deleteCookie(environment.USER_COOKIE);
    this.userProfile.userData = null;
    this.cookieService.removeLocalStorage('airIndiaShare');
    this.cookieService.removeLocalStorage(environment.USER_LOCAL_STORAGE);
  }

  getStoredBCAuthToken(isPlatformMobile: boolean) {
    const bctokenname = this.getBCTokenName(isPlatformMobile);
    return this.cookieService.getLocalStorage(bctokenname);
  }

  getBCAuthToken() {
    const bctokenname = this.getBCTokenName(this.userProfile.isPlatformMobile);
    if (!this[bctokenname]) {
      const bcktokenname = this.getStoredBCAuthToken(this.userProfile.isPlatformMobile);
      if (bcktokenname && bcktokenname !== 'null') {
        this[bctokenname] = bcktokenname;
      } else {
        this[bctokenname] = null;
      }
    }
    return this[bctokenname];
  }

  setBCAuthToken(token: any, isPlatformMobile: boolean) {
    const bctokenname = this.getBCTokenName(isPlatformMobile);
    this[bctokenname] = token;
    this.cookieService.setLocalStorage(bctokenname, token);
  }

  getAuthToken() {
    return this.authToken;
  }

  setAuthToken(token: any) {
    let localStorageToken = null, userLocalData = this.cookieService.getLocalStorage(environment.USER_LOCAL_STORAGE);
    this.authToken = token;
    if (userLocalData) {
      localStorageToken = JSON.parse(userLocalData).authtoken;
      this.setUserProfileData();
    }
    if(token && token !== localStorageToken) {
      let url = `${environment.STUDIO_API_URL}${Constants.apiEndPoints.getUserDetail}`;
      this.http.get(url).subscribe((res: any) => {
        this.cookieService.setLocalStorage(environment.USER_LOCAL_STORAGE, JSON.stringify(res));
        this.setUserProfileData();
      });
    }
  }

  setUserProfileData() {
    this.userProfile.userData = this.getLoggedInUser();
    this.userProfile.isCPEUser = this.isCPERole(this.userProfile.userData);
    this.userProfile.isClient = this.isClientRole(this.userProfile.userData);
    this.userProfile.isDesigner = this.isDesignerRole(this.userProfile.userData);
  }

  getBCTokenName(isPlatformMobile: boolean) {
    return isPlatformMobile ? 'bcAuthTokenMobile' : 'bcAuthTokenWeb';
  }

  isPlatformMobile() {
    if (this.windowRef.nativeWindow.location.href.indexOf('/mobile') !== -1) {
      return true;
    } else {
      return false;
    }
  }
  checkBCTokenInUrl() {
    const isPlatformMobile = this.userProfile.isPlatformMobile;
    let bcAuthToken = this.windowRef.getQueryStringValue('bcauthtoken');
    if (bcAuthToken) {
      this.userProfile.isUrlWithBCToken = true;
      this.setBCAuthToken(bcAuthToken, this.userProfile.isPlatformMobile);
    } else {
      bcAuthToken = this.getBCAuthToken();
      const bctokenname = this.getBCTokenName(isPlatformMobile);
      if (bcAuthToken) {
        this[bctokenname] = bcAuthToken;
        this.userProfile.isUrlWithBCToken = true;
      } else {
        this[bctokenname] = bcAuthToken;
      }
    }
  }

  setUserProfile() {
    this.userProfile.isPlatformMobile = this.isPlatformMobile();
    this.checkBCTokenInUrl();

    const userCookieData = this.cookieService.getCookie(environment.USER_COOKIE);
    if (userCookieData) {
      let userData = JSON.parse(userCookieData[1]);
      if (userData && userData.authtoken.toString()) {
        this.setAuthToken(userData.authtoken.toString());
      } else {
        this.setAuthToken('');
      }
    }
  }

  setCookie(user) {
    this.cookieService.setCookieDomain();
    /*user.frontend_url = window.location.origin;
    if (user.address) {
      if (user.address.street) {
        user.address.street = encodeURIComponent(user.address.street);
      }
      if (user.address.apartment) {
        user.address.apartment = encodeURIComponent(user.address.apartment);
      }
    }*/
    const userCookieVar = {"id": user.id,"pm_dashboard_token": user.pm_dashboard_token,"authtoken": user.authtoken};
    // const usercookie = JSON.parse(JSON.stringify(userCookieVar));
    // if (usercookie.currency && usercookie.currency.symbol) {
    //   usercookie.currency.symbol = encodeURIComponent(usercookie.currency.symbol);
    // }
    document.cookie = `${this.user_cookie}=${JSON.stringify(userCookieVar)}; path=/; domain=${this.cookie_domain};`;
  }

  setDomain() {
    const hostfromurl = window.location.hostname;
    this.user_cookie = environment.USER_COOKIE;
    if (hostfromurl === 'localhost') {
      this.cookie_domain = 'localhost';
    } else {
      const separate = hostfromurl.split('.');
      separate.shift();
      const currentDomain = separate.join('.');
      this.cookie_domain = '.' + currentDomain;
    }
  }

  getTrackerSecretKey() {
    return environment.TRACKER_SECRET_KEY;
  }

  getdashboardSecretKey() {
    return environment.PM_DASHBOARD_AUTH;
  }

}

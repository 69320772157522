import { Directive, ElementRef, Input, Renderer2, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';

@Directive({
  selector: '[bindAction]'
})
export class BindActionDirective implements AfterViewInit {

  @Input('bindAction') gesture;
  @Output() performAction: EventEmitter<any> = new EventEmitter();

  constructor(
    private elementRef: ElementRef, private dataCommService: DataCommService,
    private renderer: Renderer2
  ) {
  }

  ngAfterViewInit() {
    this.gesture = this.gesture ? this.gesture : 'click';
    this.renderer.listen(this.elementRef.nativeElement, this.gesture, (event) => {
      this.dataCommService.childSwipeTriggered = true;
      this.performAction.emit(this.gesture);
    });
  }

}

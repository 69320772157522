import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'searchPipeComment'
})
export class CommentPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            let exist = it.body.toLowerCase().includes(searchText); 
            if(exist){
                return exist;
            }
            else{
             exist = it.replies.filter(e=> e.body.includes(searchText));
             if(exist.length > 0){
                 exist = true;
             }
             else{
                 exist = false;
             }
            }  
            return exist;
        });
    }
}

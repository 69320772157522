import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {environment} from '@env/environment';
import {DataService} from '../../../../../main/shared/services/data.service';
import {DataCommService} from '../../../../../main/shared/services/data-comm.service';
import {AuthService} from '@core/auth.service';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent implements OnInit, AfterViewInit {
  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  public isNavigationClicked = false;

  constructor(public dataCommService: DataCommService, public dataService: DataService, public authService: AuthService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.iframe.nativeElement.setAttribute('src', environment.PAYMENT_URL + this.dataCommService.paymentUrl);
  }

  getPaymentUrl() {
    return environment.PAYMENT_URL + this.dataCommService.paymentUrl;
  }

  openInNewTab() {
    this.dataCommService.trackEvent('Open payment gateway clicked', {page_source : 'Custom Prototype'});
    if (this.dataCommService.windowToCLose) {
      return;
    }
    this.dataCommService.windowToCLose = window.open(this.getPaymentUrl(), '_blank', '');
  }

  getTotalAmount() {
    return this.dataService.getLocaledPrice(this.dataCommService.prototypeInstallment.installment_amount + this.dataCommService.prototypeInstallment.tax_amount);
  }

  getDeposit() {
    return this.dataService.getLocaledPrice(this.dataCommService.prototypeInstallment.installment_amount);
  }

  getCurrency() {
    return this.dataCommService.prototypeInstallment.currency.symbol;
  }
}

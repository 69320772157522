import { Component, OnInit } from '@angular/core';
import {DataCommService} from '../../../main/shared/services/data-comm.service';
import {CookieService} from '@core/cookie.service';
import { BaseService } from '@core/base.service';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth.service';
import moment from 'moment';
@Component({
  selector: 'app-custom-prototype-payment',
  templateUrl: './custom-prototype-payment.component.html',
  styleUrls: ['./custom-prototype-payment.component.scss']
})
export class CustomPrototypePaymentComponent implements OnInit {
  public userData;
  public payDisabled = true;
  public deliveryDate;
  constructor(public dataCommService: DataCommService, private router: Router,
              private cookieService: CookieService, private authService: AuthService,
              public baseUrl: BaseService) { }

  ngOnInit() {
    this.getDeliveryDate();
  }
  hideCustomPrototypePopupWindow() {
    this.dataCommService.showCustomPrototypePopup = false;
  }
  customPrototypePaymentUrl() {
    const segmentTrackerData = {
      'Prototype Type': this.dataCommService.prototypeType,
      User: this.dataCommService.isOwner ? 'Owner' : this.authService.userProfile.isDesigner ? 'Designer' : 'Visitor',
      page_source: this.router.url.includes('preview') ? 'Preview' : 'User Flow'
    };
    const customPrototypePaymentLink = this.baseUrl.studioUrl + '/buildcard-payment/' + this.dataCommService.buildCardUniqueCode +'/billingdetails?is_custom_prototype=true';
    window.location.href = customPrototypePaymentLink;
  }
  getTermsUrl() {
      return `/terms`;
  }
  tncCheck(event) {
    this.payDisabled = (event.target.checked) ? false : true;
  }
  getDeliveryDate() {
    // const todaysDate = new Date(2020, 1, 29);
    const todaysDate = moment();
    const nextDay = moment(todaysDate);
    let i = 1;
    while (i <= 5) {
      nextDay.date(nextDay.date() + 1);
      if (![0, 6].includes(nextDay.day())) {
        i++;
      }
    }
    this.deliveryDate = nextDay.format('ddd D MMM');
  }
}

import { AuthService } from '@core/auth.service';
import { DataService } from 'src/app/main/shared/services/data.service';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  public moment;
  constructor(public dataCommService : DataCommService,
    public dataService :DataService,
    public authService :AuthService) { }

  ngOnInit() {
    this.moment = moment;
    // const userDetails = this.authService.getLoggedInUser();
    // if(userDetails.email){
    //   this.getNotification( this.dataCommService.nowBuildCardId,userDetails.email,1);
    // }

  }

  // getNotification(build_card_id,userEmail,page){
  //   this.dataService.getNotification(build_card_id,userEmail,page).subscribe((res)=>{

  //   })
  // }

  toggleNotificaton(event?){
      event.stopPropagation();
    //notification-- toggle
    this.dataCommService.openNotification = !this.dataCommService.openNotification;          
  }
  clickNotification(notification, event){
    event.stopPropagation();
    if (!notification.read_at) {
      const userDetails = this.authService.getLoggedInUser();
        const payload = {
        "build_card_id": this.dataCommService.nowBuildCardId,
        "user_email": userDetails.email,
        'device' : this.dataCommService.platform,
        'platform': this.dataCommService.platformType
      }
      this.markReadNotifications(payload, notification.id);
    }

    //notification-- data set in service
    this.toggleNotificaton(event);
    this.dataCommService.selectedNotification = notification;
    this.dataCommService.setNotificationValue(true);

  }
  markReadNotifications(payload,notification_id){
    this.dataService.markAsReadSingle(payload, notification_id).subscribe( (res: any) => {
      let notification = this.dataCommService.allNotification.notifications.find(not => not.id == notification_id);
      if (notification) {
        notification.read_at = new Date().getTime();
      }
      this.dataCommService.allNotification.total_unread_count = res.total_unread_count;
    });
  }

  markAll(event){
    event.stopPropagation();

    const userDetails = this.authService.getLoggedInUser();
    const payload = {
    'build_card_id': this.dataCommService.nowBuildCardId,
    'user_email': userDetails.email,
    'device' : this.dataCommService.platform,
    'platform': this.dataCommService.platformType
  }
    this.dataService.markAllRead(payload).subscribe(res=>{
      this.dataCommService.allNotification.total_unread_count = 0;
      this.dataCommService.allNotification.notifications.forEach(element => {
        if(element.read_at == null){
          element.read_at ="";
        }
      });
    });
  }

  // onReachEnd = async (ev) => {
  //   const values = this.perfectScroll.directiveRef.geometry();
  //   if (values.y != 0) {
  //     this.pageNumber++;
  //     this.getAllNotifications(this.pageNumber);
  //   }
  // }


}

import { Component, OnInit, Input, OnChanges } from '@angular/core';
import {AuthService as Auth} from '@core/auth.service';
import {DataCommService} from '../../../main/shared/services/data-comm.service';
import {Constants} from '@shared/services/constants';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import {BaseService} from '@core/base.service';
import {Router} from '@angular/router';
import {CommentService} from '@shared/components/comments/comment.service';
@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit, OnChanges {
  @Input() userInfo: any;
  public showProfileMenu = false;
  public userName;
  public userEmail;
  constructor(
     public authService: Auth,
     public dataCommService: DataCommService,
     public commentService: CommentService,
     private socialAuth: SocialAuthService,
     private baseurl: BaseService,
     private router: Router
  ) { }

  ngOnChanges() {
    if (this.userInfo) {
      this.userName = this.userInfo.first_name + (this.userInfo.last_name ? ' ' + this.userInfo.last_name : '');
      this.userEmail = this.userInfo.email;
    }
  }

  ngOnInit() {
    this.userInfo = this.authService.getLoggedInUser();
    if (this.userInfo) {
      this.userName = this.userInfo.first_name + (this.userInfo.last_name ? ' ' + this.userInfo.last_name : '');
      this.userEmail = this.userInfo.email;
    }
  }
  loginSignupPopup() {
    this.dataCommService.touchPoint = Constants.touchPoints.previewHeader;
    this.dataCommService.getTouchPointHeadings('checkEmail');
    this.dataCommService.isCommentSigninSignUpForm = true;
    this.dataCommService.selectedSectionIndex = 0;
    this.authService.countryNameCode = 'in';
    const segmentTrackerData = {
      referrer_trigger: this.dataCommService.referrer,
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice
    };
    this.dataCommService.trackEvent('sign_up_screen_opened', segmentTrackerData);
    this.dataCommService.showHidePaymentFlow(true);
  }
  profileMenu(action) {
    if (action) {
      this.showProfileMenu = true;
    } else {
      this.showProfileMenu = false;
    }
  }
  goToIdeaboard() {
    this.dataCommService.trackEvent('profile_dashboard_selected' ,
        {
          user_id: this.dataCommService.userUniqueid,
          user_browser: this.dataCommService.userBrowser,
          user_device: this.dataCommService.userDevice,
          'dropdown_elements' : 'Go to ideaboard',
          'screen_edited' : this.screenName()
        }
    );
    window.location.href = this.baseurl.studioUrl + '/dashboard';
  }
  screenName() {
    return (this.router.url.includes('preview') ? 'preview' : (this.router.url.includes('flowchart') ? 'Flowchart' : 'Dashboard'));
  }
  logout() {
    this.dataCommService.signInOut = true;
    this.socialAuth.signOut();
    this.authService.logout();
    this.authService.setAuthToken('');
    this.dataCommService.featureList = [];
    this.dataCommService.showBottomBar = false;
    this.dataCommService.showLogoutMessage = true;
    this.commentService.commentModeToggleSubject.next(false);
    this.dataCommService.isCpe = false;
    this.dataCommService.releasePopup = false;
    this.dataCommService.releaseNotes = null;
    this.dataCommService.maintenancePopup = false;
    this.dataCommService.maintenancePopupMsg = null;
    if (this.authService.cutsotmPrototypeVersion) {
      const dcs = this.dataCommService;
      if (dcs.platform && dcs.platformType) {
        this.router.navigateByUrl(`${dcs.buildcardId}/${this.authService.cutsotmPrototypeVersion}/preview/${dcs.platform}/${dcs.platformType}`);
      } else {
        this.router.navigateByUrl(`${dcs.buildcardId}/${this.authService.cutsotmPrototypeVersion}/preview`);
      }

    } else {
        if (this.dataCommService.platform && this.dataCommService.platformType) {
            this.router.navigateByUrl(`${this.dataCommService.buildcardId}/preview/${this.dataCommService.platform}/${this.dataCommService.platformType}`);
        }
    }

  }
  goToAddBranding() {
    this.dataCommService.isCommentSigninSignUpForm = true;
    this.dataCommService.showHidePaymentFlow(true);
    this.dataCommService.handleNextPrevForRentalFlow(6);
    this.dataCommService.showCalendar = false;
  }
  scheduledCall() {
    this.dataCommService.pollForSchedular(this.dataCommService.buildcardId);
    this.dataCommService.isCommentSigninSignUpForm = true;
    this.dataCommService.showHidePaymentFlow(true);
    if (!this.dataCommService.scheduleCallWaiting) {
      this.dataCommService.showCalendar = true;
      this.dataCommService.schedulerURL = this.dataCommService.sanitizer.bypassSecurityTrustResourceUrl(this.dataCommService.showTimeKitWindow(false, this.dataCommService.buildcardId, 'kickoff'));

    }
    this.dataCommService.handleNextPrevForRentalFlow(4);
  }

}

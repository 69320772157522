export const environment = {
  production: false,
  envName: 'dev',
  API_URL: 'https://api-dev-now.engineer.ai',
  STUDIO_API_URL:'https://api-dev-builder.engineer.ai',
  TRACKER_API_URL: 'https://staging.api.tracker.engineer.ai',
  TRACKER_SECRET_KEY: 'PXIdJKzxCXowjXPIRgLm',
  STUDIO_URL: 'https://dev.engineer.ai',
  BUILDER_PAYMENT_URL: 'https://dev.engineer.ai/instant_prototype?build_card_id={buildCardId}',
  USER_COOKIE: 'user_dev',
  USER_LOCAL_STORAGE: 'bnowuser_dev',
  BUILDER_FEATURES_URL: 'https://dev.engineer.ai/features',
  GUIDLINE_TOOTIP_COOKIE: 'dev_guidline',
  ONBOARDING_COOKIE: 'dev_onboarding',
  VERSION0_TOOLTIP_COOKIE: 'dev_version0',
  enableSentry: false,
  PAYMENT_APP_TOKEN: '9ut4ECNZXKlh5oBKclp_MA',
  PAYMENT_URL : 'https://staging-payments.engineer.ai/',
  INTERCOM_APP_ID : 'ob3az46u',
  CALENDLY_URL :  'https://staging-scheduler.builder.ai/?call_type={type}&token=tpdpdxTvmdfGFyDpWTaK',
  PM_DASHBOARD_AUTH: 'tpdpdxTvmdfGFyDpWTaK',
  PM_DASH_URL:  'https://api-staging-pmdashboard.engineer.ai/api/v1/',
  white_board_base_url: 'https://www.whiteboard.team',
  whiteBoardClientId: '0a5513c6d360b46ec7c45aa045642f4c',
  whiteBoardClientSecret: '02466ba44b99bd7404b5d574f92bff48',
  nowUrl: 'https://dev-now.engineer.ai',
  // apiBackendUrl: window['env'].API_BACKEND_URL,
  // externalIntegrationUrl: window['env'].EXTERNAL_INTEGRATION_URL
};
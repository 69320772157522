import { Component, HostListener, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilesUtilityService } from '@shared/services/files.utility.service';
import { DataCommService } from '../../../main/shared/services/data-comm.service';
import { FileUploadService } from '@shared/services/file-upload.service';
import { DataService } from '../../../main/shared/services/data.service';
import { WindowRefService } from '@core/window-ref.service';
import { ToasterService } from '@core/toaster.service';
import { Constants } from '@shared/services/constants';
import { Subscription, Subject } from 'rxjs';
import { AuthService } from '@core/auth.service';
import { takeUntil } from 'rxjs/operators';
import { CommentService } from '../comments/comment.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    isBranding = false;
    public hello = true;
    public isPrototypeScreen = false;
    public isMobileView = true;
    public allowHeaderActions = true;
    public hideIndustryType = false;
    public isEditable = false;
    private isProjectTitleSaved = true;
    private cpeSaveDisabled;
    private cpeEdited = false;
    private cpeSaved = false;
    private cpeSaveText = 'Save as template';
    subscriptions: Subscription[] = [];
    apiSubscriptions = [];
    updateProjectNameSubscription: Subscription;
    setCPEPrototypeSubscription: Subscription;
    dropDownFlag = false;
    prototypeSelected;
    projectName: string;
    private destroy$ = new Subject();
    commentModeEnabled = false;
    featureSelectedForDetail;
    isFeatureDetailPage;
    plateformsData: string;
    showDropdown = false;
    openDropDownBoolean =false;
    clickedButton: string;
    selectedValues: any ={};
    previousIndex: any=null;
    platformName: string;
    clickedPlatformIndex: number;
    @ViewChild('divClicked', { static: false }) divClicked;
    public undoList: any = [];
    public redoList: any = [];
    public selectedVersion = 0;
    public prototypeFlag: string = 'tailorMade';
    isAddMenuOpenClose = false;
    //@ViewChild('undoRedoDiv', { static: false }) undoRedoDivClicked;
    constructor(
        public filesUtility: FilesUtilityService,
        private router: Router,
        public dataCommService: DataCommService,
        private fileUploadService: FileUploadService,
        public dataService: DataService,
        public authService: AuthService,
        private toasterService: ToasterService,
        private windowRef: WindowRefService,
        private commentService: CommentService,
        private elRef: ElementRef,
        private route: ActivatedRoute
    ) {
        if (this.dataCommService.platform === 'mobile' || this.dataCommService.platform === 'watch') {
            this.isMobileView = true;
        } else {
            this.isMobileView = false;
        }
        this.subscriptions.push(this.router.events.subscribe(_ => this.onRouteChange()));
        this.subscriptions.push(this.route.params.subscribe(_ => this.onRouteChange()));
        this.subscriptions.push(this.dataCommService.switchViewSource$.subscribe((value: string) => {
            this.isMobileView = (value === 'mobile' || value === 'watch') ? true : false;
        }));
        this.hideIndustryType = this.hideIndustryOption();
        if (this.authService.userProfile.userData) {
            const isProd = this.windowRef.nativeWindow.location.href.indexOf('now.builder.ai') !== -1 ? true : false;
            const cpeEmail = isProd ? Constants.cpeProdEmail : Constants.cpeEmailOther;
            const userRoles = this.authService.userProfile.userData.roles.split(',');
            if (userRoles.includes('CPE') && this.authService.userProfile.userData.email === cpeEmail) {
                this.dataCommService.isCpe = true;
            }
            this.cpeSaveDisabled = this.dataCommService.cpeSaveDisabled;
        }
        this.subscriptions.push(this.dataCommService.cpeSaved$.subscribe((isRefreshed) => {
            if (this.dataCommService.applicationId > 0) {
                this.dataCommService.showTemplateBtn = true;
                if (this.dataCommService.isMasterTemplate) {
                    this.cpeSaveDisabled = true;
                    this.cpeEdited = true;
                    this.cpeSaved = true;
                    if (isRefreshed) {
                        this.cpeSaveText = 'Template saved';
                    } else {
                        this.cpeSaveText = 'Saving...';
                        setTimeout(() => {
                            this.cpeSaveText = 'Template saved';
                        }, 2000);
                    }
                }
            } else {
                this.dataCommService.showTemplateBtn = false;
            }
        }));
        this.subscriptions.push(this.dataCommService.prototypeList$.subscribe((data) => {
            this.dataCommService.prototypeList = data;
        }));
        this.subscriptions.push(this.commentService.commentModeToggle$.subscribe(val => {
            this.commentModeEnabled = val;
            if (val) {
                this.dataCommService.showUnlinkSlider = false;
                this.dataCommService.showAllFeaturesWindow = false;
            }
        }));
        this.isFeatureDetailPage = router.url.includes('sub-features');
        if (this.isFeatureDetailPage) {
            setTimeout(() => {
                this.featureSelectedForDetail = dataCommService.featureList.find((ftr) => ftr.id === +dataCommService.featureIdForDetailPage);
                if (this.featureSelectedForDetail) {
                    this.dataCommService.featureDetailReviewScreenCount = this.featureSelectedForDetail.status === 'review' ? 1 : 0;
                    this.featureSelectedForDetail.sub_features.forEach(ftr => {
                        if (ftr.status === 'review') {
                            dataCommService.featureDetailReviewScreenCount++;
                        }
                    });
                }
            }, 1500);
        }
        this.subscriptions.push(this.dataCommService.undoList$.subscribe((data) => {
            this.undoList = data;
            this.undoList.forEach((val, i) => {
                this.undoList[i].actionText = val.action_text;
            });
        }));
        this.subscriptions.push(this.dataCommService.redoList$.subscribe((data) => {
            this.redoList = data;
            this.redoList.forEach((val, i) => {
                this.redoList[i].actionText = val.action_text;
            });
        }));
    }

    checkBranding() {
        this.isBranding = this.authService.isBranding();
        if (!this.isBranding) {
            this.destroy$.next();
            this.destroy$.complete();
        }
    }

    getNameBranding() {
        this.dataCommService.getProjectName().pipe(takeUntil(this.destroy$)).subscribe(name => {
            this.projectName = name;
        });
    }

    webValues() {
        if (this.clickedButton === 'a') {
            this.toggleDropdown();
        }
        this.clickedButton  = 'a';
        this.plateformsData = 'SELECT A DESKTOP PLATFORM';
    }
    mobileValues() {
        if (this.clickedButton === 'b') {
            this.toggleDropdown();
        }
        this.clickedButton  = 'b';
        this.plateformsData = 'SELECT A MOBILE PLATFORM';
    }
    tabValues() {
        if (this.clickedButton === 'c') {
            this.toggleDropdown();
        }
        this.clickedButton  = 'c';
        this.plateformsData = 'SELECT A TAB PLATFORM';
    }
    watchValues() {
        if (this.clickedButton === 'd') {
            this.toggleDropdown();
        }
        this.clickedButton  = 'd';
        this.plateformsData = 'SELECT A WATCH PLATFORM';
    }
    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
        this.clickedButton = '';
    }
    undoHandle(flag) {
        this.dataCommService.undoListDisplay = flag;
        this.dataCommService.redoListDisplay = false;
        this.selectedVersion = 0;
        this.dataCommService.openedUndoRedo = this.dataCommService.undoListDisplay;
    }
    redoHandle(flag) {
        this.dataCommService.redoListDisplay = flag;
        this.dataCommService.undoListDisplay = false;
        this.selectedVersion = 0;
        this.dataCommService.openedUndoRedo = this.dataCommService.redoListDisplay;
    }

    ngOnInit(): void {
        this.getNameBranding();
        if (this.dataCommService.isMasterTemplate) {
            this.cpeSaveDisabled = true;
            this.cpeSaved = true;
            this.cpeEdited = true;
            this.cpeSaveText = 'Template saved';
        }
        // this.customPrototypeAmount();
        this.prototypeSelected = this.dataCommService.getPrototypeType();
        this.commentService.commentModeToggleSubject.next(false);
        this.dataCommService.closeAddButton.subscribe(res => {
            this.isAddMenuOpenClose = !this.isAddMenuOpenClose
        });
    }

    @HostListener('document:mouseup', ['$event'])
    MouseEvent($event: MouseEvent) {
        const targetElement = $event.target as HTMLElement;
        if (!this.dataCommService.showAllFeaturesWindow) {
            if (this.isProjectTitleSaved === false && ($event.target as HTMLInputElement).className.search('projectTitleInput') === -1) {
                const eventTitle = {
                    target: {
                        value: (document.getElementById('projectTitleInput') as HTMLInputElement).value
                    }
                };
                this.updateProjectName(eventTitle);
                this.isProjectTitleSaved = true;
            }
            if (this.dropDownFlag &&  !targetElement.closest('.prototype-type-list')) {
                this.showDropDown();
            }
        }
    }
    // @HostListener('click', ['$event'])
    // public onClick(event: any): void {
    //     event.stopPropagation();
    // }

    @HostListener('document:click', ['$event','$event.target'])  
    onClickHeader(event,targetElement) {
        event.stopPropagation();
        if (this.divClicked) {
            const clickedInside = this.divClicked.nativeElement.contains(targetElement);
            if (!clickedInside && this.dataCommService.openDropDownBoolean) {
                 this.resetValuesOfDropDown();
            }
            // this.dataCommService.resolvedCheckbox = false;
            this.dataCommService.openTailorDropDown = false;
        }
        this.dataCommService.closeFilter();
        // if (this.undoRedoDivClicked) {
        //     const clickedRedoUndo = this.undoRedoDivClicked.nativeElement.contains(targetElement);
        //     if (!clickedRedoUndo && this.dataCommService.openedUndoRedo) {
        //         this.dataCommService.undoListDisplay = false;
        //         this.dataCommService.redoListDisplay = false;
        //     }
        // }
    }


    hideIndustryOption() {
        return (this.windowRef.nativeWindow.location.href.indexOf('beta-now.builder.ai') !== -1 || this.windowRef.nativeWindow.location.href.indexOf('now.builder.ai') !== -1);
    }

    allowHeaders(): boolean {
        return (this.windowRef.nativeWindow.location.href.indexOf('/welcome') === -1) ? true : false;
    }

    prototypeScreen(): boolean {
        return (this.windowRef.nativeWindow.location.href.indexOf('/preview') !== -1) ? true : false;
    }

    shareAppUrl() {
        this.dataCommService.showShareModal = true;
    }

    setSelectedFile(event) {
        this.dataCommService.trackEvent('image_added', {
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            add_type: 'drag'
        });
        this.fileUploadService.validateSelectedFiles(event.target.files);
    }

    addImage(e) {
        e.stopPropagation();
        this.dataCommService.showAllFeaturesWindow = false;
        this.commentService.commentModeToggleSubject.next(null);
        this.dataCommService.showUnlinkSlider = false;
        if (this.dataCommService.showLogoPopup) {
            this.dataCommService.showLogoPopup = false;
        }
    }

     addLogo(e) {
        e.stopPropagation();
        this.dataCommService.showAllFeaturesWindow = false;
        this.commentService.commentModeToggleSubject.next(null);
        this.dataCommService.showUnlinkSlider = false;
    }

    processLogoFile(event) {
        //this.fileUploadService.validateSelectedFiles(event.target.files, '', 'logo');
        this.dataCommService.processLogoFun(event);
    }

    switchPreview(value: string) {
        let platformDisabled = false
        if (this.windowRef.nativeWindow.location.href.indexOf('preview') !== -1) {
            this.dataCommService.trackEvent('device_selected', {
                user_id: this.dataCommService.userUniqueid,
                'device_selected': this.dataCommService.userDevice,
                'OS_selected': this.dataCommService.userDeviceOS
            });
        } else {
            this.dataCommService.trackEvent('device_selected', {
                user_id: this.dataCommService.userUniqueid,
                'device_selected': this.dataCommService.userDevice,
                'OS_selected': this.dataCommService.userDeviceOS
            });
        }
        if (!(this.authService.userProfile.userData && this.dataCommService.isCpe)  && value === 'web') {
            platformDisabled = true;
            this.toasterService.success('Coming Soon');
        }
        let currentUrl;
        if (!platformDisabled) {
            if (value === 'web') {
                currentUrl = this.router.url.replace('mobile', value);
            } else {
                currentUrl = this.router.url.replace('web', value);
            }
            this.router.navigate([currentUrl]);
            this.dataCommService.switchView(value);
        }
    }

    showIndustryTypes() {
        this.dataCommService.industryTypeClicked();
    }
    segmentData() {
    }
    getPreviewUrl() {
        return `preview/${this.dataCommService.platform}`;
    }
    updateProjectName(event) {
        const projectName = event.target.value;
        if (projectName.trim() === this.dataCommService.projectName) {
            this.isEditable = false;
        } else if (projectName.trim() === '') {
            this.toasterService.warning(Constants.ChangeTitletoasterMessage.BLANKTITLE);
            (document.getElementById('projectTitleInput') as HTMLInputElement).value = this.dataCommService.projectName;
            this.isEditable = false;
        } else {
            if (this.updateProjectNameSubscription) {
                this.updateProjectNameSubscription.unsubscribe();
            }
            this.updateProjectNameSubscription = this.dataService.updateProjectName({ title: projectName },
                this.dataCommService.nowBuildCardId).subscribe((res: any) => {
                    this.dataCommService.projectName = event.target.value;
                    // this.toasterService.success(res.message);
                    this.isEditable = false;
                }, err => {
                    // this.toasterService.success(Constants.toasterMessage.ERROR);
                    this.isEditable = false;
                });
            this.apiSubscriptions.push(this.updateProjectNameSubscription);
        }
    }
    editableEnable() {
        this.isEditable = true;
        this.isProjectTitleSaved = false;
        setTimeout(() => {
            document.getElementById('projectTitleInput').focus();
        }, 0);
    }
    showCustomPrototypePopupWindow(prototypeFlag) {
        this.dataCommService.openTailorDropDown = false;
        this.prototypeFlag = prototypeFlag;
        if (prototypeFlag === 'professional') {
            this.dataCommService.showViewUnlimitedVersion = true;
            this.dataCommService.prototypeInstallment = this.dataCommService.customPrototypeObj.attributes;
            this.dataCommService.prototypeInstallment.currency = (this.dataCommService.customPrototypeObj.attributes.currency.data ? this.dataCommService.customPrototypeObj.attributes.currency.data.attributes : this.dataCommService.customPrototypeObj.attributes.currency);
        } else {
            this.dataCommService.showViewUnlimitedVersion = false;
            this.dataCommService.prototypeInstallment = this.dataCommService.tailorMadePrototypeObj.attributes;
            this.dataCommService.prototypeInstallment.currency = (this.dataCommService.tailorMadePrototypeObj.attributes.currency.data ? this.dataCommService.tailorMadePrototypeObj.attributes.currency.data.attributes : this.dataCommService.tailorMadePrototypeObj.attributes.currency);
            this.dataCommService.trackEvent('tailored_cta_selected', {
                user_id: this.dataCommService.userUniqueid,
                user_browser: this.dataCommService.userBrowser,
                user_device: this.dataCommService.userDevice
            });
        }

        // this.dataCommService.showCustomPrototypePopup = true;
        this.dataCommService.isCommentSigninSignUpForm = true;
        this.dataCommService.showHidePaymentFlow(true);
        this.dataCommService.handleNextPrevForRentalFlow(1);
    }
    showCustomPrototypeHandlr(protoTypeFlag) {
        this.showCustomPrototypePopupWindow(protoTypeFlag);
    }
    saveCPEPrototype() {
        if (this.setCPEPrototypeSubscription) {
            this.setCPEPrototypeSubscription.unsubscribe();
        }
        this.setCPEPrototypeSubscription = this.dataService.setCPEPrototype({ application_id: this.dataCommService.applicationId },
            this.dataCommService.nowBuildCardId)
            .subscribe((res: any) => {
                this.cpeSaveDisabled = true;
                this.cpeEdited = true;
                this.cpeSaved = true;
                this.cpeSaveText = 'Template saved';
                this.toasterService.success('Prototype successfully saved as template for App and Web Modes.');
                /*if (this.dataCommService.launchScreenObj) {
                    this.dataService.launchScreen(this.dataCommService.nowBuildCardId,
                        this.dataCommService.launchScreenObj.id,
                        this.dataCommService.launchScreenObj.device,
                        this.dataCommService.launchScreenObj.platform,
                        this.dataCommService.nowBuildCardId,
                        this.dataCommService.launchScreenObj.version,
                        this.dataCommService.featureList[0].id).subscribe((data: any) => {
                            this.dataCommService.successHandlr(data);
                        }, (error) => {
                            // this.dataCommService.errorHandlr(error);
                        });
                }*/
            }, (err) => {
                // this.toasterService.error(err.error.message);
            });
        this.apiSubscriptions.push(this.setCPEPrototypeSubscription);
    }

    fetchDataOverlay() {
        this.dataCommService.syncOverlayFun(true);
    }

    closeDataOverlay() {
        this.dataCommService.syncOverlayFun(false);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subs: Subscription) => {
            subs.unsubscribe();
        });
        this.apiSubscriptions.forEach((apiSubs: Subscription) => {
            apiSubs.unsubscribe();
        });

        this.elRef.nativeElement.removeEventListener('document:mouseup', this.MouseEvent);
        this.resetValuesOfDropDown();
    }
    // customPrototypeAmount() {
    //     this.dataService.customPrototypeAmount(this.dataCommService.buildcardId).subscribe((data: any) => {
    //         if (data.data.attributes.installment_amount) {
    //             this.dataCommService.customPrototypeSysmbol = data.data.attributes.currency.data.attributes.symbol;
    //             this.dataCommService.customPrototypePrice = data.data.attributes.installment_amount+data.data.attributes.tax_amount;
    //         }
    //     }, (error) => {
    //         // this.dataCommService.errorHandlr(error);
    //     });
    // }

    selectPrototype(prototype) {
        let urlArr;
        let urlToRedirect;
        const segmentTrackerData = {
            'Prototype Type': this.dataCommService.prototypeType,
            User: this.dataCommService.isOwner ? 'Owner' : this.authService.userProfile.isDesigner ? 'Designer' : 'Visitor',
            Source: this.router.url.includes('preview') ? 'Preview' : 'Dashboard',
            name: prototype
        };
        if (prototype === 'Custom' && this.authService.cutsotmPrototypeVersion === null) {
            urlArr = this.router.url.split('/' + this.dataCommService.buildcardId + '/');
            urlToRedirect = this.dataCommService.buildcardId + '/v1/' + urlArr[1];
        }
        if (prototype === 'Instant' && this.authService.cutsotmPrototypeVersion === 'v1') {
            urlArr = this.router.url.split('/' + this.dataCommService.buildcardId + '/v1/');
            urlToRedirect = this.dataCommService.buildcardId + '/' + urlArr[1];
        }
        if (urlToRedirect) {
            this.windowRef.nativeWindow.location.href = urlToRedirect;
        }
    }

    prototypeClickedHandlr(prototype) {
        this.selectPrototype(prototype);
    }

    dropDownShowHandlr() {

        this.showDropDown();
    }

    showDropDown() {
        this.dropDownFlag ? this.dropDownFlag = false : this.dropDownFlag = true;
    }

    showUpgradeButton() {
        if ((this.dataCommService.isOwner || this.dataCommService.isGuestUser)
            && !this.dataCommService.customPrototypePaid
            && !this.dataCommService.buildcardPaid) {
            return true;
        }
        return false;
    }
    showAddFeaturesWindow(e) {
        e.stopPropagation();
        if (this.dataCommService.allFeatures) {
            this.dataCommService.showAllFeaturesWindow = true;
            this.dataCommService.showUnlinkSlider = false;
            this.commentService.commentModeToggleSubject.next(null);
        }
        if (this.dataCommService.showLogoPopup) {
            this.dataCommService.showLogoPopup = false;
        }
    }

    toggleCommentMode(e) {
        e.stopPropagation();
        if (this.dataCommService.prototypeType === 'instant' || (this.dataCommService.prototypeType === 'custom' && this.dataCommService.trackerProjectId)) {
            if (!this.commentModeEnabled) {
                //const customPtototypeStage = this.dataCommService.grayStyle ? 'Custom Prototype: V0' : 'Custom Prototype';
                const dashboardScreeName = this.router.url.includes('flowchart') ? 'Flowchart' : 'Dashboard';
                this.dataCommService.trackEvent('comments_icon_clicked', {
                    user_id: this.dataCommService.userUniqueid,
                    user_browser: this.dataCommService.userBrowser,
                    user_device: this.dataCommService.userDevice,
                    Screen_name: dashboardScreeName
                });
                //Trigger: 'Header',
                //Stage: (this.dataCommService.prototypeType === 'instant') ? 'Instant Prototype' : customPtototypeStage
            }
            this.dataCommService.selectedNotification ={};
            this.commentService.commentModeToggleSubject.next(!this.commentModeEnabled);
            if (this.commentModeEnabled) {
                if(this.router.url.includes('sub-features')) {
                    this.commentService.getAllSubFeatureCommentMarks();
                } else {
                    this.commentService.getAllCommentMarks();
                }
            }
        }
    }

    openReviewPopup($event) {
        $event.preventDefault();
        $event.stopPropagation();
        /*this.dataCommService.reviewScreenPopup = true;*/
    }

    goToReviewScreen() {
        this.dataCommService.appReloaded = false;
        const screenData = this.dataCommService.getUpdatedFeatuersData.updatedScreensArr[0];
        if (screenData && typeof(screenData) == 'string' && screenData.includes('>')) {
            this.dataCommService.featureIdForDetailPage = this.dataCommService.getUpdatedFeatuersData.updatedScreensArr[0].split('>')[0];
        }
        this.dataCommService.getUpdatedFeatuersData.selectedFeatureId = this.dataCommService.getUpdatedFeatuersData.updatedScreensArr[0];
        this.commentService.commentModeToggleSubject.next(false);
        this.router.navigate([`${this.dataCommService.buildcardId}/v1/dashboard/${this.dataCommService.platform}/${this.dataCommService.platformType}/reviewScreens`]);
    }

    goToNextStep(stepName) {
        switch (stepName) {
            case 'addExtraFeatures':
                this.dataCommService.setOnBoardingCookie('addExtraFeatures', 'areYouDone');
                break;
            default:
                break;
        }
    }

    undoRedo(val, itemObj) {
        const payload = {
            'step_version': itemObj.version,
            'device' : itemObj.device,
            'platform': itemObj.platform
          }
        this.dataService.putUndoRedo(val, this.dataCommService.nowBuildCardId, payload).subscribe((data) => {
            let urlToRedirect = '';
            if (this.authService.cutsotmPrototypeVersion !== null) {
                urlToRedirect = this.dataCommService.buildcardId + '/v1/dashboard/' + this.dataCommService.platform +  '/' + this.dataCommService.platformType + '/flowchart';
            } else {
                urlToRedirect = this.dataCommService.buildcardId + '/dashboard/' + this.dataCommService.platform +  '/' + this.dataCommService.platformType + '/flowchart';
            }
            this.windowRef.nativeWindow.location.href = urlToRedirect;
        });
    }

    selectVersion(itemObj) {
        this.selectedVersion = itemObj.version;
    }

    gotoFlowchart() {
        this.isFeatureDetailPage = false;
        this.router.navigate([`${this.dataCommService.buildcardId}/v1/dashboard/${this.dataCommService.platform}/${this.dataCommService.platformType}/flowchart`]);
    }
    openDropDown(value , index, e) {
        e.stopPropagation();
        this.platformName = value.title;
        this.dataCommService.clickedPlatformIndex = index;
        if (value.comingSoon) {
            this.toasterService.success('Coming Soon');
            this.dataCommService.previousIndex = null;
            this.dataCommService.clickedPlatformIndex = -1;
            this.dataCommService.openDropDownBoolean = false;
        } else {
            if (this.dataCommService.previousIndex != index) {
                this.dataCommService.previousIndex = index;
                this.selectedValues = value;
                this.dataCommService.openDropDownBoolean = true;
                this.resetSeleted();
                value.selected = true;
            } else {
                this.dataCommService.previousIndex = null;
                this.dataCommService.openDropDownBoolean = false;
                this.dataCommService.clickedPlatformIndex = -1;
            }
        }
    }
    resetSeleted(){
        this.dataCommService.headerloop.forEach(element => {
            element.selected = false;
        });
    }

    resetInner(){
        this.dataCommService.headerloop.forEach(element => {
            const values = element.values;
            values.forEach(element => {
                element.selected = false;
            });
        });
    }

    resetValuesOfDropDown(){
        this.dataCommService.previousIndex = null;
        this.dataCommService.openDropDownBoolean = false;
        this.dataCommService.clickedPlatformIndex = -1;   
        this.dataCommService.undoListDisplay = false;
        this.dataCommService.redoListDisplay = false;
    }
    changeRoute(value , e) {
        e.stopPropagation();
        if (!value.comingSoon) {
            value.selected = true;
            this.dataCommService.platform = value.device;
            this.dataCommService.platformType = value.title.replace(' ', '-').toLowerCase();
            if (this.dataCommService.platform && this.dataCommService.platformType) {
                this.dataCommService.trackEvent('device_selected', {
                    user_id: this.dataCommService.userUniqueid,
                    'device_selected': this.dataCommService.platform,
                    'OS_selected': this.dataCommService.platformType
                });
                const dcs = this.dataCommService;
                if (this.router.url.includes('preview')) {
                    if (this.authService.cutsotmPrototypeVersion) {
                        window.location.href = `${dcs.buildcardId}/${this.authService.cutsotmPrototypeVersion}/preview/${dcs.platform}/${dcs.platformType}`;
                    } else {
                        window.location.href = `${dcs.buildcardId}/preview/${dcs.platform}/${dcs.platformType}`;
                    }
                } else {
                    if (this.authService.cutsotmPrototypeVersion) {
                        window.location.href = `${dcs.buildcardId}/${this.authService.cutsotmPrototypeVersion}/dashboard/${dcs.platform}/${dcs.platformType}/flowchart`;
                    } else {
                        window.location.href = `${dcs.buildcardId}/dashboard/${dcs.platform}/${dcs.platformType}/flowchart`;
                    }
                }
            }
        } else {
            this.toasterService.success('Coming Soon');
        }

    }
    toggleNotificaton(event){
        event.stopPropagation();
        this.dataCommService.openNotification = !this.dataCommService.openNotification;         
    } 
    isCpe() {
        const cpeUser = this.authService.isMasterTemplateUser();
        return cpeUser;
    }

    openTailorDD(event) {
        event.stopPropagation();
        this.dataCommService.openTailorDropDown = !this.dataCommService.openTailorDropDown;
    }

      downLoadFile(event,value){
          event.stopPropagation();
          this.dataCommService.showDownloader = true;
          if(value == 'ZIP'){
        this.filesUtility.downloadAsZipFlowChart(this.dataCommService.urls);      
          }
          else{
        this.filesUtility.downloadAsPdfFlowChart(this.dataCommService.urls);
          }
    }

    addMenuOpenClose(event) {
        event.stopPropagation();
        this.isAddMenuOpenClose = !this.isAddMenuOpenClose;
    }

    onRouteChange() {
        this.isPrototypeScreen = this.prototypeScreen();
        this.allowHeaderActions = this.allowHeaders();
        this.checkBranding();
    }
}

// tslint:disable: jsdoc-format
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import jspdf from 'jspdf';
import JSZip from 'jszip';
import html2canvas from 'html2canvas';
import JSZipUtils from '../../../assets/script/jszip-utils.js';
import {DataCommService} from '../../main/shared/services/data-comm.service';
import {ToastrService} from 'ngx-toastr';
import {FileSaverService} from 'ngx-filesaver';

@Injectable({
    providedIn: 'root'
})
export class FilesUtilityService {
    baseUrl: string;
    imageData = [];
    HTMLWidth: any = '';
    HTMLHEIGHT: any = '';
    PDFWidth: any = '';
    PDFHeight: any = '';
    pdf: any;
    imageFormat = 'JPG';
    xCoordinate = 75;
    yCoordinate = 90;
    imageWidth = 240;
    imageHeight = 485;
    pdfWidth = 400;
    pdfHeight = 700;
    isDownloadingStart = false;
    zipFile;
    downloadCount = 0;

    constructor(
        public dataCommService: DataCommService,
        private toasterService: ToastrService,
        private fileSaverService: FileSaverService) {
        this.baseUrl = environment.API_URL;
    }

    /**
     * @description method will return absolute path of
     * the uploaded image
     * @param url image url
     */
    checkImageURL(url: string): string {
        if (url && (url !== '' && url !== null)) {
            if (url.indexOf('http') === 0 || url.indexOf('https') === 0) {
                return url;
            } else {
                return `${this.baseUrl}${url}`;
            }
        }
    }

    /**
     * @description encode image url in base62
     * @param url feature image url
     */
    imgToBase64(featureImage) {
        const src = this.checkImageURL(featureImage);
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = src + '?v=' + Math.random();
            image.crossOrigin = '*';
            image.onload = () => {
                const base64 = this.getBase64Image(image);
                resolve(base64);
            };
            image.onerror = err => reject(err);
        });
    }

    /**
     * @description get base 64 images
     * @param img image url
     * @param type image type
     */
    getBase64Image(img, type = 'image/png') {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const dataURL = canvas.toDataURL(type);
        return dataURL;
    }

    /**
     * @description Generate PDF after creating html to canvas
     */
    generatePDF() {
        this.downloadCount = 0;
        setTimeout(() => {
            this.toasterService.success('Preparing Download', 'Please wait...', {
                progressBar: true,
                progressAnimation: 'increasing'
            });
        }, 0);

        if (this.dataCommService.platform === 'web') {
            this.pdfWidth = 700;
            this.pdfHeight = 400;
            this.imageWidth = 485;
            this.imageHeight = 240;
            this.xCoordinate = 90;
            this.yCoordinate = 75;
            this.pdf = new jspdf('l', 'pt', [this.pdfWidth, this.pdfHeight]);
        } else {
            this.pdfWidth = 400;
            this.pdfHeight = 700;
            this.imageWidth = 240;
            this.imageHeight = 485;
            this.xCoordinate = 75;
            this.yCoordinate = 90;
            this.pdf = new jspdf('p', 'pt', [this.pdfWidth, this.pdfHeight]);
        }

        this.dataCommService.featureListMobile.forEach((featureData, index) => {
                setTimeout(() => {
                    const element = document.getElementById('feature-' + featureData.id);
                    html2canvas(element, {allowTaint: true}).then((canvas) => {
                        if (this.downloadCount > 0) {
                            this.pdf.addPage();
                        }
                        const imgData = canvas.toDataURL('image/jpeg', 0.5);
                        this.downloadCount++;
                        this.pdf.addImage(imgData, this.imageFormat, this.xCoordinate, this.yCoordinate, this.imageWidth, this.imageHeight);
                        if (this.dataCommService.featureListMobile.length === this.downloadCount) {
                            this.pdf.save(`${this.dataCommService.projectName}.pdf`);
                            this.dataCommService.downloadFormat = null;
                            this.dataCommService.showDownloader = false;
                        }
                    });
                }, 0);
        });
    }

    imageToBinary(url) {
        return new Promise((resolve, reject) => {
            JSZipUtils.getBinaryContent(url, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    }

    /**
     * @description Generate ZIP file from html content
     */
    generateZIP() {
        this.toasterService.success('Preparing Download', 'Please wait...', {
            progressBar: true,
            progressAnimation: 'increasing'
        });
        this.zipFile = new JSZip();
        const img = this.zipFile.folder(this.dataCommService.projectName);
        this.downloadCount = 0;
        this.dataCommService.featureList.forEach((featureData, index) => {
            html2canvas(document.getElementById('feature-' + featureData.id), {allowTaint: true}).then((canvas) => {
                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png', 0.5);
                link.download = featureData.title + '.jpeg';
                const url = link.href;
                const data = this.imageToBinary(url);
                this.downloadCount++;
                img.file(`${featureData.title}.png`, data, {binary: true});
                if (this.dataCommService.featureList.length === this.downloadCount) {
                    this.zipFile.generateAsync({type: 'blob'})
                        .then((content) => {
                            // see FileSaver.js
                            this.fileSaverService.save(content, this.dataCommService.projectName + '.zip');
                            this.dataCommService.downloadFormat = null;
                             this.dataCommService.showDownloader = false;
                        });
                }
            });
        });
    }

    downloadPreviewe($event, downloadAs) {
        const segmentData = (downloadAs === 'ZIP') ? 'PNG' : 'PDF';
        this.dataCommService.trackEvent('prototype_downloaded', {
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            export_format: segmentData
        });
        this.dataCommService.downloadFormat = downloadAs;
        this.dataCommService.showDownloader = true;
        /*if (downloadAs === 'ZIP') {
            this.generateZIP($event);
        } else {
            this.generatePDF($event);
        }*/
    }

    downloadAsZipFlowChart(files): void {
        this.toasterService.success('Preparing Download', 'Please wait...', {
            progressBar: true,
            progressAnimation: 'increasing'
        });
        let count = 0;
        const zip = new JSZip();
        files.forEach((url) => {
            const filename = url.split('/')[url.split('/').length - 1];
            JSZipUtils.getBinaryContent(url, (err, data) => {
                if (err) {
                    throw err;
                }
                zip.file(filename, data, { binary: true });
                count++;
                if (count === files.length) {
                    zip.generateAsync({ type: 'blob' }).then((content) => {
                        const objectUrl: string = URL.createObjectURL(content);
                        const link: any = document.createElement('a');
                        link.download = this.dataCommService.projectName;
                        link.href = objectUrl;
                        link.click();
                        this.dataCommService.showDownloader = false;
                    });
                }
            });
        });
    }

    downloadAsPdfFlowChart(files): void {
        this.downloadCount = 0;
        setTimeout(() => {
            this.toasterService.success('Preparing Download', 'Please wait...', {
                progressBar: true,
                progressAnimation: 'increasing'
            });
        }, 0);

        if (this.dataCommService.platform === 'web') {
            this.pdfWidth = 700;
            this.pdfHeight = 400;
            this.imageWidth = 485;
            this.imageHeight = 240;
            this.xCoordinate = 90;
            this.yCoordinate = 75;
            this.pdf = new jspdf('l', 'pt', [this.pdfWidth, this.pdfHeight]);
        } else {
            this.pdfWidth = 400;
            this.pdfHeight = 700;
            this.imageWidth = 240;
            this.imageHeight = 485;
            this.xCoordinate = 75;
            this.yCoordinate = 90;
            this.pdf = new jspdf('p', 'pt', [this.pdfWidth, this.pdfHeight]);
        }
        files.forEach((url) => {
            this.toDataURL(url, (dataUrl) => {
                if (this.downloadCount > 0) {
                    this.pdf.addPage();
                }
                this.downloadCount++;
                this.pdf.addImage(dataUrl, 'PNG', this.xCoordinate, this.yCoordinate, this.imageWidth, this.imageHeight);
                if (files.length === this.downloadCount) {
                    this.pdf.save(`${this.dataCommService.projectName}.pdf`);
                    this.dataCommService.downloadFormat = null;
                    this.dataCommService.showDownloader = false;
                }
            })




            //   const filename = url.split('/')[url.split('/').length - 1];
            //   JSZipUtils.getBinaryContent(url, (err, data) => {
            //     if (err) {
            //       throw err;
            //     }
            //     if (this.downloadCount > 0) {
            //         this.pdf.addPage();
            //       }
            //      this.downloadCount++;
            //      try {
            //         this.pdf.addImage(data, 'PNG', this.xCoordinate, this.yCoordinate, this.imageWidth, this.imageHeight);

            //      } catch (error) {
            //          console.log(error);
            //      }
            //      if (files.length === this.downloadCount) {
            //         this.pdf.save(`${this.dataCommService.projectName}.pdf`);
            //         this.dataCommService.downloadFormat = null;
            //         this.dataCommService.showDownloader = false;
            //     }
            //   });
        });
    }

    toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    b64toBlob(base64String) {    
        var byteString = atob(base64String.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    }

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      }
}
import { Component, OnInit, Input, HostListener, Output, EventEmitter, OnChanges } from '@angular/core';
import { ToasterService } from '@core/toaster.service';
import { Constants } from '@shared/services/constants';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { DataService } from 'src/app/main/shared/services/data.service';
import {AuthService} from '@core/auth.service';
import { Router } from '@angular/router';
import { CommentService } from '../comments/comment.service';
@Component({
  selector: 'app-feature-action',
  templateUrl: './feature-action.component.html',
  styleUrls: ['./feature-action.component.scss']
})
export class FeatureActionComponent implements OnInit, OnChanges {

  @Input() feature;
  // @Input() finalArray = [];
  @Input() featureIndex;
  @Input() columnIndex;
  @Input() isZoomDragging;
  @Input() hasDefaultScreen;
  @Input() featTitleUpdated;
  @Input() detailType;
  @Input() isNewFeature;
  @Input() page = '';
  // @Input() isClient;
  @Input() frameWidth; number;
  @Output() screenNameUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() titleEditStart: EventEmitter<any> = new EventEmitter<any>();
  @Output() screenRejected: EventEmitter<any> = new EventEmitter<any>();
  @Output() screenAccepted: EventEmitter<any> = new EventEmitter<any>();
  @Input() hotspotCount;
  @Input() mirrorOptions;
  @Input() parentFeature;

  public selectedFeatureIndex;
  public isEditable: any;
  public featureTitleID;
  public isSaved = true;
  public featureTitleIndex: any;
  public windowWidth: number = window.innerWidth;
  public view;
  public showCustomImageTag: boolean = false;
  reviewCount;

  constructor(
    private toasterService: ToasterService,
    public dataCommService: DataCommService,
    private dataService: DataService, private commentService: CommentService,
    public authService: AuthService, private router: Router
  ) {
    this.view = this.dataCommService.platform;
    this.dataCommService.switchViewSource$.subscribe((value: string) => {
      this.view = value;
    });
  }

  ngOnInit() {
    this.hotspotCount = this.dataCommService.getVisibleHotspotsCounts(this.feature.hotspots);
    if(!this.feature.story_id) {
      this.feature.status == 'review' ? (this.reviewCount = 1) : (this.reviewCount = 0);
      if(this.feature.sub_features && this.feature.sub_features.length) {
        this.reviewCount += this.feature.sub_features.filter(sf => sf.status == 'review').length
      }
    }
    if (this.feature.custom_image && !this.router.url.includes('edit-screen') && !this.router.url.includes('add-hotspot') && !this.router.url.includes('add-icon') && !this.router.url.includes('add-sidekick') && !this.router.url.includes('add-edit-carousel')) {
      this.showCustomImageTag = true;
    }
}


  ngOnChanges() {
    if (this.dataCommService.featureTitleUpdated && this.isEditable === this.feature.id) {
      this.isSaved = true;
      this.isEditable = '';
      this.dataCommService.featureTitleUpdated = false;
    }
  }

  // Methods related Screen Renaming
  @HostListener('document:mouseup', ['$event'])
  MouseEvent($event: MouseEvent) {
      if (!this.dataCommService.showAllFeaturesWindow) {
          this.isZoomDragging = false;
          if (this.isSaved === false && ($event.target as HTMLInputElement).className.search('featureTitle') === -1) {
              const eventTitle = {
                  target: {
                      value: (document.getElementById('title-id-' + this.featureTitleID) as HTMLInputElement).value
                  }
              };
              this.updateFeatureTitle(eventTitle, this.featureTitleID, this.featureTitleIndex, this.featureIndex);
              this.isSaved = true;
          } else if (!(($event.target as HTMLInputElement).closest('.prototype-details-wrap'))) {
              $event.stopPropagation();
          }
      }
  }

  updateFeatureTitle(event, featureId, columnIndex, ftrIndex) {
    if (event.target.value.trim() === '') {
        this.toasterService.warning(Constants.ChangeTitletoasterMessage.BLANKTITLE);
        (document.getElementById('title-id-' + this.featureTitleID ) as HTMLInputElement).value = this.feature.title;
        this.screenNameUpdated.emit({isChanged: false});
        this.isEditable = '';
    } else if (event.target.value.length > 50) {
        this.toasterService.warning(Constants.ChangeTitletoasterMessage.TITLELENGTH);
        (document.getElementById('title-id-' + this.featureTitleID ) as HTMLInputElement).value = this.feature.title;
        this.screenNameUpdated.emit({isChanged: false});
        this.isEditable = '';
    } else if (event.target.value === this.feature.title) {
        this.isEditable = '';
    } else {
      if (this.feature) {
          this.feature.title = event.target.value;
          const payloadObj = {title: this.feature.title, featId: featureId, colmIndex: columnIndex, featIndex: ftrIndex};
          this.screenNameUpdated.emit({isChanged: true, value: payloadObj});
      }
        // this.dataService.updateFeatureName({title: event.target.value}, featureId).subscribe((res: any) => {
        //     this.toasterService.success(res.message);
        //     if (this.feature) {
        //         this.feature.title = event.target.value;
        //         this.screenNameUpdated.emit(this.feature.title);
        //     }
        //     this.isEditable = '';
        // }, err => {
        //     this.toasterService.success(Constants.toasterMessage.ERROR);
        //     this.isEditable = '';
        // });
    }
  }

  /*editableEnable(feature, index, indexId, featureIndex) {
    if ((!this.dataCommService.designsApproved && this.authService.userProfile.isDesigner && feature.user_uploaded)
      || this.dataCommService.designsApproved && feature.user_uploaded) {
      this.isEditable = feature.id;
      this.featureTitleID = index;
      this.featureTitleIndex = indexId;
      this.selectedFeatureIndex = featureIndex;
      this.isSaved = false;
      setTimeout(() => {
        document.getElementById('title-id-' + index).focus();
      }, 0);
      this.titleEditStart.emit(true);
    }
  }*/

  // Methods related to approve/reject screens
  // approveAction() {
  //   this.screenAccepted.emit(new Event('any'));
  // }

  // rejectAction($event) {
  //   this.screenRejected.emit($event);
  // }

  setScale(frameWidth) {
    const styleObj: any = {};
    if (!this.router.url.includes('edit-screen') && !this.router.url.includes('add-hotspot') && !this.router.url.includes('add-icon') && !this.router.url.includes('add-sidekick')) {
      const viewWidth = this.view === 'web' ? 1440 : 375;
      if (this.windowWidth > 480) {
        // styleObj.transform = `translate(-50%,-50%) scale( ${frameWidth / viewWidth} )`;
        // styleObj.top = `50%`;
        // styleObj.left = `50%`;
        styleObj.transform = `scale( ${frameWidth / viewWidth} )`;
      } else {
        styleObj.transform = `translate(-50%,-50%) scale( ${this.windowWidth / viewWidth} )`;
        styleObj.top = `50%`;
        styleObj.left = `50%`;
      }
    }
    return styleObj;
  }

  // showRejectionComment(feature: PreviewModel, $event) {
  //   if (this.authService.userProfile.isDesigner) {
  //     if (feature.comment) {
  //       this.dataCommService.rejectionCommentDetails.position  = this.dataCommService.getRejCommBoxPosition($event);
  //       this.dataCommService.rejectionCommentDetails.show = true;
  //       this.dataCommService.rejectionCommentDetails.featureId = feature.hero_image_id;
  //       this.dataCommService.rejectionCommentDetails.comment = feature.comment;
  //     } else {
  //       this.dataCommService.rejectionCommentDetails.show = false;
  //     }
  //   } else if (this.authService.userProfile.isClient) {
  //     if (feature.status === 'rejected') {
  //       this.dataCommService.rejectionCommentDetails.position  = this.dataCommService.getRejCommBoxPosition($event);
  //       this.dataCommService.rejectionCommentDetails.show = true;
  //       this.dataCommService.rejectionCommentDetails.featureId = feature.hero_image_id;
  //       this.dataCommService.rejectionCommentDetails.comment = feature.comment;
  //     }
  //   }
  // }

  // showComment() {
  //   //this method only does the mix panel event trigerring
  //   const segmentTrackerData = {
  //     'Prototype Type': this.dataCommService.prototypeType,
  //     User: this.dataCommService.isOwner ? 'Owner' : this.authService.userProfile.isDesigner ? 'Designer' : 'Visitor',
  //     Source: (this.detailType === 'fullScreenDesigner' || this.detailType === 'fullScreenClient') ? 'Screen Detail Popup' : 'Dashboard'
  //   };
  // }

  /*goToReviewScreen(bcFeatureId) {
    this.commentService.commentModeToggleSubject.next(false);
    this.dataCommService.appReloaded = false;
    if (this.feature.story_type === 'sub_task') {
      this.dataCommService.getUpdatedFeatuersData.selectedFeatureId = this.parentFeature.id + '>' + bcFeatureId;
    } else {
      if (this.feature.status !== 'review') {
        this.dataCommService.getUpdatedFeatuersData.selectedFeatureId =
        this.feature.id + '>' + this.feature.sub_features.find(x => x.status === 'review').id;
      } else {
        this.dataCommService.getUpdatedFeatuersData.selectedFeatureId = bcFeatureId;
      }
    }
    this.router.navigate([`${this.dataCommService.buildcardId}/v1/dashboard/${this.dataCommService.platform}/${this.dataCommService.platformType}/reviewScreens`]);
  }*/

  /* getReviewText() {
    let text = '';
    ((!this.feature.story_id && this.page == 'flow-chart' && this.feature.sub_features && this.feature.sub_features.length > 0) ? (text = 'Review ' + this.reviewCount + (this.reviewCount == 1 ? ' Update' : ' Updates')): text = 'Review Updates')
     return text;
  }*/

  /* showReview() {
    return (!this.feature.story_id && (this.page == 'flow-chart' && this.reviewCount > 0) || this.feature.status == 'review') || (this.feature.story_id && this.feature.status == 'review')
  }*/
}


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  @Input() progressBarData: any;
  @Output() progressCompleted: EventEmitter<any> = new EventEmitter();

  isProgressDone: boolean = false;
  isRightTickShow: boolean = false;
  stepCompleted: string = '';
  percentLoaded: string = '0';
  progressBarText: string = 'Collecting the feature screens';

  constructor() { }

  ngOnInit(): void {
    this.animationHandler();
  }

  animationHandler() {
    let progressBar: any = document.querySelector(".progressBarFilled");

    progressBar.ontransitionstart = (event) => {
      if (event.propertyName === 'width') this.stepCompleted = event.target.className.split('step')[1][0];
    };

    progressBar.ontransitionend = (event) => {
      if (event.propertyName === 'width') {
        if (this.stepCompleted === '1') this.percentLoaded = "15";
        else if (this.stepCompleted === '2') this.percentLoaded = "30";
        else if (this.stepCompleted === '3') this.percentLoaded = "50";
        else if (this.stepCompleted === '4') this.percentLoaded = "70";
        else if (this.stepCompleted === '5') this.percentLoaded = "85";
        else if (this.stepCompleted === '6') {
          this.percentLoaded = "100";
          this.isProgressDone = true;
        }
      }
      else if (event.propertyName === 'transform' && !event.srcElement.className.includes('rightTickShow')) this.isRightTickShow = true;
      else if (event.propertyName === 'transform' && event.srcElement.className.includes('rightTickShow')) this.progressCompleted.next();

      if (this.stepCompleted.match(/2|3/)) this.progressBarText = 'Using AI to create the best user flow';
      if (this.stepCompleted.match(/4|5|6/)) this.progressBarText = 'Linking all the screens together';
    };
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/auth.service';
import { CookieService } from '@core/cookie.service';
import { DataCommService } from '../../../main/shared/services/data-comm.service';

@Component({
  selector: 'app-guest-show-warning',
  templateUrl: './guest-show-warning.component.html',
  styleUrls: ['./guest-show-warning.component.scss']
})
export class GuestShowWarningComponent implements OnInit {

  constructor(public dataCommService: DataCommService,
              public cookieService: CookieService,
              private authService: AuthService) { }

  ngOnInit() {
  }

  closWarningPopup(e) {
    e.stopPropagation();
    this.dataCommService.guestWarningPopup = false;
    this.dataCommService.checkGuestWarningPopup();
  }

  openSignUpWindow() {
    if (this.dataCommService.isGuestUser) {
      const payload = {
        user_id: this.dataCommService.userUniqueid,
        user_browser: this.dataCommService.userBrowser,
        user_device: this.dataCommService.userDevice,
        clicked_source: 'Banner'
      };
      this.dataCommService.trackEvent('sign_up_clicked', payload);
      this.dataCommService.selectedSectionIndex = 0;
      this.authService.countryNameCode = 'in';
      this.dataCommService.showHidePaymentFlow(true);
    }
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataCommService } from '../../../../main/shared/services/data-comm.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-mob-other-options',
  templateUrl: './mob-other-options.component.html',
  styleUrls: ['./mob-other-options.component.scss']
})
export class MobOtherOptionsComponent implements OnInit {

  @Input() showPanel: boolean;
  @Output() hidePanel = new EventEmitter();
  public toggleView = (this.dataCommService.platform === 'mobile' ) ? 'web' : 'mobile';

  constructor(private dataCommService: DataCommService, private router: Router ) { }

  ngOnInit() {
  }

  hide() {
    this.hidePanel.next();
  }
  takeActions(value: string) {
    this.hidePanel.next();
    this.dataCommService.mobOtherOptions(value);
  }
  switchPreview(toggleView) {
    if (this.dataCommService.platform !== toggleView) {
      this.dataCommService.switchView(toggleView);
      let previousValue;
      if (window.location.href.indexOf('/web') === -1) {
        previousValue  = 'mobile';
      } else {
        previousValue  = 'web';
      }
      const currentUrlArray = this.router.url.replace(previousValue, this.dataCommService.platform);
      this.router.navigate([currentUrlArray]);
    }
  }
}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { FileUploadService } from '@shared/services/file-upload.service';

@Component({
  selector: 'app-drag-drop-upload',
  templateUrl: './drag-drop-upload.component.html',
  styleUrls: ['./drag-drop-upload.component.scss']
})
export class DragDropUploadComponent implements OnInit {

  showFileOverlay = false;
  @Input() config;
  @Output() filesDropped = new EventEmitter();
  guidelineTypesText = '';
  guidelineSizeText = '';
  guidelineDimensionText = 'Max ';

  constructor(private fileUploadService: FileUploadService) { }

  ngOnInit() {
   this.processTextforUI();
  }

  processTextforUI() {
    this.config.types.forEach((type: string, index) => {
      if (index < this.config.types.length - 1) {
        this.guidelineTypesText = this.guidelineTypesText + type + ', ';
      }
      if (index === this.config.types.length - 1) {
        this.guidelineTypesText = this.guidelineTypesText + 'and' + type;
      }
    });
    this.guidelineSizeText = '' + (+this.config.fileSize) / (1024 * 1024) + 'MB';
    this.guidelineDimensionText = this.guidelineDimensionText + this.config.dimensions.height + 'px*' + this.config.dimensions.width + 'px';
  }

  dropped(files: NgxFileDropEntry[]) {
    this.showFileOverlay = false;
    const filesToSend = [];
    const fileCount = files.length;
    let count = 0;
    files.forEach(droppedFile => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          filesToSend.push(file);
          count++;
          if (count === fileCount) {
            this.fileUploadService.validateSelectedFiles(filesToSend);
          }
        });
      }
    });
  }

  fileOver(event) {
    this.showFileOverlay = true;
  }

  fileLeave(event) {
  }

}

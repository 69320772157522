import { Component, Input, OnChanges, SimpleChanges, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { PreviewModel } from 'src/app/main/shared/models/preview.model';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { HotspotModel } from 'src/app/main/shared/models/hotspot.model';
import { UtilityService } from '@shared/services/utility.service';
import { Constants } from '@shared/services/constants';
import { ManualHotspotService } from '../manual-hotspot/manual-hotspot.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OverlayWithContentService } from '../overlay-with-content/overlay-with-content.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-feature-frame-editable',
  templateUrl: './feature-frame-editable.component.html',
  styleUrls: ['./feature-frame-editable.component.scss']
})
export class FeatureFrameEditableComponent implements OnChanges, AfterViewInit {

  @Input() frameData: PreviewModel;
  @Input() moreClickableItems;
  @Input() showUserHotspot;
  @Input() listenHotspotClick;
  @Output() linkScrnClick = new EventEmitter();
  @Output() removeSpotClick = new EventEmitter();
  manualHotspotSvg = {
    top: 0,
    height: 667
  };
  isEditScreen: boolean;
  isAddScreen: boolean;
  isAddSideKick: boolean;
  isManualHotspotEditable: boolean;
  squaredHotspot = true;
  addedFooterHeight = 0;
  selectedFeature;
  customHotspots: HotspotModel[] = [];
  // headerHotspots: HotspotModel[] = [];
  // footerHotspots: HotspotModel[] = [];
  customWrapperPosition;
  selectedHotspot: HotspotModel;
  @ViewChild('frameWrapper', {static: true}) frameWrapper;
  frameAttributes = {
    width: 0,
    height: 0
  };
  disableManualHotspot = false;
  subs: Subscription[] = [];
  displayHiddenItems = false;
  @Output() hiddenItemClick =  new EventEmitter();
  platform;
  selectedOption = null;
  optionPos = {
    left: null,
    top: null
  };
  @Input() showMenuDeleteBtn;
  @Input() showMenuLinkBtn;
  imageHeight: number;
  imageWidth: number;
  imgLoaded: boolean = false;
  // hotspotFrameHeight: number;
  // hotspotFrameWidth: number;
  constructor(
    public dataCommService: DataCommService, private overlayWithContentService: OverlayWithContentService,
    public utilityService: UtilityService, private router: Router,
    public manualHotspotService: ManualHotspotService, public rectangleService: ManualHotspotService) {
      this.subs.push(this.dataCommService.selectedHotspot$.subscribe((hotspot: HotspotModel) => {
        this.selectedHotspot = hotspot;
      }));
      this.isEditScreen = this.router.url.includes('edit-screen');
      this.isAddScreen = this.router.url.includes('add-hotspot');
      this.isAddSideKick = this.router.url.includes('add-sidekick');
      if (this.isAddScreen) {
        this.isManualHotspotEditable = false;
        this.disableManualHotspot = false;
      } else if (this.router.url.includes('add-sidekick')
        || this.router.url.includes('add-icon')
        || this.router.url.includes('add-edit-carousel')) {
        this.isManualHotspotEditable = true;
        this.disableManualHotspot = false;
      } else {
        this.isManualHotspotEditable = true;
        this.disableManualHotspot = true;
      }
      this.subs.push(this.dataCommService.displayMoreItems$.subscribe((value: boolean) => {
        this.displayHiddenItems = value;
      }));
      this.platform = this.dataCommService.platform;
      this.subs.push(this.dataCommService.switchViewSource$.subscribe((newView: string) => {
        this.platform = newView;
      }));
      this.getSuggestionToopTipPos();
      this.dataCommService.showHideSuggToolTipOnEditFeature = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.frameData && this.frameData) {
      if(this.frameData && !this.imgLoaded) this.getImageDetails(this.frameData.hero_image_url);
      this.filterHotspots();
    }
  }

  ngAfterViewInit(): void {
    this.frameAttributes.width = +this.frameWrapper.nativeElement.offsetWidth;
    this.frameAttributes.height = +this.frameWrapper.nativeElement.offsetHeight;
  }

  getSuggestionToopTipPos() {
    this.subs.push(this.dataCommService.setSuggestionTootipPos$.subscribe((featureId) => {
      this.displayHiddenItems = true;
      const findIndexOfItem = this.rectangleService.selectedComponent.clickableItems.findIndex
      (arrObj => arrObj.feature_id === featureId);
      if (findIndexOfItem !== -1) {
        this.rectangleService.selectedClickableItem.suggestionTooltip = this.rectangleService
        .selectedComponent.clickableItems[findIndexOfItem].suggestionTooltip;
        this.setTimeOutFun(featureId);
      }
    }));
  }

  filterHotspots() {
    this.customHotspots = this.frameData.hotspots;
  }

  setScale() {
    const styleObj: any = {},
      hotspotPanelWidth: number = 300,
      previewDetailHeight: number = 58,
      padding: number = 30,
      horizontalScrollBarHeight: number = 7,
      verticalScrollBarWidth: number = 5,
      maxHeight: number = document.getElementsByClassName('preview-frame-wrap')[0].getBoundingClientRect().height - previewDetailHeight - padding - horizontalScrollBarHeight,
      maxWidth: number = document.getElementsByClassName('preview-frame-wrap')[0].getBoundingClientRect().width - padding - verticalScrollBarWidth;

    styleObj.position = 'absolute';
    styleObj.height = this.imageHeight >= 667 ? this.imageHeight + 'px' : '667px';
    styleObj.width = this.imageWidth >= 375 ? this.imageWidth + 'px' : '375px';

    // this.hotspotFrameHeight = this.imageHeight >= 667 ? this.imageHeight : 667;
    // this.hotspotFrameWidth = this.imageWidth >= 375 ? this.imageWidth : 375;

    if (this.imageHeight > maxHeight && this.imageWidth > maxWidth) {
      let updatedImage = this.calculateAspectRatioFitDimensions(this.imageWidth, this.imageHeight, maxWidth, maxHeight);

      if (updatedImage.width > maxWidth) {
        this.overlayWithContentService.hotspotLinkingWrapperStyleObj.width = updatedImage.width + padding + 'px';
        this.overlayWithContentService.isHotspotHorizontalScroll = true;
        this.overlayWithContentService.perfectScrollBarConfig.suppressScrollX = false;
        this.overlayWithContentService.perfectScrollBarConfig.suppressScrollY = true;
        this.overlayWithContentService.perfectScrollBarConfig.useBothWheelAxes = true;
      }

      if (updatedImage.height > maxHeight) {
        this.overlayWithContentService.hotspotLinkingWrapperStyleObj.height = updatedImage.height + previewDetailHeight + padding + 'px';
        this.overlayWithContentService.isHotspotVerticalScroll = true;
        this.overlayWithContentService.perfectScrollBarConfig.suppressScrollX = true;
        this.overlayWithContentService.perfectScrollBarConfig.suppressScrollY = false;
      }

      this.overlayWithContentService.hotspotImageScale = updatedImage.ratio;
      styleObj.transform = `scale( ${updatedImage.ratio})`;
      styleObj['transform-origin'] = 'top left';
    }
    else {
      if (this.imageHeight > maxHeight) {
        this.overlayWithContentService.isHotspotVerticalScroll = true;
        this.overlayWithContentService.perfectScrollBarConfig.suppressScrollX = true;
        this.overlayWithContentService.perfectScrollBarConfig.suppressScrollY = false;
      }
      else if (this.imageWidth > maxWidth) {
        this.overlayWithContentService.isHotspotHorizontalScroll = true;
        this.overlayWithContentService.perfectScrollBarConfig.suppressScrollX = false;
        this.overlayWithContentService.perfectScrollBarConfig.suppressScrollY = true;
        this.overlayWithContentService.perfectScrollBarConfig.useBothWheelAxes = true;
      }
    }

    return styleObj;
  }

  setStyle() {
    const height = Constants.hotspotConstants.HEADER_HEIGHT + Constants.hotspotConstants.HEADER_TOP_MARGIN;
    const style: any = {};
    return style;
  }
  onMouseOut(event) {
    if (event.toElement !== null && event.toElement.nodeName === 'DIV') {
      this.manualHotspotService.resetManualHotspotFlags();
    }
  }

  hotspotSelected(hotspot: HotspotModel) {
    // this.dataCommService.spotTypeForDestination = hotspot.category;
  }
  hideItems() {
    this.dataCommService.setDisplayMoreItemsSubject(false);
    this.dataCommService.showHideSuggToolTipOnEditFeature = false;
    this.selectedOption = null;
    this.dataCommService.sidekickImageClicked({});
    this.dataCommService.showHideContxtMenuOnEditFeature = false;
    this.rectangleService.selectedComponent.isSelected = false;
    // this.overlayWithContentService.showHideSliderFun(false);
  }
  hiddenItemClicked(hiddenClickableItem, index) {
    if (this.isAddSideKick) {
      this.dataCommService.sidekickImageClicked({});
      const findIndexOfItem = this.rectangleService.selectedComponent.clickableItems.findIndex
      (arrObj => arrObj.feature_id === hiddenClickableItem.feature_id);
      if (findIndexOfItem !== -1) {
        this.rectangleService.selectedClickableItem = this.rectangleService
        .selectedComponent.clickableItems[findIndexOfItem];
        this.selectedOption = hiddenClickableItem.feature_id;
        const featureIdElem = document.getElementById('option-' + hiddenClickableItem.feature_id);
        if (featureIdElem) {
          const pos: any = featureIdElem.getBoundingClientRect();
          this.optionPos.left = parseInt(pos.left, 10) + 155;
          this.optionPos.top = parseInt(pos.top, 10) - 150;
        }

        this.dataCommService.showHideSuggToolTipOnEditFeature = true;
      }
    }
    if (this.isEditScreen) {
      const featureIdElem = document.getElementById('option-' + hiddenClickableItem.feature_id);
      if (featureIdElem) {
        const pos: any = featureIdElem.getBoundingClientRect();
        this.optionPos.left = parseInt(pos.left, 10) + 150;
        this.optionPos.top = parseInt(pos.top, 10) - 150;
      }
      this.dataCommService.showHideContxtMenuOnEditFeature = true;
      this.rectangleService.findClickableItem(hiddenClickableItem.id);
      this.hiddenItemClick.emit(hiddenClickableItem);
    }
  }
  openSuggestionSidePanel() {
    const findIndexOfItem = this.rectangleService.selectedComponent.clickableItems.findIndex
    (arrObj => arrObj.feature_id === this.selectedOption);
    if (findIndexOfItem !== -1) {
      this.rectangleService.selectedClickableItem = this.rectangleService
      .selectedComponent.clickableItems[findIndexOfItem];
      this.dataCommService.suggestionTooltipClicked( {
        tooltipclicked: true,
        item: this.rectangleService.selectedClickableItem,
      });
    }
  }
  setTimeOutFun(featureId) {
    setTimeout(() => {
      this.selectedOption = featureId;
      const pos: any = document.getElementById('option-' + featureId).getBoundingClientRect();
      this.optionPos.left = parseInt(pos.left, 10) + 155;
      this.optionPos.top = parseInt(pos.top, 10) - 150;
      this.dataCommService.showHideSuggToolTipOnEditFeature = true;
    }, 0);
  }
  linkScreenHandler() {
    this.linkScrnClick.emit();
  }
  deleteSpotHandler() {
    this.removeSpotClick.emit();
  }
  trackById(index, item) {
    return item.feature_id;
  }

  getImageDetails(url) {
    var img = new Image();
    img.onload = () => {
      this.imageHeight = img.height;
      this.imageWidth = img.width;
      this.imgLoaded = true;
    };
    img.src = url;
  }

  calculateAspectRatioFitDimensions(srcWidth, srcHeight, maxWidth, maxHeight) {
    var ratio = 1;
    if (srcWidth > srcHeight) ratio = maxHeight / srcHeight;
    else ratio = maxWidth / srcWidth;
    return { width: srcWidth * ratio, height: srcHeight * ratio, ratio: ratio };
  }

}

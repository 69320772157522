import { Directive, Input, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { RectangleComponent } from './rectangle-component/rectangle-component';

@Directive({
    selector: '[dynamicSvg]'
})
export class ManualHotspotDirective implements OnInit, OnDestroy {

  @Input() component: RectangleComponent;

  constructor(private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.component.shapeTemplate);
  }

  ngOnDestroy() {
    this.viewContainerRef.clear();
  }
}

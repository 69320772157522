import { Subscription } from 'rxjs';
import {Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, HostListener} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import {BaseService} from '@core/base.service';
import {AuthService} from '@core/auth.service';
import {DataService} from '../../../../../main/shared/services/data.service';
import {DataCommService} from '../../../../../main/shared/services/data-comm.service';
import {ToasterService} from '@core/toaster.service';
import { UtilityService } from '@shared/services/utility.service';
import { SketchService } from 'src/app/main/shared/services/sketch.service';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss']
})
export class BrandingComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('multiselect', { static: true }) multiselectDrop: ElementRef;
  baseIcons = [
    { source: '../../../assets/images/cart-blank.png', selected: false, type: 'blank' },
    { source: '../../../assets/images/cart-fill.png', selected: false, type: 'filled' }
  ]

  // formModel: StylebrandingModel;
  formModel: any = {
    project_name: '',
    project_id: '',
    logo_attachments: {
      files: [],
      custom_attribute: [],
      reference: '',
    },
    font_attachments: {
      files: [],
      custom_attribute: [],
      reference: '',
    },
    colour_attachments: {
      files: [],
      custom_attribute: [],
      reference: '',
    },
    icon_attachments: {
      files: [],
      custom_attribute: '',
      reference: '',
    },
    illustration_attachments: {
      files: [],
      custom_attribute: [],
      reference: '',
    },
    other_attachments: {
      files: [],
      custom_attribute: [],
      reference: '',
    },
  }

  public disableButton: boolean = true;
  public showFileOverlayLogo: boolean = false;
  public showFileOverlayFont: boolean = false;
  public showFileOverlayOther: boolean = false;

  public showPicker: boolean = true;
  public colorPickerModel: string | null;
  public fontDropdownSettings: Object | null = {};
  public fontList: any = [];

  public brandTextArea: boolean = false;
  public fontFileArea: boolean = false;
  public colorTextArea: boolean = false;
  public iconTextArea: boolean = false;
  $routerSubscribtion: Subscription;
  buildCardId: string;
  brandingId: string;
  showLoader = false;
  fontSelected = [];
  fontFiterList = [];
  public dropDownShow = false;
  public showClearAll = false;
  public searchQuery = '';
  isRentalCardPaid: boolean;
  showSpinner = false;

  logoLoader = false;
  fontLoader = false;
  // colourLoader = false;
  // iconLoader = false;
  // illustrationLoader = false;
  otherLoader = false;
  greyBg = false;
  fileUploadingButton = false;
  saveButton: boolean;
  currentFileNameLogo: string;
  currentFileNameFont: string;
  currentFileNameOther: string;
  ctaText = "Update branding...";
  public platformName: string;
  public selectedValues: any ={};
  public previousIndex: any=null;
  public openDropDownBoolean =false;
  public clickedPlatformIndex: number;
  public selectedFileIndex: number;
  public previousFileIndex: any=null;
  public arrowUpFlag: boolean = false;
  public platformIds = [];
  public platformObjects = [];
  public fileCounter: number = -1;
  public previews: string[];
  public sketchFileInfo = [];

  constructor(
    public authService: AuthService,
    private dataService: DataService,
    public dataCommService: DataCommService,
    private router: Router,
    private route: ActivatedRoute,
    public baseService: BaseService,
    private toasterService: ToasterService,
    private utilityService: UtilityService,
    private sketchService: SketchService,
  ) {
    this.getBrandingDetails(this.dataCommService.buildcardId);
    // this.$routerSubscribtion = this.route.parent.params.subscribe((params) => {
    //   this.buildCardId = <string>params.buildcardId;
    //   const loggedInresp = this.authService.isLoggedInUser();
    //   if (loggedInresp === true) {
    //     this.getBuildCardStatus(this.buildCardId);
    //   } else {
    //     window.open(this.baseService.studioUrl + '/dashboard', '_self');
    //   }
    // });

  }

  @HostListener('document:click', ['$event'])
  clickoutSide(event) {
    event.stopPropagation();
    if (event.target.classList.length > 0
        && !event.target.classList.contains('caret')
        && !event.target.classList.contains('selected-font')
        && !event.target.parentElement.classList.contains('font-element')
        && !event.target.classList.contains('font-element')
        && !event.target.classList.contains('itemCheck')
        && !event.target.classList.contains('icon-iconcross')
        && !event.target.classList.contains('font-list')
        && !event.target.classList.contains('selected')
        && !event.target.classList.contains('inputCheck')
        && !event.target.classList.contains('ps__thumb-y')
        && !event.target.classList.contains('ps__rail-y')
        && !event.target.classList.contains('font-dropdown-wrap')
        && !event.target.classList.contains('icon-caret-down')
        && !event.target.classList.contains('icon-web')
        && !event.target.classList.contains('icon-mobile')
        && !event.target.classList.contains('icon-desktop-1')
        && !event.target.classList.contains('icon-icon-tab')
        && !event.target.classList.contains('icon-icon-watch')
        && !event.target.classList.contains('mob-btn')
        && !event.target.classList.contains('web-btn')
        && !event.target.classList.contains('tab-btn')
        && !event.target.classList.contains('watch-btn')
        && !event.target.classList.contains('platFormCheckList')
        && !event.target.classList.contains('styled-checkbox')
    ) {
      this.dropDownShow = false;
      this.closePlatformBlock();
    }
  }
  gotoMain() {
    window.location.href = this.dataCommService.buildcardId + '/v1/dashboard/' + this.dataCommService.platform +  '/' + this.dataCommService.platformType + '/flowchart';
  }
  gotoPreview() {
    this.router.navigateByUrl(`${this.dataCommService.buildcardId}`);
  }


  ngAfterViewInit() {
    // console.log(this.multiselectDrop.dropdownListElem.nativeElement.innerHTML);
  }

  changeState(value) {
    this[(value)] = !this[(value)];
  }

  enableButton() {
    this.saveButton = true;
    this.disableButton = false;
  }

  icon_Selection(item) {

    this.enableButton();
    this.baseIcons.forEach(e => {
      e.selected = false;
    });
    item.selected = true;
  }

  colourValidation() {
    const length = this.formModel.colour_attachments.custom_attribute.length;
    if (length < 3) {
      this.showPicker = true;
    }  else {
      this.showPicker = false;
    }
  }

  updateModel(type, value) {

    switch (type) {

      case 'logo_attachments':
        this.updateLogo(value);
        break;
      case 'font_attachments':
        this.updateFont(value);
        break;
      case 'colour_attachments':
        this.updateColor(value);
        break;
      case 'icon_attachments':
        this.updateIcon(value);
        break;
      case 'illustration_attachments':
        this.updateIllustration(value);
        break;
      case 'other_attachments':
        this.updateother(value);
        break;

      default:
        break;
    }
  }

  updateLogo(updatedResponse) {
    this.formModel.logo_attachments = updatedResponse;
  }

  updateFont(updatedResponse) {
    this.formModel.font_attachments = updatedResponse;
    this.setFont(updatedResponse.custom_attribute);
  }

  updateColor(updatedResponse) {
    this.formModel.colour_attachments = updatedResponse;
    this.colourValidation();
  }

  updateIcon(updatedResponse) {
    this.formModel.icon_attachments = updatedResponse;
  }

  updateIllustration(updatedResponse) {
    this.formModel.illustration_attachments = updatedResponse;
  }

  updateother(updatedResponse) {
    this.formModel.other_attachments = updatedResponse;
  }

  /*getBuildCardStatus(buildCardId) {
    this.dataService.pollBuildCardStatus(this.dataCommService.buildcardId).subscribe(data => {
      this.isRentalCardPaid = data['is_paid'];
      if (this.isRentalCardPaid) {
        this.getBrandingDetails(buildCardId);
      } else {
        this.gotoPreview();
      }
    }, error => {
      this.gotoPreview();
    });
  }*/

  getBrandingDetails(buildCardId) {
    this.dataService.getBranding(buildCardId).subscribe(res => {
      this.showLoader = true;
      this.dataCommService.setProjectName(res['project_name']);
      this.brandingId = res['branding_id'];
      this.formModel = res;
      if (this.formModel.other_attachments.files.length > 0) {
        this.formModel.other_attachments.files.forEach((fileArray, i) => {
        this.fileCounter++;
        this.platformIds[this.fileCounter] = [];
        this.platformObjects[this.fileCounter] = [];
        if (this.utilityService.isSketch(fileArray.name)) {
          this.sketchFileInfo[this.fileCounter] ={
            sketchFile: {},
            artBoardCount: fileArray.sketch_details.artboard_count
          };
          if (fileArray.sketch_details && fileArray.sketch_details.platforms.length > 0) {
            fileArray.sketch_details.platforms.forEach((val, j) => {
              this.platformIds[this.fileCounter].push(val.id);
              this.platformObjects[this.fileCounter].push(val);
            });
          }
        }
      });
    }
      this.colourValidation();
      this.setFont(res.font_attachments.custom_attribute);
      this.setIcon(res.icon_attachments.custom_attribute);
      this.setTogglesForText(
        res.logo_attachments.reference,
        res.font_attachments.files.length,
        res['colour_attachments'].reference,
        res.icon_attachments.reference);
    }, err => {
      this.toasterService.error(err);
      this.gotoPreview();
    });
  }

  setTogglesForText(logoRef, fontFileLength, colourRef, iconRef) {
    this.logoRefSetter(logoRef);
    this.fontFileSetter(fontFileLength);
    this.colourRefSetter(colourRef);
    this.iconRefSetter(iconRef);

  }

  logoRefSetter(logoRef) {
    if (logoRef === null || logoRef === '') {

      this.brandTextArea = false;
    } else {
      this.brandTextArea = true;
    }
  }
  fontFileSetter(fontFileLength) {
    if (fontFileLength === 0) {
      this.fontFileArea = false;
    } else {
      this.fontFileArea = true;
    }
  }
  colourRefSetter(colourRef) {
    if (colourRef === null || colourRef === '') {
      this.colorTextArea = false;
    } else {
      this.colorTextArea = true;
    }
  }
  iconRefSetter(iconRef) {
    if (iconRef === null || iconRef === '') {
      this.iconTextArea = false;
    } else {
      this.iconTextArea = true;
    }
  }

  setFont(value) {
    if (value.length > 0) {
      const mapping = Object.assign([], value);
      this.fontSelected = [];
      this.fontList.forEach(element => {
        mapping.forEach(element2 => {
          if (element['name'] === element2) {
            this.fontSelected.push(element);
          }
        });
      });
    }
  }


  setIcon(value) {
    this.baseIcons.forEach(element => {
      if (element.type === value) {
        element.selected = true;
      }
    });
  }



  dropDownInit() {
    this.fontList = [
      { id: 1, name: 'American Typewriter' },
      { id: 2, name: 'Andale Mono' },
      { id: 3, name: 'Apple Chancery' },
      { id: 4, name: 'Arial Black' },
      { id: 5, name: 'Arial' },
      { id: 6, name: 'Brush Script' },
      { id: 7, name: 'Big Caslon' },
      { id: 8, name: 'Comic Sans MS' },
      { id: 9, name: 'Copperplate' },
      { id: 10, name: 'Courier New' },
      { id: 11, name: 'Techno' },
      { id: 12, name: 'Hoefler Text' },
    ];

    this.formModel.font_attachments.custom_attribute = [];
    this.fontFiterList = this.fontList;

    this.fontDropdownSettings = {
      labelKey: 'name',
      singleSelection: false,
      text: 'Select Font',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class'
    };
  }


  ngOnInit() {
    this.dataService.getPlatforms(this.dataCommService.buildcardId).subscribe((response: any) => {
      this.dataCommService.selectedPlatforms = response.platforms;
      this.dataCommService.createMapping();
    });
    this.dropDownInit();
  }

  onItemSelect(event, item: any) {
    if (event.target.checked) {
      this.fontSelected.push(item);
    } else {
      const index = this.fontSelected.findIndex(arrObj => arrObj.id === item.id);
      if (index > -1) {
        this.fontSelected.splice(index, 1);
      }
    }
    this.enableButton();
  }
  OnItemDeSelect(item: any) {
    this.enableButton();
  }



  ngOnDestroy() {
    //destroy c-1
    if (this.toasterService && this.toasterService.toasterOptions) {
      this.toasterService.toasterOptions.timeOut = 2000;
    }
    this.$routerSubscribtion.unsubscribe();
  }

  uploadFile(event, type) {
    //cal error c -2
    let isError: number = 0;
    const file = event.target.files;
    if(file.length > 0){
      this.manageLoader(type,true);
    }    
    for (let i = 0; i < file.length; i++) {
      if (type === 'other') {
        this.fileCounter++;
      }  
      if (type === 'other' && this.utilityService.isSketch(file[i].name)) { // If File is a Sketch
        if(this.sketchService.validateSketchFile(file[i].name, file[i].size)) {
          this.addFiles(file[i], 'sketch');
        } else {
          this.onMaxUpload();
          break;
        }
      } else {
        let sizeValidation = this.sizeValidator(file[i],type);
        let validation = this.validationMethod(file[i], type);
        if(validation && sizeValidation){
          this.addFiles(file[i], type);
        }
        else if(!validation){
          isError++;
        }
      } 
    }
    if (isError > 0) {
      this.manageLoader(type,false);
      this.removeLoaderBg();
      this.toasterService.toasterOptions.timeOut = 5000;
      this.toasterService.error(this.getString(type));
    }
  }
  //c-3
  validationMethod(file, type) {
    switch (type) {
      case 'logo':
        return this.validateLogo(file);
      case 'font':
        return this.validateFont(file);
      case 'other':
        return this.validateOther(file);
    }
  }

  // c-4
  getString(type) {
    switch (type) {
      case 'logo':
        return 'Logo files only support JPEG and PNG. Kindly reupload the logo file.';
      case 'font':
        return 'Font does not support the uploaded file. Supported file formats are TTF, TTC, OTF, FON, AFM, TTE,FFIL, EUF.';
      case 'other':
        return 'Maximum upload file size: 50MB.';
        case 'size':
          return 'Maximum upload file size: 50MB.';
    }
  }
// c-5
  validateLogo(file) {
    if (file.type.includes('image') || file.type.includes('jpeg') || file.type.includes('jpg') || file.type.includes('gif') || file.type.includes('png')) {
      return true;
    }
    else {
      return false;
    }
  }
  // c-6
  validateFont(file) {
    const fileExt = file.name.split('.');
    let fileExtention = fileExt[1];
    if (fileExtention.includes('ttf') || fileExtention.includes('ttc') ||
      fileExtention.includes('otf') || fileExtention.includes('fon') ||
      fileExtention.includes('afm') || fileExtention.includes('tte') ||
      fileExtention.includes('ffil') || fileExtention.includes('euf')) {
      return true;
    }
    else {
      return false;
    }
  }
  // c-7
  validateOther(file) {
    let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
    if (sizeInMB < '48') {
      return true;
    }
    else {
      return false;
    }
  }

  sizeValidator(file,type){
    let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
    if (sizeInMB < '48') {
      return true;
    }
    else {
      this.manageLoader(type,false);
      this.removeLoaderBg();
      this.toasterService.toasterOptions.timeOut = 5000;
      this.toasterService.error(this.getString('size'));
      return false;
    }
  }

  hideOverLay() {
    this.showFileOverlayLogo = false;
    this.showFileOverlayFont = false;
    this.showFileOverlayOther = false;
  }

  onMaxUpload() {
    this.otherLoader =false;
    this.greyBg = false;
    this.fileUploadingButton = false;
    this.saveButton = !this.saveButton ? true : false;
  }

  public dropped(files: NgxFileDropEntry[], type) {
    // c-8
    let isError: number = 0;
    let errorShown = false;
    this.hideOverLay();
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        this.manageLoader(type,true);
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (type === 'other') {
            this.fileCounter++;
          }
          if (type === 'other' && this.utilityService.isSketch(file.name)) { // If File is a Sketch
            if(this.sketchService.validateSketchFile(file.name, file.size)) {
              this.addFiles(file, 'sketch');
            } else {
              this.onMaxUpload();
            }
          } else {
            let sizeValidation = this.sizeValidator(file,type);
            let validation = this.validationMethod(file, type);
            if (validation  && sizeValidation) {
              this.addFiles(file, type);
            }
          }
          if (isError > 0 && !errorShown) {
            errorShown =true;
            this.manageLoader(type,false);
            this.removeLoaderBg();
            this.toasterService.toasterOptions.timeOut = 5000;
            this.toasterService.error(this.getString(type));
          }
        });
      }
    }
  }
  async addFiles(file: File, type: string) {
    this.platformIds[this.fileCounter] = [];
    this.platformObjects[this.fileCounter] = [];
    this.sketchFileInfo[this.fileCounter] = {};
    //Start-Code for extracting artboard out of sketch files
    if (type === 'sketch') {
      try {
        const data = await this.sketchService.loadSketchFile(file);
        this.previews = data.previews.map(preview => {
            return `data:image/jpg;base64,${preview}`;
        });
        const {pageName, artBoardCount} = this.utilityService.validationOfLayers(data.pages);
        const fileObj = this.utilityService.blobCreationFromURL(this.previews[0], pageName);
        
        this.sketchFileInfo[this.fileCounter] = {
          sketchFile: fileObj,
          artBoardCount: artBoardCount
        };
      } catch (error) {
          this.toasterService.error(error);
      }
    }
    //End-Code for extracting artboard out of sketch files 

    this.enableButton();
    switch (type) {
      case 'logo':
        this.currentFileNameLogo = file.name;
        this.formModel.logo_attachments.files.push(file);
          this.manageLoader('logo',false);
          this.removeLoaderBg();
        break;
      case 'font':
        this.currentFileNameFont = file.name;
        this.formModel.font_attachments.files.push(file);
        this.fontFileSetter(this.formModel.font_attachments.files.length);
        this.manageLoader('font',false);
        this.removeLoaderBg();
        break;
      case 'other':
      case 'sketch':
        this.currentFileNameOther = file.name;
        this.formModel.other_attachments.files.push(file);
        this.manageLoader('other',false);
        this.removeLoaderBg();
        break;

      default:
        break;
    }
  }


  removeFiles(file: File, type: string, fileIndex) {
    this.fileCounter--;
    if(type === 'other' && fileIndex > -1) {
      this.platformIds[fileIndex] = [];
      this.platformObjects[fileIndex] = [];
      this.sketchFileInfo[fileIndex] = {};

      this.platformIds = this.platformIds.filter(e => e != this.platformIds[fileIndex]);
      this.platformObjects = this.platformObjects.filter(e => e != this.platformObjects[fileIndex]);
      this.sketchFileInfo = this.sketchFileInfo.filter(e => e != this.sketchFileInfo[fileIndex]);
    }
    switch (type) {
      case 'logo':
        this.formModel.logo_attachments.files = this.formModel.logo_attachments.files.filter(e => e != file);
        break;
      case 'font':
        this.formModel.font_attachments.files = this.formModel.font_attachments.files.filter(e => e != file);
        this.fontFileSetter(this.formModel.font_attachments.files.length);
        break;
      case 'other':
        this.formModel.other_attachments.files = this.formModel.other_attachments.files.filter(e => e != file);
        break;

      default:
        break;
    }
  }



  fileOver(event, type) {

    if (type === 'logo') {
      this.showFileOverlayLogo = true;

    } else if (type === 'font') {
      this.showFileOverlayFont = true;
    } else if (type === 'other') {

      this.showFileOverlayOther = true;
    }
  }

  public fileLeave(event) {
    this.hideOverLay();
  }

  remove(value, type, fileIndex = -1) {
    if (type === 'other' && value.sketch_details && !value.sketch_details.deleteable) {
      this.toasterService.error("Story is mapped to sketch file.");
    } else {
      if (type === 'logo' || type === 'font' || type === 'other') {
        if (value.id !== undefined) {
          this.delete(value.id);
          this.removeFiles(value, type, fileIndex);
        } else {
          this.removeFiles(value, type, fileIndex);
        }
      } else {
        this.removeFiles(value, type, fileIndex);
      }
    }
  }

  hideAdd(length) {
    if (length < 3) {
      this.showPicker = true;
    } else {
      this.showPicker = false;
    }
  }

  addColor(pickedColor) {
    this.enableButton();
    if (pickedColor !== undefined) {
      if (this.formModel.colour_attachments.custom_attribute.length < 3) {
        this.formModel.colour_attachments.custom_attribute.push(pickedColor);
        this.hideAdd(this.formModel.colour_attachments.custom_attribute.length);
      } else {
      }
    } else {
    }
  }

  removeColor(item) {
    this.enableButton();
    this.formModel.colour_attachments.custom_attribute = this.formModel.colour_attachments.custom_attribute.filter(e => e !== item);
    this.hideAdd(this.formModel.colour_attachments.custom_attribute.length);
  }


  delete(attachmentId) {
    this.dataService.delete(attachmentId, this.brandingId).subscribe(response => {
      this.enableButton();
    }, err => {
      this.enableButton();
    });
  }

  saveForm() {
    this.showSpinner = true;
    this.disableButton = true;
    this.greyBg = true;
    this.fileUploadingButton = true;
    const loginUpdate = this.logo();
    const fontUpdate = this.font();
    const colorUpdate = this.color();
    const iconUpdate = this.icon();
    const illustrationUpdate = this.illustration();
    const otherpdate = this.other();
    setTimeout(() => {
      this.ctaText = "File is still uploading...";
    }, 2000);
    this.updateApi(loginUpdate, fontUpdate, colorUpdate, iconUpdate, illustrationUpdate, otherpdate);
    const myArray = [
      { key: 'Logo', value: this.formModel.logo_attachments.reference },
      { key: 'Colour', value: this.formModel.colour_attachments.reference },
      { key: 'Icon style', value: this.formModel.icon_attachments.reference },
      { key: 'Illustration style', value: this.formModel.illustration_attachments.reference },
      { key: 'Other', value: this.formModel.other_attachments.reference },
    ]
    let textString: string = '';


    myArray.forEach(element => {
      if (element.value !== null && element.value !== '' && element.value !== undefined) {
        textString += `${element.key},`;
      }
    });
    textString = textString.substring(0, textString.length - 1);
  }

  updateApi(loginUpdate, fontUpdate, colorUpdate, iconUpdate, illustrationUpdate, otherpdate) {
    this.dataService.updateBranding
      (
        loginUpdate,
        fontUpdate,
        colorUpdate,
        iconUpdate,
        illustrationUpdate,
        otherpdate,
        this.dataCommService.buildcardId,
        this.brandingId
      ).subscribe(updatedBranding => {
      this.showSpinner = false;
      this.disableButton = false;
      this.greyBg = false;
      this.fileUploadingButton = false;
      this.dataCommService.showBrandingThankyouPopup = true;
        // this.router.navigateByUrl(`${this.buildCardId}/branding/success`);
      this.dataCommService.showHidePaymentFlow(false);
      this.dataCommService.showBottomBar = false;


        // updatedBranding.forEach(element => {
        //   console.log(element);
        //   const type = element.attachment_type;
        //   let payload = Object.assign({}, element);
        //   delete payload.attachment_type;
        //   delete payload.branding_id;
        //   this.updateModel(type, payload);
        // });

      }, err => {
        this.saveButton = true;
        this.showSpinner = false;
        this.disableButton = false;
        this.greyBg = false;
        this.fileUploadingButton = false;
        this.ctaText = 'Update branding...';
        this.toasterService.error(err);

       // this.router.navigateByUrl(`${this.buildCardId}/branding/success`);

      });
  }


  logo() {
    const logoForm = new FormData();
    logoForm.append(`attachment_type`, 'logo_attachments');
    logoForm.append(`branding[logo_name]`, `${this.formModel.logo_attachments.reference}`);
    if (this.formModel.logo_attachments.files.length > 0) {
      const logoFiles = this.formModel.logo_attachments.files;
      logoFiles.forEach((element, index) => {
        if (element.id === undefined) {
          logoForm.append(`file_attributes[${index}][file]`, element);
          logoForm.append(`file_attributes[${index}][file_name]`, `${element.name}`);
        }
      });
    }
    return logoForm;
  }

  font() {
    const fontForm = new FormData();
    fontForm.append(`attachment_type`, 'font_attachments');
    if (this.formModel.font_attachments.files.length > 0) {
      const fontFiles = this.formModel.font_attachments.files;
      fontFiles.forEach((element, index) => {
        if (element.id === undefined) {
          fontForm.append(`file_attributes[${index}][file]`, element);
          fontForm.append(`file_attributes[${index}][file_name]`, `${element.name}`);
        }
      });
    }
    if (this.fontSelected.length > 0) {
      const customFiles = this.fontSelected;
      customFiles.forEach((element, index) => {
        fontForm.append(`branding[fonts][${index}]`, element.name);
      });
    }
    return fontForm;
  }

  color() {

    const colorForm = new FormData();
    colorForm.append(`attachment_type`, 'colour_attachments');
    colorForm.append(`branding[colour_reference]`, `${this.formModel.colour_attachments.reference}`);
    const colourFiles = this.formModel.colour_attachments.custom_attribute;
    colourFiles.forEach((element, index) => {
      colorForm.append(`branding[colours][${index}]`, element);
    });
    return colorForm;
  }

  icon() {

    const iconForm = new FormData();
    iconForm.append(`attachment_type`, 'icon_attachments');
    iconForm.append(`branding[icon_style_reference]`, `${this.formModel.icon_attachments.reference}`);
    const selectedIcon = this.baseIcons.find(e => e.selected === true);
    if (selectedIcon !== undefined) {
      iconForm.append(`branding[icon_style]`, `${selectedIcon.type}`);
    }
    else {
      iconForm.append(`branding[icon_style]`, ``);
    }


    return iconForm;
  }

  illustration() {
    const illustrationForm = new FormData();
    illustrationForm.append(`attachment_type`, 'illustration_attachments');
    illustrationForm.append(`branding[illustration_style]`, `${this.formModel.illustration_attachments.reference}`);

    return illustrationForm;
  }

  other() {
    const otherForm = new FormData();
    otherForm.append(`attachment_type`, 'other_attachments');
    otherForm.append(`branding[other_reference]`, `${this.formModel.other_attachments.reference}`);

    if (this.formModel.other_attachments.files.length > 0) {
      const otherFiles = this.formModel.other_attachments.files;
      otherFiles.forEach((element, index) => {
        if (element.id === undefined) {
          otherForm.append(`file_attributes[${index}][file]`, element);
          otherForm.append(`file_attributes[${index}][file_name]`, `${element.name}`);
          if (this.utilityService.isSketch(element.name)) {
            otherForm.append(`file_attributes[${index}][sketch_details][artboard_count]`, `${this.sketchFileInfo[index].artBoardCount}`);
            otherForm.append(`file_attributes[${index}][sketch_details][image_file]`, this.sketchFileInfo[index].sketchFile);
            otherForm.append(`file_attributes[${index}][sketch_details][image_file_name]`, `${this.sketchFileInfo[index].sketchFile.name}`);
            otherForm.append(`file_attributes[${index}][sketch_details][platform_ids]`, this.platformIds[index]);
          }
        } else if (element.sketch_details && this.utilityService.isSketch(element.name)) {
          otherForm.append(`updated_sketches[${index}][id]`, element.sketch_details.id);
          otherForm.append(`updated_sketches[${index}][platform_ids]`, this.platformIds[index]);
        }
      });
    }

    return otherForm;
  }
  removeSelected(item) {
    this.enableButton();
    const index = this.fontSelected.findIndex(arrObj => arrObj.id === item.id);
    if (index > -1) {
      this.fontSelected.splice(index, 1);
    }
  }
  removeAllSelected() {
    this.enableButton();
    this.fontSelected = [];
  }
  dropBoxHandler() {
    this.dropDownShow  = this.dropDownShow ? false : true;
  }

  searchFilter() {
    if (this.searchQuery) {
      this.showClearAll = true;
      this.fontFiterList = this.fontList.filter(feature => {
        return feature.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1;
      });
    } else {
      this.fontFiterList = this.fontList;
    }
  }

  clearAll() {
    this.showClearAll = false;
    this.searchQuery = '';
    this.searchFilter();
  }

  commonLoader(){
    this.greyBg = true;
    this.fileUploadingButton = true;
    this.saveButton = false;
  }
  removeLoaderBg(){
    this.greyBg = false;
    this.fileUploadingButton = false;
    this.saveButton = true;
  }

  manageLoader(type,value) {
    switch (type) {
      case 'logo':
        this.logoLoader = value;
        this.fontLoader = false;
        this.otherLoader =false;
        this.commonLoader();
        break;
      case 'font':
        this.logoLoader = false;
        this.fontLoader = value;
        this.otherLoader =false;
        this.commonLoader();
        break;
      case 'other':
        this.logoLoader = false;
        this.fontLoader = false;
        this.otherLoader =value;
        this.commonLoader();
        break;      

      default:
        break;
    }
  }

  showPlatforms(value, index, fileIndex) {
    this.selectedValues = value;
    this.selectedFileIndex = fileIndex;
    this.platformName = value.title;
        
    if (value.comingSoon) {
        this.toasterService.success('Coming Soon');
        this.previousIndex = null;
        this.clickedPlatformIndex = -1;
        this.openDropDownBoolean = false;
    } else {
      if(this.selectedFileIndex != this.previousFileIndex) {
        this.openDropDownBoolean = true;
        
      } else {
        if (this.clickedPlatformIndex == index) {
          this.openDropDownBoolean = !this.openDropDownBoolean;
        } else if(this.clickedPlatformIndex !== index){
          this.openDropDownBoolean = true;
        } else {
          this.openDropDownBoolean = false;
        }
      }
      this.getSelectedValues(fileIndex);
      this.clickedPlatformIndex = index;

      if (this.selectedFileIndex != this.previousFileIndex || this.clickedPlatformIndex != this.previousIndex) {
        this.arrowUpFlag = true;
      } else {
        this.arrowUpFlag = !this.arrowUpFlag;
      }
      this.previousFileIndex = fileIndex;
      this.previousIndex = index;
    }
  }

  closePlatformBlock() {
    this.openDropDownBoolean = false;
    this.selectedFileIndex = -1;
    this.clickedPlatformIndex = -1;
    this.previousFileIndex = -1;
    this.previousIndex = -1;
  }

  getSelectedValues(fileIndex) {
    this.selectedValues.values.forEach((val, i) => {
      if (this.platformIds[fileIndex].length > 0) {
        const index = this.platformIds[fileIndex].findIndex(itemId => itemId === val.id);
        this.selectedValues.values[i].selected = (index > -1) ? true : false;
        //if(val.is_active === false)
        const pltIndex= this.dataCommService.selectedPlatforms.findIndex(arrObj => arrObj.id === val.id && arrObj.is_active === false);
        if (pltIndex > -1) {
          this.selectedValues.values[i].is_active = true;
        } else {
          const index2 = this.platformObjects[fileIndex].findIndex(arrObj => arrObj.id === val.id && arrObj.tagged === true);
          this.selectedValues.values[i].is_active = (index2 > -1) ? true : false;
        }
      } else {
        this.selectedValues.values[i].selected = false;
        const pltIndex= this.dataCommService.selectedPlatforms.findIndex(arrObj => arrObj.id === val.id && arrObj.is_active === false);
        if (pltIndex > -1) {
          this.selectedValues.values[i].is_active = true;
        } else {
          this.selectedValues.values[i].is_active = false;
        }        
      }
    });
  }

  onPlatformSelect(event, item: any, fileIndex) {
    this.saveButton = true;
    this.disableButton = false;
    if (event.target.checked) {
      this.platformIds[fileIndex].push(item.id);
      this.platformObjects[fileIndex].push(item);
    } else {
      const index = this.platformIds[fileIndex].findIndex(platformId => platformId === item.id);
      if (index > -1) {
        this.platformIds[fileIndex].splice(index, 1);
      }
    }
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {BaseService} from '@core/base.service';

@Component({
  selector: 'app-success-branding',
  templateUrl: './success-branding.component.html',
  styleUrls: ['./success-branding.component.scss']
})
export class SuccessBrandingComponent implements OnInit , OnDestroy {
  $router_subscribtion: Subscription;
  buildCardId: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public baseService: BaseService
  ) {
      this.$router_subscribtion = this.route.parent.params.subscribe((params) => {
        this.buildCardId = <string>params.buildcardId;
     });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.$router_subscribtion.unsubscribe();
  }

  gotoMain() {
    window.open(`${this.buildCardId}/v1/dashboard/mobile/flowchart`, '_self');
  }
}

import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/main/shared/services/data.service';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-review-screen-popup',
  templateUrl: './review-screen-popup.component.html',
  styleUrls: ['./review-screen-popup.component.scss']
})
export class ReviewScreenPopupComponent implements OnInit {

  constructor(
    private dataService: DataService, private router: Router,
    public dataCommService: DataCommService
  ) { }

  ngOnInit() {
  }

  /**
   * @description: Method is used to closed the review screen popup
   * by calling an API and update the flag
   */
  clickPopup(action?) {
    const payload = {
      device: this.dataCommService.platform,
      platform: this.dataCommService.platformType
    };
    this.dataService.updateReviewScreenFlag(payload, this.dataCommService.nowBuildCardId).subscribe((data) => {
      this.dataCommService.showHideReviewScreenPopup = false;
      if (action === 'review') {
        this.goToReviewScreen();
      }
    });
  }

  goToReviewScreen() {
    this.dataCommService.appReloaded = false;
    this.router.navigate([`${this.dataCommService.buildcardId}/v1/dashboard/${this.dataCommService.platform}/${this.dataCommService.platformType}/reviewScreens`]);
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { CookieService } from '@core/cookie.service';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';

@Component({
  selector: 'app-like-dislike',
  templateUrl: './like-dislike.component.html',
  styleUrls: ['./like-dislike.component.scss']
})
export class LikeDislikeComponent implements OnInit {
  @Output() reOpenLikePopup: EventEmitter<any> = new EventEmitter();
  constructor(
    private _dataCommService: DataCommService,
    private _cookieService: CookieService
  ) { }

  ngOnInit(): void {
  }

  closePopup(event) {
    event.stopPropagation();
    this._dataCommService.trackEvent('air_india_feedback_popup_closed',  this._dataCommService.getAirIndiaCampaignEventData());
    this._dataCommService.showLikePopup = false;
    // if (this._dataCommService.isAirIndiaV1ProtoType()) this.reOpenLikePopup.emit(true); //Uncomment this when Voting-popup needed
  }

  hitLike() {
    this._dataCommService.trackEvent('air_india_feedback_yes_clicked',  this._dataCommService.getAirIndiaCampaignEventData());
    this._cookieService.setCookie('BNOW_Liked_Air_India_Prototype', "true");
    window.open('https://www.builder.ai/thank-you-feel-the-love-survey');
    this._dataCommService.showLikePopup = false;
  }

}





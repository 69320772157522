import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommentService } from '../comments/comment.service';
import { Subscription } from 'rxjs';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { DataService } from 'src/app/main/shared/services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import { UtilityService } from '@shared/services/utility.service';
import { AuthService } from '@core/auth.service';
import { ToasterService } from '@core/toaster.service';
import { CookieService } from '@core/cookie.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-review-screen',
  templateUrl: './review-screen.component.html',
  styleUrls: ['./review-screen.component.scss']
})
export class ReviewScreenComponent implements OnInit, OnDestroy {

  genericSubs: Subscription[] = [];
  public commentModeEnabled = false;
  public currentIndex = 0;
  public commentMarksDataRejVer = [];
  public commentMarksDataNewVer = [];
  public maxVersion = 0;
  public featureData;
  public updatedVersion;
  public rejectedVersion;
  public reviewData;
  rejectCommentBtn = false;
  windowWidth: number = window.innerWidth;
  allCommentsData = [];
  selectedCommentData;
  selectedFeature;
  userDetails;
  currentUserName;
  showLoader = false;
  parentFeature;
  allVersions = [];
  varianceMultipleCase=[];
  isActionTaken =false;
  allRejectionComments=[];
  multipleCommentResponse=[];
  openToolTip = true;
  hideButtonsOnload =true;
  public toBeReviewed = [];
  public underReviewFlag = false;
  isLeftSection: boolean = true;
  actionTakerEmail: string;

  constructor(
    public commentService: CommentService, public dataCommService: DataCommService,
    private dataService: DataService, private router: Router, private route: ActivatedRoute,
    private utilityService: UtilityService, private authService: AuthService,
    public toaster: ToasterService,
    private cookieService: CookieService
    ) {
    this.genericSubs.push(this.commentService.commentModeToggle$.subscribe(val => {
      this.commentModeEnabled = val;
      if (this.commentModeEnabled) {
          if (this.selectedFeature?.story_type === 'sub_task') {
              this.commentService.getAllSubFeatureCommentMarks(this.parentFeature.studio_feature_id);
          } else {
          this.commentService.getAllCommentMarks();
        }
        this.commentService.getCommentsData(this.selectedFeature, false, 1, 100, true);
      }
    }));
    this.genericSubs.push(this.commentService.commentMarks$.subscribe(markArr => {
      if (markArr) {
        if (markArr.comments.length > 0) {
          if(this.rejectedVersion) {
            this.commentMarksDataRejVer = this.segregateVersionComments(markArr.comments, this.rejectedVersion);
          }
          this.commentMarksDataNewVer = this.segregateVersionComments(markArr.comments, this.updatedVersion);
          if (this.showLoader && this.commentMarksDataRejVer.length > 0) {
            this.showComment(null, this.commentMarksDataRejVer[this.commentMarksDataRejVer.length - 1]);
          } else {
            this.showLoader = false;
          }
        } else {
          this.showLoader = false;
        }
      } else {
        this.commentMarksDataRejVer = [];
        this.commentMarksDataNewVer = [];
      }
    }));
    this.genericSubs.push(this.commentService.singleCommentData$.subscribe((val: any) => {
      this.removeInterimComment();
      this.selectedCommentData = val;
      if (this.selectedCommentData) {
        if (this.isLeftSection) {
          this.selectedCommentData.position.originX = this.selectedCommentData.position.originX * dataCommService.leftReviewImageScale - this.dataCommService.reviewScreenLeftScrollPosition.scrollLeft;
          this.selectedCommentData.position.originY = this.selectedCommentData.position.originY * dataCommService.leftReviewImageScale - this.dataCommService.reviewScreenLeftScrollPosition.scrollTop;
        }
        else {
          this.selectedCommentData.position.originX = this.selectedCommentData.position.originX * dataCommService.rightReviewImageScale - this.dataCommService.reviewScreenRightScrollPosition.scrollLeft;
          this.selectedCommentData.position.originY = this.selectedCommentData.position.originY * dataCommService.rightReviewImageScale - this.dataCommService.reviewScreenRightScrollPosition.scrollTop;
        }
      }
      if (this.showLoader) {
        this.showLoader = false;
      }
    }));
    this.userDetails = this.authService.getLoggedInUser();
    if (this.userDetails) {
      this.currentUserName = this.userDetails.first_name + (this.userDetails.last_name ? ' ' + this.userDetails.last_name : '');
    }
    this.reviewData = utilityService.cloneObject(this.dataCommService.getUpdatedFeatuersData);
    this.actionTakerEmail = this.cookieService.getCookieEmail(environment.USER_LOCAL_STORAGE);
  }

  ngOnInit() {
      this.route.params.subscribe((params) => {
          if (params.device) {
              this.dataCommService.platform = params.device;
          }
          if (params.platform) {
              this.dataCommService.platformType = params.platform;
          }

    if (this.dataCommService.appReloaded) {
      this.goToFlowchart();
    } else {
      this.showLoader = true;
      this.getVersionData();
      this.commentService.commentModeToggleSubject.next(true);
      this.initialApprovedAllStatus();
    }
      });
  }

  getVersionData() {
    if (this.reviewData.selectedFeatureId) {
      this.currentIndex = this.reviewData.updatedScreensArr
        .findIndex(x => x === this.reviewData.selectedFeatureId);
    } else {
      this.reviewData.selectedFeatureId = this.reviewData.updatedScreensArr[0];
    }
    this.setFeatureData();
    this.commentService.commentModeToggleSubject.next(false);
    this.dataService.getFeatureVersions(this.selectedFeature?.id, this.dataCommService.platform, this.dataCommService.platformType)
     .subscribe((res: any) => {
      this.featureData = res;
      this.fetchMaxVersion(res);
      this.isActionTaken = false;
      this.hideButtonsOnload = true;
      //change 1//

      //single key change //
       let updated =[];
       let rejected =[];
      if(res.build_card_hero_images[1].length == 0){
         updated = res.build_card_hero_images[0];
         rejected = [];
        this.allVersions = res.build_card_hero_images[0];

      }
      else {
         rejected = res.build_card_hero_images[0];
         updated = res.build_card_hero_images[1];
        this.allVersions = res.build_card_hero_images[1];
      }
      for (let i = 0; i < this.allVersions.length; i++) {
        if (i == 0) {
          this.allVersions[i].selected = true;
        } else {
          this.allVersions[i].selected = false;
        }
      }
      
        if (updated && updated.length > 0) { 
          this.updatedVersion = updated.find(ftr => ftr.version === this.maxVersion);
        }
        if(rejected && rejected.length > 0) { 
          this.rejectedVersion = rejected.find(ftr => ftr.version === this.maxVersion - 1);
        }

      this.showLoader = false;
      this.commentService.commentModeToggleSubject.next(true);

      if (this.allVersions && this.allVersions.length > 0) {
        this.allVersions.forEach(element => {
          if(element.status == 'approved'){
            this.isActionTaken = true;
          }
        });
      }      
    });
  }

  toggleCommentMode() {
    this.commentService.commentModeToggleSubject.next(!this.commentModeEnabled);
  }

  getCommentPosition(positionObj) {
    return {
      top : parseInt(positionObj.originY, 10) + 'px',
      left : parseInt(positionObj.originX, 10) + 'px'
    };
  }

  segregateVersionComments(allComments, ftrVersion) {
    return allComments.filter(comment => comment.hero_image_feature_id === ftrVersion.build_card_hero_image_id)
            .sort((a, b) => a.number - b.number);
  }

  goToFlowchart() {
    this.reInitializeScrollPosition();
    this.dataCommService.getUpdatedFeatuersData.selectedFeatureId = null;
    this.dataCommService.reviewScreenOpen = false;
    this.dataCommService.featureList = [];
    this.commentService.commentModeToggleSubject.next(false);
    this.router.navigate([`${this.dataCommService.buildcardId}/v1/dashboard/${this.dataCommService.platform}/${this.dataCommService.platformType}/flowchart`]);
  }

  fetchMaxVersion(featureData) {
    //change 2//
    if (featureData.build_card_hero_images) {
      featureData.build_card_hero_images.forEach(ftrVersion => {
        if (ftrVersion && ftrVersion.length > 0) {
          ftrVersion.forEach(ftrVersion1 => {
            if (ftrVersion1.version > this.maxVersion) {
              this.maxVersion = ftrVersion1.version;
            }
          });
        }          
      });
    }
  }

  changeFrameData(versionNumber) {
    //change 3//
    let rejected = this.featureData.build_card_hero_images[0];
    this.commentService.commentModeToggleSubject.next(null);
    this.rejectedVersion = rejected.find(x => x.version === versionNumber);
  }


  checkForRejection(){
    const rejected = this.varianceMultipleCase.filter(e=> e.status == "rejected");

    if(rejected.length > 0) {
      this.commentsApiCall();
    }
    else {
      this.manageAllVarientApiCall();
    }

  }

  multipleRejectCase(rejectedData){
    rejectedData.forEach(res => {
      this.dataCommService.rejectionCommentDetails.featureData = res;
      const commentResponse :any = this.multipleCommentResponse.find(e=> e.hero_image_feature_id == res.build_card_hero_image_id);
      this.rejectCommentBtn = false;
      this.dataCommService.rejectionCommentDetails.featureData.status = 'rejected';
      this.dataCommService.rejectionCommentDetails.show = false;
      const comment = {
          id: commentResponse.id,
          body: commentResponse.note,
          position: {
            originX: commentResponse.position.originX.toString(),
            originY: commentResponse.position.originY.toString()
          },
          created_at: commentResponse.created_at,
          updated_at: commentResponse.created_at,
          commented_by: commentResponse.user_name,
          commented_on: this.featureData.title,
          target_feature_id: commentResponse.hero_image_feature_id,
          stamp_mark: commentResponse.number,
          is_owner: true,
          replies: commentResponse.replies,
          version: commentResponse.version
        };
      // this.commentService.commentsData.comment = this.utilityService.cloneObject(comment);
      // this.commentService.commentsData.commentAction = 'added';
      // this.commentService.allCommentsDataSubject.next(this.commentService.commentsData);
      this.commentService.singleCommentDataSubject.next(comment);

      if(res.is_primary){
        this.dataCommService.underClientReview = res.under_client_review;
        this.dataCommService.designsApproved = res.prototype_approved;
        this.checkOverallApprovalStatus('reject');

        if (this.selectedFeature?.story_type === 'sub_task') {

          this.dataCommService.featureList.find(ftr =>
            ftr.id === this.parentFeature.id).sub_features.find(subFtr =>
              subFtr.id === this.selectedFeature.id).status = 'rejected';
          this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.splice(
            this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.findIndex(x =>
              x === this.reviewData.selectedFeatureId), 1
          );
        } else {
          this.dataCommService.featureList.find(x => x.id === this.selectedFeature.id).status = 'rejected';
          this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.splice(
            this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.findIndex(x => x === this.selectedFeature.id), 1
          );
        }
      }  
      this.addNewMark({
        commentMark: {
            id: comment.id, body: comment.body, position: comment.position,
            stamp_mark: comment.stamp_mark, build_card_feature_id: this.featureData.id
        },
        targetFeatureId: comment.target_feature_id}, this.updatedVersion);
    });
  }


  manageAllVarientApiCall(){
    this.dataService.approveRejectVersion(this.dataCommService.nowBuildCardId, this.varianceMultipleCase, this.actionTakerEmail)
    .subscribe((response: any) => {
      let rejectedData =[];
      let acceptedData =[];

      this.varianceMultipleCase=[];
      this.isActionTaken =true;
      this.hideButtonsOnload =true;
     response.forEach(element => {
       if(element.status == 'approved'){
        acceptedData.push(element);
       }
       else if(element.status == 'rejected'){
        rejectedData.push(element);
        }
     });

     if(rejectedData.length > 0) {
       this.multipleRejectCase(rejectedData);
     }

     if(acceptedData.length > 0) {
        this.multipleApproveCase(acceptedData);
    }

    }, (err) => {
      this.varianceMultipleCase=[];
      //   console.log(err);
    });
  }


  multipleApproveCase(res){
    res.forEach(element => {
      this.dataCommService.underClientReview = element.under_client_review;
    // if (this.dataCommService.isMobileReviewed && this.dataCommService.isWebReviewed) {
    //     this.dataCommService.designsApproved = true;
    // }
    this.dataCommService.designsApproved = element.prototype_approved;
    this.dataCommService.rejectionCommentDetails.show = false;
    this.checkOverallApprovalStatus('approve');
    // feature.status = 'approved';
    if(element.is_primary == true) {
    if (this.selectedFeature?.story_type === 'sub_task') {
      // let featureIdForDetail = this.dataCommService.featureIdForDetailPage;
      // let selectedFeatureId;
      // if(this.dataCommService.featureIdForDetailPage.indexOf('>') >= 0) {
      //   featureIdForDetail = +this.dataCommService.featureIdForDetailPage.split('>')[0];
      //   selectedFeatureId =  +this.dataCommService.featureIdForDetailPage.split('>')[1];
      // }
      this.dataCommService.featureList.find(ftr =>
        ftr.id === +this.parentFeature.id).sub_features.find(subFtr =>
          subFtr.id === this.selectedFeature.id).status = 'approved';
      this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.splice(
        this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.findIndex(x =>
          x === this.reviewData.selectedFeatureId), 1
      );
    } else {
      this.dataCommService.featureList.find(x => x.id === this.selectedFeature.id).status = 'approved';
      this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.splice(
        this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.findIndex(x => x === this.reviewData.selectedFeatureId), 1
      );
    }
  }
    });
  }





  clearStatus(){

    if(this.varianceMultipleCase.length > 0){
      const previousApproved = this.varianceMultipleCase.find(e=> e.status == 'approved');
      this.allVersions.forEach(e => {
        if(e.build_card_hero_image_id == previousApproved.build_card_hero_image_id ){
          e.status ='review';
        }
      });
      this.varianceMultipleCase = this.varianceMultipleCase.filter(e=> e.status != "approved");
    }
  }



  manageMultipleVarianceAcceptanceAndRejection(feature,status){
    feature.status =status;
    this.clearStatus();
    let object ={
      build_card_hero_image_id: feature.hero_image_id,
      status: status
    }
    this.varianceMultipleCase.push(object);
  }

  screenAcceptHandler($event, feature) {
     if(this.allVersions.length > 1){
       this.hideButtonsOnload = false;
        this.manageMultipleVarianceAcceptanceAndRejection(feature,'approved');
    }
    else {
    //change 4//
    $event.stopImmediatePropagation();
    const payload = [{
        build_card_hero_image_id: feature.hero_image_id,
        status: 'approved'
    }];
    this.dataService.approveRejectVersion(this.dataCommService.nowBuildCardId, payload, this.actionTakerEmail)
      .subscribe((response: any) => {
        if (this.dataCommService.featureList.length> 0 && response && response.length > 0) {
          //start loop//
          response.forEach(res => {
            this.dataCommService.underClientReview = res.under_client_review;
            // if (this.dataCommService.isMobileReviewed && this.dataCommService.isWebReviewed) {
            //     this.dataCommService.designsApproved = true;
            // }
            this.dataCommService.designsApproved = res.prototype_approved;
            this.dataCommService.rejectionCommentDetails.show = false;
            this.checkOverallApprovalStatus('approve');
            feature.status = 'approved';
            if (this.selectedFeature?.story_type === 'sub_task') {
              // let featureIdForDetail = this.dataCommService.featureIdForDetailPage;
              // let selectedFeatureId;
              // if(this.dataCommService.featureIdForDetailPage.indexOf('>') >= 0) {
              //   featureIdForDetail = +this.dataCommService.featureIdForDetailPage.split('>')[0];
              //   selectedFeatureId =  +this.dataCommService.featureIdForDetailPage.split('>')[1];
              // }
              this.dataCommService.featureList.find(ftr =>
                ftr.id === +this.parentFeature.id).sub_features.find(subFtr =>
                  subFtr.id === this.selectedFeature.id).status = 'approved';
              this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.splice(
                this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.findIndex(x =>
                  x === this.reviewData.selectedFeatureId), 1
              );
            } else {
              this.dataCommService.featureList.find(x => x.id === this.selectedFeature.id).status = 'approved';
              this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.splice(
                this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.findIndex(x => x === this.reviewData.selectedFeatureId), 1
              );
            }
            //end loop//
          });
        }
      }, (err) => {
        //   console.log(err);
      });
    }
  }

  checkOverallApprovalStatus(status) {
    let featureCount = 0;
    this.dataCommService.featureList.forEach((ftr) => {
      if (ftr.studio_feature_id !== null) {
        featureCount++;
        if (ftr.sub_features.length > 0) {
          featureCount += ftr.sub_features.length;
        }
      }      
    });
    let approvedMode = '';
    let approvedVersion = '';
    let pendingVersion = '';
    let tempVersion = '';
    /*if (this.dataCommService.isAndroidReviewed) {
        approvedVersion = 'android';
        if (this.dataCommService.isWebReviewed) {
            pendingVersion = '';
            this.dataCommService.overallApprovalPopupData.pendingMode = '';
        } else {
            pendingVersion = 'desktop';
            this.dataCommService.overallApprovalPopupData.pendingMode = 'web';
        }
    } else {
        approvedVersion = 'desktop';
        if (this.dataCommService.isAndroidReviewed) {
            pendingVersion = '';
            this.dataCommService.overallApprovalPopupData.pendingMode = '';
        } else {
            pendingVersion = 'mobile';
            this.dataCommService.overallApprovalPopupData.pendingMode = 'mobile';
        }
    }*/

    if (status === 'approve') {
      this.dataCommService.approvedFeatureCount++;
    } else if (status === 'reject') {
        this.dataCommService.rejectedFeatureCount++;
    }
    
    if (this.dataCommService.approvedFeatureCount === featureCount || (this.dataCommService.rejectedFeatureCount > 0
      && this.dataCommService.approvedFeatureCount + this.dataCommService.rejectedFeatureCount === featureCount)) {
      this.underReviewFlag = false;
      if (this.dataCommService.underClientReview.android) {
        this.toBeReviewed.push('android');
      }
      if (this.dataCommService.underClientReview.web) {
        this.toBeReviewed.push('web');
      }
      if (this.dataCommService.underClientReview.ios) {
        this.toBeReviewed.push('ios');
      }     
      if (this.dataCommService.underClientReview.windows) {
        this.toBeReviewed.push('windows');
      }
      if (this.dataCommService.underClientReview.macos) {
        this.toBeReviewed.push('macos');
      }
      if (this.dataCommService.underClientReview.watchos) {
        this.toBeReviewed.push('watchos');
      }
      if (this.dataCommService.underClientReview.mobile_site) {
        this.toBeReviewed.push('mobile_site');
      }

      if (this.toBeReviewed.length > 0) {
        const aURL = this.router.url.split("/");
        let platformURL = this.router.url.replace(aURL[aURL.length - 3], "firstParam");
        platformURL = platformURL.replace(aURL[aURL.length - 2], "secondParam");
        platformURL = platformURL.replace(aURL[aURL.length - 1], "flowchart");
        approvedVersion = aURL[aURL.length - 2];
        for (let i = 0; i < this.toBeReviewed.length; i++) {
          this.underReviewFlag = true;
          if (this.toBeReviewed[i]) {
            pendingVersion = this.toBeReviewed[i];
            this.dataCommService.overallApprovalPopupData.pendingMode = (pendingVersion === 'android' || pendingVersion === 'ios') ? 'mobile' : 'desktop';
            approvedMode = (pendingVersion === 'android' || pendingVersion === 'ios') ? 'mobile' : 'web';
          }
          
          switch(this.toBeReviewed[i]) {
            case 'android':
              tempVersion = 'android';
              break;
            case 'web':
              tempVersion = 'web';
              break;
            case 'ios':
              tempVersion = 'ios';
              break;
            case 'windows':
              tempVersion = 'windows';
              break;
            case 'macos':
              tempVersion = 'macos';
              break;
            case 'watchos':
              tempVersion = 'watchos';
              break;
            case 'mobile_site':
              tempVersion = 'mobile-site';
              break;
          }
          let tempURL = platformURL.replace("firstParam", approvedMode);
          this.dataCommService.platformURL = tempURL.replace("secondParam", tempVersion);
          break;
        }
      }
      
      if(this.underReviewFlag === false) {
        pendingVersion = '';
        this.dataCommService.overallApprovalPopupData.pendingMode = '';
      }

      if (this.underReviewFlag === true) {
        // const segmentTrackerData = {
        //     'Prototype Type': this.dataCommService.prototypeType,
        //     User: this.dataCommService.isOwner ? 'Owner' : this.authService.userProfile.isDesigner ? 'Designer' : 'Visitor',
        //     Type: approvedVersion
        // };
        // this.overallApprovalPopupData.msg = `You’ve reviewed your ${approvedVersion} prototype. Now review the ${pendingVersion} version.`;
          if (this.dataCommService.approvedFeatureCount === featureCount) {
              this.dataCommService.overallApprovalPopupData.confirmHeaderTxt = 'Congratulations';
              if (pendingVersion !== '') {
                  this.dataCommService.overallApprovalPopupData.msg =
                  `You’ve reviewed your ${approvedVersion} prototype. Now review the ${pendingVersion} version.`;
                  this.dataCommService.overallApprovalPopupData.confirmBtnTxt = `Switch to ${pendingVersion} mode`;
              } else {
                  this.dataCommService.overallApprovalPopupData.msg = `You’ve reviewed your whole prototype.`;
                  this.dataCommService.overallApprovalPopupData.confirmBtnTxt = `Close`;
              }
              // this.overallApprovalPopupData.confirmBtnTxt = `Switch to ${pendingVersion} mode`;
              // this.dataCommService.allScreensApproved = true;
              this.dataCommService.showReviewedAllPopup = true;
          }

          if (this.dataCommService.rejectedFeatureCount > 0
          && this.dataCommService.approvedFeatureCount + this.dataCommService.rejectedFeatureCount === featureCount) {
            this.dataCommService.overallApprovalPopupData.confirmHeaderTxt = 'Thanks for your feedback';
            if (pendingVersion !== '') {
                this.dataCommService.overallApprovalPopupData.msg =
                `You’ve reviewed your ${approvedVersion} prototype. Now review the ${pendingVersion} version.`;
                this.dataCommService.overallApprovalPopupData.confirmBtnTxt = `Now tell us about ${pendingVersion}`;
            } else {
                this.dataCommService.overallApprovalPopupData.msg = `You’ve reviewed your whole prototype.`;
                this.dataCommService.overallApprovalPopupData.confirmBtnTxt = `Close`;
                // segmentTrackerData.Type = 'Both';
            }
            // this.dataCommService.allScreensRejected = true;
            this.dataCommService.showReviewedAllPopup = true;
          }
      } else {
          // const segmentTrackerData = {
          //     'Prototype Type': this.dataCommService.prototypeType,
          //     User: this.dataCommService.isOwner ? 'Owner' : this.authService.userProfile.isDesigner ? 'Designer' : 'Visitor',
          //     Type: 'Both'
          // };
          this.dataCommService.overallApprovalPopupData.confirmHeaderTxt = 'Congratulations';
          this.dataCommService.overallApprovalPopupData.msg = `You’ve reviewed your whole prototype.`;
          this.dataCommService.overallApprovalPopupData.confirmBtnTxt = `Go to Custom Prototype`;
          this.dataCommService.showReviewedAllPopup = true;
      }
    }

    if (this.dataCommService.showReviewedAllPopup) {
      this.goToFlowchart();
    }
  }

  initialApprovedAllStatus() {
    this.dataCommService.approvedFeatureCount = 0;
    this.dataCommService.rejectedFeatureCount = 0;
    this.dataCommService.featureList.forEach((feature, index) => {
        if (feature.status === 'approved') {
            this.dataCommService.approvedFeatureCount++;
        } else if (feature.status === 'rejected') {
            this.dataCommService.rejectedFeatureCount++;
        }
        if (feature.sub_features.length > 0) {
          feature.sub_features.forEach((subFtr) => {
            if (subFtr.status === 'approved') {
              this.dataCommService.approvedFeatureCount++;
            } else if (subFtr.status === 'rejected') {
                this.dataCommService.rejectedFeatureCount++;
            }
          });
        }
    });
  }

  screenRejectHandler(commentResponse) {
     //change 5//
    const payload = [{
        build_card_hero_image_id: this.dataCommService.rejectionCommentDetails.featureData.hero_image_id,
        status: 'rejected'
    }];
    this.dataService.approveRejectVersion(this.dataCommService.nowBuildCardId, payload, this.actionTakerEmail)
      .subscribe((response: any) => {
        //loop start//
        response.forEach(res => {
        this.rejectCommentBtn = false;
        this.dataCommService.underClientReview = res.under_client_review;
        this.dataCommService.designsApproved = res.prototype_approved;
        this.checkOverallApprovalStatus('reject');
        this.dataCommService.rejectionCommentDetails.featureData.status = 'rejected';
        this.dataCommService.rejectionCommentDetails.show = false;
        const comment = {
            id: commentResponse.id,
            body: commentResponse.note,
            position: {
              originX: commentResponse.position.originX.toString(),
              originY: commentResponse.position.originY.toString()
            },
            created_at: commentResponse.created_at,
            updated_at: commentResponse.created_at,
            commented_by: commentResponse.user_name,
            commented_on: this.dataCommService.rejectionCommentDetails.featureData.title,
            target_feature_id: commentResponse.hero_image_feature_id,
            stamp_mark: commentResponse.number,
            is_owner: true,
            replies: commentResponse.replies,
            version: commentResponse.version
          };
        // this.commentService.commentsData.comment = this.utilityService.cloneObject(comment);
        // this.commentService.commentsData.commentAction = 'added';
        // this.commentService.allCommentsDataSubject.next(this.commentService.commentsData);
        this.commentService.singleCommentDataSubject.next(comment);
        if (this.selectedFeature?.story_type === 'sub_task') {
          // let featureIdForDetail;
          // let selectedFeatureId;
          // if (this.dataCommService.getUpdatedFeatuersData.selectedFeatureId.indexOf('>') >= 0) {
          //   featureIdForDetail = +this.dataCommService.getUpdatedFeatuersData.selectedFeatureId.split('>')[0];
          //   selectedFeatureId =  +this.dataCommService.getUpdatedFeatuersData.selectedFeatureId.split('>')[1];
          // }
          this.dataCommService.featureList.find(ftr =>
            ftr.id === this.parentFeature.id).sub_features.find(subFtr =>
              subFtr.id === this.selectedFeature.id).status = 'rejected';
          this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.splice(
            this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.findIndex(x =>
              x === this.reviewData.selectedFeatureId), 1
          );
        } else {
          this.dataCommService.featureList.find(x => x.id === this.selectedFeature.id).status = 'rejected';
          this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.splice(
            this.dataCommService.getUpdatedFeatuersData.updatedScreensArr.findIndex(x => x === this.selectedFeature.id), 1
          );
        }
        this.addNewMark({
        commentMark: {
            id: comment.id, body: comment.body, position: comment.position,
            stamp_mark: comment.stamp_mark, build_card_feature_id: this.dataCommService.rejectionCommentDetails.featureData.id
        },
        targetFeatureId: comment.target_feature_id}, this.updatedVersion);
      });
      //loop end//
      }, (err) => {
        //   console.log(err);
    });
  }

  rejectionReason(reason) {
  }

  postRejectionComment(rejectionComment: string) {
    if (this.rejectCommentBtn) {
        return false;
    }
    this.rejectCommentBtn = true;
    // const userData = this.authService.getLoggedInUser();
    const payload = {
        builder_feature_id: this.selectedFeature.studio_feature_id,
        note: `Rejection reason: ${rejectionComment}`,
        version: this.dataCommService.rejectionCommentDetails.featureData?.version,
        user_name: this.userDetails?.first_name + (this.userDetails?.last_name ? ' ' + this.userDetails?.last_name : ''),
        user_type: 'User',
        user_email: this.userDetails?.email,
        hero_image_feature_id: this.dataCommService.rejectionCommentDetails.featureData?.hero_image_id,
        set_as_deigner_task: true,
        position: {originX: 160, originY: 300},
    };
    if(this.selectedFeature.story_id) {
      payload['story_id'] = +this.selectedFeature.story_id;
      payload['builder_feature_id'] = this.dataCommService.studioFeatureIdForSubFeatureComment;
    }

    this.isLeftSection = false;

    if(this.allVersions.length > 1){
      this.hideButtonsOnload = false;
      this.rejectCommentBtn = false;
      this.dataCommService.rejectionCommentDetails.featureData.status = 'rejected';
      this.dataCommService.rejectionCommentDetails.show = false;
       this.dataCommService.rejectionCommentDetails.featureData.status = 'rejected';

       let object ={
        build_card_hero_image_id: this.dataCommService.rejectionCommentDetails.featureData.hero_image_id,
        status: 'rejected'
      }
      this.allRejectionComments.push(payload);
      this.varianceMultipleCase.push(object);

    }
    else {
      payload['platform_name'] =  this.dataCommService.platformType;
      if (this.cookieService.getLocalStorage('roleId') && this.cookieService.getLocalStorage('roleId') !== 'undefined') payload['studio_role_id'] = this.dataCommService.userRoles.filter(e => e.id === +this.cookieService.getLocalStorage('roleId'))[0].studio_id;
      this.dataService.postRejectionComment(this.dataCommService.trackerProjectId, payload).subscribe((res: any) => {
        this.dataCommService.rejectionCommentDetails.featureData.comment = rejectionComment;
        this.screenRejectHandler(res);
      },err=>{
        if(err && err.error && err.error.errors){
          let msg = this.dataCommService.genericMethodForNoStory(err.error.errors);
          if(msg){
           this.hideCommentBox();
           this.toaster.error(msg);
          }
        }
      });
    }

  }


  commentsApiCall(){
    this.dataCommService.manageCommentsMultiple =true;
    this.multipleCommentRejection(this.allRejectionComments);
  }


  multipleCommentRejection(payloads){
      this.dataService.postRejectionCommentMultiple(this.dataCommService.trackerProjectId,payloads).subscribe(res=>{
        this.multipleCommentResponse = res;
        this.authService.isTrackerApi = false;
        this.dataCommService.manageCommentsMultiple =false;
        this.manageAllVarientApiCall();
      },
      err=>{
        this.authService.isTrackerApi = false;
        this.dataCommService.manageCommentsMultiple =false;
      })
  }


  openRejectFeaturePopup($event: Event, feature) {

    this.dataCommService.rejectionCommentDetails.featureData = feature;
    this.dataCommService.rejectionCommentDetails.show = true;
    this.dataCommService.rejectionCommentDetails.position = this.dataCommService.getRejCommBoxPosition($event);
    this.dataCommService.rejectionCommentDetails.featureId = feature.hero_image_id;
    this.dataCommService.rejectionCommentDetails.comment = '';
  }

  addNewMark($event, ftrVersion) {
    if (this.isLeftSection) {
      $event.commentMark.position = {
        originX: ($event.commentMark.position.originX + this.dataCommService.reviewScreenLeftScrollPosition.scrollLeft) / this.dataCommService.leftReviewImageScale,
        originY: ($event.commentMark.position.originY + this.dataCommService.reviewScreenLeftScrollPosition.scrollTop) / this.dataCommService.leftReviewImageScale,
      }
    }
    else {
      $event.commentMark.position = {
        originX: ($event.commentMark.position.originX + this.dataCommService.reviewScreenRightScrollPosition.scrollLeft) / this.dataCommService.rightReviewImageScale,
        originY: ($event.commentMark.position.originY + this.dataCommService.reviewScreenRightScrollPosition.scrollTop) / this.dataCommService.rightReviewImageScale,
      }
    }

    $event.commentMark.number = $event.commentMark.stamp_mark;
    if (ftrVersion === this.rejectedVersion) {
      this.commentMarksDataRejVer.push($event.commentMark);
    } else {
      this.commentMarksDataNewVer.push($event.commentMark);
    }
  }

  removeCommentMark($event, ftrVersion) {
    const chore = (marksArr) => {
      const commInd = marksArr.findIndex(x => x.id === $event.commentId);
      if (commInd !== -1) {
          marksArr.splice(commInd, 1);
      }
    };
    if (ftrVersion === this.rejectedVersion) {
      chore(this.commentMarksDataRejVer);
    } else {
      chore(this.commentMarksDataNewVer);
    }
  }

  calculatePosition(positionObj) {
    return {
        top : positionObj.top + 'px',
        left : positionObj.left + 'px'
    };
  }

  setCommentWrapperScale(frameWidth) {
    const styleObj: any = {};
    const viewWidth = this.dataCommService.platform === 'web' ? 1440 : 375;
    // const webViewScreen = frameWidth - ((40 * frameWidth) / 100 );
    if (this.windowWidth > 480) {
        styleObj.transform = `translate(-50%,-50%) scale( ${frameWidth / viewWidth} )`;
        styleObj.top = `50%`;
        styleObj.left = `50%`;
    } else {
        styleObj.transform = `translate(-50%,-50%) scale( ${this.windowWidth / viewWidth} )`;
        styleObj.top = `50%`;
        styleObj.left = `50%`;
    }
    return styleObj;
  }

  showComment($event, comment, isLeftSection?) {
    this.isLeftSection = isLeftSection;
    if ($event) {
      $event.stopPropagation();
    }
    if (comment) {
        this.removeInterimComment();
        this.commentService.showComment($event, comment);
    }
  }

  putComment($event, ftrVersion, isLeftSection) {
    this.isLeftSection = isLeftSection;
    $event.stopPropagation();
    this.removeInterimComment();
    this.commentService.putNewComment($event);
    if (this.rejectedVersion && (ftrVersion === this.rejectedVersion)) {
      this.commentMarksDataRejVer.push({
          id: null, position: {originX: $event.offsetX, originY: $event.offsetY}
      });
    } else {
      this.commentMarksDataNewVer.push({
        id: null, position: {originX: $event.offsetX, originY: $event.offsetY}
    });
    }
  }

  removeInterimComment() {
    const chore = (marksArr) => {
      if (marksArr.length > 0) {
        const commIndex = marksArr.findIndex(x => x.id === null);
        if ( commIndex !== -1) {
            marksArr.splice(commIndex, 1);
        }
      }
    };
    chore(this.commentMarksDataRejVer);
    chore(this.commentMarksDataNewVer);
  }

  cancelComment(ftrVersion) {
    this.commentService.singleCommentDataSubject.next(null);
    const chore = (marksArr) => {
      const commIndex = marksArr.findIndex(x => x.id === null);
      if (commIndex !== -1) {
          marksArr.splice(commIndex, 1);
      }
    };
    if (ftrVersion === this.rejectedVersion) {
      chore(this.commentMarksDataRejVer);
    } else {
      chore(this.commentMarksDataNewVer);
    }
  }

  hideCommentBox() {
    this.selectedCommentData = null;
    this.dataCommService.rejectionCommentDetails.show = false;
    this.dataCommService.rejectionCommentDetails.featureId = '';
    this.dataCommService.rejectionCommentDetails.comment = null;
  }

  ngOnDestroy() {
    this.utilityService.unsubscribeOnDestroy(this.genericSubs);
  }

  changeFeature(direction) {
    const updatedFtrsArr = this.reviewData.updatedScreensArr;
    this.reInitializeScrollPosition();
    this.hideCommentBox();
    const chore = () => {
      this.varianceMultipleCase=[];
      this.allRejectionComments=[];
      this.commentService.commentModeToggleSubject.next(false);
      this.reviewData.selectedFeatureId = updatedFtrsArr[this.currentIndex];
      this.showLoader = true;
      this.getVersionData();
      this.rejectCommentBtn = false;
      // this.commentService.commentModeToggleSubject.next(true);
    };
    if (direction === 'next') {
      if (this.currentIndex < updatedFtrsArr.length - 1) {
        this.currentIndex++;
        chore();
      }
    } else {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        chore();
      }
    }
  }

  setFeatureData() {
    if (typeof(this.reviewData.selectedFeatureId) === 'string' && this.reviewData.selectedFeatureId.includes('>')) {
      this.parentFeature = this.dataCommService.featureList.find(x => x.id === +this.reviewData.selectedFeatureId.split('>')[0]);
      this.selectedFeature = this.dataCommService.featureList.find(x => x.id === +this.reviewData.selectedFeatureId.split('>')[0]).
        sub_features.find((sftr) => sftr.id === +this.reviewData.selectedFeatureId.split('>')[1]);
    } else {
      this.selectedFeature = this.dataCommService.featureList.find(x => x.id === this.reviewData.selectedFeatureId);
      this.parentFeature = this.selectedFeature;
    }
  }

  changeVarient(index) {
    this.commentService.commentModeToggleSubject.next(false);
    this.allVersions.forEach(e => {
      e.selected = false;
    });
    this.allVersions[index].selected = true;
    // this.allVersions[index].status = 'approved';
    this.updatedVersion = this.allVersions[index];
    this.commentService.commentModeToggleSubject.next(true);
  }
  closeToolTip(){
    this.openToolTip = false;
  }

  clearLocalState(){
   for (let i = 0; i < this.varianceMultipleCase.length; i++) {
     for (let j = 0; j < this.allVersions.length; j++) {
        if(this.varianceMultipleCase[i].build_card_hero_image_id == this.allVersions[j].build_card_hero_image_id){
          this.allVersions[j].status ='review';
        }
     }
   }
      this.hideButtonsOnload = true;
      this.varianceMultipleCase=[];
      this.allRejectionComments=[];
  }
 
  moveRight() {
      const optionSlides = document.querySelector('.optionSlides');
      optionSlides.scrollLeft += 70;
      if (optionSlides.scrollLeft >= this.findMaxScroll()) {
        document.getElementById('rightArrow').style.display = 'none';
      }
  }
  moveleft() {
      document.getElementById('rightArrow').style.display = 'flex';
      const optionSlides = document.querySelector('.optionSlides');
      optionSlides.scrollLeft -= 70;
  }
  findScrollPosition() {
        const styleObj: any = {};
        const optionSlides = document.querySelector('.optionSlides');
        if (optionSlides.scrollLeft === 0) {
            styleObj.display = 'none';
        }
        return styleObj;
  }
  findMaxScroll() {
      const optionSlides = document.querySelector('.optionSlides');
      const maxscroll = optionSlides.scrollWidth - optionSlides.clientWidth;
      return maxscroll;
  }
  findRightScrollDisplay() {
      const styleObj: any = {};
      const optionSlides = document.querySelector('.optionSlides');
      if (optionSlides.scrollLeft >= this.findMaxScroll()) {
          styleObj.display = 'none';
      }
      return styleObj;
   }

  onLeftSectionScrollEvent(event) {
    this.dataCommService.reviewScreenLeftScrollPosition = {
      scrollLeft: event.srcElement.scrollLeft,
      scrollTop: event.srcElement.scrollTop
    }
  }

  onRightSectionScrollEvent(event) {
    this.dataCommService.reviewScreenRightScrollPosition = {
      scrollLeft: event.srcElement.scrollLeft,
      scrollTop: event.srcElement.scrollTop
    }
  }

  reInitializeScrollPosition(){
    this.dataCommService.reviewScreenLeftScrollPosition = {
      scrollLeft: 0,
      scrollTop: 0
    };
    this.dataCommService.reviewScreenRightScrollPosition = {
      scrollLeft: 0,
      scrollTop: 0
    };
  }
}

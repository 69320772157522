import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Input,
    ComponentFactoryResolver,
    ViewContainerRef,
    Injector,
    OnDestroy,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    DoCheck
  } from '@angular/core';
import { MousePosition, ShapeProperties } from './models/shape';
import { RectangleComponent } from './rectangle-component/rectangle-component';
import { Subscription } from 'rxjs';
import { ManualHotspotService } from './manual-hotspot.service';
import { UtilityService } from '@shared/services/utility.service';
import { ManualShareService } from './manual.shared.service';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { OverlayWithContentService } from '../overlay-with-content/overlay-with-content.service';
import {Router} from '@angular/router';
import { ToasterService } from '@core/toaster.service';

@Component({
  selector: 'app-manual-hotspot',
  templateUrl: './manual-hotspot.component.html',
  styleUrls: ['./manual-hotspot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManualHotspotComponent implements OnInit, OnDestroy, DoCheck {
  @ViewChild('manualref', {static: false}) manualref: ElementRef;
  @Input() manualHotspotSvg: any;
  @Input() isManualHotspotEditable: boolean;
  @Input() addedFooterHeight = 0;
  @Input() hotspots: any;
  @Input() disableManualHotspot;
  @Input() showUserHotspot;
  @Input() listenClick;
  @Input() styleObj;
  @Output() linkScreenClick = new EventEmitter();
  @Output() deleteSpotClick = new EventEmitter();
  private hotspotSize = 48;
  private toolTipPos = {
    x: null,
    y: null
  };
  private menuPos = {
    x: null,
    y: null
  };
  public svg: any;
  public currentPosition: MousePosition = new MousePosition();
  public shapeProperties: ShapeProperties = new ShapeProperties();
  public selectedShape = 'Rectangle';
  public data: Array<object>;
  public selectedComponent: RectangleComponent;
  public count = 0;
  public hotspotData: any = {};
  public hotspotHeight = 0;
  public positionTop = 0;
  public getManualHotstpotDataArray: any = [];
  public deletedManualHotspotIdArray: any = [];
  public startSetInterval: any;
  sourcePage = '';
  addEditCarouselVar: Subscription;
  changeManualVar: Subscription;
  deleteManualHotSpot: Subscription;
  addSideKick: Subscription;
  addIconServiceVar: Subscription;
  addCarouselServiceVar: Subscription;
  clickableItemClicked: boolean;
  @Input() showLinkBtn: boolean;
  @Input() showDeleteBtn: boolean;
  mouseHoveredLink = false;
  mouseHoveredDelete = false;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private viewContainerRef: ViewContainerRef,
              public rectangleService: ManualHotspotService,
              private utilityService: UtilityService,
              private manualShareService: ManualShareService,
              private dataCommService: DataCommService,
              private overlayWithContentService: OverlayWithContentService,
              private router: Router,
              private cd: ChangeDetectorRef,
              public toasterService: ToasterService
            ) {
    this.sourcePage = this.dataCommService.sourcePage();
  }

  ngOnInit(): void {
    // add/delete manual hotspot image on checkbox of sidekick
    this.rectangleService.showContextMenu = false;
    this.changeManualVar = this.manualShareService.changeManualHotspotImage$.subscribe(featureData => {
      if (this.selectedComponent && this.selectedComponent.isSelected) {
        this.rectangleService.isNewHotspotDraw = true;
        this.selectedComponent.isNewHotspotDraw = false;
      }
    });
    this.addEditCarouselVar = this.manualShareService.addDeleteCarouselImageSource$.subscribe((data) => {
      if (data.isAdd) {
        this.rectangleService.getRectangleComponent().shape.imgUrl = data.carouselData.image;
      } else {
        this.rectangleService.getRectangleComponent().shape.imgUrl = null;
        this.rectangleService.getRectangleComponent().clickableItems.splice(data.index, 1);
      }
    });

    this.manualShareService.setSuggetionTooltipPos$.subscribe(() => {
      setTimeout(() => {
        this.rectangleService.showHideToolTip = true;
        const pos: any = document.getElementById(
          `clickable-item-${this.rectangleService.selectedClickableItem.feature_id}`);
        if (pos) {
          this.toolTipPos = {
            x: parseInt(pos.getAttribute('x'), 10) + 52,
            y: parseInt(pos.getAttribute('y'), 10)
          };
        }
      }, 100);
    });

    this.dataCommService.switchHotspotOnDelete.asObservable().subscribe((flag: boolean) => this.selectFirstAvailableHotspot());

    this.renderHotspot();
    this.uncheckSelectedHotspot();
    this.sideKickAddRectangle();
    this.addIconHotspot();
    this.addCarouselHotSpot();
    this.rectangleService.isNewHotspotDraw = true;
    this.rectangleService.showHideToolTip = false;
  }
  
  // ngOnChanges() {
  //   if (this.styleObj.height && this.styleObj.width) {
  //     this.renderHotspot();
  //     this.uncheckSelectedHotspot();
  //     this.sideKickAddRectangle();
  //     this.addIconHotspot();
  //     this.addCarouselHotSpot();
  //     this.rectangleService.isNewHotspotDraw = true;
  //     this.rectangleService.showHideToolTip = false;
  //   }
  // }

  ngDoCheck() {
    this.cd.markForCheck();
  }

  uncheckSelectedHotspot() {
    this.manualShareService.uncheckSelectedHotspot$.subscribe(() => {
      this.deSelectComponents();
    });
  }
  renderHotspot() {
    this.rectangleService.removeAllRectangleComponents();
    this.positionTop = this.manualHotspotSvg.top;
    this.hotspotHeight = this.manualHotspotSvg.height;
    if ((this.hotspots !== null) && (this.hotspots.length > 0)) {
      this.startSetInterval =  setInterval(() => {
        this.viewHotspot();
      }, 0);
    }
  }

  sideKickAddRectangle() {
    this.addSideKick = this.dataCommService.isSidePanelClicked$.subscribe((data: any) => {
      this.rectangleService.removeAllRectangleComponents();
      this.count = 0;
      this.hotspots = data;
      this.startSetInterval =  setInterval(() => {
        this.viewHotspot();
      }, 0);
    });
  }
  /**
   * @description: Set SVG Height from top and
   * footer
   */
  setStyle() {
    const style = {
      top : this.manualHotspotSvg.top,
      // height : `${(this.manualHotspotSvg.height - this.addedFooterHeight)}px`,
      position: 'relative'
    };
    return style;
  }
  /**
   * @description: clear all shapes.
   */
  clearShapes(): void {
    this.rectangleService.removeAllRectangleComponents();
    this.data = [];
  }
  /**
   * @description: Enable view mode to show manual
   * hotspot on the hero image.
   */
  viewHotspot() {
    this.getManualHotstpotDataArray = [];
    this.getManualHotstpotDataArray = JSON.parse(JSON.stringify(this.hotspots));
    this.getManualHotstpotDataArray = this.getManualHotstpotDataArray.map(x => {
      let defaultWidth = this.dataCommService.platform === 'web' ? 1440 : 375,
        defaultHeight = this.dataCommService.platform === 'web' ? 800 : 667,
        updatedDate = new Date(x.updated_at),
        releaseDate = new Date('2021-06-03');
      if (updatedDate < releaseDate && (this.styleObj.height > defaultHeight || this.styleObj.width > defaultWidth)) this.migrationProcess(x, defaultWidth, defaultHeight);
      return x;
    });
    if (this.getManualHotstpotDataArray.length > this.count) {
      this.editMode(this.count);
    } else {
      if (!this.router.url.includes('add-hotspot')) this.selectFirstAvailableHotspot();
      clearInterval(this.startSetInterval);
    }
    this.count++;
  }

  migrationProcess(x, defaultWidth, defaultHeight) {
    let newScaleFactor: number = 1,
      previousScaleFactor: number = 1,
      previousContainerHeight: number = defaultHeight,
      previousContainerWidth: number = defaultWidth,
      updatedPreviousContainerWidth: number = 0,
      updatedPreviousContainerHeight: number = 0,
      updatedPreviousHotspotAttributes: any = {},
      updatedNewHotspotAttributes: any = {},
      deltaX: number = 0,
      deltaY: number = 0,
      hotspotPanelWidth: number = 300,
      previewDetailHeight: number = 58,
      padding: number = 30,
      horizontalScrollBarHeight: number = 7,
      verticalScrollBarWidth: number = 5,
      maxHeight: number = document.getElementsByClassName('preview-frame-wrap')[0].getBoundingClientRect().height - previewDetailHeight - padding - horizontalScrollBarHeight,
      maxWidth: number = document.getElementsByClassName('preview-frame-wrap')[0].getBoundingClientRect().width - hotspotPanelWidth - padding - verticalScrollBarWidth;

    if (9 * this.styleObj.width > 16 * this.styleObj.height) {
      // newScaleFactor = maxHeight / this.styleObj.height;
      previousScaleFactor = previousContainerWidth / this.styleObj.width;
      updatedPreviousContainerHeight = previousContainerHeight * previousScaleFactor;
      deltaY = (previousContainerHeight - updatedPreviousContainerHeight) / 2;
      updatedPreviousHotspotAttributes = {
        originX: x.image_attributes.originX,
        originY: x.image_attributes.originY - deltaY,
        width: x.image_attributes.width,
        height: x.image_attributes.height
      };
      updatedNewHotspotAttributes = {
        originX: (updatedPreviousHotspotAttributes.originX * this.styleObj.width) / previousContainerWidth,
        originY: (updatedPreviousHotspotAttributes.originY * this.styleObj.height) / (previousContainerHeight - 2 * deltaY),
        width: (updatedPreviousHotspotAttributes.width * this.styleObj.width) / previousContainerWidth,
        height: (updatedPreviousHotspotAttributes.height * this.styleObj.height) / (previousContainerHeight - 2 * deltaY),
      };
    }
    else {
      // newScaleFactor = maxWidth / this.styleObj.width;
      previousScaleFactor = previousContainerHeight / this.styleObj.height;
      updatedPreviousContainerWidth = previousContainerWidth * previousScaleFactor;
      deltaX = (previousContainerWidth - updatedPreviousContainerWidth) / 2;
      updatedPreviousHotspotAttributes = {
        originX: x.image_attributes.originX - deltaX,
        originY: x.image_attributes.originY,
        width: x.image_attributes.width,
        height: x.image_attributes.height
      };
      updatedNewHotspotAttributes = {
        originX: (updatedPreviousHotspotAttributes.originX * this.styleObj.width) / (previousContainerWidth - 2 * deltaX),
        originY: (updatedPreviousHotspotAttributes.originY * this.styleObj.height) / previousContainerHeight,
        width: (updatedPreviousHotspotAttributes.width * this.styleObj.width) / (previousContainerWidth - 2 * deltaX),
        height: (updatedPreviousHotspotAttributes.height * this.styleObj.height) / previousContainerHeight,
      };
    }

    x.image_attributes.originX = updatedNewHotspotAttributes.originX > 0 ? updatedNewHotspotAttributes.originX : 100;
    x.image_attributes.originY = updatedNewHotspotAttributes.originY > 0 ? updatedNewHotspotAttributes.originY : 100;
    x.image_attributes.width = updatedNewHotspotAttributes.width;
    x.image_attributes.height = updatedNewHotspotAttributes.height;

  }

  /**
   * @descriptoin Generate all manual hotspot and render on
   * hero image
   * @param key Param is used as a index to get the data from the array
   */
  editMode(key) {
    // create dysnamic object for component
    const injector = Injector.create({providers: [], parent: this.viewContainerRef.injector});
    const factory = this.componentFactoryResolver.resolveComponentFactory(RectangleComponent);
    const component = factory.create(injector);
    const selectedComponent = component.instance as RectangleComponent;
    this.rectangleService.setRectangleComponent(selectedComponent);
    this.rectangleService.isDrawing = false;
    const rectComponent = this.rectangleService.getRectangleComponents();
    rectComponent[key].shape.height = parseInt(this.getManualHotstpotDataArray[key].position.height, 10);
    if (this.getManualHotstpotDataArray[key].category_id === null
      && this.getManualHotstpotDataArray[key].clickable_items.length > 0) {
      rectComponent[key].shape.imgUrl = this.utilityService.checkImageURL(this.getManualHotstpotDataArray[key].clickable_items[0].image);
      rectComponent[key].target_feature_uniq_code = this.getManualHotstpotDataArray[key].clickable_items[0].target_feature_uniq_code;
      rectComponent[key].transition = this.getManualHotstpotDataArray[key].clickable_items[0].transition;
      rectComponent[key].hero_feature_id = this.getManualHotstpotDataArray[key].clickable_items[0].feature_id;
      rectComponent[key].gesture = this.getManualHotstpotDataArray[key].clickable_items[0].gesture;
    } else {
      rectComponent[key].target_feature_uniq_code = this.getManualHotstpotDataArray[key].uniq_code;
      rectComponent[key].transition = this.getManualHotstpotDataArray[key].transition;
      rectComponent[key].hero_feature_id = this.getManualHotstpotDataArray[key].hero_feature_id;
    }
    rectComponent[key].shape.originX = parseInt(this.getManualHotstpotDataArray[key].position.originX, 10);
    rectComponent[key].shape.originY = parseInt(this.getManualHotstpotDataArray[key].position.originY, 10);
    rectComponent[key].shape.width = parseInt(this.getManualHotstpotDataArray[key].position.width, 10);
    rectComponent[key].shape.percent_width = this.getManualHotstpotDataArray[key].position.percent_width;
    rectComponent[key].shape.percent_height = this.getManualHotstpotDataArray[key].position.percent_height;
    rectComponent[key].shape.percent_originX = this.getManualHotstpotDataArray[key].position.percent_originX;
    rectComponent[key].shape.percent_originY = this.getManualHotstpotDataArray[key].position.percent_originY;
    rectComponent[key].shape.shapeProperties.name = `rectangle-${key}`;
    rectComponent[key].shape.shapeProperties.userRequested = this.getManualHotstpotDataArray[key].user_requested;
    rectComponent[key].sidekickId = this.getManualHotstpotDataArray[key].sidekick_id;
    rectComponent[key].hotspotId = this.getManualHotstpotDataArray[key].id;
    rectComponent[key].sideKickImageId = this.getManualHotstpotDataArray[key].sidekick_image_id;
    rectComponent[key].isSaved = true;
    if (this.getManualHotstpotDataArray[key].category_id === 6
      && this.router.url.includes('add-edit-carousel')) {
      rectComponent[key].hotspotEditable = true;
      rectComponent[key].isCarousel = false;
      rectComponent[key].isSelected = true;
      if (this.getManualHotstpotDataArray[key].clickable_items.length > 0) {
        rectComponent[key].shape.imgUrl = this.getManualHotstpotDataArray[key].clickable_items[0].image;
      }
    } else if (this.getManualHotstpotDataArray[key].category_id === 6
      && this.getManualHotstpotDataArray[key].clickable_items.length > 0) {
      rectComponent[key].hotspotEditable = false;
      rectComponent[key].shape.imgUrl = this.getManualHotstpotDataArray[key].clickable_items[0].image;
    }  else if (this.getManualHotstpotDataArray[key].category_id === 5
      && this.getManualHotstpotDataArray[key].clickable_items.length > 0) {
      rectComponent[key].shape.imgUrl = this.getManualHotstpotDataArray[key].clickable_items[0].image;
      rectComponent[key].transition = this.getManualHotstpotDataArray[key].clickable_items[0].transition;
      rectComponent[key].gesture = this.getManualHotstpotDataArray[key].clickable_items[0].gesture;
    } else {
      rectComponent[key].hotspotEditable = this.disableManualHotspot;
    }

    if (this.router.url.includes('add-sidekick')) {
      rectComponent[key].hotspotEditable = this.getManualHotstpotDataArray[key].category_id ? true : this.disableManualHotspot;
    }
    rectComponent[key].userRequested = this.getManualHotstpotDataArray[key].user_requested;
    rectComponent[key].isNewHotspotDraw = false;
    rectComponent[key].hotspotCategoryId = this.getManualHotstpotDataArray[key].category_id;
    if (this.getManualHotstpotDataArray[key].category_id === 6) {
      if (this.getManualHotstpotDataArray[key].clickable_items.length > 0) {
        rectComponent[key].clickableItems = this.getManualHotstpotDataArray[key].clickable_items;
        rectComponent[key].clickableItems.sort((a, b) => {
          if (a.image_attributes.image_number > b.image_attributes.image_number) {
            return 0;
          } else {
            return -1;
          }
        });
        rectComponent[key].shape.imgUrl = rectComponent[key].clickableItems[0].image;
      } else {
        rectComponent[key].shape.imgUrl = '';
      }
    } else {
      rectComponent[key].clickableItems = this.getManualHotstpotDataArray[key].clickable_items;
    }
    this.randomlyScatteredHotspots(rectComponent[key]);
  }
  /**
   * @description: Get all created manual hotspot in an array
   * from the RectangleComponet
   */
  getShapes(): RectangleComponent[] {
    return this.rectangleService.getRectangleComponents();
  }
  /**
   * @description: Get mouse position on svg template
   * @param event Event
   */
  getMousePosition(event: MouseEvent) {
    const CTM = this.manualref.nativeElement.getScreenCTM();
    this.currentPosition.x = (event.clientX - CTM.e) / CTM.a;
    this.currentPosition.y = (event.clientY - CTM.f) / CTM.d;
  }

  /**
   * @description: Deselect any selected
   * manual hotspot
   */
  deSelectComponents() {
    const shapes = this.getShapes();
    shapes.forEach((elm, index) => {
      shapes[index].isSelected = false;
    });
  }
  /**
   * @description: Method is used to draw, drag and resize
   * manual hotspot based on the conditions
   * @param event Event
   */
  onMouseDown(event): void {
    this.getMousePosition(event); // get mouse possiontion
    // CLASS is DRAGGABLE!!!!!!';
    if (this.rectangleService.showHideToolTip) {
      this.rectangleService.showHideToolTip = false;
      this.dataCommService.sidekickImageClicked({});
    }
    this.dataCommService.disableRightPanel = false;
    this.selectedComponent = null;
    if (event.target.classList.contains('clickable-item') && !event.target.classList.contains('more-icon')) {
      this.clickableItemClicked = true;
      const clickableItemObj = JSON.parse(event.target.dataset.item);
      this.dataCommService.setSelectedHotspot(clickableItemObj);
      this.selectedComponent = this.rectangleService.findRectangleComponent(event.target.dataset.hotspotId);
      if (this.selectedComponent.hotspotCategoryId === 6) {
        this.selectedComponent.isCarousel = false;
      }
      this.hotstpotIsSelected('mouseDown');
      this.rectangleService.findClickableItem(clickableItemObj.id);
      this.rectangleService.showHideToolTip = false;
      if (this.router.url.includes('add-icon') || this.router.url.includes('add-hotspot')) {
        this.rectangleService.showContextMenu = false;
      } else {
        this.rectangleService.showContextMenu = true;
      }

      this.menuPos = {
        x: parseInt(document.getElementById(
            `clickable-item-${this.rectangleService.selectedClickableItem.feature_id}`).getAttribute('x'), 10) + 52,
        y: parseInt(document.getElementById(
            `clickable-item-${this.rectangleService.selectedClickableItem.feature_id}`).getAttribute('y'), 10)
      };

      if (this.listenClick) {
        const pos: any = document.getElementById(
          `clickable-item-${this.rectangleService.selectedClickableItem.feature_id}`);
        this.toolTipPos = {
          x: parseInt(pos.getAttribute('x'), 10) + 52,
          y: parseInt(pos.getAttribute('y'), 10)
        };
        if (this.rectangleService.selectedClickableItem.suggestionTooltip) {
          this.rectangleService.showHideToolTip = true;
        }
        this.dataCommService.sidekickImageClicked( {
          sidekickImageClicked: true,
          item: this.rectangleService.selectedClickableItem,
          toolTipPos: this.toolTipPos
        });
      }
    } else if (event.target.classList.contains('suggestion-tooltip')) {
      this.rectangleService.showContextMenu = false;
      event.stopPropagation();
      if (this.rectangleService.selectedClickableItem.suggestionTooltip) {
        this.rectangleService.showHideToolTip = true;
      }
      if (this.listenClick) {
        this.dataCommService.suggestionTooltipClicked( {
          tooltipclicked: true,
          item: this.rectangleService.selectedClickableItem,
        });
      }
    } else if (event.target.classList.contains('more-icon')) {
      this.rectangleService.showContextMenu = false;
      this.rectangleService.showHideToolTip = false;
      const findRectangleComponent = this.rectangleService.findRectangleComponent(event.target.dataset.hotspotId);
      if (findRectangleComponent) {
        this.rectangleService.getRectangleComponents().forEach((hotspot) => {
          if (hotspot.hotspotId !== findRectangleComponent.hotspotId) {
            hotspot.isSelected = false;
          }
        });
      }
      if (this.showUserHotspot) {
        this.overlayWithContentService.showHideSliderFun(false);
        this.dataCommService.setSelectedArea(findRectangleComponent);
        this.selectedComponent = findRectangleComponent;
        this.hotstpotIsSelected('mouseDown');
        this.dataCommService.setDisplayMoreItemsSubject(true);
      }
    } else if (event.target.classList.contains('draggable')) {
      this.rectangleService.selectedClickableItem = null;
      this.deSelectComponents(); // Deselect hotspot
      this.removeMouseDown();
      this.selectedComponent = this.rectangleService.findRectangleComponent(event.target.id);
      if (this.selectedComponent) {
        this.getToolTipPosition();
        if (this.selectedComponent.hotspotCategoryId === null && this.selectedComponent.shape.imgUrl === null) {
          this.selectedComponent.target_feature_uniq_code = this.selectedComponent.clickableItems[0].target_feature_uniq_code;
        }
        if (this.sourcePage !== 'Add icon') {
          this.dataCommService.setSelectedArea(this.selectedComponent);
        }
        this.rectangleService.selectedComponent = this.selectedComponent;
        this.selectedComponent.startDrawingFlag = false;
        this.rectangleService.isDragging = true;
        this.shapeProperties = Object.assign({}, this.selectedComponent.shape.shapeProperties);
        this.hotstpotIsSelected('mouseDown');
        this.selectedComponent.isMouseDown = true;
        this.uncheckSideKick();
        this.startDragging();
      }
    } else if (event.target.classList.contains('resize')) {
      // this.overlayWithContentService.showHideSliderFun(false);
      this.selectedComponent = this.rectangleService.findRectangleComponent(event.target.id);
      const featureFrame = document.getElementById('prototype-frame-id');
      // CLASS is RESIZE!!!!!!;
      this.rectangleService.isResizing = true;
      this.onMouseMove(event);
    } else if (!event.target.classList.contains('menuItemIndicator')
      && this.rectangleService.isNewHotspotDraw
      && !this.isManualHotspotEditable
      && !this.disableManualHotspot) {
      this.overlayWithContentService.enableSaveButtonFun(true);
      this.rectangleService.showContextMenu = false;
      const injector = Injector.create({providers: [], parent: this.viewContainerRef.injector});
      const factory = this.componentFactoryResolver.resolveComponentFactory(RectangleComponent);
      const component = factory.create(injector);
      this.selectedComponent = component.instance as RectangleComponent;
      this.rectangleService.setRectangleComponent(this.selectedComponent);
      // Initialize shape properties
      this.shapeProperties = new ShapeProperties();
      this.shapeProperties.name = this.selectedComponent.shape.shapeProperties.name;
      this.selectedComponent.shape.shapeProperties = Object.assign({}, this.shapeProperties);
      this.selectedComponent.shape.shapeProperties.userRequested = true;
      this.rectangleService.isDrawing = true;
      if (this.selectedComponent) {
        this.selectedComponent.footerHeight = this.addedFooterHeight;
      }
      this.selectedComponent.startDrawing(this.currentPosition);
      this.dataCommService.setSelectedHotspot(this.selectedComponent);
    } else {
      if (!event.target.classList.contains('menuItemIndicator')) {
        this.rectangleService.showContextMenu = false;
      }
    }
    
    if(this.router.url.includes('edit-screen') && this.selectedComponent && !this.rectangleService.selectedClickableItem && !this.selectedComponent.target_feature_uniq_code && this.selectedComponent.hotspotCategoryId != 5) this.dataCommService.disableRightPanel = true;
  }

  onMouseMove(event): void {
    this.checkIsFeatureNotMapped();
    if (event.target.classList.contains('rectangle') || this.isManualHotspotEditable || !this.rectangleService.isNewHotspotDraw) {
      this.manualref.nativeElement.classList.remove('tooltip-cursor');
    } else {
      this.manualref.nativeElement.classList.add('tooltip-cursor');
    }
    this.getMousePosition(event);
    if (this.selectedComponent) {
      this.selectedComponent.footerHeight = this.addedFooterHeight;
    }
    if (this.selectedComponent && (this.rectangleService.isDrawing)) {
      this.selectedComponent.draw(this.currentPosition, this.styleObj);
    } else if (this.selectedComponent && this.rectangleService.isDragging) {
      // start dragging move !!!
      if (!this.dataCommService.screenEdited.includes('hotspot')) this.dataCommService.screenEdited.push('hotspot');
      this.selectedComponent.drag(this.currentPosition, this.styleObj);
      this.getToolTipPosition();
      this.enableOverSaveButton();
    } else if (this.rectangleService.isResizing) {
      // start resizing move !!!
      if (!this.dataCommService.screenEdited.includes('hotspot')) this.dataCommService.screenEdited.push('hotspot');
      this.getToolTipPosition();
      this.selectedComponent.resizeShape(event, this.currentPosition, this.styleObj);
      this.enableOverSaveButton();
    }
  }

  onMouseUp(event): void {
    const featureFrame = document.getElementById('prototype-frame-id');
    if (featureFrame) {
      featureFrame.classList.add('overflowInharit');
    }
    this.getMousePosition(event);
    if (this.selectedComponent) {
      if (this.selectedComponent.hotspotCategoryId === null || this.selectedComponent.hotspotCategoryId === 5) {
        this.menuPosition();
      } else {
        if (this.rectangleService.selectedClickableItem) {
          this.menuPos = {
            x: parseInt(document.getElementById(
              `clickable-item-${this.rectangleService.selectedClickableItem.feature_id}`).getAttribute('x'), 10) + 52,
            y: parseInt(document.getElementById(
              `clickable-item-${this.rectangleService.selectedClickableItem.feature_id}`).getAttribute('y'), 10)
          };
        }
      }
      if (!this.selectedComponent.shape.imgUrl && this.selectedComponent.hotspotCategoryId === null && (this.selectedComponent.shape.width < this.hotspotSize || this.selectedComponent.shape.height < this.hotspotSize)) {
        this.rectangleService.isNewHotspotDraw = true;
        if (this.router.url.includes('add-hotspot')) {
          this.toasterService.error('Hotspot size cannot be less than 48X48px');
          this.deleteHotspot();
          this.selectedComponent = null;
        }
      } else {
        // keeping this commented as this might be needed in future
        // if (this.clickableItemClicked) {
        //   this.rectangleService.showContextMenu = true;
        //   this.menuPos = {
        //     x: this.selectedComponent.shape.originX + this.selectedComponent.shape.width + 10,
        //     y: this.selectedComponent.shape.originY + this.selectedComponent.shape.height / 2
        //   };
        // }
        if (this.rectangleService.isDrawing) {
          this.menuPosition();
          this.rectangleService.showContextMenu = true;
          this.manualShareService.updateHotspotCountFun({isAdded: true}); // update count of hotspot
        }
        if (this.selectedComponent.hotspotCategoryId === null || this.selectedComponent.hotspotCategoryId === 5) {
          this.dataCommService.setSelectedArea(this.selectedComponent);
          this.overlayWithContentService.showHideSliderFun(true);
        }
        this.resetIsDrawingFlag();
        this.deSelectComponents();
        this.hotstpotIsSelected('mouseUp');
        if (!this.rectangleService.isDragging) {
        this.uncheckSideKick();
        }
      }
    } else {
      if (this.selectedComponent) {
        if (!this.selectedComponent.shape.imgUrl && this.selectedComponent.hotspotCategoryId === null && (this.selectedComponent.shape.width < this.hotspotSize || this.selectedComponent.shape.height < this.hotspotSize)) {
          this.toasterService.error('Hotspot size cannot be less than 48X48px');
          this.deleteHotspot();
        } else {
          this.rectangleService.getRectangleComponents().forEach((elm, index) => {
            if (elm.startDrawingFlag) {
              this.deSelectComponents();
              this.rectangleService.getRectangleComponents()[index].startDrawingFlag = false;
              this.rectangleService.getRectangleComponents()[index].isSelected = true;
              if (!this.rectangleService.isDragging) {
                this.uncheckSideKick();
              }
            } else {
              this.rectangleService.getRectangleComponents()[index].startDrawingFlag = false;
            }
          });
        }
      }
    }
    // if (this.isSelectingPoints) {
    //   this.selectedComponent.setPoint(this.currentPosition);
    // }
    if (this.rectangleService.isDrawing && this.selectedComponent) {
      if (this.selectedComponent.hotspotId === null) {
        this.rectangleService.showContextMenu = true;
      } else {
        this.rectangleService.showContextMenu = false;
      }
    }
    this.rectangleService.isDrawing = false;
    this.rectangleService.isDragging = false;
    this.rectangleService.isResizing = false;
    // set as undefined offset to reset mouse position while dragging hotspot
    if (this.selectedComponent) {
      this.selectedComponent.offset = undefined;
    }
  }
  removeMouseDown() {
    const shapes = this.getShapes();
    shapes.forEach((elm, index) => {
      shapes[index].isMouseDown = false;
    });
  }
  /**
   * @description: Start dragging hotspot
   */
  startDragging(): void {
    this.rectangleService.isDragging = true;
  }

  /**
   * @description: End dragging hotspot
   */
  endDragging(): void {
    this.selectedComponent = null;
  }
  /**
   * @description Method is used to push hotspot Ids
   * in an array to delete from the server
   * and to uncheck checkbox of sidekick feature
   * @param: set flag true/false wheather we want to close
   * sidekik panel or not
   */
  deleteHotspot() {
    this.rectangleService.removeSelectedRectangleComponent(this.selectedComponent);
    const isIndex = this.rectangleService.getRectangleComponents().findIndex(arrObj => arrObj.isSelected === true);
    if (isIndex !== -1) {
      this.selectedComponent = this.rectangleService.getRectangleComponents()[isIndex];
    }
  }
  /**
   * @description: Method is used to uncheck, disable, enable
   * sidekick feature based on certain conditions.
   */
  uncheckSideKick() {
    // this.dataCommService.setSelectedHotspot(this.selectedComponent);
    this.dataCommService.spotTypeForDestination = 'custom';

  }
  resetIsDrawingFlag() {
    const shapes = this.getShapes();
    shapes.forEach((elm, index) => {
      shapes[index].startDrawingFlag = false;
    });
  }
  hotstpotIsSelected(action) {
    if (this.selectedComponent.hotspotEditable) {
      this.selectedComponent.isSelected = true;
      // this.overlayWithContentService.enableDeleteButtonFun(false);
      if (action === 'mouseDown') {
        if (this.router.url.includes('edit-screen')) {
          this.dataCommService.trackEvent('prototype_interacted', {
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            Source: 'Edit hotspot'
          });
        } else if (this.router.url.includes('add-hotspot')) {
          this.dataCommService.trackEvent('prototype_interacted', {
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            Source: 'Add hotspot'
          });
        } else if (this.router.url.includes('add-sidekick')) {
          this.dataCommService.trackEvent('prototype_interacted', {
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            Source: 'Add sidekick'
          });
        } else if (this.router.url.includes('add-icon')) {
          this.dataCommService.trackEvent('prototype_interacted', {
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice,
            Source: 'Add icon'
          });
        }
      }
    }
  }
  checkIsFeatureNotMapped() {
    const isIndex = this.rectangleService.getRectangleComponents().findIndex(arrObj =>
      arrObj.isNewHotspotDraw === true && arrObj.hero_feature_id === null);
    if (isIndex !== -1) {
      this.rectangleService.isNewHotspotDraw = false;
    }
  }
  ngOnDestroy() {
    if (this.changeManualVar) {
      this.changeManualVar.unsubscribe();
    }
    if (this.deleteManualHotSpot) {
      this.deleteManualHotSpot.unsubscribe();
    }
    if (this.addSideKick) {
      this.addSideKick.unsubscribe();
    }
    if (this.addIconServiceVar) {
      this.addIconServiceVar.unsubscribe();
    }
    if (this.addCarouselServiceVar) {
      this.addCarouselServiceVar.unsubscribe();
    }
    if (this.addEditCarouselVar) {
      this.addEditCarouselVar.unsubscribe();
    }
    this.dataCommService.disableRightPanel = false;
  }

  enableOverSaveButton() {
    this.dataCommService.isContentChanged = true;
    if (this.isManualHotspotEditable) {
      this.overlayWithContentService.enableSaveButtonFun(false);
    }
  }
  addIconHotspot() {
    // create dysnamic object for component
    this.addIconServiceVar = this.dataCommService.addIconsSource$.subscribe((hotspot: any) => {
      if (hotspot) {
        this.overlayWithContentService.enableSaveButtonFun(false);
        // this.overlayWithContentService.enableDeleteButtonFun(false);
        this.deSelectComponents(); // Deselect hotspot
        const d = new Date();
        const injector = Injector.create({providers: [], parent: this.viewContainerRef.injector});
        const factory = this.componentFactoryResolver.resolveComponentFactory(RectangleComponent);
        const component = factory.create(injector);
        const selectedComponent = component.instance as RectangleComponent;
        this.rectangleService.setRectangleComponent(selectedComponent);
        this.rectangleService.isDrawing = false;
        const rectComponent = this.rectangleService.getRectangleComponent();
        rectComponent.shape.height = parseInt(hotspot.position.height, 10);
        rectComponent.shape.imgUrl = this.utilityService.checkImageURL(hotspot.sidekick_image_url);
        rectComponent.shape.originX = parseInt(hotspot.position.originX, 10);
        rectComponent.shape.originY = parseInt(hotspot.position.originY, 10);
        rectComponent.shape.width = parseInt(hotspot.position.width, 10);
        rectComponent.shape.shapeProperties.name = `rectangle-${d.getTime()}`;
        rectComponent.shape.shapeProperties.userRequested = true;
        rectComponent.sidekickId = hotspot.sidekick_id;
        rectComponent.hotspotId = hotspot.id;
        rectComponent.sideKickImageId = hotspot.sidekick_image_id;
        rectComponent.isSaved = true;
        rectComponent.target_feature_uniq_code = hotspot.uniq_code;
        rectComponent.hero_feature_id = hotspot.hero_feature_id;
        rectComponent.featureTitle = hotspot.title;
        rectComponent.hotspotEditable = true;
        rectComponent.transition = hotspot.transition;
        rectComponent.userRequested = hotspot.user_requested;
        rectComponent.isNewHotspotDraw = false;
        rectComponent.isSelected = true;
        rectComponent.target_feature_uniq_code = hotspot.target_feature_uniq_code;
        this.rectangleService.showContextMenu = true;
        this.menuPos = {
          x: rectComponent.shape.originX + rectComponent.shape.width + 10,
          y: rectComponent.shape.originY + rectComponent.shape.height / 2
        };
        this.dataCommService.setSelectedArea(rectComponent);
      }
    });
  }

  addCarouselHotSpot() {
    this.addCarouselServiceVar = this.dataCommService.addCarouselSource$.subscribe((hotspot: any) => {
      if (hotspot) {
        // this.overlayWithContentService.enableDeleteButtonFun(false);
        this.deSelectComponents(); // Deselect hotspot
        const d = new Date();
        const injector = Injector.create({providers: [], parent: this.viewContainerRef.injector});
        const factory = this.componentFactoryResolver.resolveComponentFactory(RectangleComponent);
        const component = factory.create(injector);
        const selectedComponent = component.instance as RectangleComponent;
        this.rectangleService.setRectangleComponent(selectedComponent);
        this.rectangleService.isDrawing = false;
        const rectComponent = this.rectangleService.getRectangleComponent();
        rectComponent.shape.height = parseInt(hotspot.position.height, 10);
        rectComponent.shape.imgUrl = this.utilityService.checkImageURL(hotspot.sidekick_image_url);
        rectComponent.shape.originX = parseInt(hotspot.position.originX, 10);
        rectComponent.shape.originY = parseInt(hotspot.position.originY, 10);
        rectComponent.shape.width = parseInt(hotspot.position.width, 10);
        rectComponent.shape.shapeProperties.name = `rectangle-${d.getTime()}`;
        rectComponent.shape.shapeProperties.userRequested = true;
        rectComponent.sidekickId = hotspot.sidekick_id;
        rectComponent.hotspotId = hotspot.id;
        rectComponent.sideKickImageId = hotspot.sidekick_image_id;
        rectComponent.isSaved = true;
        rectComponent.target_feature_uniq_code = hotspot.uniq_code;
        rectComponent.hero_feature_id = hotspot.hero_feature_id;
        rectComponent.featureTitle = hotspot.title;
        rectComponent.hotspotEditable = true;
        rectComponent.transition = hotspot.transition;
        rectComponent.userRequested = hotspot.user_requested;
        rectComponent.isNewHotspotDraw = false;
        rectComponent.isSelected = true;
        rectComponent.isCarousel = false;
        rectComponent.hotspotCategoryId = hotspot.hotspotCategoryId;
        rectComponent.clickableItems = [];
        this.rectangleService.showContextMenu = false;
        this.menuPos = {
          x: rectComponent.shape.originX + rectComponent.shape.width + 10,
          y: rectComponent.shape.originY + rectComponent.shape.height / 2
        };
        this.dataCommService.setSelectedArea(rectComponent);
      }
    });
  }

  linkScreenClicked($event) {
    $event.stopPropagation();
    this.linkScreenClick.emit();
  }

  deleteSpotClicked($event) {
    $event.stopPropagation();
    this.deleteSpotClick.emit();
  }

  addClass(text) {
      (text === 'link') ? this.mouseHoveredLink = true : this.mouseHoveredLink = false;
      (text === 'delete') ? this.mouseHoveredDelete = true : this.mouseHoveredDelete = false;
  }
  removeClass() {
    this.mouseHoveredLink = false;
    this.mouseHoveredDelete = false;
  }

  getToolTipPosition() {
    if (this.selectedComponent && this.selectedComponent.hotspotCategoryId === 5) {
      this.rectangleService.showContextMenu = true;
      this.menuPosition();
    }  else if (this.selectedComponent && this.selectedComponent.hotspotCategoryId === null && this.selectedComponent.isCarousel) {
      this.rectangleService.showContextMenu = true;
      this.menuPosition();
    } else {
      this.rectangleService.showContextMenu = false;
    }
  }

  menuPosition() {
    this.menuPos = {
      x: this.selectedComponent.shape.originX + this.selectedComponent.shape.width + 10,
      y: this.selectedComponent.shape.originY + this.selectedComponent.shape.height / 2
    };
  }
  trackById(index, shape) {
    return index;
  }

  selectFirstAvailableHotspot() {
    if (!this.router.url.includes('add-icon') && this.rectangleService.getRectangleComponents().length) {
      this.rectangleService.selectedClickableItem = null;
      let findUnsavedHotspotIndex = this.rectangleService.getRectangleComponents().findIndex(x => !x.isSaved),
        i = findUnsavedHotspotIndex != -1 ? findUnsavedHotspotIndex : 0;

      while (i < this.rectangleService.getRectangleComponents().length) {
        this.selectedComponent = this.rectangleService.getRectangleComponents()[i];
        if(this.selectedComponent.target_feature_uniq_code || (!this.selectedComponent.target_feature_uniq_code && this.selectedComponent.clickableItems.length && this.selectedComponent.clickableItems[0].image)) break;
        else i++;
      }

      this.getToolTipPosition();

      if (this.selectedComponent.hotspotCategoryId === null && this.selectedComponent.shape.imgUrl === null) {
        this.selectedComponent.target_feature_uniq_code = this.selectedComponent.clickableItems[0].target_feature_uniq_code;
      }

      this.rectangleService.selectedComponent = this.selectedComponent;

      if(this.router.url.includes('edit-screen') && this.selectedComponent.clickableItems.length && this.selectedComponent.target_feature_uniq_code) {
        this.rectangleService.findClickableItem(this.selectedComponent.clickableItems[0].id);
        this.dataCommService.setSelectedHotspot(this.rectangleService.selectedClickableItem);
      }

      this.dataCommService.setSelectedArea(this.selectedComponent);
      this.selectedComponent.startDrawingFlag = false;
      this.rectangleService.isDragging = false;
      this.shapeProperties = Object.assign({}, this.selectedComponent.shape.shapeProperties);
      this.hotstpotIsSelected('mouseDown');
      this.selectedComponent.isMouseDown = true;
      this.uncheckSideKick();

      if(this.router.url.includes('add-hotspot')){
        if(findUnsavedHotspotIndex != -1) this.overlayWithContentService.enableSaveButtonFun(false);
        else {
          this.rectangleService.showContextMenu = false;
          this.dataCommService.disableRightPanel = true;
        }
      }
    }
  }

  randomlyScatteredHotspots(component: RectangleComponent) {
    let currentSize = this.styleObj;
    if(component.shape.width > currentSize.width) component.shape.width = currentSize.width - 20;
    if(component.shape.height > currentSize.height) component.shape.height = currentSize.height - 20;
    if (component.shape.originX > (currentSize.width - component.shape.width)) {
      component.shape.originX = currentSize.width - component.shape.width - 10;
    }
    if (component.shape.originY > (currentSize.height - component.shape.height)) {
      component.shape.originY = currentSize.height - component.shape.height;
    }
  }
}

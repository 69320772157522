import {Component, ViewChild, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import {TagModel} from 'ngx-chips/core/accessor';
import {Observable, of, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {DataCommService} from 'src/app/main/shared/services/data-comm.service';
import {DataService} from 'src/app/main/shared/services/data.service';
import {ToasterService} from '@core/toaster.service';

@Component({
    selector: 'app-mail-sms-share',
    templateUrl: './mail-sms-share.component.html',
    styleUrls: ['./mail-sms-share.component.scss', './prototype-themes.scss']
})
export class MailSMSShareComponent implements OnDestroy {

    countryCodeIndia = 91;
    mailAddresses = [];
    sendMode = 'mail';
    phoneNumber = '';
    mailSMSSent = false;
    @ViewChild('mobileNumber', {static: false}) mobNumber;
    invalidPhoneNumber = false;
    disableSendBtn = true;
    platform;
    sharingUrl;
    apiSubscriptions = [];
    sendMailSubscription: Subscription;
    public sendBtnClicked: boolean = false;

    constructor(
        private dataCommService: DataCommService,
        private dataService: DataService,
        private toasterService: ToasterService,
        private router: Router
    ) {
        this.platform = this.dataCommService.platform;
    }

    ngOnInit() {
        this.sharingUrl = this.dataCommService.sharingUrl + `/${this.platform}` + `/${this.dataCommService.platformType}`;
    }

    changeSendingMode(mode: string) {
        this.sendMode = mode;
        if (mode === 'mail') {
            this.checkEmailValid();
        } else {
            this.checkNumberValid();
        }
    }

    validateEmail(email) {
        if (!email) {
            return;
        }
        const rest = email.substring(0, email.lastIndexOf('@'));
        if (rest.includes('..') && (rest.includes('"')) && ((rest.charAt(0) !== '"') || (rest.charAt(rest.length - 1) !== '"'))) {
            return false;
            // tslint:disable-next-line: quotemark
        } else if (rest.includes('..') && (rest.includes("'")) && ((rest.charAt(0) !== "'") || (rest.charAt(rest.length - 1) !== "'"))) {
            return false;
        } else if (rest.includes('..') && !(/^'|"/.test(rest))) {
            return false;
        }
        if ((rest.charAt(0) === '.') || (rest.charAt(rest.length - 1) === '.')) {
            return false;
        }
        if (email.includes('@') && !(email.split('@')[1].split('.').length >= 2)
            && (email.split('@')[1].split('.')[email.split('@').length - 1] !== '')) {
            return false;
        }
        if (!rest.includes('..') && (/[ !#$%&'*+-/=?^_`{|}~]/.test(rest) && !/["(),:;<>@[\]]/.test(rest))) {
            return true;
        } else if (rest.includes('..') && (/[ !#$%&'*+-/=?^_`{|}~]/.test(rest) && !/[(),:;<>@[\]]/.test(rest))) {
            return true;
        } else if (((rest.charAt(1) === '.') || (rest.charAt(rest.length - 2) === '.'))
            && ((rest.charAt(0) === '"') && (rest.charAt(rest.length - 1) === '"'))) {
            return true;
        } else if (/^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{2,3})+$/.test(email)) {
            return true;
        } else {
            return false;
        }
    }

    onAdding = (tag: TagModel): Observable<TagModel> => {
        const item = {display: tag.toString(), error: !this.validateEmail(tag)};
        return of(item)
            .pipe(filter(() => true));
    };

    checkEmailValid() {
        let invalidEmailIndex;
        if (this.mailAddresses.length > 0) {
            invalidEmailIndex = this.mailAddresses.findIndex(elem => {
                return elem.error;
            });
            this.disableSendBtn = invalidEmailIndex !== -1;
        } else {
            this.disableSendBtn = true;
        }
    }

    checkNumberValid() {
        if (this.mobNumber.pristine) {
            this.invalidPhoneNumber = false;
            this.disableSendBtn = true;
        } else {
            if (this.mobNumber.errors) {
                this.invalidPhoneNumber = true;
                this.disableSendBtn = true;
            } else {
                this.invalidPhoneNumber = false;
                this.disableSendBtn = false;
            }
        }
    }

    sendSMS() {
        if (!this.mobNumber.errors) {
            const data = {
                channel: 'SMS',
                recipient_array: [`+${this.phoneNumber.substr(1)}`],
                prototype_link: this.sharingUrl,
                country_code: +this.phoneNumber.substr(1, 2) === this.countryCodeIndia ? this.countryCodeIndia : 0,
                build_card_id: this.dataCommService.nowBuildCardId
            };
            let eventName = this.router.url.includes('playmode') ? 'play_mode_shared' : 'prototype_shared';
            this.dataCommService.trackEvent(eventName, {
                user_id: this.dataCommService.userUniqueid,
                user_browser: this.dataCommService.userBrowser,
                user_device: this.dataCommService.userDevice,
                'Share type': 'SMS',
                'Password protected?': this.dataCommService.isPasswordProtected ? 'Y' : 'N',
                'Social channel': ''
            });
            this.sendDataToApi(data);
        }
    }

    sendMail() {
        if (this.mailAddressesValid()) {
            const recipientArray = [];
            this.mailAddresses.forEach(elem => {
                recipientArray.push(elem.display);
            });

            let eventName = this.router.url.includes('playmode') ? 'play_mode_shared' : 'prototype_shared';
            this.dataCommService.trackEvent(eventName, {
                user_id: this.dataCommService.userUniqueid,
                user_browser: this.dataCommService.userBrowser,
                user_device: this.dataCommService.userDevice,
                'Share type': 'Email',
                'Password protected?': this.dataCommService.isPasswordProtected ? 'Y' : 'N',
                'Social channel': ''
            });

            const data = {
                channel: 'email',
                recipient_array: recipientArray,
                prototype_link: this.sharingUrl,
                country_code: '',
                build_card_id: this.dataCommService.nowBuildCardId
            };
            this.sendDataToApi(data);
        }
    }

    mailAddressesValid(): boolean {
        const errorMailId = this.mailAddresses.findIndex((elem) => {
            return elem.error;
        });
        return errorMailId === -1;
    }

    sendDataToApi(data) {
        if (this.sendMailSubscription) {
            this.sendMailSubscription.unsubscribe();
        }
        this.sendMailSubscription = this.dataService.sendMailSMS(data, this.dataCommService.nowBuildCardId).subscribe((res) => {
            this.mailSMSSent = true;
            setTimeout(() => {
                this.mailSMSSent = false;
                this.sendBtnClicked = false;
                this.mailAddresses = [];
                this.phoneNumber = '';
                this.disableSendBtn = true;
            }, 1500);
        }, (err) => {
            this.toasterService.error('Something Went Wrong!');
        });
        this.apiSubscriptions.push(this.sendMailSubscription);
    }

    sendBtnHandler() {
        this.sendBtnClicked = true;
        this.disableSendBtn = true;
        if (this.sendMode === 'SMS') {
            this.sendSMS();
        } else if (this.sendMode === 'mail') {
            this.sendMail();
        }
    }

    inputTextChange($event) {
        if ($event && $event.length > 0) {
            this.disableSendBtn = false;
        }
    }

    ngOnDestroy() {
        this.apiSubscriptions.forEach((apiSub: Subscription) => {
            apiSub.unsubscribe();
        });
    }
}

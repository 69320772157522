import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
@Component({
  selector: 'app-empty-prototype',
  templateUrl: './empty-prototype.component.html',
  styleUrls: ['./empty-prototype.component.scss']
})
export class EmptyPrototypeComponent implements OnInit {
  constructor(
    public dataCommService: DataCommService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  showAddFeaturesWindow(e) {
    e.stopPropagation();
    if (this.router.url.includes('flowchart')) {
      if (this.dataCommService.allFeatures) {
        this.dataCommService.showAllFeaturesWindow = true;
        this.dataCommService.showUnlinkSlider = false;
      }
      if (this.dataCommService.showLogoPopup) {
          this.dataCommService.showLogoPopup = false;
      }
    } else {
      this.router.navigateByUrl(`${this.dataCommService.buildcardId}/dashboard/${this.dataCommService.platform}/${this.dataCommService.platformType}/flowchart?allFeatures=1`);
    }
  }
}

import {Component, EventEmitter, HostListener, Input, OnDestroy, Output} from '@angular/core';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';
import { Subscription } from 'rxjs';
import {Constants} from '@shared/services/constants';
import {FileUploadService} from '@shared/services/file-upload.service';
import {DataService} from '../../../../main/shared/services/data.service';
import {ToasterService} from '@core/toaster.service';
import {AuthService} from '@core/auth.service';

@Component({
  selector: 'app-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss']
})
export class HeaderLogoComponent implements OnDestroy {
  @Input() dropDownFlag;
  @Input() prototypeSelected;
  @Output() prototypeClicked = new EventEmitter();
  @Output() dropDownShow = new EventEmitter();
  featureCount: number;
  isError = false;
  private subs: Subscription[] = [];
  public isEditable = false;
  public previousTitle: string;
  private isProjectTitleSaved = true;
  public prototypeList = [];
  constructor(public dataCommService: DataCommService,
              public dataService: DataService,
              private toasterService: ToasterService,
              public authService: AuthService) {
    this.previousTitle = this.dataCommService.projectName;
    this.subs.push(this.dataCommService.featureList$.subscribe((data: any) => {
      this.featureCount = data.features_count;
    }, (err) => {
    }));
    this.subs.push(this.dataCommService.dataError$.subscribe((data) => {
      this.isError = data;
    }));
    this.featureCount = this .dataCommService.featureCount;
  }
  @HostListener('document:mouseup', ['$event'])
  MouseEvent($event: MouseEvent) {
    if (!this.dataCommService.showAllFeaturesWindow) {
      if (this.isProjectTitleSaved === false && ($event.target as HTMLInputElement).className.search('projectTitleInput') === -1) {
        const eventTitle = {
          target: {
            value: (document.getElementById('projectTitleInput') as HTMLInputElement).value
          }
        };
        this.updateProjectName(eventTitle);
        this.isProjectTitleSaved = true;
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach( (sub: Subscription) => {
      sub.unsubscribe();
    });
  }
  updateProjectName(event) {
    const projectName = event.target.value;
    if (projectName.trim() === this.dataCommService.projectName) {
      this.isEditable = false;
    } else if (projectName.trim() === '') {
      this.toasterService.warning(Constants.ChangeTitletoasterMessage.BLANKTITLE);
      (document.getElementById('projectTitleInput' ) as HTMLInputElement).value = this.dataCommService.projectName;
      this.isEditable = false;
    } else {
      this.dataService.updateProjectName({title: projectName}, this.dataCommService.nowBuildCardId).subscribe((res: any) => {
        this.dataCommService.projectName = event.target.value;
        // this.toasterService.success(res.message);
        this.isEditable = false;
      }, err => {
        // this.toasterService.success(Constants.toasterMessage.ERROR);
        this.isEditable = false;
      });
    }
  }
  editableEnable() {
    this.isEditable = true;
    this.isProjectTitleSaved = false;
    setTimeout(() => {
      document.getElementById('projectTitleInput').focus();
    }, 0);
  }

  selectPrototype(prototype) {
    this.prototypeClicked.emit(prototype);
  }

  showDropDown() {
    this.dropDownShow.emit();
  }
  // updateProjectName(projectName) {
  //   this.dataService.updateProjectName({title: projectName}, this.dataCommService.nowBuildCardId).subscribe((res: any) => {
  //     this.toasterService.success(res.message);
  //   }, err => {
  //     this.toasterService.success(Constants.toasterMessage.ERROR);
  //   });
  //   this.isEditable = false;
  // }
  // editableEnable() {
  //   this.isEditable = true;
  // }

}

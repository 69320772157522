import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CookieService } from '@core/cookie.service';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';

@Component({
  selector: 'app-header-roles',
  templateUrl: './header-roles.component.html',
  styleUrls: ['./header-roles.component.scss']
})
export class HeaderRolesComponent implements OnInit {

  selectedRole: any;

  constructor(
    private _route: ActivatedRoute,
    public _router: Router,
    public _dataCommService: DataCommService,
    private _cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this._route.queryParams.subscribe(value => {
      if (value.roleId) {
        this.selectedRole = this._dataCommService.userRoles.find(e => e.id === +value.roleId);
        this._cookieService.setLocalStorage('roleId', this.selectedRole.id);
        let url: string = this._router.url.substring(0, this._router.url.indexOf("?"));
        this._router.navigateByUrl(url);
      }
    });
    let roleId = this._cookieService.getLocalStorage('roleId');
    if (this._dataCommService.userRoles.length && roleId && roleId !== 'undefined' && !this.selectedRole) {
      this.selectedRole = this._dataCommService.userRoles.find(e => e.id === +this._cookieService.getLocalStorage('roleId'));
    }
    if (this._dataCommService.userRoles.length && !this.selectedRole) {
      this.selectedRole = this._dataCommService.userRoles[0];
      this._cookieService.setLocalStorage('roleId', this.selectedRole.id);
    }
    this._dataCommService.selectedRole = this.selectedRole;
    this._dataCommService.switchRoleSource.next();
  }

  changeRole(event, item) {
    event.stopPropagation();
    this.selectedRole = item;
    this._cookieService.setLocalStorage('roleId', this.selectedRole.id);
    window.location.reload();
  }
}

import {Component, EventEmitter, OnDestroy, Output, OnInit, HostListener, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DataCommService} from 'src/app/main/shared/services/data-comm.service';
import {FilesUtilityService} from '@shared/services/files.utility.service';
import {WindowRefService} from '@core/window-ref.service';
import {AuthService} from '@core/auth.service';
import { Subscription } from 'rxjs';
import { CommentService } from '../../comments/comment.service';
import {Constants} from '@shared/services/constants';
import {ToasterService} from '@core/toaster.service';
@Component({
    selector: 'app-header-actions',
    templateUrl: './header-actions.component.html',
    styleUrls: ['./header-actions.component.scss']
})
export class HeaderActionsComponent implements OnInit, OnDestroy {
    @Output() showCustomPrototype = new EventEmitter();
    public isMobileView: boolean;
    subs: Subscription[] = [];
    isDesigner = false;
    commentModeEnabled = false;
    selectedValues: any ={};
    previousIndex: any=null;
    openDropDownBoolean: boolean;
    platformName: string;
    clickedPlatformIndex: number;
    notificationSub: Subscription;
    @ViewChild('divClicked', { static: false }) divClicked;
    public prototypeFlag: string = 'tailorMade';
    constructor(
        private router: Router,
        public dataCommService: DataCommService,
        private windowRef: WindowRefService,
        public filesUtility: FilesUtilityService,
        public authService: AuthService,
        private toasterService: ToasterService,
        private commentService: CommentService) {
        this.isDesigner = this.authService.userProfile.isDesigner;
        if (this.router.url.includes('web')) {
            this.isMobileView = false;
            this.dataCommService.platform = 'web';
        } else if(this.router.url.includes('watch')){
            this.isMobileView = true;
            this.dataCommService.platform = 'watch';
        }
        else{
            this.isMobileView = true;
            this.dataCommService.platform = 'mobile';
        }
        if (this.authService.userProfile.userData) {
            const isProd = this.windowRef.nativeWindow.location.href.indexOf('now.builder.ai') !== -1 ? true : false;
            const cpeEmail = isProd ? Constants.cpeProdEmail : Constants.cpeEmailOther;
            const userRoles = this.authService.userProfile.userData.roles.split(',');
            if (userRoles.includes('CPE') && this.authService.userProfile.userData.email === cpeEmail) {
                this.dataCommService.isCpe = true;
            }
        }
        this.subs.push(this.dataCommService.switchViewSource$.subscribe((value: string) => {
                this.isMobileView = (value === 'mobile' || value === 'watch');
                this.dataCommService.platform = value;
            }
        ));
    }

    @HostListener('document:click', ['$event.target'])
    onClick(targetElement) {
        if (this.divClicked) {
            const clickedInside = this.divClicked.nativeElement.contains(targetElement);
            if (!clickedInside && this.openDropDownBoolean) {
                this.previousIndex = null;
                this.openDropDownBoolean = false;
                this.clickedPlatformIndex = -1;
            }
        }
        this.dataCommService.closeFilter();
        // this.dataCommService.resolvedCheckbox = false;
    }
    ngOnInit() {
        this.subs.push(this.commentService.commentModeToggle$.subscribe(val => {
            this.commentModeEnabled = val;
        }));

    }

    navigateView(view: string) {
        this.router.navigateByUrl(`${this.dataCommService.buildcardId}/${view}`);
    }

    toggleCommentMode() {
          if (this.dataCommService.prototypeType === 'instant' || (this.dataCommService.prototypeType === 'custom' && this.dataCommService.trackerProjectId)) {
            if (!this.commentModeEnabled) {
                const customPtototypeStage = this.dataCommService.grayStyle ? 'Custom Prototype: V0' : 'Custom Prototype';
                this.dataCommService.trackEvent('comments_icon_clicked', {
                    user_id: this.dataCommService.userUniqueid,
                    user_browser: this.dataCommService.userBrowser,
                    user_device: this.dataCommService.userDevice,
                    Screen_name: 'Preview',
                });
                // Trigger: 'Header',
                // Stage: (this.dataCommService.prototypeType === 'instant') ? 'Instant Prototype' : customPtototypeStage
                this.dataCommService.showFtrDesc = false;
            }
            this.dataCommService.selectedNotification ={};
            this.commentService.commentModeToggleSubject.next(!this.commentModeEnabled);
        }
    }

    switchPreview(selectedMode: string) {
        let platformDisabled = false;
        if (this.windowRef.nativeWindow.location.href.indexOf('preview') !== -1) {
            this.dataCommService.trackEvent('device_selected', {
                user_id: this.dataCommService.userUniqueid,
                'device_selected': this.dataCommService.userDevice,
                'OS_selected': this.dataCommService.userDeviceOS
            });
        } else {
            this.dataCommService.trackEvent('device_selected', {
                user_id: this.dataCommService.userUniqueid,
                'device_selected': this.dataCommService.userDevice,
                'OS_selected': this.dataCommService.userDeviceOS
            });
        }
        if (!(this.authService.userProfile.userData && this.dataCommService.isCpe) && selectedMode === 'web') {
            platformDisabled = true;
            this.toasterService.success('Coming Soon');
        }
        if (this.dataCommService.platform !== selectedMode && !platformDisabled) {
            this.dataCommService.switchView(selectedMode);
            if (!this.router.url.includes('dashboard')) {
                let currentUrl;
                if (selectedMode === 'web') {
                    currentUrl = this.router.url.replace('mobile', selectedMode);
                } else {
                    currentUrl = this.router.url.replace('web', selectedMode);
                }
                this.router.navigate([currentUrl]);
            }
        }
    }

    isUrlContainsV1(){
        if(this.authService.isLoggedInUser()){
            return true;
        }
        else {
            if(this.dataCommService.isGuestUser) return true;
            else return false
        }      
    }

    isUrlContainsV1Inner()
         {
        if(this.authService.cutsotmPrototypeVersion){
         if(this.dataCommService.isOwner && (this.authService.userProfile.isClient || this.authService.userProfile.isCPEUser)){
            return true;
           }
        else {
            return false;
              }
        }
        else {
            return true;
             }
        }

    shareAppUrl() {
        if (this.authService.isLoggedInUser()) {
            this.dataCommService.shareAppUrl();
        }
        else {
            this.dataCommService.callbackForDownLoadLink.isDownload = false;
            this.dataCommService.touchPoint = Constants.touchPoints.previewHeader;
            this.dataCommService.callbackForSharelink = true;
            this.dataCommService.showRentalPaymentFlow = true;
            this.dataCommService.selectedSectionIndex = 0;
        }
    }

    downLoadFile($event,value){
        if (this.authService.isLoggedInUser()) {
            if (value === 'WEBM') {
                this.dataCommService.trackEvent('prototype_downloaded', {
                    user_id: this.dataCommService.userUniqueid,
                    user_browser: this.dataCommService.userBrowser,
                    user_device: this.dataCommService.userDevice,
                    export_format: 'VIDEO'
                });
                this.dataCommService.startAutomateVideoDownload(true);
            } else if (value === 'SLIDE') {
                this.dataCommService.trackEvent('prototype_downloaded', {
                    user_id: this.dataCommService.userUniqueid,
                    user_browser: this.dataCommService.userBrowser,
                    user_device: this.dataCommService.userDevice,
                    export_format: 'SLIDE'
                });
                this.dataCommService.getGoogleSlidePresentation(true);  
            } else this.filesUtility.downloadPreviewe($event, value);
        } else {
          this.dataCommService.callbackForSharelink = false;
          this.dataCommService.touchPoint = Constants.touchPoints.previewHeader;
          this.dataCommService.showRentalPaymentFlow = true;
          this.dataCommService.callbackForDownLoadLink.filetype = value;
          this.dataCommService.callbackForDownLoadLink.isDownload = true;
          this.dataCommService.selectedSectionIndex = 0;
        }        
    }

    areImagesDownloaded() {
        // console.log(this.dataCommService.featureListMobile.findIndex(feature => {
        //     return feature.image_downloaded === false;
        // }));
        return this.dataCommService.featureListMobile.findIndex(feature => {
            return feature.image_downloaded === false;
        }) === -1;
    }
    segmentData() {
        this.dataCommService.trackEvent('edit_viewed', {
            user_id: this.dataCommService.userUniqueid,
            user_browser: this.dataCommService.userBrowser,
            user_device: this.dataCommService.userDevice
        });
    }
    showCustomPrototypePopupWindow(prototypeFlag) {
        this.prototypeFlag = prototypeFlag;
        this.dataCommService.openTailorDropDown = false;
        this.showCustomPrototype.emit(prototypeFlag);
    }
    ngOnDestroy() {
        this.subs.forEach((sub: Subscription) => {
            sub.unsubscribe();
        });
    }
    showUpgradeButton() {
        if ((this.dataCommService.isOwner || this.dataCommService.isGuestUser)
            && !this.dataCommService.customPrototypePaid
            && !this.dataCommService.buildcardPaid) {
                return true;
            }
        return false;
    }
    openDropDown(value , index) {
        this.clickedPlatformIndex = index;
        this.platformName = value.title;
        if (value.comingSoon) {
            this.toasterService.success('Coming Soon');
            this.previousIndex = null;
            this.clickedPlatformIndex = -1;
            this.openDropDownBoolean = false;
        } else {
            if (this.previousIndex != index) {
                this.previousIndex = index;
                this.selectedValues = value;
                this.openDropDownBoolean = true;
                this.resetSeleted();
                value.selected = true;
            } else {
                this.previousIndex = null;
                this.openDropDownBoolean = false;
                this.clickedPlatformIndex = -1;
            }
        }
   }
   resetSeleted(){
     this.dataCommService.headerloop.forEach(element => {
         element.selected = false;
      });
   }

   resetInner(){
    this.dataCommService.headerloop.forEach(element => {
        const values = element.values;
        values.forEach(element => {
            element.selected = false;
        });
    });
   }

   changeRoute(value) {
       if (!value.comingSoon) {
           value.selected = true;
           this.dataCommService.platform = value.device;
           this.dataCommService.platformType = value.title.replace(' ', '-').toLowerCase();
           if (this.dataCommService.platform && this.dataCommService.platformType) {
                this.dataCommService.trackEvent('device_selected', {
                    user_id: this.dataCommService.userUniqueid,
                    'device_selected': this.dataCommService.platform,
                    'OS_selected': this.dataCommService.platformType
                });
               let redirectUrl = '';
               const dcs = this.dataCommService;
               this.dataCommService.platformComingSoon = false;
               if (this.router.url.includes('preview')) {
                   if (this.authService.cutsotmPrototypeVersion) {
                     redirectUrl = `${dcs.buildcardId}/${this.authService.cutsotmPrototypeVersion}/preview/${dcs.platform}/${dcs.platformType}`;
                   } else {
                    redirectUrl = `${dcs.buildcardId}/preview/${dcs.platform}/${dcs.platformType}`;
                   }
                   if (this.dataCommService.featureUniqCode) {
                    redirectUrl += '/' + this.dataCommService.featureUniqCode;
                   }
                   window.location.href = redirectUrl;
               } else {
                   if (this.authService.cutsotmPrototypeVersion) {
                       window.location.href = `${dcs.buildcardId}/${this.authService.cutsotmPrototypeVersion}/dashboard/${dcs.platform}/${dcs.platformType}/flowchart`;
                   } else {
                       window.location.href = `${dcs.buildcardId}/dashboard/${dcs.platform}/${dcs.platformType}/flowchart`;
                   }
               }
           }
       } else {
           this.toasterService.success('Coming Soon');
       }

   }
   toggleNotificaton(event){
        event.stopPropagation();
       this.dataCommService.openNotification = !this.dataCommService.openNotification;          
   }
    isCpe() {
        const cpeUser = this.authService.isMasterTemplateUser();
        return cpeUser;
    }
    openTailorDD(e) {
        e.stopPropagation();
       this.dataCommService.openTailorDropDown = !this.dataCommService.openTailorDropDown;
    }
}

import {Component, ViewChild, TemplateRef, ChangeDetectionStrategy, SecurityContext } from '@angular/core';

import { Shape, MousePosition, Rectangle } from '../models/shape';
import { ManualHotspotService } from '../manual-hotspot.service';
import { ClickableItemsModel } from 'src/app/main/shared/models/clickable-items.model';
import {DataCommService} from '../../../../main/shared/services/data-comm.service';

@Component({
    selector: 'app-rectangle',
    templateUrl: './rectangle-component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RectangleComponent {
  @ViewChild('shapeTemplate', {static: true}) shapeTemplate: TemplateRef<any>;
  rectangleSelected: any = '';
  public shape: Shape;
  shapeType = 'Rectangle';
  offset: MousePosition;
  isSelected = false;
  selectionPoints: MousePosition[] = [];
  deleteComponent = false;
  id: number;
  hotspotId: number = null;
  sidekickId: number = null;
  sideKickImageId: number = null;
  isSaved = false;
  isMouseDown = false;
  footerHeight = 0;
  startDrawingFlag = false;
  tempHeight: number = null;
  tempOriginY: number = null;
  uniqCode: string = null;
  hero_feature_id: number = null;
  hotspotEditable = true;
  transition = null;
  userRequested = true;
  isNewHotspotDraw = true;
  math = Math;
  clickableItems: ClickableItemsModel[] = [];
  hotspotCategoryId = null;
  target_feature_uniq_code: string;
  featureTitle = null;
  gesture: string;
  isCarousel = true;

  constructor(public rectangleService: ManualHotspotService, private dataCommService: DataCommService) {
      this.shape = new Rectangle();
  }

  setStyles() {
    let styles = {};
    if (this.isSelected) {
        styles = {
        stroke : '#0087ed',
        fill : 'rgba(0, 136, 231, 0.6)',
        'stroke-width': 3,
        width: this.shape.width,
        height: this.shape.height,
      };
    } else if (!this.hotspotEditable) {
      styles = {
        stroke : 'rgba(29, 44, 65, 0.6)',
        fill : 'rgba(29, 44, 65, 0.4)',
        'stroke-width': 3,
        width: this.shape.width,
        height: this.shape.height,
      };
    } else {
        styles = {
        stroke : '#0087ed',
        fill : 'rgba(0, 136, 231, 0.3)',
        'stroke-width': 3,
        width: this.shape.width,
        height: this.shape.height,
      };
    }
    return styles;
  }
  calculatePosition(index, axis) {
    const col = this.math.floor((this.shape.width - 6) / 40);
    const row = this.math.floor((this.shape.height - 6) / 40);

    if (axis === 'moreX') {
      return this.shape.originX + 3 + ((col - 1 ) * 40);
    } else if (axis === 'moreY') {
        return this.shape.originY + 3 + ((row - 1 ) * 40);
    }

    if (this.hotspotCategoryId === null || this.hotspotCategoryId === 5 || this.hotspotCategoryId === 6) {
      return {display : 'none'};
    } else if (!(col * row === index)) {
      if (axis === 'x') {
        return {pos: this.shape.originX + 3 + (index % col) * 40};
      } else if ( axis === 'y') {
        return {pos: this.shape.originY + 3 + ( this.math.floor(index / col)) * 40};
      }
    } else {
      return {pos: 0, display: 'none'};
    }
    if (index > col * row - 1) {
      return {display: 'none'};
    }
  }

  showMoreIcon() {
    const col = this.math.floor(this.shape.width / 40);
    const row = this.math.floor(this.shape.height / 40);
    if (this.clickableItems.length > 1) {
      if (row * col >= this.clickableItems.length) {
        return {display: 'none'};
      } else {
        return {display: 'block'};
      }
    } else {
      return {display: 'none'};
    }
  }


  startDrawing(beginPosition: MousePosition): void {
    if (this.shape instanceof Rectangle) {
      this.startDrawingFlag = true;
      this.shape.originX = beginPosition.x;
      this.shape.originY = beginPosition.y;
    }
  }

  draw(currentPosition: MousePosition, styleObj: any): void {
    if (this.shape instanceof Rectangle) {
      let currentSize = styleObj;
      if ((this.shape.width + this.shape.originX) <= currentSize.width
      && (this.shape.height + this.shape.originY) <= (currentSize.height - this.footerHeight)) {
        this.shape.width = Math.abs(currentPosition.x - this.shape.originX);
        this.shape.height = Math.abs(currentPosition.y - this.shape.originY);
      }
      this.calculateCoordinatesinPercent(currentSize);
    }
  }
  drag(draqPosition: MousePosition, styleObj: any): void {
    if (this.offset === undefined) {
      this.offset = Object.assign({}, draqPosition);
      this.offset.x -= this.shape.originX;
      this.offset.y -= this.shape.originY;
    }
    /**
     * set dragPosX and offsetX as 0, to now allow user
     * to drag hotspot outside on left side
     */
    let dragPosX = draqPosition.x;
    let offsetX = this.offset.x;
    if ((draqPosition.x - this.offset.x) <= 0) {
      dragPosX = 0;
      offsetX = 0;
    }
    /**
     * set dragPosY and offsetY as 0, to now allow user
     * to drag hotspot outside on top side
     */
    let dragPosY = draqPosition.y;
    let offsetY = this.offset.y;
    if ((draqPosition.y - this.offset.y) <= 0) {
      dragPosY = 0;
      offsetY = 0;
    }
    let currentSize = styleObj;
    if (((dragPosY - offsetY) >= 0) && ((dragPosX - offsetX) >= 0)
    && ((((dragPosY - offsetY) + this.shape.height)) <= currentSize.height - this.footerHeight)
    && ((((dragPosX - offsetX) + this.shape.width)) <= currentSize.width)) {
      this.shape.originX = (dragPosX - offsetX);
      this.shape.originY = (dragPosY - offsetY);
    }
    this.calculateCoordinatesinPercent(currentSize);
  }
  resizeShape(event: any, resizePosition: MousePosition, styleObj: any) {
    if (this.offset === undefined) {
      this.offset = Object.assign({}, resizePosition);
      this.offset.x -= this.shape.originX;
      this.offset.y -= this.shape.originY;
    }
    let currentSize = styleObj;
    if ((((resizePosition.x - this.shape.originX) + this.shape.originX) <= currentSize.width
    && (((resizePosition.y - this.shape.originY) + this.shape.originY)) <= (currentSize.height - this.footerHeight) )
    && (resizePosition.x - this.shape.originX >= 48) && (resizePosition.y - this.shape.originY >= 48)) {
      this.shape.width = resizePosition.x - this.shape.originX;
      this.shape.height = resizePosition.y - this.shape.originY;
    }
    this.calculateCoordinatesinPercent(currentSize);
  }

  calculateCoordinatesinPercent(currentSize) {
    this.shape.percent_originX = (this.shape.originX/currentSize.width) * 100;
    this.shape.percent_originY = (this.shape.originY/currentSize.height) * 100;
    this.shape.percent_width = (this.shape.width/currentSize.width) * 100;
    this.shape.percent_height = (this.shape.height/currentSize.height) * 100;
  }
  
  rectangleShapeSelect(rect): void {
    this.rectangleSelected = rect;
  }

  setPoint(point: MousePosition): void {
  }

  highlightSelectedSpot(clickableItemId) {
    if (this.rectangleService.getClickableItem() && this.rectangleService.getClickableItem().id === clickableItemId) {
      return {
        stroke : 'black',
        'stroke-width': 3
      };
    }
  }
  trackById(index: number, item: any) {
    return item.feature_id;

  }
  calculateImageSize(shape: Shape) {
    if (shape.width > 60) {
      return {
        width : shape.width,
        height: shape.height,
        x: 0,
        y: 0
      };
    } else {
      const ratio = 60 + ((shape.width - 40) * 2);
      return {
        width : (shape.width * ratio) / 100,
        height: (shape.height * ratio) / 100,
        x: (shape.width * ((100 - ratio) / 2)) / 100,
        y: (shape.height * ((100 - ratio) / 2)) / 100
      };
    }
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from '@core/cookie.service';
import { UtilityService } from '@shared/services/utility.service';
import { DataCommService } from 'src/app/main/shared/services/data-comm.service';

@Component({
  selector: 'app-link-share',
  templateUrl: './link-share.component.html',
  styleUrls: ['./link-share.component.scss']
})
export class LinkShareComponent {

  shareUrl = '';
  urlCopied = false;
  platform;
  platformType;

  constructor(
    private utilitySerice: UtilityService,
    private dataCommService: DataCommService,
    private cookieService: CookieService,
    private router: Router
  ) {
    this.platform = this.dataCommService.platform;
    this.platformType = this.dataCommService.platformType
  }

  ngOnInit() {
    this.shareUrl = this.dataCommService.sharingUrl + `/${this.platform}` + `/${this.platformType}`;
    if (this.cookieService.getLocalStorage("airIndiaShare") === 'yes') {
      this.shareUrl += "?share=1";
    }
  }

  copyUrl(inputElement) {
    let eventName = this.router.url.includes('playmode') ? 'play_mode_shared' : 'prototype_shared';
    this.dataCommService.trackEvent(eventName, {
      user_id: this.dataCommService.userUniqueid,
      user_browser: this.dataCommService.userBrowser,
      user_device: this.dataCommService.userDevice,
      'Share type': 'link',
      'Password protected?': this.dataCommService.isPasswordProtected ? 'Y' : 'N',
      'Social channel': ''
    });
    inputElement.disabled = false;
    this.utilitySerice.copyText(inputElement);
    inputElement.disabled = true;
    this.urlCopied = true;
    setTimeout( () => {
      this.urlCopied = false;
    }, 1500);

  }

}

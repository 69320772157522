import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HammerGestureConfig } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { Page404Component } from './page404/page404.component';
import { EmptyboardComponent } from './emptyboard/emptyboard.component';
import { FileSaverModule } from 'ngx-filesaver';
import {Angulartics2Module} from 'angulartics2';
import {Angulartics2Segment} from 'angulartics2/segment';
import {environment} from '@env/environment';
import { WipComponent } from './wip/wip.component';
import {AuthInterceptor} from '@core/auth-interceptor';
import {TermsComponent} from './terms/terms.component';
import { IntercomModule} from "ng-intercom";
import { GlobalErrorHandler } from 'src/GlobalErrorHandler';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { Router } from "@angular/router";

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (environment.enableSentry) {
      const eventId = Sentry.captureException(error.originalError || error);
      //   Sentry.showReportDialog({ eventId });
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    Page404Component,
    EmptyboardComponent,
    WipComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HammerModule,
    SharedModule,
    AppRoutingModule,
    SocialLoginModule,
    FileSaverModule,
    IntercomModule.forRoot({
      appId: environment.INTERCOM_APP_ID, // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    Angulartics2Module.forRoot([Angulartics2Segment])
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },   
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('399522936347-01vsdt9ffp5kvf4bm1c05g0ut0m7homi.apps.googleusercontent.com')
        },
        {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1889590484589531')
        }
        ]
      } as SocialAuthServiceConfig,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    if (window.location.href.indexOf('localhost') === -1) {
      Sentry.init({
        dsn: "https://1bc3243860b24abc996eed55100306c8@o1107930.ingest.sentry.io/6379890",
        integrations: [
          new BrowserTracing({
            tracingOrigins: ["localhost", "https://yourserver.io/api"],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
      
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataCommService} from '../../../main/shared/services/data-comm.service';
import { AuthService } from '@core/auth.service';

@Component({
  selector: 'app-feature-full-screen',
  templateUrl: './feature-full-screen.component.html',
  styleUrls: ['./feature-full-screen.component.scss']
})
export class FeatureFullScreenComponent implements OnInit {
  @Input() screenDetail;
  // @Input() isClient;
  @Output() closeScreen = new EventEmitter();
  @Output() screenApproved = new EventEmitter();
  @Output() screenRejected = new EventEmitter();
  modal;
  constructor(public dataCommService: DataCommService , private authService: AuthService) {
  }

  ngOnInit() {
  }

  screenAcceptHandler($event) {
    this.screenApproved.emit($event);
  }

  screenRejectHandler($event) {
    this.screenRejected.emit($event);
  }

  closeFullScreen() {
    this.closeScreen.emit();
  }

  getDetailType() {
    if (this.authService.userProfile.isClient) {
      return 'fullScreenClient';
    } else if (this.authService.userProfile.isDesigner) {
      return 'fullScreenDesigner';
    }
  }
}

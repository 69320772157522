import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({name: 'timeLabel'})
export class TimeLabelPipe implements PipeTransform {
  transform(timestamp: string, showTime?: boolean): string {
    const UTC = moment.utc(timestamp, 'YYYY/MM/DD HH:mm:ss Z');
    const local = UTC.local();
    const b = local.format();
    const clocal = moment();
    const now  = clocal.format();
    const ms = moment(now).diff(moment(b));
    // const d = moment.duration(ms, 'seconds');
    let seconds: any;
    seconds = Math.floor(( ms) / 1000);
    let timeFormat = '';
    if (showTime === true) {
        timeFormat = ' ' + local.format('H: m');
    }
    let interval;
    if (seconds < 60) {
        return 'Just Now';
    }
    if (seconds > 60 && seconds < 3600) {
        interval = Math.floor(seconds / 60);
        return interval + (interval === 1 ? ' min ago' : ' mins ago');
    }
    if (seconds > 3600 && seconds < 7200) {
        return 'an hour ago';
    }
    if (seconds > 7200 && seconds < 86400) {
        return 'Today' + timeFormat;
    }
    if (seconds > 86400 && seconds < 172800) {
        return 'Yesterday' + timeFormat;
    }
    const dateTime =  local.format('d MMM YYYY');
    return dateTime + timeFormat;
  }
}

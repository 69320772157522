import { Component, Input, OnChanges, OnDestroy, OnInit, SecurityContext, HostListener, ViewChild } from '@angular/core';
import { DataCommService } from '../../../main/shared/services/data-comm.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadService } from '@shared/services/file-upload.service';
import { DataService } from '../../../main/shared/services/data.service';
import { Router } from '@angular/router';
import { ManualHotspotService } from '../manual-hotspot/manual-hotspot.service';
import { Subscription } from 'rxjs';
import { OverlayWithContentService } from '../overlay-with-content/overlay-with-content.service';
import { Constants } from '@shared/services/constants';
import { CookieService } from '@core/cookie.service';
import { environment } from '@env/environment';
import { CroppieOptions } from 'croppie';
import { NgxCroppieComponent } from '@shared/modules/ngx-croppie/ngx-croppie.component';
import { UtilityService } from '@shared/services/utility.service';
import { AuthService } from '@core/auth.service';
@Component({
  selector: 'app-logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.scss']
})
export class LogoUploadComponent implements OnInit, OnDestroy, OnChanges {
  @Input() logoData;
  @Input() frameData;
  public uploadedLogo = null;
  public showError = false;
  public btnDisable = true;
  public uploadDone = true;
  public uploadIcon = '../../../../assets/images/upload-gray.png';
  public logoMsgObj: any = '';
  public showLogoPopup = true;
  public showPreviewScreen = false;
  public subscription: Subscription;
  @ViewChild('ngxCroppie', { static: true }) ngxCroppie: NgxCroppieComponent;
  public widthPx = '485';
  public heightPx = '200';
  public viewPortwidth = 320;
  public viewPortheight = 124;
  public imageUrl = '';
  public currentImage: string;
  public croppieImage: any;
  public editedImage: any;
  public subs: Subscription[] = [];
  public carouselPage: boolean = false;
  public unlimitedFlag: boolean = false;
  public videoHalfViewed: boolean = false;
  constructor(public dataCommService: DataCommService,
    public sanitizer: DomSanitizer,
    public fileUploadService: FileUploadService,
    public dataService: DataService,
    private router: Router,
    private rectangleService: ManualHotspotService,
    private overlayWithContentService: OverlayWithContentService,
    private cookieService: CookieService,
    private authService: AuthService,
    public utilityService: UtilityService) {
    this.subs.push(this.dataCommService.processLogo$.subscribe((data) => {
      this.processLogoFile(data);
      this.dataCommService.showLogoPopup = true;
    }));
  }
  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    event.stopPropagation();
  }

  public get imageToDisplay() {
    if (this.currentImage) { return this.currentImage; }
    if (this.imageUrl) { return this.imageUrl; }
    return `http://placehold.it/${this.widthPx}x${this.heightPx}`;
  }

  public get croppieOptions(): CroppieOptions {
    if (this.dataCommService.popupScreenOne) {
      this.widthPx = '350';
      this.heightPx = '174';
      this.viewPortwidth = 320;
      this.viewPortheight = 135;
    }
    const opts: CroppieOptions = {};
    opts.viewport = {
      width: this.viewPortwidth,
      height: this.viewPortheight
    };
    opts.boundary = {
      width: parseInt(this.widthPx, 10),
      height: parseInt(this.heightPx, 10)
    };
    opts.enforceBoundary = true;
    opts.customClass = "croppie-custom";
    opts.enableExif = true;
    return opts;
  }

  ngOnInit() {
    this.currentImage = this.imageUrl;
    this.croppieImage = this.imageUrl;
    if (this.router.url.includes('add-edit-carousel')) {
      this.logoMsgObj = Constants.addCarouselData;
      this.carouselPage = true;
    } else {
      this.logoMsgObj = Constants.addLogoPopupData;
      this.carouselPage = false;
    }
    if (this.router.url.includes('preview')) {
      this.showPreviewScreen = true;
    }
  }

  ngOnChanges(): void {
    this.logUploadHandler();
  }

  logoPopup() {
    this.showLogoPopup = true;
    /*if (this.router.url.includes('add-edit-carousel')) {
      this.showLogoPopup = false;
    } else {
      this.showLogoPopup = true;
    }*/
  }

  processLogoFile(event) {
    if (this.carouselPage) {
      this.fileUploadService.validateSelectedFiles(event.target.files, '', 'carousel');
    } else {
      this.fileUploadService.validateSelectedFiles(event.target.files, '', 'logo');
    }
    //this.logUploadHandler();
  }

  logUploadHandler() {
    if (this.logoData) {
      if (this.logoData.file.name) {
        this.uploadedLogo = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.logoData.file));
        this.btnDisable = false;
        this.showError = false;
        const fr = new FileReader();
        fr.onloadend = (loadEvent) => {
          this.croppieImage = fr.result;
          this.editedImage = fr.result;
        };
        fr.readAsDataURL(this.logoData.file);
      } else {
        this.btnDisable = true;
        this.showError = true;
        this.logoData = null;
        this.uploadedLogo = null;
        this.editedImage = null;
      }
    }
    this.logoPopup();
  }

  closeLogoSlider() {
    this.dataCommService.showLogoPopup = false;
    //this.deleteLogo();
  }

  deleteLogo() {
    this.logoData = null;
    this.uploadedLogo = null;
    this.btnDisable = true;
    this.showError = false;
    this.editedImage = null;
  }

  uploadLogo(flag) {
    this.uploadDone = false;
    this.btnDisable = true;
    if (this.carouselPage) {
      this.uploadDone = true;
      this.dataCommService.showLogoPopup = false;
      let fileName = Math.random().toString(36).substring(7);
      const fileLogo = this.dataURLtoFile(this.editedImage, fileName + '.png');
      this.rectangleService.getRectangleComponent().shape.imgUrl = this.editedImage;
      this.rectangleService.getRectangleComponent().clickableItems.push({
        build_card_hotspot_id: null,
        id: null,
        image: this.editedImage,
        image_attributes: {
          width: this.frameData.carouselWidth,
          height:this.frameData.carouselHeight
        },
        target_feature_uniq_code: null,
        transition: null,
        user_created: null,
        encodedImageUrl: null,
        clickableItemId: null,
        feature_id: null,
        suggestionTooltip: null,
        gesture: null,
        imageData: fileLogo
      });
      this.dataCommService.carouselData = this.rectangleService.getRectangleComponent().clickableItems;
      this.overlayWithContentService.enableSaveButtonFun(false);
      this.uploadedLogo = null;
    } else {
      // BN_modal upload logo clicked
      this.uploadDone = true;
      this.uploadedLogo = null;
      this.dataCommService.showLogoPopup = false;
      this.dataCommService.findLogoTypeFeatureSource.next(true);

      if (!flag) {
        this.updateFtueScreenData('one');
      }
      setTimeout(() => {
        this.showLogoAnimation();
      }, 2000);
    }
  }

  showLogoAnimation() {
    //Logo Upload form data 
    let fileName = Math.random().toString(36).substring(7);
    const fileLogo = this.dataURLtoFile(this.editedImage, fileName + '.png');
    const formdata = new FormData();
    formdata.append(`brand_logo`, fileLogo);
    formdata.append(`device`, this.dataCommService.platform);
    formdata.append(`platform`, this.dataCommService.platformType);
    formdata.append(`version`, this.dataCommService.featureVersion);

    this.dataService.uploadLogo(formdata, this.dataCommService.nowBuildCardId).subscribe((res: any) => {
      this.dataCommService.undoRedoToastFun(res);
      this.dataCommService.trackEvent('logo_added', {
        user_id: this.dataCommService.userUniqueid,
        user_browser: this.dataCommService.userBrowser,
        user_device: this.dataCommService.userDevice
      });
      this.dataCommService.logoUpdateFun(res);
    }, err => {
    });
  }

  setFTUEFlag() {
    const userLocalData = this.cookieService.getLocalStorage(environment.USER_LOCAL_STORAGE);
    if (userLocalData) {
      const userData = JSON.parse(userLocalData);
      this.dataCommService.trackEvent('instant_prototype_selected', {
        'prototype_option': 'instant',
        'currency': userData.currency.name,
        user_id: this.dataCommService.userUniqueid,
        user_browser: this.dataCommService.userBrowser,
        user_device: this.dataCommService.userDevice
      });
    }
    // Version_selected
    this.dataCommService.showLogoPopup = false;
    this.updateFtueScreenData('two');
  }

  openRentalPopup(prototypeFlag) {
    this.dataCommService.prototypeInstallment = (prototypeFlag === 'professional') ? this.dataCommService.customPrototypeObj.attributes : this.dataCommService.tailorMadePrototypeObj.attributes;
    this.dataCommService.prototypeInstallment.currency = (prototypeFlag === 'professional') ? this.dataCommService.customPrototypeObj.attributes.currency.data.attributes : (this.dataCommService.tailorMadePrototypeObj.attributes.currency.data ? this.dataCommService.tailorMadePrototypeObj.attributes.currency.data.attributes : this.dataCommService.tailorMadePrototypeObj.attributes.currency);
    
    if (prototypeFlag === 'professional') {
      this.dataCommService.trackEvent('professional_prototype_confirmed', {
        user_id: this.dataCommService.userUniqueid,
        'prototype_option': 'professional',
        'currency': this.dataCommService.prototypeInstallment.currency.name
      });
    } else {
      this.dataCommService.trackEvent('tailored_prototype_confirmed', {
        user_id: this.dataCommService.userUniqueid,
        'prototype_option': 'tailored',
        'currency': this.dataCommService.prototypeInstallment.currency.name
      });
    }

    // Tailor Made
    this.dataCommService.showLogoPopup = false;
    if (this.authService.getLoggedInUser()) {
      this.updateFtueScreenData('two');
      this.dataCommService.showHidePaymentFlow(true);
      this.dataCommService.selectedSectionIndex = 2;
      this.dataCommService.isCommentSigninSignUpForm = true;
    } else {
      this.dataCommService.touchPoint = Constants.touchPoints.previewHeader;
      this.cookieService.setLocalStorage('prototypeFlag', prototypeFlag);
      this.dataCommService.selectedSectionIndex = 0;
      this.authService.countryNameCode = 'in';
      this.dataCommService.showHidePaymentFlow(true);
    }    
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  updateFtueScreenData(screen) {
    // BN_modal I'll do this later clicked //  
    const payload = {
      key: null,
      value: null
    };
    if (screen === 'one') {
      payload.key = 'ftue_step_1';
      payload.value = true;
    } else {
      payload.key = 'ftue_step_2';
      payload.value = true;
    }
    //this.dataCommService.showLogoPopup = true;
    this.subscription = this.dataService.postFtueData(this.dataCommService.buildcardId, payload).subscribe((data: any) => {
      if (data.data.ftue_step_1 === 'true' && data.data.ftue_step_2 === 'false') {
        this.dataCommService.popupScreenOne = false;
        this.dataCommService.popupScreenTwo = true;
      } else if ((data.data.ftue_step_1 === 'false' && data.data.ftue_step_2 === 'true') || (data.data.ftue_step_1 === 'true' && data.data.ftue_step_2 === 'true')) {
        this.dataCommService.popupScreenTwo = false;
        if (!this.cookieService.getLocalStorage(environment.ONBOARDING_COOKIE)) {
          this.dataCommService.onBoarding.inProgressPrototype = true;
          this.dataCommService.setOnBoardingCookie('', 'welcomeVideo');
          setTimeout(() => {
            this.dataCommService.demoVideoLoad();
          }, 2000);            
        }
      }
    });
  }

  newImageResultFromCroppie(img: string) {
    if (!this.showError) {
      this.editedImage = img;
    } else {
      this.editedImage = null;
    }
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  saveImageFromCroppie() {
    this.currentImage = this.editedImage;
  }

  imageUploadEvent(evt: any) {
    evt.stopPropagation();
    if (!evt.target) { return; }
    if (!evt.target.files) { return; }
    if (evt.target.files.length !== 1) { return; }
    const file = evt.target.files[0];
    if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif' && file.type !== 'image/jpg') { return; }
    const fr = new FileReader();
    fr.onloadend = (loadEvent) => {
      this.croppieImage = fr.result;
    };
    fr.readAsDataURL(file);
  }

  getCropPoints() {
    if (this.ngxCroppie) {
      alert('Crop points: ' + this.ngxCroppie.get().points);
    }
  }

  toggleUnlimitedVersion(eventFlag = '') {
    if (eventFlag) {
      this.dataCommService.trackEvent(eventFlag, {
        user_id: this.dataCommService.userUniqueid,
        'prototype_option': 'custom',
        'currency': this.dataCommService.tailorMadePrototypeObj.attributes.currency.data.attributes.name
      });
    }    
    this.unlimitedFlag = !this.unlimitedFlag;
  }
}
